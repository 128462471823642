import { Grid } from '@mui/material'
import { useEffect } from 'react'
import { useSColors } from 'src/app/styles/scripta-theme'
import STypography from 'src/app/system/customcomponents/STypography'
import { LOG } from 'src/app/v2/applog'
import unsub from './unsub.svg'

const getQueryParam = (param: string): string | null => {
  const search = window.location.search.substring(1)
  const params = search.split('&')
  for (let i = 0; i < params.length; i++) {
    const pair = params[i].split('=')
    if (pair[0] === param) {
      return decodeURIComponent(pair[1])
    }
  }
  return null
}

export function UnsubscribeFromNotifications() {
  const { COLORS } = useSColors()

  useEffect(() => {
    console.log('UnsubscribeFromNotifications')
    let memberIdFromUrl: string | null = null
    let clientUuidFromUrl: string | null = null
    try {
      memberIdFromUrl = getQueryParam('memberId')
      clientUuidFromUrl = getQueryParam('clientUuid')
    } catch (e) {
      console.error('Failed to get memberId or clientUuid from URL', e)
    }
    if (memberIdFromUrl && clientUuidFromUrl) {
      LOG.initMember(memberIdFromUrl, clientUuidFromUrl)
      LOG.warn('unsubscribe', 'User unsubsribed', {
        memberId: memberIdFromUrl,
        clientUuid: clientUuidFromUrl,
      })
    } else {
      LOG.warn(
        'unsubscribe',
        'User unsubsribed but no memberId or clientUuid resolved in the URL',
      )
    }
  }, [])

  return (
    <Grid
      container
      spacing={3}
      sx={{
        padding: '35px',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        display: '-ms-grid',
        msGridColumns: '1fr',
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          '-ms-grid-column': '1',
          '-ms-grid-row': '1',
        }}
      >
        <img
          src={unsub}
          alt="unsubscribed"
          width={220}
          height={160}
          style={{
            display: 'block',
            margin: '0 auto',
            maxWidth: '100%',
          }}
        />
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          '-ms-grid-column': '1',
          '-ms-grid-row': '2',
        }}
      >
        <STypography
          variant="title3_bold"
          sx={{ color: COLORS.TEXT_BRAND, textAlign: 'center' }}
        >
          Unsubscribed
        </STypography>
      </Grid>

      <Grid item xs={12}>
        <STypography variant="body_regular">
          We will miss sending you ways to save $$!
        </STypography>
      </Grid>

      <Grid item xs={12}>
        <STypography
          variant="body_regular"
          sx={{ maxWidth: '600px', margin: '0 auto' }}
        >
          You are now unsubscribed from receiving savings notifications to your
          e-mail.
        </STypography>
      </Grid>

      <Grid item xs={12}>
        <STypography variant="body_regular">
          If you change your mind and want to stay in the loop on how to save,
          you can reactivate e-mail notifications any time by logging in and
          going into your account settings.
        </STypography>
      </Grid>
    </Grid>
  )
}
