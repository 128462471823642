import { Box, Divider, Grid, Link, Typography } from '@mui/material'
import { getOS, IS_NATIVE } from '../apiexec/utils'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { usePortalAppConfig } from 'src/app/config/usePortalAppConfig'
import STypography from 'src/app/system/customcomponents/STypography'
import EmailLink from 'src/app/widgets/EmailLink'
import PhoneLink from 'src/app/widgets/PhoneLink'

export default function MemberSupportFooter({
  customFontSx,
  variant = 'default',
  additionalSingleLineText,
}: {
  customFontSx?: any
  variant?: 'default' | 'single-line'
  additionalSingleLineText?: any
}) {
  const CONFIG = usePortalAppConfig()
  const email: string = CONFIG.MEMBER_SUPPORT_EMAIL
  const phoneNumber: string = CONFIG.MEMBER_SUPPORT_NUMBER
  const isNative: boolean = IS_NATIVE()
  const isAndroid: boolean = getOS() === 'Android'

  const { t } = useSTranslate(['common', 'login'])

  if (variant === 'single-line') {
    return (
      <STypography
        variant="bodybig_regular"
        style={{
          display: 'flex',
          gap: '3px',
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}
      >
        {additionalSingleLineText}
        <span>
          E-mail{' '}
          <EmailLink shouldShowUnderline customLinkSx={{ fontWeight: 600 }} />{' '}
          or
        </span>
        <span>
          Call{' '}
          <PhoneLink shouldShowUnderline customLinkSx={{ fontWeight: 600 }} />
        </span>
      </STypography>
    )
  }

  const textSx: any = {
    fontSize: '13px',
    lineHeight: '16px',
    fontWeight: '400',
    textAlign: 'center',
    color: '#fff',
    ...customFontSx,
    // textShadow: 'black 3px 3px 10px',
  }

  return (
    <Grid
      container
      // mt={'0px'}
      // mb={'35px'}
      sx={{
        // backgroundColor: 'blue',
        // display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Grid item xs={12} sx={{ paddingLeft: '15px', paddingRight: '15px' }}>
        <Box>
          <Typography sx={textSx}>
            {t('login:needHelpFooter')}
            <Link
              href={`mailto:${email}`}
              onClick={
                isNative && isAndroid
                  ? () => {
                      window.open(`mailto:${email}`)
                    }
                  : undefined
              }
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
                color: '#fff',
                ...customFontSx,
              }}
            >
              {email}
            </Link>
          </Typography>
          <Typography sx={textSx}>
            or call us at{' '}
            <Link
              href={`tel:+${phoneNumber}`}
              onClick={
                isNative && isAndroid
                  ? () => {
                      window.open(`tel:+${phoneNumber}`)
                    }
                  : undefined
              }
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
                color: '#fff',
                ...customFontSx,
              }}
            >
              {phoneNumber}
            </Link>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}
