import {
  Grid,
  Paper,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { useCallback, useEffect, useState } from 'react'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { useNotifier } from '../hooks/useNotify'
import { useMemberAppContext } from '../MemberAppContext'
import { useMemberService } from './useMemberService'
import { useSizes } from '../v2/useNewHooks'
import SButton from '../system/customcomponents/SButton'
import STypography from '../system/customcomponents/STypography'
import { SPACING } from '../system/theme2'
import { checkMicroServiceResponseStatus } from '../apiexec/utils'
import { useAmazon } from 'src/app/hooks/useAmazon'
import { LOG } from 'src/app/v2/applog'
import { EKSMemberProfile } from 'src/app/member/types'

interface Pref {
  translationKey: string
  apiObjectKey: keyof EKSMemberProfile
  isInverse?: boolean
}

const SAVINGS_REPORT_PREFS: Pref[] = [
  {
    translationKey: 'sms',
    apiObjectKey: 'savings-report-sms-opt-out',
    isInverse: true,
  },
  {
    translationKey: 'email',
    apiObjectKey: 'savings-report-email-opt-out',
    isInverse: true,
  },
  {
    translationKey: 'mail',
    apiObjectKey: 'savings-report-mail-opt-out',
    isInverse: true,
  },
]
const MARKETING_PREFS: Pref[] = [
  {
    translationKey: 'sms',
    apiObjectKey: 'marketing-sms-opt-out',
    isInverse: true,
  },
  {
    translationKey: 'email',
    apiObjectKey: 'marketing-email-opt-out',
    isInverse: true,
  },
  {
    translationKey: 'mail',
    apiObjectKey: 'marketing-mail-opt-out',
    isInverse: true,
  },
]

const COMMUNICATION_PREF_KEYS: (keyof EKSMemberProfile)[] = [
  'savings-report-email-opt-out',
  'savings-report-mail-opt-out',
  'savings-report-sms-opt-out',
  'marketing-email-opt-out',
  'marketing-mail-opt-out',
  'marketing-sms-opt-out',
]

//this is a work in progress. i think there are some bugs in backend api where its not saving correct keys
//also need to check rest of the system behavior i.e. if these are respected
export function CommunicationPreferences({
  variant,
}: {
  variant: 'saving_report' | 'marketing'
}) {
  const { t } = useSTranslate('settings')

  const { sendMsg } = useNotifier()
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  const { currentMemberProfile, history } = useMemberAppContext()

  const { isAmazon } = useAmazon()

  const [isDisabled, setIsDisabled] = useState(false)

  const memberService = useMemberService()
  const { isDesktop } = useSizes()

  const [prefs, setPrefs] = useState<EKSMemberProfile | undefined>()

  useEffect(() => {
    if (currentMemberProfile?.id) {
      fetchPref(currentMemberProfile.id)
    }
  }, [currentMemberProfile])

  async function fetchPref(memberId: number) {
    try {
      setIsDisabled(true)

      const resp = await memberService.getMemberProfile_EKS(memberId)
      setIsDisabled(false)

      const respCheck = checkMicroServiceResponseStatus(resp)
      if (!respCheck.isError) {
        setPrefs(resp)
      } else {
        LOG.error(
          'member_profile',
          'Error response returned fetching member prefrenes',
        )
      }
    } catch (error) {
      setIsDisabled(false)
      throw Error('Error in fetch pref api exec')
    }
  }

  async function updatePref(
    memberId: number,
    prefs: EKSMemberProfile,
    variant: 'saving_report' | 'marketing',
    //adding new flag just to always hide printed mail for amazon users just in case
    isAmazon: boolean,
  ) {
    try {
      setIsDisabled(true)

      let keysToUpdate: (keyof EKSMemberProfile)[] = []
      if (variant === 'saving_report') {
        keysToUpdate = COMMUNICATION_PREF_KEYS.filter((key) =>
          SAVINGS_REPORT_PREFS.some((pref) => pref.apiObjectKey === key),
        )
      } else if (variant === 'marketing') {
        keysToUpdate = COMMUNICATION_PREF_KEYS.filter((key) =>
          MARKETING_PREFS.some((pref) => pref.apiObjectKey === key),
        )
      }

      const values = keysToUpdate.map((key) => prefs[key])
      //build up the payload
      const payload: Partial<EKSMemberProfile> = {}

      keysToUpdate.forEach((key, index) => {
        ;(payload[key as keyof EKSMemberProfile] as any) = values[index]
      })

      const resp = await memberService.updateMemberProfile_EKS(
        memberId,
        payload,
      )
      setIsDisabled(false)
      const respStatus = checkMicroServiceResponseStatus(resp, true)

      if (respStatus.isError) {
        // history.push(REG_ROUTE_SERVER_ERROR)
        LOG.error(
          'member_profile',
          'Failed to update member preferences, eror response =',
          resp,
        )
        sendMsg(t('saveComPrefFailed'), 'error')
        return
      }

      sendMsg(t('saveComPrefSuccess'), 'success')
    } catch (error) {
      console.error('error in update prefs=', error)
      LOG.error(
        'member_profile',
        'Exception cought trying to update member preferences, eror  =',
        error,
      )
      setIsDisabled(false)
      // throw Error('Error in update pref api exec')
    }
  }

  function handleSavingsReportPrefChange(e: any) {
    if (prefs) {
      setPrefs({
        ...prefs,
        [e.target.name]: !e.target.checked,
      })
    }
  }

  function handleMarketingPrefChange(e: any) {
    if (prefs) {
      setPrefs({
        ...prefs,
        [e.target.name]: !e.target.checked,
      })
    }
  }

  const handleUpdateButtonClick = useCallback(
    (variant: 'saving_report' | 'marketing') => {
      if (currentMemberProfile?.id && prefs) {
        if (isAmazon) {
          prefs['savings-report-mail-opt-out'] = true
        }
        updatePref(currentMemberProfile.id, prefs, variant, isAmazon)
      }
    },
    [currentMemberProfile, prefs, isAmazon],
  )

  return (
    <Grid
      container
      spacing={1}
      justifyContent="center"
      sx={{ marginTop: SPACING._1 }}
    >
      <Grid item xs={12} container>
        <Box sx={{ marginBottom: 2 }}>
          <STypography variant="bodybig_bold">
            {t('genericPrefTitle')}
          </STypography>
        </Box>
      </Grid>
      <Grid item xs={12} container>
        <Box sx={{ marginBottom: 2 }}>
          <STypography variant="body_bold">
            {variant === 'saving_report'
              ? t('savingReportPref')
              : t('communicationPref')}
          </STypography>
        </Box>
        {prefs ? (
          <>
            <Grid item xs={12}>
              <STypography
                variant="body_regular"
                sx={{ marginBottom: SPACING._1 }}
              >
                {variant === 'saving_report'
                  ? t('sendMeSavingsReportInfo')
                  : t('sendMeInfoAboutScripta')}
              </STypography>
              {variant === 'saving_report' && (
                <FormGroup sx={{ paddingLeft: 2 }}>
                  {SAVINGS_REPORT_PREFS.filter((preference) => {
                    if (!isAmazon) {
                      return true // Show all preferences for non-Amazon users
                    }
                    return (
                      preference.apiObjectKey !== 'savings-report-mail-opt-out'
                    ) // Hide mail preference for Amazon users
                  }).map(
                    (pref) =>
                      prefs[pref.apiObjectKey] !== undefined && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              key={pref.apiObjectKey}
                              name={pref.apiObjectKey}
                              checked={
                                (pref.isInverse
                                  ? !prefs[pref.apiObjectKey]
                                  : prefs[pref.apiObjectKey]) as boolean
                              }
                              disabled={isDisabled}
                              onChange={handleSavingsReportPrefChange}
                            />
                          }
                          label={t(pref.translationKey)}
                        />
                      ),
                  )}
                </FormGroup>
              )}
            </Grid>

            <Grid item xs={12}>
              {variant === 'marketing' && (
                <FormGroup sx={{ paddingLeft: 2 }}>
                  {MARKETING_PREFS.map(
                    (pref) =>
                      prefs[pref.apiObjectKey] !== undefined && (
                        <FormControlLabel
                          key={pref.apiObjectKey}
                          control={
                            <Checkbox
                              key={pref.apiObjectKey}
                              name={pref.apiObjectKey}
                              checked={
                                (pref.isInverse
                                  ? !prefs[pref.apiObjectKey]
                                  : prefs[pref.apiObjectKey]) as boolean
                              }
                              disabled={isDisabled}
                              onChange={handleMarketingPrefChange}
                            />
                          }
                          label={t(pref.translationKey)}
                        />
                      ),
                  )}
                </FormGroup>
              )}
            </Grid>
            <Grid item xs={12} sx={{ marginTop: 2.5 }}>
              <Grid item container justifyContent="center">
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                  <SButton
                    fullWidth
                    disabled={isDisabled}
                    onClick={() => {
                      handleUpdateButtonClick(variant)
                    }}
                    sx={isDesktop ? { maxWidth: '300px' } : undefined}
                    noCaps
                  >
                    {t('updatePref')}
                  </SButton>
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid item container justifyContent="center" alignItems={'center'}>
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}
