import i18next from 'i18next'

import { initReactI18next } from 'react-i18next'

//this is what will server from /public/locales folder
import Backend from 'i18next-http-backend'
import { IS_PROD } from './app/apiexec/utils'
//import directly so we can use it immediatelly
import loginResourcesEn from 'src/app/bootstrap/en/login.json'
import registerResourcesEn from 'src/app/bootstrap/en/register.json'
import { LOG } from 'src/app/v2/applog'
// Add import for LOG

const i18n = i18next.createInstance()

i18n
  .use(Backend)

  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: IS_PROD() ? false : false,
    // debug: true,
    fallbackLng: 'en',
    defaultNS: 'login',
    partialBundledLanguages: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      useSuspense: false, // Disable suspense to prevent UI from showing a loading indicator
    },
    // Add login resources for immediate availability
    resources: {
      en: {
        login: loginResourcesEn,
        register: registerResourcesEn,
      },
    },
    // Enable loading of other resources
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    saveMissing: true,
    saveMissingPlurals: false, // This ensures each key is reported only once

    // Add missing key handler
    missingKeyHandler: (lng, ns, key, fallbackValue) => {
      LOG.error(
        'translations',
        `Missing translation key: ${key} in namespace: ${ns} for language: ${lng}`,
      )
    },
  })

export default i18n
