import CloseIcon from '@mui/icons-material/Close'
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  Stepper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

import { useCallback, useState } from 'react'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { useMemberAppContext } from '../MemberAppContext'
import { toDisplayFriendlyString } from '../member/phone-field-utils'

import { useMemberService } from '../member/useMemberService'
import { AuthorizationFormTitle } from './AuthorizationFormTitle'
import { IncompleteReminder } from './IncompleteReminder'
import { IntroScreen } from './IntroScreen'
import { LegalReleaseForm } from './LegalReleaseForm'

import { PrefContactMethod } from './PrefContactMethod'

import {
  STEP_INTRO,
  STEP_LEGAL_RELEASE,
  STEP_PATIENT_DETAILS,
  STEP_PHARMACY_DETAILS,
  STEP_PREF_CONTACT_METHOD,
  STEP_PRESCRIBER_DETAILS,
  SwitchData,
  UiFormData,
  ValidationResult,
  isFullyValid,
  toBackendPayload,
  validateUiData,
} from './types'
import { PrescriberDetails } from './PrescriberDetails'
import { PharmacyDetails } from './PharmacyDetails'
import { PatientDetails } from './PatientDetails'
import { checkMicroServiceResponseStatus } from '../apiexec/utils'
import { StepperDots } from './StepperDots'
import SButton from '../system/customcomponents/SButton'

export const useRedBorderMobile = makeStyles((theme: Theme) => {
  return createStyles({
    dialogPaper: {
      borderRadius: '20px',
      borderLeft: '20px solid #6D0B91',
      // minHeight: '80vh',
      padding: '0px',
    },
  })
})
export const useRedBorderDesktop = makeStyles((theme: Theme) => {
  return createStyles({
    dialogPaper: {
      borderRadius: '20px',
      borderLeft: '250px solid #6D0B91',
    },
  })
})

export const useRegularDialogStyle = makeStyles((theme: Theme) => {
  return createStyles({
    dialogPaper: {
      borderRadius: '20px',
      // minHeight: '80vh',
    },
  })
})
export const MOBILE_DIALOG_SX = {
  minHeight: '50vh',
  padding: '15px',
}
export const DESKTOP_DIALOG_SX = {
  // minHeight: '50vh',
  padding: '0px 20px 0px 20px',
}
export function Wizard({
  switchData,
  onCanceled,
  onSubmittedOk,
  onFailedSubmission,
}: {
  switchData: SwitchData
  onCanceled: () => void
  onSubmittedOk: () => void
  onFailedSubmission: () => void
}) {
  const memberService = useMemberService()
  const theme = useTheme()
  // const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const isSmallDisplay = !isDesktop

  const { t } = useSTranslate('contactMyPrescriber')

  const redBorderMobile = useRedBorderMobile()
  const redBorderDesktop = useRedBorderDesktop()
  const regularBorder = useRegularDialogStyle()

  const [wizardOpen, setWizardOpen] = useState(true)
  // const [activeStep, setActiveStep] = useState(STEP_PATIENT_DETAILS)
  const [activeStep, setActiveStep] = useState(STEP_INTRO)

  const [renderIncompleteReminder, setRenderIncompleteReminder] =
    useState(false)
  const [completedAll, setCompletedOk] = useState(false)
  const [promptedToConfirmExitExitOnce, setPromptedToConfirmExitExitOnce] =
    useState(false)

  const [isContinueDisabled, setIsContinueDisabled] = useState(false)

  const { currentMemberProfile } = useMemberAppContext()
  const [acceptedPrimary, setAcceptedPrimary] = useState(false)
  const [acceptedSecondary, setAcceptedSecondary] = useState(false)

  //when in desktop, dont trigger individual component validation at least untill first submit
  const [enableDesktopValidation, setEnableDesktopValidation] =
    useState<boolean>(false)

  const [uiData, setUiData] = useState<UiFormData>({
    fullName:
      currentMemberProfile?.['first-name'] +
      ' ' +
      currentMemberProfile?.['last-name'],
    email: currentMemberProfile?.email,
    phone: toDisplayFriendlyString(currentMemberProfile?.phone),
    dob: '',
    emailOk: true,
    phoneOk: false,
    prescriberName: '',
    prescriberPhone: '',
    pharmacyName: '',
    pharmacyAddress: '',
    pharmacyPhone: '',
    pharmacyZip: '',
  })

  const [validationResult, setValidationResult] = useState<ValidationResult>({
    validDob: false,
    validEmail: false,
    validName: false,
    validPhone: false,
    validPrescriberName: false,
    validPrescriberPhone: false,
    validPharmacyName: false,
    validPharmacyAddress: false,
    validPharmacyZip: false,
    validPharmacyPhone: false,
    //default is true because we auto select email as default because we have it
    validAtLeastPhoneOrEmailOk: true,
  })

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  //simple direct cancel on first screen before they start the form
  const handleCancelWithoutPromp = () => {
    setWizardOpen(false)
    onCanceled()
  }

  const handleCloseDialog = () => {
    if (!completedAll) {
      //if they actually started with the form (pass the intro screen)
      if (activeStep > 1) {
        setRenderIncompleteReminder(true)
        setPromptedToConfirmExitExitOnce(true)
      } else {
        handleCancelWithoutPromp()
      }
    } else {
      //we prompted them before, so exit
      handleCancelWithoutPromp()
    }
  }

  let isBackButtonVisible = true
  if (isSmallDisplay) {
    if (activeStep === STEP_INTRO || activeStep === STEP_LEGAL_RELEASE) {
      isBackButtonVisible = false
    }
  } else {
    if (activeStep === STEP_INTRO || activeStep === STEP_LEGAL_RELEASE) {
      isBackButtonVisible = false
    }
  }

  const doFinalSubmission = useCallback(async () => {
    const validres = validateUiData(uiData)

    const isFullValid = isFullyValid(validres)

    if (isFullValid) {
      const finalPayload = toBackendPayload(uiData, switchData)
      try {
        const submitResp = await memberService.sendHelpMeSwitch(finalPayload)
        const statusCheck = checkMicroServiceResponseStatus(submitResp, true)
        if (!statusCheck.isError) {
          onSubmittedOk()
        } else {
          onFailedSubmission()
        }
      } catch (e) {
        onFailedSubmission()
      }
    } else {
    }
  }, [uiData, switchData])

  return (
    <Dialog
      onClose={handleCloseDialog}
      open={wizardOpen}
      maxWidth={isSmallDisplay ? 'xs' : 'md'}
      fullWidth={true}
      scroll="paper"
      sx={{ minHeight: '99vh', height: '90vh' }}
      // sx={{mi}}
      PaperProps={{
        classes: {
          root:
            activeStep === STEP_INTRO || activeStep === STEP_LEGAL_RELEASE
              ? regularBorder.dialogPaper
              : isSmallDisplay
              ? redBorderMobile.dialogPaper
              : redBorderDesktop.dialogPaper,
        },
      }}
    >
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'black',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <AuthorizationFormTitle
        currentStep={activeStep}
        isSmallDisplay={isSmallDisplay}
      />
      {activeStep === STEP_INTRO && (
        <IntroScreen
          isSmallDisplay={isSmallDisplay}
          onCancel={() => {
            handleCancelWithoutPromp()
          }}
          onContinue={() => {
            handleNext()
          }}
        />
      )}
      {activeStep === STEP_LEGAL_RELEASE && (
        <LegalReleaseForm
          patientData={uiData}
          onPatientDataChange={setUiData}
          isSmallDisplay={isSmallDisplay}
          acceptedPrimary={acceptedPrimary}
          acceptedSecondary={acceptedSecondary}
          setAcceptedPrimary={(accepted: boolean) => {
            setAcceptedPrimary(accepted)
          }}
          setAcceptedSecondary={(accepted: boolean) => {
            setAcceptedSecondary(accepted)
          }}
          onContinue={() => {
            handleNext()
          }}
          onBack={() => {
            handleBack()
          }}
        />
      )}
      {activeStep === STEP_PREF_CONTACT_METHOD && (
        <PrefContactMethod
          contactData={uiData}
          onContactDataChange={setUiData}
          isSmallDisplay={isSmallDisplay}
          onContinue={() => {
            handleNext()
          }}
          onBack={() => {
            handleBack()
          }}
          forceTriggerValidation={enableDesktopValidation}
          validationResult={validationResult}
          setValidationResult={setValidationResult}
        />
      )}
      {activeStep === STEP_PRESCRIBER_DETAILS && (
        <PrescriberDetails
          prescriberData={uiData}
          onPrescriberDataChange={setUiData}
          isSmallDisplay={isSmallDisplay}
          onContinue={() => {
            handleNext()
          }}
          onBack={() => {
            handleBack()
          }}
          forceTriggerValidation={enableDesktopValidation}
          validationResult={validationResult}
          setValidationResult={setValidationResult}
        />
      )}
      {/* for mobile design, pharmacy details is the last step, then we submit */}
      {activeStep === STEP_PHARMACY_DETAILS && (
        <PharmacyDetails
          pharmacyData={uiData}
          onPharmacyDataChange={setUiData}
          isSmallDisplay={isSmallDisplay}
          validationResult={validationResult}
          setValidationResult={setValidationResult}
          onContinue={() => {
            doFinalSubmission()
          }}
          onBack={() => {
            handleBack()
          }}
          forceTriggerValidation={enableDesktopValidation}
        />
      )}

      {activeStep === STEP_PATIENT_DETAILS && isSmallDisplay && (
        <PatientDetails
          patientData={uiData}
          onPatientDataChange={setUiData}
          isSmallDisplay={isSmallDisplay}
          onContinue={() => {
            handleNext()
          }}
          onBack={() => {
            handleBack()
          }}
          forceTriggerValidation={enableDesktopValidation}
          validationResult={validationResult}
          setValidationResult={setValidationResult}
        />
      )}
      {/* //THIS IS THE MAIN DESKTOP LAYOUT - combines all the pages to one form*/}
      {activeStep === STEP_PATIENT_DETAILS && !isSmallDisplay && (
        <>
          <Grid container justifyContent="flex-start">
            <Grid item xs={12} md={12}>
              <PatientDetails
                patientData={uiData}
                onPatientDataChange={setUiData}
                isSmallDisplay={isSmallDisplay}
                onContinue={() => {
                  handleNext()
                }}
                onBack={() => {
                  handleBack()
                }}
                forceTriggerValidation={enableDesktopValidation}
                validationResult={validationResult}
                setValidationResult={setValidationResult}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <FormRule />
            </Grid>
            <Grid item xs={12} md={12}>
              <PrefContactMethod
                contactData={uiData}
                onContactDataChange={setUiData}
                isSmallDisplay={isSmallDisplay}
                onContinue={() => {
                  handleNext()
                }}
                onBack={() => {
                  handleBack()
                }}
                forceTriggerValidation={enableDesktopValidation}
                validationResult={validationResult}
                setValidationResult={setValidationResult}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <FormRule />
            </Grid>
            <Grid item xs={12} md={12}>
              <PrescriberDetails
                prescriberData={uiData}
                onPrescriberDataChange={setUiData}
                isSmallDisplay={isSmallDisplay}
                onContinue={() => {
                  handleNext()
                }}
                onBack={() => {
                  handleBack()
                }}
                forceTriggerValidation={enableDesktopValidation}
                validationResult={validationResult}
                setValidationResult={setValidationResult}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <FormRule />
            </Grid>
            <Grid item xs={12} md={12}>
              <PharmacyDetails
                pharmacyData={uiData}
                onPharmacyDataChange={setUiData}
                isSmallDisplay={isSmallDisplay}
                onContinue={() => {
                  handleNext()
                }}
                onBack={() => {
                  handleBack()
                }}
                forceTriggerValidation={enableDesktopValidation}
                validationResult={validationResult}
                setValidationResult={setValidationResult}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <FormRule />
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography
                sx={{
                  fontSize: '0.8rem',
                  textAlign: 'center',
                  fontStyle: 'italic',
                }}
              >
                {t('submitNote')}
              </Typography>
            </Grid>
          </Grid>

          {/* these are submit actions only visible in desktop
          in mobile the submit form happens in last step (pharmacy details) */}
          <DialogActions>
            <Grid
              item
              md={12}
              container
              justifyContent="center"
              alignItems="center"
              sx={{ padding: '20px' }}
            >
              <Grid item>
                <ContinueBtn
                  useSubmitLabelForContinue={true}
                  onContinue={async () => {
                    if (!enableDesktopValidation) {
                      //this is to trigger and show any validation errors on the screen
                      setEnableDesktopValidation(true)

                      //this is to cover all forms filled up corretly on the first attempt
                      doFinalSubmission()
                    } else {
                      doFinalSubmission()
                    }
                  }}
                />
              </Grid>
            </Grid>
          </DialogActions>
        </>
      )}
      {/* stepping dots only visible in mobile display
       */}
      {isSmallDisplay && <StepperDots activeStep={activeStep} />}

      {renderIncompleteReminder && (
        <IncompleteReminder
          onExitAnyay={() => {
            setRenderIncompleteReminder(false)
            setWizardOpen(false)
          }}
          onContinueForm={() => {
            setRenderIncompleteReminder(false)
            setWizardOpen(true)
          }}
          isSmallDisplay={isSmallDisplay}
        />
      )}
    </Dialog>
  )
}

function FormRule() {
  return (
    <div
      style={{
        marginTop: '10px',
        marginBottom: '10px',
        width: '100%',
        minWidth: '100%',
        borderBottom: '2px solid #EFF2F6',
        boxShadow: 'inset 0 -1px 0 0 #AFBCC6',
      }}
    />
  )
}

export function BottomActions({
  isSmallDisplay,
  onContinue,
  useSubmitLabelForContinue,
  continueDisabled,
  onCancel,
  useCancelLabelForBack,
}: {
  isSmallDisplay: boolean
  onContinue: () => void
  useSubmitLabelForContinue?: boolean
  continueDisabled?: boolean
  onCancel: () => void
  useCancelLabelForBack?: boolean
}) {
  return (
    <DialogActions>
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        xs={12}
        spacing={1}
      >
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
        >
          <ContinueBtn
            onContinue={() => {
              onContinue()
            }}
            useSubmitLabelForContinue={useSubmitLabelForContinue}
            disabled={continueDisabled}
          />
        </Grid>
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
        >
          <CancelBtn
            onClick={() => {
              onCancel()
            }}
            useCancelLabelForBack={useCancelLabelForBack}
          />
        </Grid>
      </Grid>
    </DialogActions>
  )
}

export function CancelBtn({
  onClick,
  useCancelLabelForBack,
}: {
  onClick: () => void
  useCancelLabelForBack?: boolean
}) {
  const { t } = useSTranslate('contactMyPrescriber')

  return (
    <Grid item>
      <SButton
        noCaps
        onClick={() => {
          onClick()
        }}
        variant="outlined"
      >
        {useCancelLabelForBack ? t('btnCancel') : t('btnBack')}
      </SButton>
    </Grid>
  )
}

export function ContinueBtn({
  onContinue,
  useSubmitLabelForContinue,
  disabled,
}: {
  onContinue: () => void
  useSubmitLabelForContinue?: boolean
  disabled?: boolean
}) {
  const { t } = useSTranslate('contactMyPrescriber')
  return (
    <Grid item>
      <SButton
        noCaps
        variant="contained"
        disabled={disabled}
        onClick={() => {
          onContinue()
        }}
      >
        {useSubmitLabelForContinue ? t('btnSubmit') : t('btnContinue')}
      </SButton>
    </Grid>
  )
}
