import { ApiUrl, ModuleConfig } from '../../types'

/** Member API Begins Here */

export const authenticateMemberEndpoint: ApiUrl = {
  endpoint: '/authenticate',
  apiType: 'memberapp',
  verb: 'POST',
  /**
   * request = RequestIdentificationData
   * response = None|StatusCode
   *          = HttpHeaders.AUTHORIZATION: jwtToken
   * error: 400 / Statuc Code: 301 - Username Not Provided
   * error: 400 / Statuc Code: 302 - Password not provided
   * error: 400 / Statuc Code: 320 - Password does not match
   * error: 400 / Statuc Code: 330 - Max Bad Logins Reached Account Will Be Locked
   * error: 400 / Statuc Code: 331 - Account Locked
   * error: 400 / Status Code: 332 - No Such User
   */
}

/**
 * The following requests require that the JWTToken be submitted in the  authentication header.
 * JWT Token Error Messahes are provided below for reference. They can be returned by any
 * Api call that required JWT Token
 * #JWTERRORS#
 * error: 400 / Statuc Code: 350 - No Auth header provided
 * error: 400 / Statuc Code: 351 - JWT is missing signature
 * error: 400 / Statuc Code: 352 - Authentication has expired
 * error: 400 / Statuc Code: 353 - Malformed Token
 * error: 400 / Statuc Code: 354 - Signatures do not match
 * error: 400 / Statuc Code: 355 - Other - Error Processing Authentication Token (catch all)
 */

/**
 * #STANDARDERRORS#
 * The following error codes can be returned by any of the following requests if the token is
 * mising tenant or member id - these should never be returned if the token is returned as is:
 * error: 400 / Statuc Code: 110 - A tenant is required to process this request
 * error: 400 / Statuc Code: 111 - A member is required to process this request
 * error: 400 / Statuc Code: 112 - A member and a tenant are required to process this request
 *
 */

// export const forgotUserName: ApiUrl = {
//   endpoint: '/username/send',
//   apiType: 'memberapp',
//   verb: 'POST',
//   /**
//    * request = RegistrationSetupData (only email to provided)
//    * response = None|StatusCode
//    * error: 400 / Status Code: 226 - No Matching Email
//    * error: 400 / Status Code: 221 - No Matching Tenant
//    * error: 400 / Status Code: 225 - No Matching Member
//    */
// }

export const forgotPassword: ApiUrl = {
  endpoint: '/password/forgot/portal',
  apiType: 'memberapp',
  verb: 'POST',
  /**
   * request = PasswordResetData
   * response = PasswordResetData|StatusCode (passwordRestData will be updated by the backend to contain additional info)
   * error: 400 / Status Code: 225 - No Matching Member
   */
}
export const resetPassword: ApiUrl = {
  endpoint: '/password/set/new',
  apiType: 'memberapp',
  verb: 'POST',
  /**
   * request = PasswordResetData
   * response = StatusCode
   * error: 400 / Status Code: 225 - No Matching Member
   */
}

export const changePassword: ApiUrl = {
  endpoint: '/password/change',
  apiType: 'memberapp',
  verb: 'POST',
  /**
   * request = PasswordChangeData
   *           HttpHeaders.AUTHORIZATION: jwtToken
   * response = None|StatusCode
   * error: 400 / Status Code: 310 - Password does not meet requirements - message includes password requirements
   */
}

export const acceptMemberPolicy: ApiUrl = {
  endpoint: '/privacypolicies/member_accept',
  apiType: 'memberapp',
  verb: 'POST',
  /**
   * request = RegistrationSetupData
   * response = None|StatusCode
   * error: 400 / Status Code: 303 - User Name Not Available
   * error: 400 / Status Code: 310 - Password does not meet requirements - message includes password requirements
   */
}
export const acceptMemberTOU: ApiUrl = {
  endpoint: '/tou/member_accept',
  apiType: 'memberapp',
  verb: 'POST',
  /**
   * request = RegistrationSetupData
   * response = None|StatusCode
   * error: 400 / Status Code: 303 - User Name Not Available
   * error: 400 / Status Code: 310 - Password does not meet requirements - message includes password requirements
   */
}

export const changeSmsOptIn: ApiUrl = {
  endpoint: '/settings/smsoptin',
  apiType: 'memberapp',
  verb: 'POST',
  /**
   * request = SmsOptInChangeData
   *           HttpHeaders.AUTHORIZATION: jwtToken
   * response = None|StatusCode
   * error: 400 / Status Code: X,Y,Z - invalid responses if any?
   */
}

export const getProfile: ApiUrl = {
  endpoint: '/profile',
  apiType: 'memberapp',
  verb: 'GET',
  /**
   * request = <None>
   *           HttpHeaders.AUTHORIZATION: jwtToken
   * response = MemberProfile|StatusCode
   * error: #JWTERRORS / #STANDARDERRORS
   */
}

export const setProfile: ApiUrl = {
  endpoint: '/profile',
  apiType: 'memberapp',
  verb: 'POST',
  /**
   * request = MemberProfile
   *           HttpHeaders.AUTHORIZATION: jwtToken
   * response = MemberProfile|StatusCode
   * error: #JWTERRORS / #STANDARDERRORS
   */
}

// export const getSettings: ApiUrl = {
//   endpoint: '/settings',
//   apiType: 'memberapp',
//   verb: 'GET',
//   /**
//    * request = <None>
//    *           HttpHeaders.AUTHORIZATION: jwtToken
//    * response = AccountSettings|StatusCode
//    * error: #JWTERRORS / #STANDARDERRORS
//    */
// }

// export const setSettings: ApiUrl = {
//   endpoint: '/settings',
//   apiType: 'memberapp',
//   verb: 'POST',
//   /**
//    * request = AccountSettings
//    *           HttpHeaders.AUTHORIZATION: jwtToken
//    * response = MemberProfile|StatusCode
//    * error: #JWTERRORS / #STANDARDERRORS
//    */
// }

export const getDependents: ApiUrl = {
  // endpoint: '/get/dependents',
  endpoint: '/get/portal/dependentssec',
  apiType: 'memberapp',
  verb: 'POST',
  /**
   * request = RegistrationSetupInformation { tenantId, memberId }
   *           HttpHeaders.AUTHORIZATION: jwtToken
   * response = MemberDependents|StatusCode
   * error: #JWTERRORS / #STANDARDERRORS
   */
}

export const inviteDependents: ApiUrl = {
  endpoint: '/dependents/invite',
  apiType: 'memberapp',
  verb: 'POST',
  /**
   * request = MemberDependentInviteData { stage 1: id array only, along with tenantid and memberid }
   *           HttpHeaders.AUTHORIZATION: jwtToken
   * response = StatusCode
   * error: #STANDARDERRORS
   */
}

export const inviteCustomizedDependents: ApiUrl = {
  endpoint: '/dependents/invite_customized',
  apiType: 'memberapp',
  verb: 'POST',
  /**
   * request = CustomizedDependentInviteData
   *           HttpHeaders.AUTHORIZATION: jwtToken
   * response = StatusCode
   * error: #STANDARDERRORS
   */
}

export const getReportPdf: ApiUrl = {
  endpoint: '/account/savings/download',
  apiType: 'memberapp',
  verb: 'GET',
  /**
   * request = <None>
   *           HttpHeaders.AUTHORIZATION: jwtToken
   * response = HttpHeaders.Content-Disposition = fileName=logo.png
   *          = HttpHeaders.AUTHORIZATION: jwtToken
   * error: 500 / Status Code: 332 - No Such User
   */
}
export const getCouponCardPdf: ApiUrl = {
  endpoint: '/account/savings/download_coupon_card_pdf',
  apiType: 'memberapp',
  verb: 'POST',
}

export const getSavingsReport: ApiUrl = {
  endpoint: '/account/savings/report/',
  apiType: 'memberapp',
  verb: 'POST',
  /**
   * request = <None>
   *           HttpHeaders.AUTHORIZATION: jwtToken
   * response = MemberSavingReport
   *            No Savings: savings == 0 and no other data should be relied on
   *          = HttpHeaders.AUTHORIZATION: jwtToken
   * error: 500 / Status Code: 332 - No Such User
   */
}

export const getPolicyAndTermsAcceptanceStatus: ApiUrl = {
  endpoint: '/policyandterms/acceptance_status',
  apiType: 'memberapp',
  verb: 'POST',
  /**
   * request = MemberIdData { tenantId, memberId }
   *           HttpHeaders.AUTHORIZATION: jwtToken
   * response = MembersPolicyAndTermsAcceptanceStatus|StatusCode
   * error: #JWTERRORS / #STANDARDERRORS
   */
}

//removing this endpoint as they are all treated the same way
// export const isSingleSessionClient: ApiUrl = {
//   endpoint: 'is_single_sessions_client',
//   apiType: 'memberapp',
//   verb: 'GET',
// }

export const isClientTokenExpired: ApiUrl = {
  endpoint: 'is_client_token_expired',
  apiType: 'memberapp',
  verb: 'GET',
}

export const PORTAL_MODULE_MEMBER = '@scripta-ui/portal/member'

export const memberModule: ModuleConfig = {
  name: PORTAL_MODULE_MEMBER,
  rootUrl: '/member',
  rootApiUrl: '/member',
  uiTitle: 'Member',
  order: 1,
}
