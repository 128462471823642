import { Grid, useMediaQuery, useTheme } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import {
  isServerErrorResponse,
  isValidResponse,
  PasswordChangeData,
} from '../../types'
import { useNotifier } from '../hooks/useNotify'
import { useMemberAppContext } from '../MemberAppContext'
import { CustomTextField } from '../registration/components/CustomTextField'
import { REG_ROUTE_SERVER_ERROR } from '../registration/RegistrationSubRoutes'
import { CLASS_NAMES } from '../shared/constants'
import SButton from '../system/customcomponents/SButton'
import STypography from '../system/customcomponents/STypography'
import { SPACING } from '../system/theme2'
import { useMemberService } from './useMemberService'

export function ChangePassword({
  forceMobileMode = false,
}: {
  forceMobileMode: boolean
}) {
  const { t } = useSTranslate('settings')

  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const { sendMsg } = useNotifier()
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm')) || forceMobileMode

  const { currentMemberProfile, isSsoLogin, history, jwtDetails } =
    useMemberAppContext()
  if (!currentMemberProfile)
    throw Error('cannot update password, profile not found in context')

  const [passwordsMatch, setPasswordsMatch] = useState(true)
  const [matchCondition, setmatchCondition] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [pwdChangeData, setPwdChangeData] = useState<PasswordChangeData>()
  const [pwdChangeError, setPwdChangError] = useState(false)
  const [pwdChangeErrorMsg, setPwdChangeErrorMsg] = useState<string>('')

  useEffect(() => {
    if (newPassword === passwordConfirm) {
      setPasswordsMatch(true)
    } else {
      setPasswordsMatch(false)
    }
    const isOk = validatePassword(newPassword)
    if (!isOk && newPassword !== '') {
      setPwdChangError(true)
      setPwdChangeErrorMsg('Your new password is invalid. Please try again')
      setmatchCondition(true)
    } else {
      setPwdChangError(false)
      setmatchCondition(false)
      setPwdChangeErrorMsg('')
    }
  }, [newPassword, passwordConfirm])

  const memberService = useMemberService()

  useEffect(() => {
    if (jwtDetails) {
      setPwdChangeData({
        currentPassword: currentPassword,
        newPassword: newPassword,
        tenantId: Number(jwtDetails.tenant_key),
        memberId: Number(jwtDetails.id_key),
        userName: jwtDetails.user_name_key ?? '',
      })
    }
  }, [newPassword, passwordConfirm, currentPassword, jwtDetails])

  async function asyncChangePassword(pwdChangeData: PasswordChangeData) {
    try {
      setIsDisabled(true)
      const resp = await memberService.changeMemberPasswordApi(pwdChangeData)
      setIsDisabled(false)
      if (isServerErrorResponse<void>(resp)) {
        history.push(REG_ROUTE_SERVER_ERROR)
        return
      }

      if (isValidResponse<void>(resp)) {
        setPwdChangError(false)
        setPwdChangeErrorMsg('')
        sendMsg(t('changePasswordSuccess'), 'success')
      } else {
        try {
          setPwdChangError(true)
          setPwdChangeErrorMsg(resp.description)
        } catch (error) {
          console.error('unable to extract response json from reg challenge')
        }
      }
    } catch (error) {
      setIsDisabled(false)
      throw Error('Error in chng pwd api exec')
    }
  }

  const doChangePassword = useCallback(() => {
    if (!pwdChangeData) {
      throw Error('no change data..')
    }
    asyncChangePassword(pwdChangeData)
  }, [pwdChangeData])

  const isSavePasswordDisabled =
    !currentPassword ||
    !newPassword ||
    !passwordConfirm ||
    !passwordsMatch ||
    matchCondition ||
    isDisabled

  function validatePassword(password: string) {
    var re =
      /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]).{8,}/
    if (password.length > 20) {
      return false
    }
    return re.test(password)
  }

  //no changep assf or sso users
  if (isSsoLogin) {
    return null
  }

  if (forceMobileMode) {
    return (
      <Grid container spacing={SPACING._1} direction={'column'}>
        <Grid item>
          <STypography variant="body_bold">
            {t('changePassButtonText')}
          </STypography>
        </Grid>
        <Grid item>
          <STypography variant="bodysmall_regular">
            {t('passwordHintsHeader')}
          </STypography>
        </Grid>
        <Grid item>
          <CustomTextField
            autoFocus
            autoComplete="new-password"
            type="password"
            id="currentPassword"
            name="currentPassword"
            placeholder={t('currentPassword')}
            label={t('currentPassword')}
            value={currentPassword}
            onChange={(e) => {
              setCurrentPassword(e.target.value)
            }}
          />
        </Grid>
        <Grid item>
          <CustomTextField
            autoComplete="new-password"
            type="password"
            id="newPassword"
            name="newPassword"
            placeholder={t('newPassword')}
            label={passwordsMatch ? t('newPassword') : t('doNotMatch')}
            value={newPassword}
            onChange={(e) => {
              setNewPassword(e.target.value)
            }}
            error={!passwordsMatch}
          />
        </Grid>
        <Grid item>
          <CustomTextField
            autoComplete="new-password"
            type="password"
            id="passwordConfirm"
            name="passwordConfirm"
            placeholder={t('confirmPassword')}
            label={passwordsMatch ? t('confirmPassword') : t('doNotMatch')}
            value={passwordConfirm}
            onChange={(e) => {
              setPasswordConfirm(e.target.value)
            }}
            error={!passwordsMatch}
          />
        </Grid>
        {pwdChangeError && (
          <Grid item>
            <STypography variant="body_regular" color="error">
              {pwdChangeErrorMsg}
            </STypography>
          </Grid>
        )}
        <Grid item>
          <Grid item container justifyContent="center">
            <Grid item xs={12}>
              <SButton
                className={CLASS_NAMES.updatePasswordButton}
                variant="contained"
                fullWidth
                disabled={isSavePasswordDisabled}
                onClick={(e) => {
                  doChangePassword()
                }}
                noCaps
              >
                {t('changePassButtonText')}
              </SButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
  return null
}
