import { useLayoutEffect } from 'react'
import { useLocation } from 'react-router'
import { Route, Switch } from 'react-router-dom'

import { GenericErrorPage } from './application/GenericErrorPage'
import { JwtExpiredHandler } from './application/JwtExpiredHandler'
import { CompleteLoginHandler } from './registration/CompleteLoginHandler'
import { RegistrationContainer } from './registration/RegistrationContainer'

import {
  REG_ROUTE_PREREGISTER,
  REG_ROUTE_REGISTER_SSO_COMPLETEPROFILE,
  REG_ROUTE_UNSUBSCRIBE,
} from './registration/RegistrationSubRoutes'
import { UnsubscribeFromNotifications } from './application/Unsubscribe'

export const ROUTE_APP_ROOT = '/'
export const ROUTE_REGISTER_ROOT = '/register'
export const ROUTE_PROVIDER_ROOT = '/provider'
export const ROUTE_UNSUBSCRIBE = '/register/unsubscribe'

export const ROUTE_SAVINGS = '/savings'
export const ROUTE_ALTERNATIVES = '/alternatives'
export const ROUTE_HOW_TO_SWITCH = '/howToSwitch'
export const ROUTE_COUPONS = '/coupons'

export const ROUTE_COMPLETE_LOGIN = '/completelogin'
export const ROUTE_SETTINGS = '/settings'
export const ROUTE_MYACCOUNT = '/myaccount'
export const ROUTE_FAQ = '/faq'
export const ROUTE_MY_MEDS = '/mymeds'
export const ROUTE_SAVED_MEDS = '/savedmeds'

export const ROUTE_GENERIC_ERROR = '/error'

export const ROUTE_SAML_LOGIN = '/login/saml/post'
export const ROUTE_SAML_LOGIN_ERROR = '/login/saml/error'

//need this special logout route in order to process jwt expiration
//as jwt expiration is detected outside of the component
//in the component we set the custom message, nullout the jwt completelly and send to standard login
export const REG_ROUTE_JWT_EXP_LOGOUT = '/jwtexplogout'

//NOTE: you must add a route in registration subroutes besides here if you need registration container
export function PortalPublicRoutes() {
  return (
    <Switch>
      <Route path={ROUTE_REGISTER_ROOT}>
        <RegistrationContainer />
      </Route>
      <Route path={REG_ROUTE_PREREGISTER} exact>
        <RegistrationContainer />
      </Route>
      <Route path={ROUTE_COMPLETE_LOGIN}>
        <CompleteLoginHandler />
      </Route>
      <Route path={ROUTE_SAML_LOGIN_ERROR} exact>
        <RegistrationContainer />
      </Route>
      {/* sso login processor - to init the jwt from url, 
      and check if member needs to complete registration
      if so - it will redirect to below REG_ROUTE_REGISTER_SSO_FIRSTTIME */}
      <Route path={ROUTE_SAML_LOGIN}>
        <RegistrationContainer />
      </Route>
      <Route path={REG_ROUTE_REGISTER_SSO_COMPLETEPROFILE} exact>
        <RegistrationContainer />
      </Route>

      <Route path={REG_ROUTE_JWT_EXP_LOGOUT}>
        <JwtExpiredHandler />
      </Route>
      <Route path={ROUTE_GENERIC_ERROR}>
        <RegistrationContainer />
      </Route>
      <Route path={REG_ROUTE_UNSUBSCRIBE} exact>
        <UnsubscribeFromNotifications />
      </Route>
    </Switch>
  )
}

// export function PortalAuthedRoutes() {
//   const history = useHistory()
//   const location: any = useLocation()

//   return (
//     <ScrollToTopWrapper>
//       <Switch>
//         {/* <Route exact path={ROUTE_APP_ROOT}>
//           <SavingsMainContainer />
//         </Route>

//         <Route exact path={ROUTE_SAVINGS}>
//           <SavingsMainContainer />
//         </Route> */}
//         <Route exact path={ROUTE_FAQ}>
//           <FaqMainContainer />
//         </Route>
//         <Route exact path={ROUTE_SETTINGS}>
//           {/* TO DO: Rename myaccount to settings and remove settings */}
//           <MyAccount />
//         </Route>
//         <Route exact path={ROUTE_MYACCOUNT}>
//           <MyAccount />
//         </Route>

//         <Route exact path={ROUTE_MY_MEDS}>
//           <MyMedsMainContainer />
//         </Route>
//         <Route exact path={ROUTE_SAVED_MEDS}>
//           <BookmarksMainContainer />
//         </Route>
//       </Switch>
//     </ScrollToTopWrapper>
//   )
// }
