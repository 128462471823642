import {
  Box,
  CircularProgress,
  Grid,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { checkMicroServiceResponseStatus } from '../apiexec/utils'
import { GAService } from '../application/ga/GAService'
import {
  HiddenDrug,
  HiddenDrugsResp,
  PrescribedDrugResp,
  useMemberService,
} from '../member/useMemberService'
import { MyMedsListItem } from '../system/ListItems'
import { TagLabel } from '../system/TagLabel'
import SSelect from '../system/customcomponents/SSelect'
import {
  ElipsisMenuDrugContext,
  MyMedsElipsisMenu,
} from '../widgets/DrugElipsisMenu'
import { BookmarksMainContainer } from './BookmarksMainContainer'
import { SPACING } from '../system/theme2'
import STypography from '../system/customcomponents/STypography'
import { LOG } from 'src/app/v2/applog'

interface SimpleOption {
  name: string
  label: string
  value: MyMedsilterType
}

type MyMedsilterType = 'Hidden' | 'Prescribed' | 'Saved'

export interface MyMedsRow {
  viewFilter: 'Prescribed' | 'Hidden'
  //recommended = alternative, prescribed = original
  drugContext: ElipsisMenuDrugContext
  sourceType: 'Recommended' | 'Prescribed'
  id: number | undefined
  'mma-id'?: number
  name?: string
  'last-fill-date': string
  'next-fill-date': string
  'prescribed-days-supply'?: string
  //remember for prescribed these are nested under a separate drugDto node :(
  strength: string
  'strength-unit-of-measure': string
  'dosage-form': string
  'dispensed-quantity': number
  whyItsHidden?: HiddenDrug['type']
  userReasonForHiding?: string
}

function toUiDateString(backendTimestampMilis: any): string {
  let lastFillDate = ''
  if (!backendTimestampMilis) {
    return 'NA'
  }
  try {
    const fd = new Date(backendTimestampMilis)
    if (fd) {
      lastFillDate = fd.toLocaleDateString('en-US')
    }
  } catch (e) {
    lastFillDate = 'NA'
  }
  return lastFillDate
}

export function toMyMedsRowFromPrescribedResp(
  prescribedResp: PrescribedDrugResp,
): MyMedsRow[] {
  const myMeds: MyMedsRow[] = prescribedResp['prescribed-drugs'].map((p) => {
    const drugNode = p['drug-basic-info']
    const lastFillDate = toUiDateString(p['last-fill-date'])
    const nextFillDate = toUiDateString(p['next-fill-date'])

    return {
      viewFilter: 'Prescribed',
      //this is alwyas prescribed for prescribed view, but in hidden it can be prescribed or recommended (original or aternative)
      sourceType: 'Prescribed',
      drugContext: 'original',
      id: drugNode ? drugNode['drug-id'] : undefined,
      name: drugNode ? drugNode.name : 'NA',
      'last-fill-date': lastFillDate,
      'next-fill-date': nextFillDate,
      'prescribed-days-supply': safelyTrimLeadingZerosIfAny(
        p['prescribed-days-supply'],
      ),
      strength: drugNode ? drugNode.strength : 'NA',
      'strength-unit-of-measure': drugNode ? drugNode['unit-of-measure'] : 'NA',
      'dosage-form': drugNode ? drugNode['dosage-form'] : 'NA',
      'dispensed-quantity': p['quantity-dispensed'],
    }
  })
  return myMeds
}

export function toMyMedsRowFromHiddenResp(
  hiddenResp: HiddenDrugsResp,
): MyMedsRow[] {
  const myMeds: MyMedsRow[] = hiddenResp['hidden-drugs'].map((hd) => {
    const lastFillDate = toUiDateString(hd['last-fill-date'])
    const nextFillDate = toUiDateString(hd['next-fill-date'])

    return {
      viewFilter: 'Hidden',
      whyItsHidden: hd.type,
      userReasonForHiding: hd.reason,
      //TODO - verify if this is correct.
      //MANUAL_DISCOTNUED = user hide original, SUPPRESSED = user hide alternative
      // DISCONTINUED = system discontinued(but system discntinued can be original or alternative and that is unknown based on this information
      //will do original for now
      drugContext:
        hd.type === 'MANUALLY_DISCONTINUED' || hd.type === 'DISCONTINUED'
          ? 'original'
          : 'alternative',
      sourceType:
        hd.type === 'MANUALLY_DISCONTINUED' || hd.type === 'DISCONTINUED'
          ? 'Prescribed'
          : 'Recommended',
      id: hd['drug-id'],
      'mma-id': hd['mma-id'],
      name: hd['drug-name'] ? hd['drug-name'] : '',
      'last-fill-date': lastFillDate,
      'next-fill-date': nextFillDate,
      'prescribed-days-supply': safelyTrimLeadingZerosIfAny(
        hd['prescribed-days-supply'],
      ),
      strength: hd.strength,
      'strength-unit-of-measure': hd['strength-unit-of-measure'],
      'dosage-form': hd['dosage-form'],
      'dispensed-quantity': hd['dispensed-quantity'],
    }
  })
  return myMeds
}

function safelyTrimLeadingZerosIfAny(backendValue?: string) {
  if (!backendValue) {
    return 'NA'
  }

  try {
    if (backendValue && backendValue.startsWith('0')) {
      return backendValue.replace(/^0+/, '')
    } else return backendValue
  } catch (error) {
    return backendValue
  }
}

export function MyMedsMainContainer() {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const isMobileMode: boolean = isSmall
  //need to wait for this to load bc we are using in the react select dropdown options initially
  const { t, ready } = useSTranslate('myMeds')
  const { t: newMyAccountT } = useSTranslate('newMyAccount')

  const [medsTable, setMedsTable] = useState<MyMedsRow[]>([])

  const [isLoading, setIsLoading] = useState<boolean>()

  const [dataError, setDataError] = useState<boolean>(false)
  const [errorMsg, setErrorMsg] = useState<string>()

  const DEFAULT_VIEW_SELECTED = 'Prescribed'

  const gaService = new GAService()

  const service = useMemberService()
  const [selectedView, setSelectedView] = useState<SimpleOption>()

  useEffect(() => {
    if (ready) {
      setSelectedView({
        label: t('prescribedLabel'),
        name: t('prescribedLabel'),
        value: DEFAULT_VIEW_SELECTED,
      })
    }
  }, [ready])

  const viewOptions: SimpleOption[] = [
    {
      label: t('prescribedLabel'),
      name: t('prescribedLabel'),
      value: 'Prescribed',
    },
    { label: t('hiddenLabel'), name: t('hiddenLabel'), value: 'Hidden' },
    {
      label: t('savedMedsTitle'),
      name: t('savedMedsTitle'),
      value: 'Saved',
    },
  ]
  //initial load, rest is handled refreshen wehn filter changes
  useEffect(() => {
    loadMyMeds(DEFAULT_VIEW_SELECTED)
  }, [])

  async function loadMyMeds(viewFilter: MyMedsilterType) {
    setIsLoading(true)
    try {
      if (viewFilter === 'Prescribed') {
        //prescribed drugs
        const resp = await service.getMemberPrescribedDrugs()
        const respValidation = checkMicroServiceResponseStatus(resp)

        if (respValidation.isError) {
          LOG.error('my_meds', 'Prescribed API Error resp', resp)
          setIsLoading(false)
          setDataError(true)
          setMedsTable([])

          setErrorMsg('Prescribed API Error' + respValidation.errorMsg)
          return
        }

        //check specifically for NULL - this indicaes something is WRONG with backend - it should return empty array
        if (!resp['prescribed-drugs']) {
          setIsLoading(false)
          setDataError(true)
          setErrorMsg(
            'prescribed-drugs node on the response is NULL. It should be empty array at least',
          )
          return
        }

        const myMeds = toMyMedsRowFromPrescribedResp(resp)
        setMedsTable(myMeds)
      } else if (viewFilter === 'Hidden') {
        //hidden drugs
        const resp = await service.getMemberHiddenDrugs()
        const respValidation = checkMicroServiceResponseStatus(resp)

        if (respValidation.isError) {
          LOG.error('my_meds', 'Hidden API Error resp', resp)
          setIsLoading(false)
          setDataError(true)
          setErrorMsg('Hidden API Error' + respValidation.errorMsg)
          setMedsTable([])
          return
        }

        //check specifically for NULL - this indicaes something is WRONG with backend - it should return empty array
        if (!resp['hidden-drugs']) {
          console.error('no hidden drugs found on the response....')
          setDataError(true)
          setErrorMsg(
            'hidden-drugs node on the response is NULL. It should be empty array at least',
          )
          setMedsTable([])
          return
        }

        const myMeds = toMyMedsRowFromHiddenResp(resp)
        setMedsTable(myMeds)
      } else {
        //its saved drugs, so reset this
        //we will show custom bookmarks component
        setMedsTable([])
      }
      setIsLoading(false)
    } catch (error) {
      setErrorMsg('Generic Error executing api call')
      setIsLoading(false)
    }
  }

  let prescribedDesc = newMyAccountT('myMedsPrescribedDesc')
  let hiddenDesc = newMyAccountT('myMedsHiddenDesc')
  let savedDesc = newMyAccountT('myMedsSavedDesc')

  if (!selectedView) {
    return null
  }

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        // sx={{ marginTop: SPACING._15, marginBottom: SPACING._15 }}
      >
        <Box sx={{ maxWidth: '320px', margin: 'auto' }}>
          <SSelect
            label="Viewing my"
            // components={{
            //   IndicatorSeparator: null,
            // }}
            // menuPortalTarget={document.body}
            isSearchable={false}
            options={viewOptions}
            placeholder={t('view')}
            value={selectedView}
            onChange={(option: any, actionMeta: any) => {
              if (option.value === 'Prescribed') {
                gaService.trackEvent(
                  gaService.eventMap.my_meds_filter_select_prescribed,
                )
              } else if (option.value === 'Hidden') {
                gaService.trackEvent(
                  gaService.eventMap.my_meds_filter_select_hidden,
                )
              }
              loadMyMeds(option.value)
              setSelectedView(option)
            }}
            // aria-label={'You can view hidden medications'}
          />
        </Box>
        {!errorMsg && (
          <Grid
            item
            xs={12}
            sx={{
              paddingTop: SPACING._1,
              paddingLeft: SPACING._1,
              paddingRight: SPACING._1,
            }}
          >
            <STypography variant="body_regular">
              {selectedView.value === 'Prescribed' ? prescribedDesc : undefined}{' '}
            </STypography>
            <STypography variant="body_regular">
              {selectedView.value === 'Hidden' ? hiddenDesc : undefined}{' '}
            </STypography>
            <STypography variant="body_regular">
              {selectedView.value === 'Saved' ? savedDesc : undefined}{' '}
            </STypography>
          </Grid>
        )}
        {isLoading && (
          <Grid item xs={12} sx={{ paddingTop: SPACING._15 }}>
            <Box sx={{ textAlign: 'center', marginTop: SPACING._2 }}>
              <CircularProgress />
            </Box>
          </Grid>
        )}
        {errorMsg && (
          <Grid item xs={12} sx={{ paddingTop: SPACING._15 }}>
            <Box sx={{ textAlign: 'center', marginTop: SPACING._2 }}>
              <STypography variant="body_regular" sx={{ color: 'red' }}>
                We are sorry, the medication list cannot be shown at the moment.
                Please try again later.
              </STypography>
            </Box>
          </Grid>
        )}
        {!isLoading && selectedView.value === 'Saved' && (
          <BookmarksMainContainer />
        )}
        {!isLoading && !errorMsg && selectedView.value !== 'Saved' && (
          <Grid
            item
            container
            xs={12}
            sx={{ zIndex: 0, paddingTop: SPACING._15 }}
          >
            {medsTable.map((myMed, i) => {
              const actions = getActionsForMed(myMed, selectedView.value)
              let count = myMed['dispensed-quantity']
                ? `${myMed['dispensed-quantity']}`
                : 'NA'

              return (
                <Box sx={{ width: '100%' }} key={i}>
                  <Grid item xs={12}>
                    <MyMedsListItem
                      daysSupplied={myMed['prescribed-days-supply']}
                      drugCount={count}
                      drugDosage={
                        myMed.strength + ' ' + myMed['strength-unit-of-measure']
                      }
                      drugForm={myMed['dosage-form']}
                      drugName={myMed.name}
                      infoMenu={
                        myMed.id ? (
                          <MyMedsElipsisMenu
                            id={myMed.id}
                            mmaId={myMed['mma-id']}
                            drugContext={myMed.drugContext}
                            pricingQuantityDefault={myMed['dispensed-quantity']}
                            showHideDrug={actions.showHide}
                            showUnhideDrug={actions.showUnHide}
                            showMyPricing={actions.showMyPricing}
                            onActionCompleted={(action) => {
                              //refresh the list
                              loadMyMeds(selectedView.value)
                            }}
                          />
                        ) : undefined
                      }
                      lastFilledDate={myMed['last-fill-date']}
                      refillDate={myMed['next-fill-date']}
                      //when viewing hidden drugs - show tag to tell if its original (prescribed) or alternative (recommended)
                      tags={
                        selectedView.value === 'Hidden' ? (
                          <TagLabel
                            color="blue"
                            size="small"
                            text={
                              myMed.drugContext === 'original'
                                ? 'Prescribed'
                                : 'Recommended'
                            }
                          />
                        ) : undefined
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <hr />
                  </Grid>
                </Box>
              )
            })}
            {medsTable.length === 0 && (
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                No medications found
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

function toUiReasonLabel(myMed: MyMedsRow, t: any) {
  //will need to be moved to strings when doing spanish
  //first check if there is user provided reason field and use that, otherwise fallback to default
  let backendReason = myMed.userReasonForHiding
    ? myMed.userReasonForHiding
    : myMed.whyItsHidden

  switch (backendReason) {
    case 'NO_LONGER_TAKING':
      return t('reason_no_longer_taking')
    case 'NEVER_TOOK_IT':
      return t('reason_never_tookit')
    case 'OTHER':
      return t('reason_other')
    case 'TRIED_DIDNT_WORK':
      return t('reason_tried_didntwork')
    case 'TRIED_EXPERIENCED_SIDE_EFFECTS':
      return t('reason_tried_exp_sideffect')
    case 'ALLERGIC':
      return t('reason_allergic')
    case 'DOCTOR_DECLINED_CHANGE':
      return t('reason_doctor_declined')
    case 'CURRENT_MED_IS_CHEAPER':
      return t('reason_mine_is_cheaper')
    case 'DISCONTINUED':
      return t('reason_discontinued')
    case 'MANUALLY_DISCONTINUED':
      return t('reason_manually_discontinued')
    case 'SUPPRESSED':
      return t('reason_suppressed')

    default:
      return backendReason
  }
}

function getActionsForMed(myMed: MyMedsRow, selectedView: MyMedsilterType) {
  let showDrugInfo = true

  let showUnHide = false
  let showHide = false
  let showMyPricing = false

  if (selectedView === 'Prescribed') {
    showMyPricing = true
    showUnHide = false
    showHide = true
  } else {
    if (
      myMed.whyItsHidden === 'MANUALLY_DISCONTINUED' ||
      myMed.whyItsHidden === 'SUPPRESSED'
    ) {
      //disable new pricing for hidden meds for now
      showMyPricing = false
      showUnHide = true
      showHide = false
    } else {
      showMyPricing = false
      showUnHide = false
      showHide = false
    }
  }
  return { showMyPricing, showUnHide, showHide }
}
