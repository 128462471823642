import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import { useEffect, useRef, useState, FunctionComponent, SVGProps } from 'react'
import { useMemberAppContext } from '../MemberAppContext'
import DetailedCouponCard from '../coupons/getcoupon/DetailedCouponCard'
import { SwitchData } from '../helpmeswitch/types'
import STypography from '../system/customcomponents/STypography'
import { SPACING } from '../system/theme2'
import {
  ROUTE_OPPORTUNITY_HOW_TO_SAVE_ALTERNATIVE_REVIEW,
  ROUTE_OPPORTUNITY_HOW_TO_SAVE_COUPON_CARD_REVIEW,
  ROUTE_OPPORTUNITY_HOW_TO_SAVE_FINAL_STEP,
  ROUTE_OPPORTUNITY_HOW_TO_SAVE_PHARMACY_REVIEW,
  ROUTE_OPPORTUNITY_SAME_MED_OR_NEW_MED,
  SavingGuideNavbarState,
  CouponsListFromRxSense,
} from './SavingGuide'

import step_intro_url from './images/howtosave/step_intro.svg'
import step_coupon_hero_url from './images/howtosave/step_coupon_hero.svg'
import step_pharmacy_hero_url from './images/howtosave/step_pharmacy_hero.svg'
import step_rxcompare_hero_url from './images/howtosave/step_rxcompare_hero.svg'
import step_rxcompare_hero_mycoupon_variant_url from './images/howtosave/step_rxcompare_hero_mycoupon_variant.svg'

import { useSizes, useXServiceTodoBettername } from './useNewHooks'
import { HelperCardBookmark, HelperCardHelpSwitch } from '../system/HelperCard'
import { PharmacyCardItem, PharmacyCardItemProps } from '../system/ListItems'
import RxCompare from '../system/RxCompare'
import StepCard, { StepCardProps } from '../system/StepCard'
import { TagLabel } from '../system/TagLabel'
import SButton from '../system/customcomponents/SButton'
import { SDialog } from '../system/customcomponents/SDialog'
import SDotsStepper from '../system/customcomponents/SDotsStepper'
import {
  BookmarkedOpportunity,
  CouponDetails,
  PharmacyInfo,
  SavingOpportunityDataModel,
  WayToSaveItem,
  buildPharmacyAddressFromNpiLookup,
  getDistanceStr,
  getRxCompareProps,
  isMarkCubanCostPlustName,
  needsToDiscussReportWithDoctor,
} from './saving-utils'
import { BackgroundContainer, DESKTOP_MAX_HEIGHT } from './NewPageContainer'
import {
  MemberZipCodeLocationField,
  ROUTE_NEW_COUPONS_MAIN,
  ROUTE_NEW_SAVING_REPORT_OPPORTUNITIES_MAIN,
} from './NewMemberPortalMain'
import { Wizard } from '../helpmeswitch/Wizard'
import { useNotifier } from '../hooks/useNotify'
import HowToSwitchPharmacy from '../system/HowToSwitchPharmacy'
import { CircleWrappedFontIcon } from '../system/CircleWrappedFontIcon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBallot,
  faLocationDot,
  faPills,
  faThumbsUp,
} from '@fortawesome/pro-regular-svg-icons'
import { ThankYouForSwitchDialog } from '../helpmeswitch/SubmittedDialog'
import { SavingStrategy } from '../strategies/types'
import { CheckBox } from '@mui/icons-material'
import {
  AdditionalPharmacyDetailsByNpiLookupResp,
  CostPlusStatus,
  useMemberService,
} from '../member/useMemberService'
import { checkMicroServiceResponseStatus } from '../apiexec/utils'
import { LOG } from './applog'
import {
  MemberIdData,
  MembersPolicyAndTermsAcceptanceStatus,
  isValidResponse,
} from 'src/types'
import { handleExternalLinkClick } from '../widgets/ExternalLink'
import { GAService } from '../application/ga/GAService'
import InfoCard from '../system/InfoCard'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { useSColors } from '../styles/scripta-theme'
import { ThemedSVGRemote } from './ThemedSVGRemote'
import { BannerCardHelpSwitchRx } from 'src/app/system/BannerCard'
import { useLocation } from 'react-router'
import { useAmazon } from 'src/app/hooks/useAmazon'

//TODO
// What we could do is say " Fill your new prescription at your In-house pharmacy, JPS Pharmacy"
export interface HowToSaveProps {
  selectedOpportunity: SavingOpportunityDataModel
  selectedWayToSaveItem: WayToSaveItem
  selectedCoupon?: CouponDetails
  // selectedPharmacy?: PharmacyDetails
  isMyCouponsView: boolean
  setNavbar?: (state: SavingGuideNavbarState) => void
}
export interface HowToSavePharmacyProps extends HowToSaveProps {
  onNewCouponSelected: (coupon: CouponDetails) => void
}
//need to clear out selected item and coupon in case they go
//to choose a different way to save
export interface HowToSaveLastStepProps extends HowToSaveProps {
  setSelectedCoupon: (coupon?: CouponDetails) => void
  onHandleFindAnotherWayToSave: () => void
}
// const SUMMARY_STEP_RX_COMPARE = 0
// const SUMMARY_STEP_PHARMACY = 1
// const SUMMARY_STEP_COUPON = 2
// const SUMMARY_STEP_FINAL = 3

interface HowToSaveStepperDotsProps {
  activeStep: number
  numberOfSteps: number
  onClickNext: () => void
  onClickBack: () => void
}

function HowToSaveStepperDots(props: HowToSaveStepperDotsProps) {
  return (
    <SDotsStepper
      activeStep={props.activeStep}
      steps={props.numberOfSteps}
      onStepChange={(step: number) => {
        if (step === props.activeStep + 1) {
          props.onClickNext()
        } else {
          props.onClickBack()
        }
      }}
      backButton={undefined}
      nextButton={undefined}
    />
  )
}

function SummaryPageLayout({
  heroControl,
  stepControl,
  mainControl,
  stepperControl,
}: {
  heroControl: any
  stepControl: any
  mainControl: any
  stepperControl: any
}) {
  const { isMobile } = useSizes()
  return (
    <>
      <BackgroundContainer
        variant={isMobile ? 'purple_curved_elipsis' : 'clear_white'}
      >
        <Box
          sx={{
            // border: '1px solid black',
            height: isMobile ? undefined : DESKTOP_MAX_HEIGHT - 50 - 50,
            minHeight: isMobile ? undefined : DESKTOP_MAX_HEIGHT - 50 - 50,
            // border: '1px solid black',
            display: 'flex',
            flexDirection: 'column',
            gap: SPACING._15,
          }}
        >
          <Box>{heroControl}</Box>
          {stepControl && <Box>{stepControl}</Box>}
          <Box>{mainControl}</Box>
          {!isMobile && <Box sx={{ marginTop: 'auto' }}>{stepperControl}</Box>}
        </Box>
      </BackgroundContainer>
      {isMobile && (
        <Box
          sx={{
            padding: '12px 16px',
            position: 'fixed',
            bottom: 0,
            width: '100%',
            backgroundColor: 'white',
          }}
        >
          {stepperControl}
        </Box>
      )}
    </>
  )
}

export function HowToSaveIntro(props: HowToSaveProps) {
  const { COLORS } = useSColors()
  const { history } = useMemberAppContext()
  const { selectedCoupon } = props
  const { isMobile } = useSizes()
  const needsRxTalk = needsToDiscussReportWithDoctor(
    props.selectedWayToSaveItem,
  )

  return (
    <SummaryPageLayout
      heroControl={
        <SummarHeroImageBox
          svgImageImportUrl={step_intro_url}
          alt="INTRO STEP"
          height={144}
          width={144}
          onClick={() => {
            history.push(ROUTE_OPPORTUNITY_HOW_TO_SAVE_ALTERNATIVE_REVIEW)
          }}
        />
      }
      stepControl={undefined}
      mainControl={
        <Grid
          container
          onClick={() => {
            history.push(ROUTE_OPPORTUNITY_HOW_TO_SAVE_ALTERNATIVE_REVIEW)
          }}
          sx={{
            textAlign: 'center',
            width: '100%',
          }}
          style={{
            height: isMobile
              ? 'calc(100svh - 305px)'
              : DESKTOP_MAX_HEIGHT - 325,
          }}
          direction={'column'}
          flexWrap={'nowrap'}
        >
          <Grid item>
            <STypography
              variant="title2_bold"
              sx={{ color: COLORS.TEXT_BRAND }}
            >
              How to save
            </STypography>
          </Grid>
          <Grid
            item
            sx={{
              padding: SPACING._2,
              paddingTop: SPACING._1,
              paddingBottom: SPACING._1,
            }}
          >
            <STypography variant="body_regular">
              Next, you will see the steps you need to follow to get your
              savings based on what you just chose.
            </STypography>
          </Grid>
          <Grid item sx={{ paddingLeft: SPACING._2, paddingRight: SPACING._2 }}>
            <STypography variant="body_regular">
              At the end, you can bookmark this savings opportunity to take you
              directly to the summary next time, and you can always find other
              ways to save.
            </STypography>
          </Grid>
          {needsRxTalk && (
            <>
              <Grid item flexGrow={1} />
              <Grid
                item
                sx={{
                  padding: SPACING._1,
                  textAlign: 'left',
                  width: '100%',
                  marginTop: SPACING._1,
                }}
              >
                <BannerCardHelpSwitchRx
                  isHelpMeSwitchButton={true}
                  onButtonClick={() => {
                    history.push(ROUTE_OPPORTUNITY_HOW_TO_SAVE_FINAL_STEP, {
                      shouldOpenHelpMeSwitch: true,
                    })
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>
      }
      stepperControl={
        <HowToSaveStepperDots
          onClickNext={() => {
            history.push(ROUTE_OPPORTUNITY_HOW_TO_SAVE_ALTERNATIVE_REVIEW)
            // props.setNavbar(SavingGuideNavbarState.Coupon)
          }}
          onClickBack={() => {
            history.goBack()
          }}
          activeStep={0}
          numberOfSteps={selectedCoupon ? 5 : 4}
        />
      }
    />
  )
}

export function HowToSaveAltRxCompare(props: HowToSaveProps) {
  const { selectedOpportunity, selectedWayToSaveItem, selectedCoupon } = props

  const { history } = useMemberAppContext()

  const needsDrTalk = needsToDiscussReportWithDoctor(selectedWayToSaveItem)

  // let activeStep = SUMMARY_STEP_RX_COMPARE
  let activeStep = 1
  let numberOfSteps = selectedCoupon ? 5 : 4

  let stepContent = (
    <STypography component="span" variant="body_regular">
      <STypography component="span" variant="body_bold">
        You are not changing your Rx,{' '}
      </STypography>
      <STypography component="span" variant="body_regular">
        no need to talk to your doctor!
      </STypography>
    </STypography>
  )
  if (needsDrTalk) {
    stepContent = (
      <STypography component="span" variant="body_regular">
        <STypography component="span" variant="body_bold">
          Discuss report with your doctor{' '}
        </STypography>
        <STypography component="span" variant="body_regular">
          and ask to switch your prescription.
        </STypography>
      </STypography>
    )
  }

  useEffect(() => {
    // setRightAction({
    //   actionName: 'show_selected_way_to_save_info',
    //   actionData: props,
    // })
  }, [selectedWayToSaveItem])

  const rxProps = getRxCompareProps(selectedWayToSaveItem, selectedCoupon)
  //add tags
  let tags = []

  if (selectedWayToSaveItem.type === 'basic_strategy') {
    tags.push(
      <TagLabel
        key="StrategyKey"
        size="small"
        color="purple"
        text={selectedWayToSaveItem.savingStrategy}
      />,
    )
  } else {
    //so account for it here
    //add tag for new strategy except for pharmacy change or coupons
    if (
      selectedWayToSaveItem.savingStrategy !== 'Pharmacy Change' &&
      selectedWayToSaveItem.savingStrategy !== 'Coupons'
    ) {
      tags.push(
        <TagLabel
          key="StrategyKey"
          size="small"
          color="purple"
          text={selectedWayToSaveItem.savingStrategy}
        />,
      )
    }
  }

  if (selectedCoupon) {
    tags.push(
      <TagLabel key="CouponKey" size="small" color="blue" text="Coupon" />,
    )
  }
  if (selectedWayToSaveItem.type === 'pharmacy_change') {
    tags.push(
      <TagLabel
        key="PharmacyChagneKey"
        size="small"
        color="blue"
        text="Pharmacy Change"
      />,
    )
  }

  rxProps.newRx.tags = tags

  const customWidth =
    selectedWayToSaveItem.savingStrategy === SavingStrategy.Separate
      ? '450px'
      : undefined

  return (
    <SummaryPageLayout
      heroControl={
        <SummarHeroImageBox
          // svgImage={'/howtosave/step_rxcompare_hero.svg'}
          svgImageImportUrl={step_rxcompare_hero_url}
          alt="New vs Current RxComparison"
          width={230}
          height={144}
          onClick={() => {
            history.push(ROUTE_OPPORTUNITY_HOW_TO_SAVE_PHARMACY_REVIEW)
          }}
        />
      }
      stepControl={<StepCard step={1} customStepContent={stepContent} />}
      mainControl={<RxCompare {...rxProps} customWidth={customWidth} />}
      stepperControl={
        <HowToSaveStepperDots
          onClickNext={() => {
            history.push(ROUTE_OPPORTUNITY_HOW_TO_SAVE_PHARMACY_REVIEW)
          }}
          onClickBack={() => {
            history.goBack()
          }}
          activeStep={activeStep}
          numberOfSteps={numberOfSteps}
        />
      }
    />
  )
}

export function HowToSavePharmacyDetails(props: HowToSavePharmacyProps) {
  const {
    selectedOpportunity,
    selectedWayToSaveItem,
    selectedCoupon,
    // selectedPharmacy,
    isMyCouponsView,
    onNewCouponSelected,
  } = props
  const memberService = useMemberService()

  const { savingGuide } = useMemberAppContext()

  //in case of search - we get the NPI in the results, then we have to fetch addditional
  //details such as address etc using it
  const [additionalPharmacyDetails, setAdditionalPharmacyDetails] = useState<
    AdditionalPharmacyDetailsByNpiLookupResp | undefined
  >(undefined)

  async function loadAdditionalPharmaDetailsViaNpi(npi: string) {
    try {
      const resp = await memberService.getPharmacyDetails(npi)
      const status = checkMicroServiceResponseStatus(resp)
      if (!status.isError) {
        setAdditionalPharmacyDetails(resp)
      } else {
        LOG.error(
          'search_results_data',
          'MS error response getting additional pharmacy details by NPI for npi=' +
            npi +
            ',resp=',
          resp,
        )
      }
    } catch (e) {
      LOG.error(
        'search_results_data',
        'Error getting additional pharmacy details by NPI',
        e,
      )
    }
  }

  //fetch additional pharmacy details in case of search
  useEffect(() => {
    if (
      savingGuide?.source === 'search_results' &&
      selectedWayToSaveItem.pharmacyInfo?.pharmacyNpi
    ) {
      //load additional details
      loadAdditionalPharmaDetailsViaNpi(
        selectedWayToSaveItem.pharmacyInfo.pharmacyNpi,
      )
    }
  }, [savingGuide, selectedWayToSaveItem])

  let isCopayPlan = selectedWayToSaveItem.isCopay

  const { history } = useMemberAppContext()

  const [renderUpdateLocationDialog, setRenderUpdateLocationDialog] =
    useState(false)

  const needsDrTalk = needsToDiscussReportWithDoctor(selectedWayToSaveItem)

  // let activeStep = SUMMARY_STEP_PHARMACY
  let activeStep = 2
  let numberOfSteps = selectedCoupon ? 5 : 4

  let STEP_TEXT_CONTROL_NUMBER: StepCardProps['step'] = 2
  // let STEP_TEXT_CONTROL = undefined
  let PHARMACY_CONTROL = undefined

  //for isMyCouponsView - if its original, we skip rxcompare step

  //TODO - my coupons view shortcut
  // if (isMyCouponsView) {
  //   if (selectedWayToSaveItem.savingStrategy === 'Coupons') {
  //     STEP_TEXT_CONTROL_NUMBER = 1
  //     activeStep = 0
  //     numberOfSteps = 3
  //   }
  // }

  let priceAfterDed = isCopayPlan
    ? undefined
    : selectedWayToSaveItem.altPriceAfterDed
  let priceBeforeDed = isCopayPlan
    ? undefined
    : selectedWayToSaveItem.altPriceBeforeDed
  let priceCopay = isCopayPlan ? selectedWayToSaveItem.altPriceCopay : undefined

  let priceCoupon = selectedCoupon ? `$${selectedCoupon.priceCash}` : undefined
  let priceCash =
    selectedWayToSaveItem.pharmacyInfo &&
    selectedWayToSaveItem.pharmacyInfo.isCash
      ? selectedWayToSaveItem.altPriceCashPharmacy
      : undefined

  let pharmacyName: string | undefined = selectedCoupon
    ? selectedCoupon.pharmacyName
    : selectedWayToSaveItem.pharmacyInfo &&
      selectedWayToSaveItem.pharmacyInfo.name
    ? selectedWayToSaveItem.pharmacyInfo.name
    : undefined

  //wipe out 'retail' as a name
  if (pharmacyName && pharmacyName.toLowerCase() === 'retail') {
    pharmacyName = undefined
  }

  let pharmacyLogoUrl = selectedCoupon
    ? selectedCoupon.pharmacyLogoUrl
    : selectedWayToSaveItem.pharmacyInfo
    ? selectedWayToSaveItem.pharmacyInfo.logoUrl
    : undefined

  let physicalAddress = selectedCoupon
    ? selectedCoupon.physicalAddress
    : selectedWayToSaveItem.pharmacyInfo
    ? selectedWayToSaveItem.pharmacyInfo.physicalAddress
    : undefined

  //in case of search results, resolve the name and address from the additional details
  if (additionalPharmacyDetails) {
    if (additionalPharmacyDetails.name) {
      pharmacyName = additionalPharmacyDetails.name
    }
    let rebuildAddress = buildPharmacyAddressFromNpiLookup(
      additionalPharmacyDetails,
    )
    physicalAddress = rebuildAddress
  }

  let mailOrderRedirectUrl =
    selectedWayToSaveItem.pharmacyInfo?.mailOrderRedirectUrl

  // let daysSupply = selectedWayToSaveItem.daysSupply

  let distance = selectedCoupon ? getDistanceStr(selectedCoupon) : undefined

  let tags: any[] = []
  let isAnyPharmacy: boolean = true

  let isWallmartCustomVariant = false
  if (selectedWayToSaveItem.savingStrategy === SavingStrategy.$4$9Program) {
    isWallmartCustomVariant = true
  }

  //BASIC STRATEGY RELATED TAGS
  //a) is retail and stay retail
  //b) in-house and stay in house
  if (selectedWayToSaveItem.type === 'basic_strategy') {
    if (
      selectedWayToSaveItem.pharmacyInfo === undefined ||
      selectedWayToSaveItem.pharmacyInfo.type === 'RETAIL'
    ) {
      isAnyPharmacy = true

      tags.push(
        <TagLabel
          key="AnyRetailPharmacy"
          size="small"
          color="blue"
          text={
            isWallmartCustomVariant ? 'Walmart Pharmacy' : 'Any Retail Pharmacy'
          }
        />,
      )
    }
    if (selectedWayToSaveItem.pharmacyInfo?.type === 'INHOUSE') {
      isAnyPharmacy = false
      tags.push(
        <TagLabel
          key="InhouseKey"
          size="small"
          color="blue"
          text="In-House Pharmacy"
        />,
      )
    }
  }

  //COUPON RELATE TAGSS
  if (selectedCoupon) {
    isAnyPharmacy = false
    tags.push(
      <TagLabel key="CouponKey" size="small" color="blue" text="Coupon" />,
    )
  }

  //THESE BELOW ARE SPECIFICALLY PHARMACY CHANGES TAGS
  if (selectedWayToSaveItem.type === 'pharmacy_change') {
    isAnyPharmacy =
      selectedWayToSaveItem.pharmacyInfo?.type === 'RETAIL' ? true : false
    if (isAnyPharmacy) {
      tags.push(
        <TagLabel
          key="AnyRetailPharmacy"
          size="small"
          color="blue"
          text={
            isWallmartCustomVariant ? 'Walmart Pharmacy' : 'Any Retail Pharmacy'
          }
        />,
      )
    }

    if (selectedWayToSaveItem.pharmacyInfo?.isMailOrder) {
      //alwasy push online for now if mail order
      tags.push(
        <TagLabel key="OnlineKey" size="small" color="green" text="Online" />,
      )
      tags.push(
        <TagLabel
          key="MailOrderKey"
          size="small"
          color="blue"
          text="Mail Order"
        />,
      )
    }
    if (selectedWayToSaveItem.pharmacyInfo?.type === 'INHOUSE') {
      tags.push(
        <TagLabel
          key="InhouseKey"
          size="small"
          color="blue"
          text="In-House Pharmacy"
        />,
      )
    }
    if (selectedWayToSaveItem.pharmacyInfo?.isCash) {
      tags.push(
        <TagLabel
          key="CashKey"
          size="small"
          color="blue"
          text="Cash Price Pharmacy"
        />,
      )
    }
  }

  //DAYS SUPPLY TAGS
  // if (
  //   selectedWayToSaveItem.daysSupply &&
  //   selectedWayToSaveItem.daysSupply === 90
  // ) {
  //   tags.push(
  //     <TagLabel key="90DaysKey" size="small" color="purple" text="90 Days" />,
  //   )
  // }

  let pricingShipAndTaxNote = undefined
  //if cash mccpd inlucde the disclaimer
  if (
    selectedWayToSaveItem.pharmacyInfo?.isCash &&
    isMarkCubanCostPlustName(selectedWayToSaveItem.pharmacyInfo?.name)
  ) {
    pricingShipAndTaxNote =
      '* Pricing does not include a standard shipping fee of $5 + taxes'
  }

  PHARMACY_CONTROL = (
    <PharmacyCardItem
      tags={tags}
      isAnyPharmacy={isAnyPharmacy}
      isSpecificPharmacy={!isAnyPharmacy}
      isWallmartCustomVariant={isWallmartCustomVariant}
      // daysSupply={daysSupply}
      priceAfterDed={priceAfterDed}
      priceBeforeDed={priceBeforeDed}
      priceCopay={priceCopay}
      priceCash={priceCash}
      priceCoupon={priceCoupon}
      pricingShipAndTaxNote={pricingShipAndTaxNote}
      pharmacyName={pharmacyName}
      pharmacyLogoUrl={pharmacyLogoUrl}
      physicalAddress={physicalAddress}
      distance={distance}
      onClickFindAnotherLocation={
        selectedCoupon
          ? () => {
              if (selectedCoupon) {
                setRenderUpdateLocationDialog(true)
              } else {
                //got a click for find another location but no coupon selected//this should never happen
                LOG.error(
                  'generic_error',
                  'got a click for find another location but no coupon selected selectedWayToSaveItem=',
                  selectedWayToSaveItem,
                )
              }
            }
          : undefined
      }
    />
  )

  let stepTextControlVariant:
    | 'fill_at_x'
    | 'switch_to_x'
    | 'mail_order_signup' = 'fill_at_x'

  //if no need to talk to dr, then we need to tell user switch instruction how to pharma change their rx
  //if rx change
  if (needsDrTalk) {
    stepTextControlVariant = 'fill_at_x'
  } else {
    stepTextControlVariant = 'switch_to_x'
  }

  //for mail order pharmacies, we want to render a Singup / Redirect URL
  let isMailOrder = false
  if (
    selectedWayToSaveItem.pharmacyInfo &&
    selectedWayToSaveItem.pharmacyInfo.isMailOrder === true
  ) {
    isMailOrder = true
    stepTextControlVariant = 'mail_order_signup'
  }

  //if its a coupon
  if (selectedCoupon) {
    //if rx change
    if (needsDrTalk) {
      stepTextControlVariant = 'fill_at_x'
    } else {
      stepTextControlVariant = 'switch_to_x'
    }
  }

  //if generic in-house that is not mail order
  // if (
  //   selectedWayToSaveItem.pharmacyInfo &&
  //   selectedWayToSaveItem.pharmacyInfo.type === 'INHOUSE' &&
  //   !isMailOrder
  // ) {
  //   //if we are changig from retail to JPS, text is diff + a link with how to modal
  //   if (selectedWayToSaveItem.type === 'pharmacy_change') {
  //     stepTextControlVariant = 'switch_to_x'
  //   }
  // }
  return (
    <>
      <SummaryPageLayout
        heroControl={
          <SummarHeroImageBox
            svgImageImportUrl={
              isMyCouponsView &&
              selectedWayToSaveItem.savingStrategy === 'Coupons'
                ? step_rxcompare_hero_mycoupon_variant_url
                : step_pharmacy_hero_url
              // ? '/howtosave/step_rxcompare_hero_mycoupon_variant.svg'
              // : '/howtosave/step_pharmacy_hero.svg'
            }
            height={144}
            width={183}
            onClick={() => {
              if (selectedCoupon) {
                history.push(ROUTE_OPPORTUNITY_HOW_TO_SAVE_COUPON_CARD_REVIEW)
              } else {
                history.push(ROUTE_OPPORTUNITY_HOW_TO_SAVE_FINAL_STEP)
              }
            }}
            alt="Pharmacy Details"
          />
        }
        stepControl={
          <StepCard
            step={STEP_TEXT_CONTROL_NUMBER}
            customStepContent={
              <StepTextForPharmacySummary
                variant={stepTextControlVariant}
                isWallmartFor49DollarStrategy={
                  selectedWayToSaveItem.savingStrategy ===
                  SavingStrategy.$4$9Program
                }
                pharmacyName={pharmacyName}
                signupUrl={
                  selectedWayToSaveItem.pharmacyInfo?.mailOrderRedirectUrl
                }
                saveUpToAmount={
                  selectedWayToSaveItem.type === 'coupon'
                    ? undefined
                    : selectedWayToSaveItem.saveAmountResolved
                }
              />
            }
          />
        }
        mainControl={
          <>
            <>{PHARMACY_CONTROL}</>
            {isMailOrder && mailOrderRedirectUrl && (
              <MailOrderButtonRedirect
                pharmacyName={pharmacyName}
                mailOrderRedirectUrl={mailOrderRedirectUrl}
              />
            )}
          </>
        }
        stepperControl={
          <HowToSaveStepperDots
            onClickNext={() => {
              if (selectedCoupon) {
                history.push(ROUTE_OPPORTUNITY_HOW_TO_SAVE_COUPON_CARD_REVIEW)
              } else {
                history.push(ROUTE_OPPORTUNITY_HOW_TO_SAVE_FINAL_STEP)
              }
            }}
            onClickBack={() => {
              history.goBack()
            }}
            activeStep={activeStep}
            numberOfSteps={numberOfSteps}
          />
        }
      />
      <CouponUpdateLocationDialog
        isOpen={renderUpdateLocationDialog}
        setRenderUpdateLocationDialog={setRenderUpdateLocationDialog}
        onNewCouponSelected={(coupon: any) => {
          onNewCouponSelected(coupon)
          setRenderUpdateLocationDialog(false)
        }}
        selectedWayToSaveItem={selectedWayToSaveItem}
      />
    </>
  )
}

//there are two variants - if it no New RX (i.e. coupon original, pharma original)
//- we are just telling them steps to switch to x pharmacy
//if its a new Rx - we just say fill that new prescription at x (in step 1 we already told the to talk to their dr and switch)
function StepTextForPharmacySummary({
  variant,
  pharmacyName,
  signupUrl,
  saveUpToAmount,
  isWallmartFor49DollarStrategy,
}: {
  variant: 'fill_at_x' | 'switch_to_x' | 'mail_order_signup'
  pharmacyName?: string
  signupUrl?: string
  saveUpToAmount?: number
  isWallmartFor49DollarStrategy?: boolean
}) {
  const { isAmazon } = useAmazon()
  const [renderHowToSwitchPharmacyInfoModal, setRenderHowToSwitchPharmacyInfo] =
    useState(false)

  return (
    <>
      {variant === 'fill_at_x' && (
        <STypography component="span" variant="body_regular">
          Fill your prescription at
          <STypography component="span" variant="body_bold">
            {' '}
            {pharmacyName
              ? pharmacyName
              : isWallmartFor49DollarStrategy
              ? 'Walmart pharmacy'
              : 'your in-network retail pharmacy'}
          </STypography>
          {!saveUpToAmount && (
            <STypography component="span" variant="body_regular">
              .
            </STypography>
          )}
          {saveUpToAmount && (
            <>
              <STypography component="span" variant="body_regular">
                {' '}
                to
              </STypography>
              <STypography component="span" variant="body_bold">
                {' '}
                save up to ${saveUpToAmount}/mo
              </STypography>
            </>
          )}
        </STypography>
      )}
      {variant === 'switch_to_x' && (
        <STypography component="span" variant="body_regular">
          Switch your prescription to{' '}
          {pharmacyName
            ? pharmacyName
            : isWallmartFor49DollarStrategy
            ? 'Walmart pharmacy'
            : 'your in-network retail pharmacy'}
          .{' '}
          <STypography
            component="span"
            variant="body_bold"
            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => {
              setRenderHowToSwitchPharmacyInfo(true)
            }}
          >
            How to switch your Rx to a new pharmacy?
          </STypography>
        </STypography>
      )}
      {variant === 'mail_order_signup' && (
        <STypography variant="body_regular">
          Sign up for an account and follow the steps on{' '}
          {isAmazon ? (
            <STypography
              // component="span"
              variant="body_bold"
              sx={{
                cursor: 'pointer',
                textDecoration: 'underline',
                '&:hover': {
                  opacity: 0.8,
                },
              }}
              onClick={() => {
                if (signupUrl) {
                  sendToMailOrderWebsite(signupUrl, pharmacyName)
                }
              }}
            >
              {signupUrl}
            </STypography>
          ) : (
            <STypography variant="body_bold">{signupUrl}</STypography>
          )}{' '}
        </STypography>
      )}

      {renderHowToSwitchPharmacyInfoModal && (
        <SDialog
          open={renderHowToSwitchPharmacyInfoModal}
          onClose={() => {
            setRenderHowToSwitchPharmacyInfo(false)
          }}
        >
          <HowToSwitchPharmacy />
        </SDialog>
      )}
    </>
  )
}

function MailOrderButtonRedirect({
  pharmacyName,
  mailOrderRedirectUrl,
}: {
  pharmacyName?: string
  mailOrderRedirectUrl: string
}) {
  if (!pharmacyName || !mailOrderRedirectUrl) {
    LOG.error(
      'pharmacy',
      'Missing pharmacy name or mail order redirect url for mail order button',
    )
  }

  //need some special handling for Mark Cuban agreement
  let buttonText = 'Go to ' + pharmacyName + ' website'
  //for now - we need additional agreement for cost plus drugs befor redirecting
  let promptForCostPlustAgreement = false
  if (isMarkCubanCostPlustName(pharmacyName)) {
    promptForCostPlustAgreement = true
  }

  const [
    renderLeaveDialogAndGoToCostPlus,
    setRenderLeaveDialogAndGoToCostPlus,
  ] = useState(false)
  const [dialogReloadKey, setDialogReloadKey] = useState(Math.random())

  return (
    <>
      <Grid item xs={12} sx={{ paddingTop: SPACING._1 }}>
        <SButton
          fullWidth
          onClick={() => {
            //for now - we need additional agreement for cost plus drugs befor redirecting
            if (promptForCostPlustAgreement) {
              setRenderLeaveDialogAndGoToCostPlus(true)
              setDialogReloadKey(Math.random())
            } else {
              sendToMailOrderWebsite(mailOrderRedirectUrl!, pharmacyName)
            }
          }}
          sx={{
            textTransform: 'none',
          }}
        >
          {buttonText}
        </SButton>
      </Grid>
      {renderLeaveDialogAndGoToCostPlus && (
        <CostPlusRedirectDialog
          key={dialogReloadKey}
          mailOrderRedirectUrl={mailOrderRedirectUrl}
          renderLeaveDialogAndGoToCostPlus={renderLeaveDialogAndGoToCostPlus}
          setRenderLeaveDialogAndGoToCostPlus={
            setRenderLeaveDialogAndGoToCostPlus
          }
          pharmacyName={pharmacyName}
        />
      )}
    </>
  )
}

function CostPlusRedirectDialog({
  mailOrderRedirectUrl,
  renderLeaveDialogAndGoToCostPlus,
  setRenderLeaveDialogAndGoToCostPlus,
  pharmacyName,
}: {
  mailOrderRedirectUrl: string
  renderLeaveDialogAndGoToCostPlus: boolean
  setRenderLeaveDialogAndGoToCostPlus: (value: boolean) => void
  pharmacyName?: string
}) {
  const { currentMemberProfile, jwtDetails } = useMemberAppContext()
  const memberService = useMemberService()
  const { isDesktop } = useSizes()

  //status of the current agreement, if the user has already agreed to the terms
  //we will redirect them immediatelly to the url as returned by the backend API
  const [currentCostPlusStatus, setCurrentCostPlusStatus] = useState<
    boolean | undefined
  >(undefined)
  const [isLoadingCurrentCostPlusStatus, setIsLoadingCurrentCostPlusStatus] =
    useState(false)
  const [
    isErrorLoadingCurrentCostPlusStatus,
    setIsErrorLoadingCurrentCostPlusStatus,
  ] = useState(false)

  async function loadCurrentCostPlusStatusAsync(
    tenantId: number,
    memberId: number,
  ) {
    //
    try {
      setIsLoadingCurrentCostPlusStatus(true)
      setIsErrorLoadingCurrentCostPlusStatus(false)
      const memberIdData: Partial<MemberIdData> = {
        memberId,
        tenantId,
      }
      const respStatus =
        await memberService.getMemberPolicyAndTermsAcceptanceStatusApi(
          memberIdData,
        )

      if (isValidResponse<MembersPolicyAndTermsAcceptanceStatus>(respStatus)) {
        setCurrentCostPlusStatus(respStatus.acceptedCurrentMCCPAVersion)
        setIsErrorLoadingCurrentCostPlusStatus(false)
      } else {
        LOG.error(
          'generic_error',
          'EBS service error response getting cost plus status',
          respStatus,
        )
        setIsErrorLoadingCurrentCostPlusStatus(true)
      }
      setIsLoadingCurrentCostPlusStatus(false)
    } catch (e) {
      LOG.error(
        'generic_error',
        'Net/Generic Error getting cost plus status',
        e,
      )
      setIsLoadingCurrentCostPlusStatus(false)
      setIsErrorLoadingCurrentCostPlusStatus(true)
    }
  }

  useEffect(() => {
    if (!currentMemberProfile) return
    loadCurrentCostPlusStatusAsync(
      Number(jwtDetails?.tenant_key),
      Number(jwtDetails?.id_key),
    )
  }, [jwtDetails, currentMemberProfile])

  //fetching current status - if not yet started / loading / error
  if (
    currentCostPlusStatus === undefined ||
    isLoadingCurrentCostPlusStatus ||
    isErrorLoadingCurrentCostPlusStatus
  ) {
    return (
      <SDialog
        open={renderLeaveDialogAndGoToCostPlus}
        onClose={() => {
          setRenderLeaveDialogAndGoToCostPlus(false)
        }}
      >
        <Grid
          container
          justifyContent={'center'}
          alignItems={'center'}
          sx={{ padding: SPACING._15 }}
        >
          {isLoadingCurrentCostPlusStatus && (
            <Grid item>
              <CircularProgress size={15} />
            </Grid>
          )}
          {isErrorLoadingCurrentCostPlusStatus && (
            <Grid item>
              <Box> Error getting current agreement status</Box>
            </Grid>
          )}
        </Grid>
      </SDialog>
    )
  }

  //not accepted before
  if (currentCostPlusStatus !== undefined && currentCostPlusStatus === false) {
    return (
      <CostPlusNewAcceptanceDialog
        mailOrderRedirectUrl={mailOrderRedirectUrl}
        renderLeaveDialogAndGoToCostPlus={renderLeaveDialogAndGoToCostPlus}
        setRenderLeaveDialogAndGoToCostPlus={
          setRenderLeaveDialogAndGoToCostPlus
        }
        pharmacyName={pharmacyName}
      />
    )
  }

  //already accepted
  if (currentCostPlusStatus !== undefined && currentCostPlusStatus === true) {
    sendToMailOrderWebsite(mailOrderRedirectUrl, pharmacyName)
  }

  return null
}

function sendToMailOrderWebsite(redirectUrl: string, pharmacyName?: string) {
  const gaService = new GAService()
  // window.open(redirectUrl, '_blank')

  // use this function so that mobile app can utilize the in app browser
  handleExternalLinkClick(redirectUrl)

  gaService.trackEvent({
    ...gaService.eventMap.pharmacy_change_redirect,
    label: pharmacyName,
    value: redirectUrl,
  })
}

function CostPlusNewAcceptanceDialog({
  mailOrderRedirectUrl,
  renderLeaveDialogAndGoToCostPlus,
  setRenderLeaveDialogAndGoToCostPlus,
  pharmacyName,
}: {
  mailOrderRedirectUrl: string
  renderLeaveDialogAndGoToCostPlus: boolean
  setRenderLeaveDialogAndGoToCostPlus: (value: boolean) => void
  pharmacyName?: string
}) {
  const { isMobile } = useSizes()
  const { currentMemberProfile } = useMemberAppContext()
  const memberService = useMemberService()
  const { isDesktop } = useSizes()

  const [newAgreementCheckValue, setNewAgreementCheckValue] = useState(false)

  const [saveResp, setSaveResp] = useState<CostPlusStatus | undefined>(
    undefined,
  )

  const displayName =
    currentMemberProfile?.['first-name'] +
    ' ' +
    currentMemberProfile?.['last-name']

  const termsText = getMCPD_AGREEMENT_TEXT(displayName)

  const [isSavingNewAcceptance, setIsSavingNewAcceptance] = useState(false)
  const [isErrorSavingNewAcceptance, setIsErrorSavingNewAcceptance] =
    useState(false)

  if (!mailOrderRedirectUrl) {
    LOG.error(
      'saving_report_data',
      'No expteced mail order redirect url found ',
      mailOrderRedirectUrl,
    )
  }

  return (
    <SDialog
      open={renderLeaveDialogAndGoToCostPlus}
      onClose={() => {
        setRenderLeaveDialogAndGoToCostPlus(false)
      }}
      customPaperSx={{
        width: isDesktop ? '543px' : '100%',
        padding: SPACING._15,
      }}
    >
      <Grid
        container
        sx={{ marginTop: '10px' }}
        // gap={SPACING._1}
        // direction="column"
        alignItems={'center'}
        spacing={SPACING._1}
      >
        <Grid item xs={12}>
          <STypography textAlign="center" variant="bodybig_bold">
            Are you sure you want to leave Scripta?
          </STypography>
        </Grid>
        <Grid item xs={12} sx={{ padding: '10px' }}>
          <TextField
            multiline
            rows={8}
            fullWidth
            variant="outlined"
            InputProps={{
              style: { fontSize: '13px', fontWeight: 600, width: '100%' },
            }}
            value={termsText}
          ></TextField>
        </Grid>
        <Grid item xs={12}>
          <Checkbox
            checked={newAgreementCheckValue}
            onChange={(e) => {
              setNewAgreementCheckValue(e.target.checked)
            }}
            sx={{ float: 'left', padding: '3px 3px 0px 0px', margin: '0px' }}
          />
          <STypography
            variant="bodysmall_regular"
            sx={{ marginTop: isMobile ? '0px' : '7px' }}
          >
            I understand and agree to be redirected to Mark Cuban Cost Plus
            Drugs
          </STypography>
        </Grid>
        {isSavingNewAcceptance && (
          <Grid
            item
            xs={12}
            container
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Grid item>
              <CircularProgress size={15} />
            </Grid>
          </Grid>
        )}
        {isErrorSavingNewAcceptance && (
          <Grid item xs={12}>
            <STypography variant="bodysmall_regular" sx={{ color: 'red' }}>
              Unable to save acceptance agreement. Please try again later.
            </STypography>
          </Grid>
        )}

        <Grid item xs={12}>
          <SButton
            sx={{
              textTransform: 'none',
            }}
            disabled={!newAgreementCheckValue}
            fullWidth
            variant="contained"
            onClick={async () => {
              try {
                setIsSavingNewAcceptance(true)

                const saveMcpdResp =
                  await memberService.saveNewCostPlustAcceptance()
                let allowUndefined = true
                const respStatus = checkMicroServiceResponseStatus(
                  saveMcpdResp,
                  allowUndefined,
                )
                if (!respStatus.isError) {
                  setSaveResp(saveMcpdResp)
                  setIsSavingNewAcceptance(false)
                  setIsErrorSavingNewAcceptance(false)
                  //close the dialog and redirect the user
                  setRenderLeaveDialogAndGoToCostPlus(false)
                  sendToMailOrderWebsite(mailOrderRedirectUrl, pharmacyName)
                } else {
                  LOG.error(
                    'generic_error',
                    'MS error response saving new cost plus status',
                    saveMcpdResp,
                  )
                  setIsErrorSavingNewAcceptance(true)
                }
                setIsSavingNewAcceptance(false)
              } catch (e) {
                LOG.error(
                  'generic_error',
                  'Net/Generic Error getting cost plus status',
                  e,
                )
                setIsErrorSavingNewAcceptance(true)
                setIsSavingNewAcceptance(false)
              }
            }}
          >
            Yes, continue
          </SButton>
          <Grid
            item
            xs={12}
            sx={{ marginTop: SPACING._05, marginBottom: SPACING._05 }}
          >
            <SButton
              sx={{
                textTransform: 'none',
              }}
              fullWidth
              variant="outlined"
              onClick={() => {
                setRenderLeaveDialogAndGoToCostPlus(false)
              }}
            >
              No, stay on this app
            </SButton>
          </Grid>
          {/* <Grid item sx={{ textAlign: 'center' }}>
            <Typography sx={{ fontSize: '11px', lineHeight: '12px' }}>
              By clicking “yes” you are agreeing to allow Scripta to share your
              identifying member information with the pharmacy you are
              selecting, and for that pharmacy to share information back to
              Scripta. All Privacy and HIPAA policies for both Scripta and the
              selected pharmacy will remain in-force.
            </Typography>
          </Grid> */}
          <Grid item sx={{ minHeight: '10px' }}></Grid>
        </Grid>
      </Grid>
    </SDialog>
  )
}

//show pharmacy details for the selected coupon
function CouponUpdateLocationDialog({
  isOpen,
  setRenderUpdateLocationDialog,
  onNewCouponSelected,
  selectedWayToSaveItem,
}: {
  isOpen: boolean
  setRenderUpdateLocationDialog: (render: boolean) => void
  onNewCouponSelected: (coupon: CouponDetails) => void
  selectedWayToSaveItem: WayToSaveItem
}) {
  const { COLORS } = useSColors()

  const [renderUpdatedCouponList, setRenderUpdatedCouponList] = useState(false)

  // let couponPharmacyProps: PharmacyCardItemProps = {
  //   pharmacyName: selectedCoupon.pharmacyName,
  //   // variant: 'coupon',
  //   distance: getDistanceStr(selectedCoupon),
  //   priceCoupon: `$${selectedCoupon.priceCash}`,
  //   pharmacyLogoUrl: selectedCoupon.pharmacyLogoUrl,
  //   physicalAddress: selectedCoupon.physicalAddress,
  // }
  return (
    <>
      <SDialog
        open={isOpen}
        onClose={() => {
          setRenderUpdateLocationDialog(false)
        }}
      >
        <Grid
          container
          justifyContent={'center'}
          gap={SPACING._1}
          sx={{ padding: SPACING._15 }}
        >
          <Grid item xs={12} container justifyContent={'center'}>
            <CircleWrappedFontIcon
              icon={
                <FontAwesomeIcon
                  icon={faLocationDot}
                  color={COLORS.TEXT_BRAND}
                />
              }
            ></CircleWrappedFontIcon>
          </Grid>
          <Grid item xs={12}>
            <STypography
              variant="bodybig_bold"
              sx={{ color: COLORS.TEXT_BRAND, textAlign: 'center' }}
            >
              Find another location
            </STypography>
          </Grid>

          <Grid item xs={12}>
            <MemberZipCodeLocationField
              showChangeWillReflectNextMonthDialog={false}
            />
          </Grid>
          <Grid item xs={12}>
            <SButton
              noCaps
              fullWidth
              variant="contained"
              onClick={() => {
                setRenderUpdatedCouponList(true)
                // setRenderUpdateLocationDialog(false)
              }}
            >
              Search for coupons
            </SButton>
          </Grid>
          <Grid item xs={12}>
            <SButton
              noCaps
              fullWidth
              variant="outlined"
              onClick={() => {
                setRenderUpdateLocationDialog(false)
              }}
            >
              Cancel
            </SButton>
          </Grid>
        </Grid>
      </SDialog>
      {renderUpdatedCouponList && (
        <SDialog
          open={renderUpdatedCouponList}
          onClose={() => {
            setRenderUpdatedCouponList(false)
          }}
        >
          <Grid
            container
            justifyContent={'center'}
            sx={{ padding: SPACING._15 }}
          >
            <Grid item xs={12} container justifyContent={'center'}>
              <CircleWrappedFontIcon
                icon={
                  <FontAwesomeIcon icon={faBallot} color={COLORS.TEXT_BRAND} />
                }
              ></CircleWrappedFontIcon>
            </Grid>
            <Grid item xs={12}>
              <STypography
                variant="bodybig_bold"
                sx={{ color: COLORS.TEXT_BRAND, textAlign: 'center' }}
              >
                Select a coupon
              </STypography>
            </Grid>
            <Grid item xs={12}>
              <CouponsListFromRxSense
                showMemberZipCodeUpdateField={false}
                showSaveUpToAmount={false}
                wayToSaveItem={selectedWayToSaveItem}
                onCouponClick={(updatedCoupon) => {
                  onNewCouponSelected(updatedCoupon)
                  setRenderUpdatedCouponList(false)
                  setRenderUpdateLocationDialog(false)
                }}
                showTryAnotherZipCodeButton={true}
                onClickTryAnotherZipCode={() => {
                  setRenderUpdatedCouponList(false)
                  setRenderUpdateLocationDialog(true)
                }}
              />
            </Grid>
          </Grid>
        </SDialog>
      )}
    </>
  )
}

export function HowToSaveCouponDetails(props: HowToSaveProps) {
  const {
    selectedOpportunity,
    selectedWayToSaveItem,
    selectedCoupon,
    isMyCouponsView,
  } = props

  const { history } = useMemberAppContext()
  const { t: couponT } = useSTranslate('howToSaveUsingCoupons')

  // let activeStep = SUMMARY_STEP_COUPON
  let activeStep = 3
  // let numberOfSteps =
  //   isMyCouponsView && selectedWayToSaveItem.savingStrategy === 'Coupons'
  //     ? 4
  //     : 5
  let numberOfSteps = 5

  // if (isMyCouponsView && selectedWayToSaveItem.savingStrategy === 'Coupons') {
  //   activeStep = 2
  // }

  let stepNumber: StepCardProps['step'] = 3
  let stepContent = (
    <STypography variant="body_regular">
      Show your discount card to your pharmacist at{' '}
      {selectedCoupon?.pharmacyName}.
    </STypography>
  )
  // if (isMyCouponsView && selectedWayToSaveItem.savingStrategy === 'Coupons') {
  //   stepNumber = 2
  //   //  stepContent = (
  //   //    <STypography variant="body_regular">
  //   //      Switch your prescription to {selectedCoupon?.pharmacyName}. How to
  //   //      switch your Rx to a new pharmacy?
  //   //    </STypography>
  //   //  )
  // }

  return (
    <SummaryPageLayout
      heroControl={
        selectedCoupon ? (
          <SummarHeroImageBox
            svgImageImportUrl={step_coupon_hero_url}
            alt="Coupon Details"
            height={144}
            width={183}
            onClick={() => {
              history.push(ROUTE_OPPORTUNITY_HOW_TO_SAVE_FINAL_STEP)
            }}
          />
        ) : undefined
      }
      stepControl={
        <StepCard step={stepNumber} customStepContent={stepContent} />
      }
      mainControl={
        <Grid container>
          <Grid item xs={12}>
            <InfoCard
              title={couponT('step4Reminder')}
              variant="default_secondary"
              body={couponT('step4ReminderDescription')}
              onClick={() => {}}
            ></InfoCard>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: SPACING._1 }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <DetailedCouponCard />
            </Box>
          </Grid>
        </Grid>
      }
      stepperControl={
        <HowToSaveStepperDots
          onClickNext={() => {
            history.push(ROUTE_OPPORTUNITY_HOW_TO_SAVE_FINAL_STEP)
          }}
          onClickBack={() => {
            history.goBack()
          }}
          activeStep={activeStep}
          numberOfSteps={numberOfSteps}
        />
      }
    />
  )
}

export function HowToSaveLastStep(props: HowToSaveLastStepProps) {
  const { COLORS } = useSColors()
  const { jwtDetails } = useMemberAppContext()

  const { saveBookmarkOpportunityToStorageAndContext } =
    useXServiceTodoBettername()

  const {
    selectedOpportunity,
    selectedWayToSaveItem,
    selectedCoupon,
    isMyCouponsView,
    setSelectedCoupon,
  } = props

  const needsRxTalk = needsToDiscussReportWithDoctor(selectedWayToSaveItem)

  const { history } = useMemberAppContext()
  const location = useLocation()

  const { currentMemberProfile, savingGuide } = useMemberAppContext()

  const switchData: SwitchData = {
    'original-id': selectedWayToSaveItem.originalId,
    'original-name': selectedWayToSaveItem.originalName,
    'original-dosage': selectedWayToSaveItem.originalDosage,
    'original-form': selectedWayToSaveItem.originalForm,
    'alternate-mma-id': selectedWayToSaveItem.altMmaIdForHelpMeSwitch
      ? selectedWayToSaveItem.altMmaIdForHelpMeSwitch
      : 0,
    'alternate-name': selectedWayToSaveItem.altName,
    'alternate-dosage': selectedWayToSaveItem.altDosage,
    'alternate-form': selectedWayToSaveItem.altForm,
    strategy: selectedWayToSaveItem.savingStrategy,
    isAlternativeCoupon: selectedCoupon ? true : false,
  }
  const [renderHelpMeSwitch, setRenderHelpMeSwitch] = useState(false)
  const [helpMeSwitchDialogKey, setHelpMeSwitchDialogKey] = useState(
    Math.random(),
  )
  const [showHelpMeSwitchThankYouDialog, setShowHelpMeSwitchThankYouDialog] =
    useState(false)
  const [isHelpMeSwitchErrorVariant, setIsHelpMeSwitchErrorVariant] =
    useState(false)

  const { isMobile } = useSizes()
  const notifier = useNotifier()

  // let activeStep = SUMMARY_STEP_FINAL
  let activeStep = 4
  let numberOfSteps =
    isMyCouponsView && selectedWayToSaveItem.savingStrategy === 'Coupons'
      ? 3
      : selectedCoupon
      ? 5
      : 4

  if (isMyCouponsView && selectedWayToSaveItem.savingStrategy === 'Coupons') {
    activeStep = 3
  }

  //if there is no coupon
  if (!selectedCoupon) {
    activeStep = 3
  }

  useEffect(() => {
    if (location) {
      const locationState = location.state as any
      if (locationState?.shouldOpenHelpMeSwitch) {
        setRenderHelpMeSwitch(true)
      }
    }
  }, [location])

  return (
    <SummaryPageLayout
      heroControl={
        <STypography
          variant="title2_bold"
          textAlign={'center'}
          sx={{
            color: isMobile
              ? COLORS.TEXT_LIGHT_PRIMARY_WHITE
              : COLORS.TEXT_BRAND,
          }}
        >
          That's it!
        </STypography>
      }
      stepControl={undefined}
      mainControl={
        <>
          <Grid container>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    overflowX: 'auto',
                    width: 'fit-content',
                    padding: '15px',
                    gap: '10px',
                  }}
                >
                  <Box>
                    {savingGuide && savingGuide.source === 'saving_report' && (
                      <HelperCardBookmark
                        onButtonClick={async () => {
                          const bookmark: BookmarkedOpportunity = {
                            originalDrugId: selectedWayToSaveItem.originalId,
                            alternativeDrugId: selectedWayToSaveItem.altId,
                            mmaIdForHelpMeSwitch:
                              selectedWayToSaveItem.altMmaIdForHelpMeSwitch
                                ? selectedWayToSaveItem.altMmaIdForHelpMeSwitch
                                : 0,
                            wayToSaveType: selectedWayToSaveItem.type,
                            selectedCoupon: selectedCoupon,
                            source: 'saving_report',
                          }
                          if (jwtDetails) {
                            await saveBookmarkOpportunityToStorageAndContext(
                              jwtDetails,
                              bookmark,
                            )
                          }
                          //TODO - error handling
                          notifier.sendMsg(
                            'This saving opportunity has been added to your bookmarks.',
                            'success',
                          )
                        }}
                      />
                    )}
                  </Box>
                  {needsRxTalk && (
                    <Box>
                      <HelperCardHelpSwitch
                        onButtonClick={() => setRenderHelpMeSwitch(true)}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              container
              sx={{ paddingTop: SPACING._2, width: 'max-content' }}
              //this is so we can do full widht of buttons in mobile
              direction={isMobile ? 'row' : 'column'}
              justifyContent={'center'}
              alignItems={'center'}
              gap={SPACING._1}
            >
              {!isMyCouponsView && (
                <Grid item xs={isMobile ? 12 : undefined}>
                  <SButton
                    data-testid="save-summary-go-back-to-home-button"
                    onClick={() => {
                      history.push(ROUTE_NEW_SAVING_REPORT_OPPORTUNITIES_MAIN)
                    }}
                    fullWidth={isMobile ? true : false}
                    variant="contained"
                    noCaps
                  >
                    Go back to home
                  </SButton>
                </Grid>
              )}
              {isMyCouponsView && (
                <Grid item xs={isMobile ? 12 : undefined}>
                  <SButton
                    onClick={() => {
                      history.push(ROUTE_NEW_COUPONS_MAIN)
                    }}
                    fullWidth={isMobile ? true : false}
                    noCaps
                    variant="contained"
                  >
                    Go Back to Coupons
                  </SButton>
                </Grid>
              )}
              <Grid item xs={isMobile ? 12 : undefined}>
                <SButton
                  data-testid="save-summary-find-other-ways-button"
                  fullWidth
                  onClick={() => {
                    props.onHandleFindAnotherWayToSave()
                  }}
                  variant="outlined"
                  // color="secondary"
                  noCaps
                >
                  Find other ways to save
                </SButton>
              </Grid>
            </Grid>
          </Grid>

          {renderHelpMeSwitch && (
            <Wizard
              key={helpMeSwitchDialogKey}
              onCanceled={() => {
                setRenderHelpMeSwitch(false)
                setHelpMeSwitchDialogKey(Math.random())
              }}
              onSubmittedOk={() => {
                setRenderHelpMeSwitch(false)
                setHelpMeSwitchDialogKey(Math.random())
                setShowHelpMeSwitchThankYouDialog(true)
              }}
              onFailedSubmission={() => {
                setIsHelpMeSwitchErrorVariant(true)
                setRenderHelpMeSwitch(false)
                setHelpMeSwitchDialogKey(Math.random())
                setShowHelpMeSwitchThankYouDialog(true)
              }}
              switchData={switchData}
            />
          )}
          {showHelpMeSwitchThankYouDialog && (
            <ThankYouForSwitchDialog
              onOk={() => {
                setShowHelpMeSwitchThankYouDialog(false)
              }}
              isErrorVariant={isHelpMeSwitchErrorVariant}
            />
          )}
        </>
      }
      stepperControl={
        <HowToSaveStepperDots
          onClickNext={() => {
            setRenderHelpMeSwitch(true)
          }}
          onClickBack={() => {
            history.goBack()
          }}
          activeStep={activeStep}
          numberOfSteps={numberOfSteps}
        />
      }
    />
  )
}

export function SummarHeroImageBox({
  svgImageImportUrl,
  height,
  width,
  onClick,
  alt,
  imgDataTestId,
}: {
  svgImageImportUrl: string
  height: number
  width: number
  onClick?: () => void
  alt: string
  imgDataTestId?: string
}) {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: height ?? 'max-content',
        }}
        onClick={onClick}
      >
        <ThemedSVGRemote
          imgDataTestId={imgDataTestId}
          importUrl={svgImageImportUrl}
          alt={alt}
          height={height}
          width={width}
        />
      </Box>
    </>
  )
}

//todo intl
function getMCPD_AGREEMENT_TEXT(displayName: string): string {
  return `HIPAA AUTHORIZATION FOR PHI USE OR DISCLOSURE

To facilitate care and coordinate my benefits, MARK CUBAN COST PLUS DRUG COMPANY will require access to my patient health data, including “Protected Health Information” (as defined in the Health Insurance Portability and Accountability Act of 1996, as amended). As explained below, this form constitutes Authorization for Cost Plus Drugs affiliated pharmacies (each, a “Pharmacy”) to use and disclose my PHI and other related health data to MARK CUBAN COST PLUS DRUG COMPANY.

Authorization for Use or Disclosure of Information

I, ${displayName}, hereby authorize Pharmacy to disclose my “Protected Health Information” (as defined in the Health Insurance Portability and Accountability Act of 1996, as amended) and other applicable personal health data, including my pharmacy records, to MARK CUBAN COST PLUS DRUG COMPANY. I acknowledge that the information disclosed pursuant to this authorization may be redisclosed and no longer protected under HIPAA.

I understand that this authorization is voluntary. If I do not sign this form, the treatment, payment, enrollment, or eligibility for benefits provided to me by Pharmacy will not be affected.

1. Information to Be Used and/or Disclosed and to Whom.
(a) The Pharmacy may use and disclose my pharmacy records, including PHI, to MARK CUBAN COST PLUS DRUG COMPANY pursuant to this authorization to enable MARK CUBAN COST PLUS DRUG COMPANY to coordinate my care and benefits and communicate with me regarding the same.
(b) This authorization is valid for 2 years or until I revoke this authorization.

2. Voluntary and Revocable Authorization.
I acknowledge that I have the right to revoke this authorization at any time by contacting the Pharmacy at support@mcpd.com. I understand that my revocation must be in writing. I also understand that my revocation will be valid except to the extent that the Pharmacy has taken action in reliance on the authorization before it is revoked.

3. Signature.
By signing below, I acknowledge and affirm the statements in this authorization form and acknowledge that I have received a copy of the signed form.`
}
