import React, { useContext, useEffect, useState } from 'react'
import history from 'src/app/scripta-browser-history'
import {
  DisabledFeaturesReqData,
  MemberSavingReport,
  isValidResponse,
} from '../types'
import {
  IS_NATIVE,
  nativeApi_sendLogoutMessageToNative,
  parseUiError,
} from './apiexec/utils'
import {
  DEFAULT_FEATURE_CONFIG,
  FeatureConfig,
  translateBackendFeatureConfig,
} from './config/features'
import { useConfigService } from './config/useConfigService'
import { DrugIdParams } from './drugprofile/types'
import { REG_ROUTE_LOGIN } from './registration/RegistrationSubRoutes'
import { DrugSuggestion } from './search/types'
import { SsoInitResponse } from './sso/SsoCompleteFirstTimeAccessForm'
import { LOG } from './v2/applog'
import { BookmarkedOpportunity, SavingGuideData } from './v2/saving-utils'
import { EKSMemberProfile } from './member/types'
import { SavingStrategy } from 'src/app/strategies/types'

export type PartnerCodes = 'Scripta'

export type MemberAppContextType = {
  jwt: string | undefined
  setJwt: (j: string) => void
  clearJwt: () => void
  currentMemberProfile?: EKSMemberProfile
  setCurrentMemberProfile: (profile: EKSMemberProfile | undefined) => void
  savingReport?: MemberSavingReport
  setSavingReport: (report: MemberSavingReport | undefined) => void
  history: typeof history
  navigateToLogin: any
  logout: any
  logoutMsg?: string
  setLogoutMsg: any
  featureConfig: FeatureConfig
  setFeatureConfig: (featureConfig: FeatureConfig) => void
  simpleUiSessionProps: SimpleUiSessionProps
  setSimpleUiSessionProps: (uiSession: SimpleUiSessionProps) => void
  selectedSuggestion: DrugSuggestion | undefined
  setSelectedSuggestion: (suggestion: DrugSuggestion | undefined) => void
  quickLinkCode?: string
  setQuickLinkCode: (quickLinkCode?: string) => void
  showFeedbackModal: boolean
  setShowFeedbackModal: (showFeedbackModal: boolean) => void
  mobileVersion?: string | null
  setMobileVersion: (v: string | null) => void
  clientUuidInRegistration?: string
  setClientUuidInRegistration: (clientId: string | undefined) => void
  renderDepInviteUponLoginAfterRegistration: boolean
  setRenderDepInviteUponLoginAfterRegistration: (
    renderDepInvite: boolean,
  ) => void
  langCodeSelectedOnLoginScreen?: string
  setLangCodeSelectedOnLoginScreen: (langCode?: string) => void
  isSsoLogin: boolean
  setIsSsoLogin: (isSso: boolean) => void
  ssoInitResponse?: SsoInitResponse
  setSsoInitResponse: (resp: SsoInitResponse) => void
  lastSeenDrugProfileIdParams?: DrugIdParams
  setLastSeenDrugProfileIdParams: (params: DrugIdParams) => void
  //resolved during registration/preregistration process (or after member login?)
  // clientUuid?: string
  // setClientUuid: (uuid?: string) => void
  //resolved when processing preregistration code - in case its a child company code
  //we want to fetch the logo of that child company, not the parent
  //NOTE - client child company can only be resolved when accessed by preregistration code for that company
  //clientUUid has no knowledge of child companies
  clientLogoChildCompanyId?: number
  setClientLogoChildCompanyId: (id?: number) => void

  isFirstTimePreregMemberAccess?: boolean
  setIsFirstTimePreregMemberAccess: (firstTime: boolean) => void
  preRegCredentials?: { userName: string; password: string }
  setPreRegCredentials: (creds?: { userName: string; password: string }) => void
  //for prereg members, upon successfull auth, we wanna show a little extra welcome dialog
  renderPreregFirstAccessWelcomeDialog: boolean
  setRenderPreregFirstAccessWelcomeDialog: (render: boolean) => void

  //extracted (parsed) claims from JWT
  jwtDetails?: JwtDetails
  setJwtDetails: (details?: JwtDetails) => void

  // isCopay: boolean
  // setIsCopay: (isCopay: boolean) => void
  savingGuide?: SavingGuideData
  setSavingGuide: (savingGuide: SavingGuideData) => void

  // chat widget control
  showChatWidget: () => void
  hideChatWidget: () => void

  isMobileBottomNavbarVisible: boolean
  setIsMobileBottomNavbarVisible: (isVisible: boolean) => void

  //TODO - get these from the backend instead of local storage
  bookmarkedOpportunities: BookmarkedOpportunity[]
  setBookmarkedOpportunities: (opps: BookmarkedOpportunity[]) => void

  mainDataLoading: boolean
  setMainDataLoading: (isLoading: boolean) => void
  isAmazon: boolean
  setIsAmazon: (isAmazon: boolean) => void

  isReportOpportunitiesPageFirstLoad: boolean
  setIsReportOpportunitiesPageFirstLoad: (isOpen: boolean) => void

  disabledStrategies?: SavingStrategy[]
  setDisabledStrategies: (strategies?: SavingStrategy[]) => void
}

export const MemberAppContext = React.createContext<MemberAppContextType>({
  jwt: undefined,
  setJwt: {} as any,
  clearJwt: {} as any,
  currentMemberProfile: undefined,
  setCurrentMemberProfile: {} as any,
  savingReport: undefined,
  setSavingReport: {} as any,
  history: {} as any,
  navigateToLogin: {} as any,
  logout: {} as any,
  logoutMsg: undefined,
  setLogoutMsg: {} as any,
  featureConfig: {} as any,
  setFeatureConfig: {} as any,
  simpleUiSessionProps: {} as any,
  setSimpleUiSessionProps: {} as any,
  selectedSuggestion: {} as any,
  setSelectedSuggestion: {} as any,
  quickLinkCode: {} as any,
  setQuickLinkCode: {} as any,
  showFeedbackModal: false as any,
  setShowFeedbackModal: {} as any,
  mobileVersion: {} as any,
  setMobileVersion: {} as any,
  clientUuidInRegistration: {} as any,
  setClientUuidInRegistration: {} as any,
  renderDepInviteUponLoginAfterRegistration: {} as any,
  setRenderDepInviteUponLoginAfterRegistration: {} as any,
  langCodeSelectedOnLoginScreen: {} as any,
  setLangCodeSelectedOnLoginScreen: {} as any,
  // currentRouteConfig: {} as any,
  // setCurrentRouteConfig: {} as any,
  isSsoLogin: {} as any,
  setIsSsoLogin: {} as any,
  ssoInitResponse: {} as any,
  setSsoInitResponse: {} as any,
  lastSeenDrugProfileIdParams: {} as any,
  setLastSeenDrugProfileIdParams: {} as any,
  // clientUuid: {} as any,
  // setClientUuid: {} as any,
  clientLogoChildCompanyId: {} as any,
  setClientLogoChildCompanyId: {} as any,

  isFirstTimePreregMemberAccess: {} as any,
  setIsFirstTimePreregMemberAccess: {} as any,
  preRegCredentials: {} as any,
  setPreRegCredentials: {} as any,
  renderPreregFirstAccessWelcomeDialog: false as any,
  setRenderPreregFirstAccessWelcomeDialog: {} as any,

  jwtDetails: {} as any,
  setJwtDetails: {} as any,

  savingGuide: {} as any,
  setSavingGuide: {} as any,

  showChatWidget: {} as any,
  hideChatWidget: {} as any,
  isMobileBottomNavbarVisible: false as any,
  setIsMobileBottomNavbarVisible: {} as any,
  bookmarkedOpportunities: {} as any,
  setBookmarkedOpportunities: {} as any,
  mainDataLoading: false as any,
  setMainDataLoading: {} as any,
  isAmazon: false as any,
  setIsAmazon: {} as any,

  isReportOpportunitiesPageFirstLoad: true as any,
  setIsReportOpportunitiesPageFirstLoad: {} as any,
  disabledStrategies: {} as any,
  setDisabledStrategies: {} as any,
})

export interface SimpleUiSessionProps {
  hasSeenAnimationOnce: boolean
}

export function MemberAppProvider({ children }: { children: any }) {
  const configService = useConfigService()
  const [mainDataLoading, setMainDataLoading] = useState(false)

  const [currentMemberProfile, setCurrentMemberProfile] = useState<
    EKSMemberProfile | undefined
  >()
  const [savingReport, setSavingReport] = useState<
    MemberSavingReport | undefined
  >()

  const [logoutMsg, setLogoutMsg] = useState<string | undefined>()
  const [jwt, setJwt] = useState<string | undefined>(undefined)
  const [showFeedbackModal, setShowFeedbackModal] = useState(false)

  //track quick link code, if any, given in the url for quick link registration
  const [quickLinkCode, setQuickLinkCode] = useState<string>()

  const [langCodeSelectedOnLoginScreen, setLangCodeSelectedOnLoginScreen] =
    useState<string | undefined>()

  const [featureConfig, setFeatureConfig] = useState<FeatureConfig>(
    DEFAULT_FEATURE_CONFIG,
  )

  const [disabledStrategies, setDisabledStrategies] = useState<
    SavingStrategy[] | undefined
  >([])

  const [mobileVersion, setMobileVersion] = useState<string | null>()

  //new identifier updated from registration process. we need it so we can fetch list of features
  //for client as soon as client is identified
  const [clientUuidInRegistration, setClientUuidInRegistration] = useState<
    string | undefined
  >(undefined)

  const [simpleUiSessionProps, setSimpleUiSessionProps] =
    useState<SimpleUiSessionProps>({
      hasSeenAnimationOnce: false,
    })

  const [selectedSuggestion, setSelectedSuggestion] = useState<
    DrugSuggestion | undefined
  >()

  const [
    renderDepInviteUponLoginAfterRegistration,
    setRenderDepInviteUponLoginAfterRegistration,
  ] = useState(false)

  const [isSsoLogin, setIsSsoLogin] = useState<boolean>(false)
  //response object given by initial sso call - contains info if member needs to complete profile
  const [ssoInitResponse, setSsoInitResponse] = useState<
    SsoInitResponse | undefined
  >(undefined)

  const [lastSeenDrugProfileIdParams, setLastSeenDrugProfileIdParams] =
    useState<DrugIdParams>()

  const [clientLogoChildCompanyId, setClientLogoChildCompanyId] =
    useState<number>()

  const [isFirstTimePreregMemberAccess, setIsFirstTimePreregMemberAccess] =
    useState<boolean>(false)
  const [preRegCredentials, setPreRegCredentials] = useState<{
    userName: string
    password: string
  }>()

  const [
    renderPreregFirstAccessWelcomeDialog,
    setRenderPreregFirstAccessWelcomeDialog,
  ] = useState<boolean>(false)

  const [jwtDetails, setJwtDetails] = useState<JwtDetails>()

  const [savingGuide, setSavingGuide] = useState<SavingGuideData>()

  const [bookmarkedOpportunities, setBookmarkedOpportunities] = useState<
    BookmarkedOpportunity[]
  >([])

  const [isMobileBottomNavbarVisible, setIsMobileBottomNavbarVisible] =
    useState(true)
  const [isAmazon, setIsAmazon] = useState(false)
  const [
    isReportOpportunitiesPageFirstLoad,
    setIsReportOpportunitiesPageFirstLoad,
  ] = useState(true)

  const clearJwt = () => {
    setJwt(undefined)
  }
  const navigateToLogin = () => {
    history.push(REG_ROUTE_LOGIN)
  }
  const logout = (msgReason?: string) => {
    clearJwt()
    setCurrentMemberProfile(undefined)
    setSavingReport(undefined)
    setIsReportOpportunitiesPageFirstLoad(true)
    if (IS_NATIVE()) {
      nativeApi_sendLogoutMessageToNative()
    }
    navigateToLogin()
    if (msgReason) {
      setLogoutMsg(msgReason)
    }
  }

  async function loadFeaturesConfigAsync(clientUuid: string) {
    if (!clientUuid) {
      LOG.error(
        'registration',
        'Fatala error - cannot init features without a valid client uuid - either from jwt token or from found client during registration',
        {
          clientUuid,
        },
      )
      return
    }

    try {
      const disableFeatureResp =
        await configService.getDisabledFeaturesViaClientUuid(clientUuid)
      if (isValidResponse<string[]>(disableFeatureResp)) {
        const uiConfig = translateBackendFeatureConfig(disableFeatureResp)
        //make sure its a new object so we get a proper refresh of context,
        setFeatureConfig({ ...uiConfig })
      } else {
        LOG.error(
          'generic_error',
          'Not a valid reponse from get disabled feature api,disableFeatureResp=',
          disableFeatureResp,
        )
      }
    } catch (error) {
      console.error('Error loading disabled feature config....', error)
      LOG.error('generic_error', 'Error loading disabled feature config', error)
    }
  }

  async function loadDisabledStrategiesConfigAsync(clientUuid: string) {
    if (!clientUuid) {
      LOG.error(
        'registration',
        'Cannot init disabled strategies without a valid client uuid - either from jwt token or from found client during registration',
        {
          clientUuid,
        },
      )
      return
    }

    try {
      const getStrategiesResp =
        await configService.getExcludedStrategiesViaClientUuid(clientUuid)
      if (isValidResponse<SavingStrategy[]>(getStrategiesResp)) {
        if (getStrategiesResp && getStrategiesResp.length > 0) {
          setDisabledStrategies(getStrategiesResp)
        } else {
          setDisabledStrategies([])
        }
      } else {
        LOG.error(
          'generic_error',
          'Not a valid reponse from get disabled strategies api,disabledStrategies=',
          disabledStrategies,
        )
      }
    } catch (error) {
      LOG.error(
        'generic_error',
        'Error loading disabled strategies config',
        parseUiError(error),
      )
    }
  }

  //refresh features config once member is logged in
  useEffect(() => {
    if (jwtDetails || clientUuidInRegistration) {
      let idToUse = undefined
      if (clientUuidInRegistration) {
        idToUse = clientUuidInRegistration
      } else {
        if (jwtDetails && jwtDetails.tenant_uuid_key) {
          idToUse = jwtDetails.tenant_uuid_key
        }
      }

      //start by default features config
      //this is bc we want to enable the app to run even if we get timeouts from the legacy beanstalk from now on
      //until we fully migrate the fetching of disabled features
      const newConfig = { ...DEFAULT_FEATURE_CONFIG }

      setFeatureConfig(newConfig)

      if (idToUse) {
        loadFeaturesConfigAsync(idToUse)
      }
    }
  }, [clientUuidInRegistration, jwtDetails])

  //refresh excluded strategies once member is logged in
  useEffect(() => {
    if (jwtDetails || clientUuidInRegistration) {
      let idToUse = undefined
      if (clientUuidInRegistration) {
        idToUse = clientUuidInRegistration
      } else {
        if (jwtDetails && jwtDetails.tenant_uuid_key) {
          idToUse = jwtDetails.tenant_uuid_key
        }

        if (idToUse) {
          loadDisabledStrategiesConfigAsync(idToUse)
        }
      }
    }
  }, [clientUuidInRegistration, jwtDetails])

  useEffect(() => {
    if (jwt) {
      try {
        const parsedJwt = parseJwt(jwt)
        setJwtDetails(parsedJwt)
        //
      } catch (error) {
        console.error('Error parsing JWT details', error)
        LOG.error(
          'generic_error',
          'Unable to parse JWT details',
          parseUiError(error),
        )
      }
    }
  }, [jwt])

  // chat widget control
  // https://www.zoho.com/salesiq/help/developer-section/js-api-chat-float-visibility.html
  const hideChatWidget = () => {
    const zohoValue = (window as any).$zoho

    if (
      zohoValue &&
      zohoValue.salesiq &&
      zohoValue.salesiq.floatbutton &&
      typeof zohoValue.salesiq.floatbutton.visible === 'function'
    ) {
      zohoValue.salesiq.floatbutton.visible('hide')
    } else {
    }
  }

  const showChatWidget = () => {
    const zohoValue = (window as any).$zoho

    if (
      zohoValue &&
      zohoValue.salesiq &&
      zohoValue.salesiq.floatbutton &&
      typeof zohoValue.salesiq.floatbutton.visible === 'function'
    ) {
      zohoValue.salesiq.floatbutton.visible('show')
    } else {
    }
  }

  return (
    <MemberAppContext.Provider
      value={{
        isAmazon,
        setIsAmazon,
        mainDataLoading,
        setMainDataLoading,
        currentMemberProfile,
        setCurrentMemberProfile,
        jwt,
        setJwt,
        clearJwt,
        navigateToLogin,
        history,
        logout,
        savingReport,
        setSavingReport,
        logoutMsg,
        setLogoutMsg,
        featureConfig,
        setFeatureConfig,
        simpleUiSessionProps,
        setSimpleUiSessionProps,
        selectedSuggestion,
        setSelectedSuggestion,
        quickLinkCode,
        setQuickLinkCode,
        showFeedbackModal,
        setShowFeedbackModal,
        mobileVersion,
        setMobileVersion,
        clientUuidInRegistration,
        setClientUuidInRegistration,
        renderDepInviteUponLoginAfterRegistration,
        setRenderDepInviteUponLoginAfterRegistration,
        langCodeSelectedOnLoginScreen,
        setLangCodeSelectedOnLoginScreen,
        isSsoLogin,
        setIsSsoLogin,
        ssoInitResponse,
        setSsoInitResponse,
        lastSeenDrugProfileIdParams,
        setLastSeenDrugProfileIdParams,
        // clientUuid,
        // setClientUuid,
        clientLogoChildCompanyId,
        setClientLogoChildCompanyId,

        isFirstTimePreregMemberAccess,
        setIsFirstTimePreregMemberAccess,
        preRegCredentials,
        setPreRegCredentials,
        renderPreregFirstAccessWelcomeDialog,
        setRenderPreregFirstAccessWelcomeDialog,

        jwtDetails,
        setJwtDetails,

        savingGuide,
        setSavingGuide,

        showChatWidget,
        hideChatWidget,

        bookmarkedOpportunities,
        setBookmarkedOpportunities,
        isMobileBottomNavbarVisible,
        setIsMobileBottomNavbarVisible,

        isReportOpportunitiesPageFirstLoad,
        setIsReportOpportunitiesPageFirstLoad,
        disabledStrategies,
        setDisabledStrategies,
      }}
    >
      {children}
    </MemberAppContext.Provider>
  )
}

export function useMemberAppContext() {
  return useContext(MemberAppContext)
}

export function parseJwt(token: string): JwtDetails {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(''),
  )

  //small fix to convert member id that is a string to number in
  let parsed = JSON.parse(jsonPayload)
  //member id key

  if (parsed.id_key) {
    parsed.id_key = parseInt(parsed.id_key)
  }
  // tenant_key
  if (parsed.tenant_key) {
    parsed.tenant_key = parseInt(parsed.tenant_key)
  }

  //TEMP provider test role
  if (parsed.user_name_key === 'providertest') {
    let roles: ROLE[] = ['MEMBER', 'PROVIDER']
    parsed.roles = roles
  }

  return parsed
}

export interface JwtDetails {
  id_key: string
  tenant_key: number

  company_name_key: string

  user_name_key?: string
  display_name_key: string
  middle_name_key?: string

  health_plan_id_key: number
  mail_opt_out_key: boolean
  email_opt_out_key: boolean
  sms_opt_out_key: boolean
  do_not_contact_key: boolean
  block_access_key: boolean
  marketing_mail_opt_out_key: boolean
  marketing_email_opt_out_key: boolean
  marketing_sms_opt_out_key: boolean
  tenant_short_code_key: string
  // tenant_data_key: string
  tenant_uuid_key: string
  registered_key: boolean
  pre_registered_key: boolean
  zip_key?: string

  //additional roles - default is MEMBER
  roles: ROLE[]
}
export type ROLE = 'MEMBER' | 'PROVIDER'

export function isProvider(jwtDetails?: JwtDetails): boolean {
  let isProvider = false
  if (
    jwtDetails &&
    Array.isArray(jwtDetails.roles) &&
    jwtDetails.roles.includes('PROVIDER')
  ) {
    isProvider = true
  }
  if (jwtDetails && jwtDetails.user_name_key === 'uat_provider') {
    isProvider = true
  }
  return isProvider
}

export interface TempProviderData {
  providerId: number
  providerName: string
  totalOpportunities: number
}
