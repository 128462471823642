export const URL_PARAM_QUICKLINK_CODE = 'code'

//used for 'delayed' processing of quick links - edge case where we want to still go the main portal login page
//(i.e. used in member outreach url) but in case they click Register, we retrieve data from quicklink still
export const URL_PARAM_QUICKLINK_PROCESS_LATER = 'pl'

//for preregistration
export const URL_PARAM_PREREGISTRATION_CODE = 'prc'

export const URL_PARAM_RESET_PASSWORD_USERNAME = 'username'

export interface PortalAppConfig {
  TERMS_AND_CONDITIONS_URL: string
  // PRIVACY_POLICY_URL:string,
  FAQ_URL: string
  PRIVACY_POLICY_URL: string

  MEMBER_SUPPORT_NUMBER: string
  MEMBER_SUPPORT_EMAIL: string
  TIMEOUT_MILIS: number
  TESTING_TIMEOUT_MILIS: number
}
