import { Grid, useMediaQuery, useTheme } from '@mui/material'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react'
import { checkMicroServiceResponseStatus } from 'src/app/apiexec/utils'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import STypography from 'src/app/system/customcomponents/STypography'
import BaseErrorCard from 'src/app/system/error/BaseErrorCard'
import { LOG } from 'src/app/v2/applog'
import { PasswordResetData } from '../../types'
import { GAService } from '../application/ga/GAService'
import { useNotifier } from '../hooks/useNotify'
import { PasswordHints } from './PasswordHints'
import { checkPasswordMatchesRequirements } from './PreReg'
import { useRegistration } from './RegistrationContext'
import { REG_ROUTE_LOGIN } from './RegistrationSubRoutes'
import { RegistrationToolbar } from './RegistrationToolbar'
import { useRegistrationService } from './useRegistrationService'

//need a bit more details to properly handle same error response code indifferent flows
//i.e. registration vs forgot password
export const UI_RESPONSE_CODE_DECORATION_INVALID_OTP_RESET_PWD = 'resetpwdcode'

export const EKS_CODE_INVALID_OTP = 216

export function ResetPassword() {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const gaService = new GAService()
  const { history, setApiResponse, pwdResetData, setPwdResetData } =
    useRegistration()
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const service = useRegistrationService()
  const { t } = useSTranslate(['register', 'settings'])
  const title = t('enter4DigitCode')

  // const [newPassword, setNewPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [passwordsMatch, setPasswordsMatch] = useState(true)
  //start with true just to avoid showing the warning message by default
  //will update once user clicks next
  const [newPassMeetsStandard, setNewPassMeetsStandard] = useState(true)

  const [invalidOtp, setInvalidOtp] = useState(false)

  const { sendMsg } = useNotifier()
  useEffect(() => {
    //only process comparison if both have some values
    if (pwdResetData && pwdResetData.newPassword && passwordConfirm) {
      if (pwdResetData.newPassword === passwordConfirm) {
        setPasswordsMatch(true)
      } else {
        setPasswordsMatch(false)
      }
    }
  }, [pwdResetData, passwordConfirm])

  useEffect(() => {
    document.title = 'Reset Password - Scripta Insights'
  })

  async function asyncResetPassword(resetData: PasswordResetData) {
    if (
      !resetData.oneTimePassCode ||
      !resetData.newPassword ||
      !resetData.userName
    ) {
      return
    }
    setIsLoading(true)
    try {
      const resp = await service.resetPassword_EKS(
        resetData.oneTimePassCode,
        resetData.newPassword,
        resetData.userName,
      )
      setIsLoading(false)

      let allowUndefined = true
      const respCheck = checkMicroServiceResponseStatus(resp, allowUndefined)
      if (!respCheck.isError) {
        sendMsg(t('resetPasswordSuccess'), 'success')
        const autoLogin: any = {
          justRegisteredUserName: resetData.userName,
          justRegisteredPassword: resetData.newPassword,
        }
        history.push(REG_ROUTE_LOGIN, { autoLogin: autoLogin })
      } else {
        //first check if its an expected error code where otp is invalid
        if (resp && resp.scriptaErrorCode === EKS_CODE_INVALID_OTP) {
          setInvalidOtp(true)
        } else {
          LOG.error(
            'registration',
            'Invalid server response in reset password flow',
            resp,
          )
          setError('Unable to reset password at the moment')
        }
      }
    } catch (e) {
      LOG.error(
        'registration',
        'Network level exception in reset password API - request timed out / failed to reach the API server.',
        e,
      )
      setIsLoading(false)
      setError('Unable to reset password at the moment')
    }
  }

  const isResetPasswordDisabled =
    !pwdResetData.oneTimePassCode ||
    !pwdResetData.newPassword ||
    !passwordConfirm ||
    !passwordsMatch

  if (error) {
    return (
      <BaseErrorCard
        headerTitle="Cannot reset password"
        content={
          <>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <STypography variant="body_regular">
                We are sorry, but we are unable to reset your password at the
                moment. Please contact our member support for assistance via
                chat or email.
              </STypography>
            </Grid>
          </>
        }
      />
    )
  }

  return (
    <>
      <Grid
        container
        gap={isSmall ? '10px' : '32px'}
        style={{ padding: '30px' }}
      >
        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: 700,
              textAlign: 'center',
            }}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <STypography variant="body_regular">
            A 4-digit verification code has been sent to the email address
            associated with your account. Please enter it below to reset your
            password.
          </STypography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            autoComplete="off"
            variant="outlined"
            id="otp"
            name="otp"
            fullWidth
            placeholder={title}
            label={title}
            value={
              pwdResetData.oneTimePassCode ? pwdResetData.oneTimePassCode : ''
            }
            onChange={(e) => {
              // Only allow numbers and limit to 4 digits
              const numericValue = e.target.value
                .replace(/[^0-9]/g, '')
                .slice(0, 4)
              setPwdResetData({
                ...pwdResetData,
                oneTimePassCode: numericValue,
              })
            }}
            error={invalidOtp}
            helperText={
              invalidOtp
                ? 'The code you entered is invalid. Please enter a valid code or request a new code by clicking Cancel and then Forgot Password again.'
                : ''
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            autoComplete="off"
            variant="outlined"
            fullWidth
            type="password"
            id="newPassword"
            name="newPassword"
            placeholder={t('settings:newPassword')}
            label={
              passwordsMatch
                ? t('settings:newPassword')
                : t('settings:doNotMatch')
            }
            value={pwdResetData.newPassword ? pwdResetData.newPassword : ''}
            onChange={(e) => {
              setPwdResetData({
                ...pwdResetData,
                newPassword: e.target.value,
              })
              //if it was previously false and now its true, mark it in the ui appropriatelly
              //so the error dissapears once the user provides a valid one
              if (
                !newPassMeetsStandard &&
                checkPasswordMatchesRequirements(e.target.value)
              ) {
                setNewPassMeetsStandard(true)
              }
            }}
            error={!passwordsMatch}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            autoComplete="off"
            variant="outlined"
            type="password"
            fullWidth
            id="passwordConfirm"
            name="passwordConfirm"
            placeholder={t('settings:confirmPassword')}
            label={
              passwordsMatch
                ? t('settings:confirmPassword')
                : t('settings:doNotMatch')
            }
            value={passwordConfirm}
            onChange={(e) => {
              setPasswordConfirm(e.target.value)
            }}
            error={!passwordsMatch}
          />
        </Grid>
        {!newPassMeetsStandard && (
          <Grid item xs={12}>
            <Grid item xs={12}>
              <Typography variant="caption" style={{ color: 'red' }}>
                {t('login:resetPasswordWarning')}
              </Typography>
            </Grid>
          </Grid>
        )}

        <Grid item xs={12}>
          <PasswordHints />
        </Grid>

        <Grid item xs={12}>
          <RegistrationToolbar
            customNextLabel={t('resetPassword')}
            onClickNext={async () => {
              if (
                pwdResetData.newPassword &&
                checkPasswordMatchesRequirements(pwdResetData.newPassword)
              ) {
                setNewPassMeetsStandard(true)
                await asyncResetPassword(pwdResetData)
                gaService.trackEvent(
                  gaService.eventMap.forgot_password_submit_final,
                )
              } else {
                setNewPassMeetsStandard(false)
              }
            }}
            nextDisabled={isResetPasswordDisabled || isLoading}
            nextLoading={isLoading}
            onClickCancel={() => history.push(REG_ROUTE_LOGIN)}
            sidePadding={isSmall ? undefined : '20%'}
          />
        </Grid>
      </Grid>
      {/* <div>{JSON.stringify(apiResponse)}</div> */}
    </>
  )
}

// function validatePasswordMeetsStandards(password?: string) {
//   if (!password) {
//     return false
//   }
//   var re =
//     /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]).{8,}/
//   if (password.length > 20) {
//     return false
//   }
//   return re.test(password)
// }
