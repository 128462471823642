import ReactGA from 'react-ga4'
import TagManager from 'react-gtm-module'
import { IS_PROD } from 'src/app/apiexec/utils'
import { IS_BLUE_PROD } from 'src/app/apiexec/utils'
import { IS_INTEGRATION } from 'src/app/apiexec/utils'

// poc 1 for dev, general testing, local, poc
const GA_POC_ID = 'G-VSR2XRSPGD'
// poc 2 for a cleaner testing env, only switch to this when
// you're certain the events are tagged correctly
const GA_POC_2_ID = 'G-JRS3S9E0KH'
const GA_INT_ID = 'G-E61B9KK5CC'
const GA_BLUE_ID = 'G-YMR6MXG1S2'
const GA_PROD_ID = 'G-JXHHWX1XJD'

const GTM_POC_ID = 'GTM-TQRN8VB'
const GTM_INT_ID = 'GTM-N72QWZC'
const GTM_BLUE_ID = 'GTM-N33NBJM'
const GTM_PROD_ID = 'GTM-MHJGKBS'

if (IS_INTEGRATION()) {
  ReactGA.initialize(GA_INT_ID)
  TagManager.initialize({ gtmId: GTM_INT_ID })
} else if (IS_BLUE_PROD()) {
  ReactGA.initialize(GA_BLUE_ID)
  TagManager.initialize({ gtmId: GTM_BLUE_ID })
} else if (IS_PROD()) {
  ReactGA.initialize(GA_PROD_ID)
  TagManager.initialize({ gtmId: GTM_PROD_ID })
} else {
  // local and other
  ReactGA.initialize(GA_POC_ID)
  TagManager.initialize({ gtmId: GTM_POC_ID })
}

window.onload = () => {
  const zohoValue = (window as any).$zoho

  // Check if $zoho and $zoho.salesiq are defined before proceeding
  if (zohoValue && zohoValue.salesiq) {
    zohoValue.salesiq.afterReady = () => {
      // Check if chatbutton is defined before accessing its methods
      if (zohoValue.salesiq.chatbutton) {
        zohoValue.salesiq.chatbutton.click(function () {
          const gaService = new GAService()
          gaService.trackEvent(gaService.eventMap.general_chat_widget)
        })
      }

      // @ts-ignore
      const shouldReloadSalesIqChat = window.shouldReloadSalesIqChat
      if (shouldReloadSalesIqChat) {
        // hacky way to clear the previous chat log
        zohoValue.salesiq.chatwindow.reload()
        zohoValue.salesiq.reset()
        console.log("Reloading SalesIQ chat window")
        // @ts-ignore
        window.shouldReloadSalesIqChat = false
      }
    }
  }
}

interface GAEventData {
  name: keyof typeof GAEventNames
  category: string
  action: string
  label?: string
  value?: any
}

// https://support.google.com/analytics/answer/9267735?hl=en
export enum GAEventNames {
  forgot_username = 'forgot_username',
  forgot_password = 'forgot_password',

  step_1_registration = 'step_1_registration',
  step_2_registration = 'step_2_registration',
  step_3_registration = 'step_3_registration',
  step_4_registration = 'step_4_registration',
  step_5_registration = 'step_5_registration',
  step_6_registration = 'step_6_registration',
  step_7_registration = 'step_7_registration',
  step_8_registration = 'step_8_registration',

  general = 'general',

  homepage = 'homepage',

  footer_link = 'footer_link',

  savings_report = 'savings_report',

  my_meds_page = 'my_meds_page',

  pharmacy_change = 'pharmacy_change',
}

export enum GAEventCategory {
  login_screen = 'Login Screen',
  registration = 'Registration',
  homepage = 'homepage',
  footer_section = 'Footer Section',
  chat_widget = 'Chat Widget',
  savings_report = 'Savings Report',
  my_prescribed_meds = 'My Meds - Prescribed Meds',
  pharmacy_redirect = 'Pharmacy Redirect',
}

export enum GAEventAction {
  button_click = 'Button Click',
}

export class GAService {
  public trackEvent(eventData: GAEventData): void {
    try {
      const { name, category, action, label, value } = eventData

      ReactGA.event(name, {
        category: category,
        action: action,
        label: label,
        value,
      })
    } catch (error) {
      // Handle the error here
      console.error('An error occurred while tracking the event:', error)
    }
  }

  private generate_savings_report_event(label: string) {
    return {
      name: GAEventNames.savings_report,
      category: GAEventCategory.savings_report,
      action: GAEventAction.button_click,
      label,
    }
  }

  private generate_home_page_event(label: string) {
    return {
      name: GAEventNames.homepage,
      category: GAEventCategory.homepage,
      action: GAEventAction.button_click,
      label,
    }
  }

  // each event corresponds to a single row on this table
  // https://scripta-insights.atlassian.net/wiki/spaces/PR/pages/2051342352/Google+Events+Tagging+-+Member+Portal
  public eventMap = {
    forgot_username: {
      name: GAEventNames.forgot_username,
      category: GAEventCategory.login_screen,
      action: GAEventAction.button_click,
      label: 'Forgot Username',
    },
    forgot_username_submit: {
      name: GAEventNames.forgot_username,
      category: GAEventCategory.login_screen,
      action: GAEventAction.button_click,
      label: 'Forgot Username Submit',
    },

    forgot_password: {
      name: GAEventNames.forgot_password,
      category: GAEventCategory.login_screen,
      action: GAEventAction.button_click,
      label: 'Forgot Password',
    },
    forgot_password_submit_email: {
      name: GAEventNames.forgot_password,
      category: GAEventCategory.login_screen,
      action: GAEventAction.button_click,
      label: 'Forgot Password Submit',
    },
    forgot_password_submit_final: {
      name: GAEventNames.forgot_password,
      category: GAEventCategory.login_screen,
      action: GAEventAction.button_click,
      label: 'Forgot Password Submit - Reset Password Final',
    },

    general_chat_widget: {
      name: GAEventNames.general,
      category: GAEventCategory.chat_widget,
      action: GAEventAction.button_click,
      label: 'Chat Widget Icon',
    },

    step_1_reg_next: {
      name: GAEventNames.step_1_registration,
      category: GAEventCategory.registration,
      action: GAEventAction.button_click,
      label: 'Step-1 Next',
    },
    step_1_reg_failed: {
      name: GAEventNames.step_1_registration,
      category: GAEventCategory.registration,
      action: GAEventAction.button_click,
      label: 'Step-1 Next - failed',
    },
    step_2_reg_next: {
      name: GAEventNames.step_2_registration,
      category: GAEventCategory.registration,
      action: GAEventAction.button_click,
      label: 'Step-2 Next',
    },
    step_3_reg_next_email: {
      name: GAEventNames.step_3_registration,
      category: GAEventCategory.registration,
      action: GAEventAction.button_click,
      label: 'Email Radio-3',
    },
    step_3_reg_next_text: {
      name: GAEventNames.step_3_registration,
      category: GAEventCategory.registration,
      action: GAEventAction.button_click,
      label: 'Text Radio-3',
    },
    step_3_reg_next_security_q: {
      name: GAEventNames.step_3_registration,
      category: GAEventCategory.registration,
      action: GAEventAction.button_click,
      label: 'Answer Security Questions - 3',
    },
    step_4_reg_next: {
      name: GAEventNames.step_4_registration,
      category: GAEventCategory.registration,
      action: GAEventAction.button_click,
      label: 'Verify - 4',
    },
    step_5_reg_next: {
      name: GAEventNames.step_5_registration,
      category: GAEventCategory.registration,
      action: GAEventAction.button_click,
      label: 'Enter Email Address- 5',
    },
    step_6_reg_next_opt_in: {
      name: GAEventNames.step_6_registration,
      category: GAEventCategory.registration,
      action: GAEventAction.button_click,
      label: 'Mobile Opt-In - 6',
    },
    step_6_reg_next_opt_out: {
      name: GAEventNames.step_6_registration,
      category: GAEventCategory.registration,
      action: GAEventAction.button_click,
      label: 'Mobile Opt-Out - 6',
    },
    step_7_reg_next: {
      name: GAEventNames.step_7_registration,
      category: GAEventCategory.registration,
      action: GAEventAction.button_click,
      label: 'username and password created - 7',
    },
    step_7_reg_next_error: {
      name: GAEventNames.step_7_registration,
      category: GAEventCategory.registration,
      action: GAEventAction.button_click,
      label: 'FAILED missing registration JWT - 7',
    },
    step_8_reg_next: {
      name: GAEventNames.step_8_registration,
      category: GAEventCategory.registration,
      action: GAEventAction.button_click,
      label: 'Continue to Login',
    },

    generate_footer_link_event: (label: string) => {
      return {
        name: GAEventNames.footer_link,
        category: GAEventCategory.footer_section,
        action: GAEventAction.button_click,
        label,
      }
    },

    home_page_download_savings_report: this.generate_home_page_event(
      'Download Savings Report',
    ),
    home_page_dependent_invite_sent: this.generate_home_page_event(
      'Dependent Invite Modal - sent',
    ),
    home_page_learn_more: this.generate_home_page_event('Learn More Footer'),

    savings_report_hide_drug_A:
      this.generate_savings_report_event('Hiding Drug A'),
    savings_report_hide_drug_B:
      this.generate_savings_report_event('Hiding Drug B'),
    savings_report_save_button: this.generate_savings_report_event(
      'Switch & Save Button in Savings Report',
    ),

    my_meds_filter_select_prescribed: {
      name: GAEventNames.my_meds_page,
      category: GAEventCategory.my_prescribed_meds,
      action: GAEventAction.button_click,
      label: 'Prescribed Meds Filter',
    },
    my_meds_filter_select_hidden: {
      name: GAEventNames.my_meds_page,
      category: GAEventCategory.my_prescribed_meds,
      action: GAEventAction.button_click,
      label: 'Hidden Meds Filter',
    },
    my_meds_hide_med: {
      name: GAEventNames.my_meds_page,
      category: GAEventCategory.my_prescribed_meds,
      action: GAEventAction.button_click,
      label: 'Hide this Med',
    },

    pharmacy_change_redirect: {
      name: GAEventNames.pharmacy_change,
      category: GAEventCategory.pharmacy_redirect,
      action: GAEventAction.button_click,
    },
  }
}
