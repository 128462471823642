import { useEffect, useState } from 'react'
import { useNotifier } from '../hooks/useNotify'
import ssoWelcomeHeroUrl from './sso_welcome_hero.svg'

import {
  Box,
  Checkbox,
  CircularProgress,
  Grid,
  Link,
  Typography,
} from '@mui/material'
import { useRegistrationService } from '../registration/useRegistrationService'
import { CompleteSsoPayload, useSsoService } from './useSsoService'

// import { SsoSmsOptInSection } from './SsoSmsOptInSection'

import { useHistory } from 'react-router'
import { validateEmail } from 'src/app/registration/EditContactInfoEKS'
import { PortalTextField } from 'src/app/registration/PreReg'
import { getScriptaTheme } from 'src/app/styles/theme-config-scripta'
import STypography from 'src/app/system/customcomponents/STypography'
import { SPACING } from 'src/app/system/theme2'
import { ThemedSVGRemote } from 'src/app/v2/ThemedSVGRemote'
import { handleExternalLinkClick } from 'src/app/widgets/ExternalLink'
import { v4 as uuidv4 } from 'uuid'
import { useMemberAppContext } from '../MemberAppContext'
import { ROUTE_COMPLETE_LOGIN } from '../PortalRoutes'
import { checkMicroServiceResponseStatus, parseUiError } from '../apiexec/utils'
import { NewPortalDialogDesign } from '../application/NewDialogs'
import { DEFAULT_PORTAL_APP_CONFIG } from '../config/appconfig'
import { CernerDialog } from '../drugprofile/CernerTermsDialog'
import {
  isDisplayFriendlyStringValidNumber,
  toDigitsOnly,
  toDisplayFriendlyString,
} from '../member/phone-field-utils'
import { SMSTermsOfUseContent } from '../registration/SMSTermsOfUseContent'
import SButton from '../system/customcomponents/SButton'
import { TargetRoute } from '../v2/SavingGuide'
import { LOG } from '../v2/applog'
import { useSizes } from 'src/app/v2/useNewHooks'
import { useSColors } from 'src/app/styles/scripta-theme'

export interface SsoForm {
  email?: string
  mobileNumber?: string
  acceptedAll3?: boolean
  smsOptIn?: string
  smsAcceptedTerms?: boolean // needs to be true when smsOptIn = true
}
export interface SsoInitResponse {
  askToRegister: boolean
  //carry jwt here, in case user needs to complete registration first
  jwt: string
}

export interface SsoFormValidationResult {
  validEmail: boolean
  validPhone: boolean
}

function checkDuplicateFields(resp: any) {
  let emailAlreadyUsed = false
  let phoneAlreadyUsed = false
  if (
    resp &&
    resp['fieldErrors'] &&
    resp['fieldErrors'].length > 0 &&
    resp['fieldErrors'].some((fe: any) => fe.propertyName === 'email')
  ) {
    emailAlreadyUsed = true
  }
  if (
    resp &&
    resp['fieldErrors'] &&
    resp['fieldErrors'].length > 0 &&
    resp['fieldErrors'].some((fe: any) => fe.propertyName === 'phone')
  ) {
    phoneAlreadyUsed = true
  }

  return {
    emailAlreadyUsed,
    phoneAlreadyUsed,
  }
}

export function SsoCompleteFirstTimeAccessForm() {
  const ssoService = useSsoService()
  //member id and tenant id will be here
  const { ssoInitResponse, setJwt } = useMemberAppContext()
  const [targetRoute, setTargetRoute] = useState<TargetRoute>()
  const { isMobile } = useSizes()
  const { setRenderDepInviteUponLoginAfterRegistration } = useMemberAppContext()

  const [isSavingNewSsoProfile, setIsSavingNewSsoProfile] =
    useState<boolean>(false)
  function pushToSubrouteViaEffect(subroute: string) {
    //we use the object, not a simple string, to ensure useEffect triggers
    const route: TargetRoute = {
      //todo - this guid is probably not needed anymore, we use a object in state
      uuid: uuidv4(),
      route: subroute,
    }

    setTargetRoute(route)
  }
  useEffect(() => {
    if (targetRoute) {
      history.push(targetRoute.route)
    }
  }, [targetRoute])

  useEffect(() => {
    setRenderDepInviteUponLoginAfterRegistration(true)
  }, [])

  const { sendMsg } = useNotifier()

  const [completeErrorMsg, setCompleteErrorMsg] = useState<string | undefined>()

  const history = useHistory()

  //dont enable validation of the step until at first click submit
  const [enableValidation, setEnableValidation] = useState<boolean>(false)

  const [ssoAdditionalInfo, setSsoAdditionalInfo] = useState<SsoForm>({} as any)

  //fields from backend validation to be treated differently
  const [validEmail, setValidEmail] = useState<boolean>(false)
  const [validPhone, setValidPhone] = useState<boolean>(false)
  const [emailAlreadyUsed, setEmailAlreadyUsed] = useState<boolean>(false)
  const [phoneAlreadyUsed, setPhoneAlreadyUsed] = useState<boolean>(false)
  const { COLORS } = useSColors()

  useEffect(() => {
    let validPhone = false
    if (ssoAdditionalInfo.smsOptIn === 'yes') {
      const phone = toDigitsOnly(ssoAdditionalInfo.mobileNumber ?? '')
      if (isDisplayFriendlyStringValidNumber(phone)) {
        validPhone = true
      } else {
        validPhone = false
      }
    } else {
      //not opted in - if present, it should be valid at least
      if (ssoAdditionalInfo.mobileNumber) {
        const phone = toDigitsOnly(ssoAdditionalInfo.mobileNumber ?? '')
        if (isDisplayFriendlyStringValidNumber(phone)) {
          validPhone = true
        } else {
          validPhone = false
        }
      } else {
        validPhone = true
      }
    }
    setValidPhone(validPhone)
  }, [ssoAdditionalInfo, enableValidation])

  useEffect(() => {
    let validEmail = false
    if (!ssoAdditionalInfo.email) {
      validEmail = false
    }

    if (validateEmail(ssoAdditionalInfo.email ?? '')) {
      validEmail = true
    } else {
      validEmail = false
    }
    setValidEmail(validEmail)
  }, [ssoAdditionalInfo, enableValidation])

  const theme = getScriptaTheme()

  const [showTermsDialog, setShowTermsDialog] = useState<boolean>(false)
  //dont start showing errors until they start type in the field

  const handleSubmit = () => {
    setEnableValidation(true)

    // Basic required validations
    if (!validEmail) {
      console.log('sso', 'submit blocked - invalid email', {
        email: ssoAdditionalInfo.email,
        validEmail,
      })
      return
    }

    if (!ssoAdditionalInfo.acceptedAll3) {
      console.log('sso', 'submit blocked - terms not accepted')
      return
    }

    // SMS opt-in specific validations
    if (ssoAdditionalInfo.smsOptIn === 'yes') {
      if (!validPhone) {
        console.log('sso', 'submit blocked - invalid phone number')
        return
      }
      if (!ssoAdditionalInfo.smsAcceptedTerms) {
        console.log('sso', 'submit blocked - SMS terms not accepted')
        return
      }
    }

    console.log('all validations passed, proceeding with profile creation')
    // All validations passed, proceed with profile creation
    createSsoProfile(ssoAdditionalInfo)
  }

  async function createSsoProfile(ssoForm: SsoForm) {
    // setLoadingBackDropOpen(true)
    setIsSavingNewSsoProfile(true)
    try {
      let completeSsoProfilePayload: CompleteSsoPayload = {
        email: ssoAdditionalInfo.email!,
        'sms-opt-in': false,
      }
      if (
        ssoAdditionalInfo.smsOptIn &&
        validPhone &&
        ssoAdditionalInfo.mobileNumber
      ) {
        let backendPhone = `1${toDigitsOnly(ssoAdditionalInfo.mobileNumber)}`
        console.log('will use phone= ' + backendPhone)
        completeSsoProfilePayload['sms-opt-in'] = true
        completeSsoProfilePayload.phone = backendPhone
      }

      if (!ssoInitResponse) {
        LOG.error(
          'sso',
          'cannot complete sso profile, no initial sso data found, ',
          ssoInitResponse,
        )
        setCompleteErrorMsg(
          "Unfortunately, we're unable to complete your registration at this time. Please try starting a new session from A to Z.",
        )
        setIsSavingNewSsoProfile(false)
        return
      }
      if (!ssoInitResponse!.jwt) {
        LOG.error(
          'sso',
          'cannot complete sso profile, no jwt found in initial sso data',
          ssoInitResponse,
        )
        setIsSavingNewSsoProfile(false)
        setCompleteErrorMsg(
          "Unfortunately, we're unable to complete your registration at this time. Please try starting a new session from A to Z.",
        )
        return
      }

      const completeSsoResp = await ssoService.submitCompleteSsoProfile(
        ssoInitResponse!.jwt,
        completeSsoProfilePayload,
      )

      //add a dedicated check here to check for 401 unauthorized meaning jwt issued by the SAML is expired
      if (
        completeSsoResp !== undefined &&
        completeSsoResp.httpStatusCode === 401
      ) {
        LOG.error(
          'sso',
          'jwt issued by the SAML procesor is expired, cannot completed profile.',
        )
        setCompleteErrorMsg(
          'Your session has expired. Please login again via your benefits provider in order to complete your registration.',
        )
        setIsSavingNewSsoProfile(false)
        return
      }
      let allowUndefined = true
      let responseStatus = checkMicroServiceResponseStatus(
        completeSsoResp,
        allowUndefined,
      )
      if (!responseStatus.isError) {
        const tempJwt = ssoInitResponse.jwt

        sendMsg('Registered successfully', 'success')
        //now we can set the login token back to context and continue to main page
        setJwt(tempJwt)
        setIsSavingNewSsoProfile(false)

        //TODO - is this push even needed? setting the JWT shold be enough to trigger the normal login flow
        pushToSubrouteViaEffect(ROUTE_COMPLETE_LOGIN)
      } else {
        LOG.error('sso', 'error completing sso profile', {
          ssoInitResponse,
          completeSsoResp,
        })
        const { emailAlreadyUsed, phoneAlreadyUsed } =
          checkDuplicateFields(completeSsoResp)

        //handle expected (duplicate emails or phones differently)
        if (emailAlreadyUsed || phoneAlreadyUsed) {
          LOG.warn(
            'sso',
            'duplicate email or phone,emailAlreaydUsed,phoneAlreadyUsed',
            { emailAlreadyUsed, phoneAlreadyUsed },
          )
          if (emailAlreadyUsed) {
            setEmailAlreadyUsed(emailAlreadyUsed)
            setValidEmail(false)
            setCompleteErrorMsg(
              'This email address is already in use. Please use a different email address.',
            )
          } else {
            setEmailAlreadyUsed(false)
            setValidEmail(true)
            setCompleteErrorMsg('')
          }

          if (phoneAlreadyUsed) {
            setPhoneAlreadyUsed(phoneAlreadyUsed)
            setValidPhone(false)
            setCompleteErrorMsg(
              'This phone number is already in use. Please use a different phone number.',
            )
          } else {
            setPhoneAlreadyUsed(false)
            setValidPhone(true)
            setCompleteErrorMsg('')
          }
        } else {
          setCompleteErrorMsg(
            'Unfortunately, we are not able to complete your registration at this time. Please try to login again via your benefits provider.',
          )
          LOG.error(
            'sso',
            'Error completing SSO first time access form',
            completeSsoResp,
          )
        }
        setIsSavingNewSsoProfile(false)
        return

        // setSsoValidationResult({
        //   ...ssoValidationResult,
        // })
      }
    } catch (e) {
      LOG.error(
        'sso',
        'Error cought in creating SSO user profile ',
        parseUiError(e),
      )
      setCompleteErrorMsg(
        'Unfortunately, we are not able to complete your registration at this time. Please contact our member support.',
      )
    } finally {
      setIsSavingNewSsoProfile(false)
    }
  }

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        xs={12}
        sx={{
          padding: '10px 10px 20px 10px',
          // minHeight: '200px',
          borderRadius: '15px 15px 0px 0px',
          background: theme.customized.colors.BACKGROUND_GRADIENT,
          color: theme.customized.colors.TEXT_LIGHT_PRIMARY_WHITE,
        }}
      >
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <ThemedSVGRemote
            importUrl={ssoWelcomeHeroUrl}
            alt={'Welcome to scripta'}
            width={160}
            height={160}
          />
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <STypography
            variant="bodybig_bold"
            sx={{ fontSize: '24px', fontWeight: 700 }}
          >
            Welcome to Scripta 👋
          </STypography>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <STypography
            variant="body_regular"
            sx={{
              fontSize: '18px',
              fontWeight: 400,
              paddingLeft: isMobile ? SPACING._1 : '20px',
              paddingRight: isMobile ? SPACING._1 : '20px',
              paddingTop: '10px',
            }}
          >
            You’re on your way to prescription savings! Let’s complete your
            account set up.
          </STypography>
        </Grid>
      </Grid>

      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        xs={12}
        sx={{
          padding: isMobile ? SPACING._1 : SPACING._2,
        }}
      >
        <Grid item xs={12}>
          <STypography
            variant="bodybig_bold"
            sx={{ color: theme.customized.colors.TEXT_BRAND }}
          >
            Enter your preferred email
          </STypography>
        </Grid>
        <Grid item xs={12}>
          <STypography variant="body_regular">
            This is where we'll send your Personalized Savings Notifications.
          </STypography>
        </Grid>

        <Grid item xs={12} sx={{ marginTop: '10px' }}>
          <PortalTextField
            dataTestId="sso-email-input"
            // variant="outlined"
            id="email"
            sx={{ maxWidth: '300px', width: '300px' }}
            name="email"
            // placeholder={tRegister('enterYourEmailAddress')}
            customLabelNode={
              <Box display="flex">
                <Typography sx={{ fontSize: '13px', fontWeight: 600 }}>
                  Email address
                </Typography>
                <Typography sx={{ color: 'red' }}>*</Typography>
              </Box>
            }
            value={ssoAdditionalInfo.email ? ssoAdditionalInfo.email : ''}
            // required
            onChange={(e: any) => {
              // setIsEmailTouched(true)
              if (e.target.value.length < 44) {
                setSsoAdditionalInfo({
                  ...ssoAdditionalInfo,
                  email: e.target.value,
                })
              } else {
                setSsoAdditionalInfo({
                  ...ssoAdditionalInfo,
                  email: e.target.value,
                })
              }
            }}
            error={enableValidation && !validEmail ? true : undefined}
            helperText={
              enableValidation && !validEmail
                ? //check if its bc its a duplicate email by backend response
                  emailAlreadyUsed
                  ? 'This email address is already in use'
                  : 'Please enter a valid email address'
                : ''
            }
          />
        </Grid>

        <Grid item xs={12}>
          <STypography
            variant="bodybig_bold"
            sx={{ color: theme.customized.colors.TEXT_BRAND }}
          >
            Never miss out on Rx savings!
          </STypography>
        </Grid>
        <Grid item xs={12}>
          <STypography variant="body_regular">
            Can we send you text messages as we find opportunities to save money
            on your prescriptions?
          </STypography>
        </Grid>
        <Grid item container xs={12} sx={{ marginTop: '10px' }}>
          <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              data-testid="sso-sms-opt-in-checkbox"
              sx={{
                float: 'left',
                padding: '0px',
                margin: '2px',
                color: theme.customized.colors.TEXT_BRAND,
                '&.Mui-checked': {
                  color: theme.customized.colors.TEXT_BRAND,
                },
              }}
              checked={ssoAdditionalInfo.smsOptIn === 'yes' ? true : false}
              onChange={(e: any) => {
                if (e.target.checked) {
                  setSsoAdditionalInfo({
                    ...ssoAdditionalInfo,
                    smsOptIn: 'yes',
                  })
                } else {
                  setSsoAdditionalInfo({
                    ...ssoAdditionalInfo,
                    smsOptIn: 'no',
                  })
                }
              }}
              name="checkedB"
              color="primary"
            />
          </Grid>
          <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
            <STypography variant="body_regular">
              Sign me up. I love savings!
            </STypography>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '10px' }}>
          <PortalTextField
            dataTestId="sso-mobile-input"
            customLabelNode={
              <Typography sx={{ fontSize: '13px', fontWeight: 600 }}>
                Cell phone
              </Typography>
            }
            sx={{ maxWidth: '300px', width: '300px' }}
            startAdornment={
              <Typography
                sx={{
                  color: 'text.primary',
                  marginRight: '4px',
                  userSelect: 'none',
                }}
              >
                +1
              </Typography>
            }
            // placeholder={t('mobilePlaceholder')}
            value={ssoAdditionalInfo.mobileNumber}
            onChange={(e) => {
              //process and format input to display friendly form
              //up to 10 digits
              if (toDigitsOnly(e.target.value).length <= 10) {
                let displayNumberValue = toDisplayFriendlyString(e.target.value)
                setSsoAdditionalInfo({
                  ...ssoAdditionalInfo,
                  mobileNumber: displayNumberValue,
                })
              }
            }}
            error={
              enableValidation &&
              ssoAdditionalInfo.smsOptIn === 'yes' &&
              !validPhone
                ? true
                : undefined
            }
            helperText={
              enableValidation &&
              ssoAdditionalInfo.smsOptIn === 'yes' &&
              !validPhone
                ? phoneAlreadyUsed
                  ? 'This phone number is already in use'
                  : 'Please enter a valid phone number if you want to opt in to SMS notifications'
                : ''
            }
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ textAlign: 'left', display: 'flex', alignItems: 'center' }}
        >
          <Checkbox
            data-testid="sso-sms-terms-checkbox"
            checked={ssoAdditionalInfo.smsAcceptedTerms ? true : false}
            onChange={(e) => {
              //render the confirmation dialog, then process the opt out from it
              if (e.target.checked) {
                setSsoAdditionalInfo({
                  ...ssoAdditionalInfo,
                  smsAcceptedTerms: true,
                })
              } else {
                setSsoAdditionalInfo({
                  ...ssoAdditionalInfo,
                  smsAcceptedTerms: false,
                })
              }
            }}
            sx={{
              float: 'left',
              padding: '0px',
              margin: '2px',
              color: theme.customized.colors.TEXT_BRAND,
              '&.Mui-checked': {
                color: theme.customized.colors.TEXT_BRAND,
              },
            }}
          />
          <STypography variant="bodysmall_regular">
            I agree to the text messaging 
          </STypography>
          <STypography variant="body_regular" sx={{ fontSize: '13px' }}>
            <span>
              <Link
                onClick={(e: any) => {
                  setShowTermsDialog(true)
                }}
                sx={{ fontSize: '13px', cursor: 'pointer' }}
                underline={'always'}
              >
                {' '}
                Terms of Use
              </Link>
            </span>
          </STypography>
          <NewPortalDialogDesign
            open={showTermsDialog}
            onClose={() => {
              setShowTermsDialog(false)
            }}
            title={'SMS Terms of Use'}
          >
            <SMSTermsOfUseContent />
          </NewPortalDialogDesign>
        </Grid>
        {enableValidation &&
          ssoAdditionalInfo.smsOptIn === 'yes' &&
          !ssoAdditionalInfo.smsAcceptedTerms && (
            <Grid item xs={12}>
              <Typography
                sx={{ color: 'red', fontSize: '13px', fontWeight: 500 }}
              >
                Please accept the SMS Terms of Use to sign up for SMS
                notifications.
              </Typography>
            </Grid>
          )}
        <Grid
          item
          xs={12}
          sx={{
            marginTop: '5px',
            textAlign: 'left',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Checkbox
            data-testid="sso-all-three-licenses-checkbox"
            checked={ssoAdditionalInfo.acceptedAll3 ? true : false}
            onChange={(e) => {
              if (e.target.checked) {
                setSsoAdditionalInfo({
                  ...ssoAdditionalInfo,
                  acceptedAll3: e.target.checked,
                })
              } else {
                setSsoAdditionalInfo({
                  ...ssoAdditionalInfo,
                  acceptedAll3: false,
                })
              }
            }}
            sx={{
              float: 'left',
              padding: '0px',
              margin: '2px',
              color: theme.customized.colors.TEXT_BRAND,
              '&.Mui-checked': {
                color: theme.customized.colors.TEXT_BRAND,
              },
            }}
          />
          <Grid item>
            <DenseAll3Licenses />
          </Grid>
        </Grid>
        {enableValidation && !ssoAdditionalInfo.acceptedAll3 && (
          <Grid item xs={12}>
            <Typography
              sx={{ color: 'red', fontSize: '13px', fontWeight: 500 }}
            >
              Please accept our Terms of Service and Privacy Policy before
              continuing{' '}
            </Typography>
          </Grid>
        )}
        <Grid
          item
          container
          justifyContent="center"
          xs={12}
          sx={{ marginTop: '20px' }}
        >
          <SButton
            data-testid="sso-submit-button"
            noCaps
            // variant="contained"
            sx={{
              marginLeft: 'auto',
              marginRight: 'auto',
              height: '40px',
              width: '240px',

              fontSize: '1rem',
            }}
            disabled={
              isSavingNewSsoProfile ||
              !ssoAdditionalInfo.email ||
              !ssoAdditionalInfo.acceptedAll3 ||
              !validEmail
            }
            onClick={async () => {
              handleSubmit()
            }}
          >
            {isSavingNewSsoProfile && (
              <CircularProgress
                size={14}
                sx={{
                  color: COLORS.TEXT_GREY_LIGHT,
                  marginRight: 1,
                }}
              />
            )}
            Submit
          </SButton>
        </Grid>
        {completeErrorMsg && (
          <Grid item xs={12} sx={{ padding: '10px' }}>
            <Typography
              sx={{
                color: 'red',
                fontSize: '1rem',
                marginTop: '16px',
                textAlign: 'center',
              }}
            >
              {completeErrorMsg}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export function DenseAll3Licenses() {
  const [showCernerDialog, setShowCernerDialog] = useState(false)
  const fontSize = '13px'

  return (
    <>
      <Typography
        variant="body2"
        component="p"
        style={{ wordBreak: 'break-word', lineHeight: '15px', fontSize }}
      >
        I agree to the Scripta Insights{' '}
        <Link
          onClick={() => {
            handleExternalLinkClick(
              DEFAULT_PORTAL_APP_CONFIG.TERMS_AND_CONDITIONS_URL,
            )
          }}
          sx={{ fontSize, cursor: 'pointer' }}
        >
          Terms of Use
        </Link>
        ,{' '}
        <Link
          onClick={() => {
            handleExternalLinkClick(
              DEFAULT_PORTAL_APP_CONFIG.PRIVACY_POLICY_URL,
            )
          }}
          sx={{ fontSize, cursor: 'pointer' }}
        >
          Privacy Policy
        </Link>{' '}
        and{' '}
        <Link
          onClick={() => {
            setShowCernerDialog(true)
          }}
          sx={{ fontSize, cursor: 'pointer' }}
        >
          Cerner Multum's License
        </Link>
        .
      </Typography>
      <CernerDialog
        cernerDialogType={'userAgreement'}
        open={showCernerDialog}
        onClose={() => setShowCernerDialog(false)}
      />
    </>
  )
}
