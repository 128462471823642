import { useEffect, useState } from 'react'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { IS_DEV, IS_NATIVE, getUrlParameter } from '../apiexec/utils'
import { ROUTE_APP_ROOT, ROUTE_COMPLETE_LOGIN } from '../PortalRoutes'
import { usePortalAppConfig } from '../config/usePortalAppConfig'
import DataNotFoundImage from './../../images/data_not_found.png'
import OfflineLogo from '../../images/logo.png'
import { useMemberAppContext } from '../MemberAppContext'
import history from 'src/app/scripta-browser-history'
import { REG_ROUTE_LOGIN } from '../registration/RegistrationSubRoutes'
import { useLocation } from 'react-router-dom'
import { URL_PARAM_MOBILE_APP_VERSION } from './ForceUpgradeDialog'
import SButton from 'src/app/system/customcomponents/SButton'
import STypography from 'src/app/system/customcomponents/STypography'
import MemberSupportFooter from 'src/app/registration/MemberSupportFooter'
import { Grid } from '@mui/material'

export function GenericErrorPage({
  additionalDetails,
  //extract from the url in the case of context error handler so we can maintain it when user clicks back to home page
  mobileVersionToRedirectTo,
}: {
  additionalDetails?: string
  mobileVersionToRedirectTo?: string
}) {
  const PORTAL_APP_CONFIG = usePortalAppConfig()
  const location = useLocation()

  const { t } = useSTranslate(['application'])
  const { mobileVersion, isSsoLogin } = useMemberAppContext()
  //also enable some additional details when routing via react router
  const [additionalRoutedErrorDetails, setAdditionalRoutedErrorDetails] =
    useState<string | undefined>()
  useEffect(() => {
    document.title = 'Error Page'
  })
  useEffect(() => {
    if (
      location.state &&
      (location.state as any).additionalRoutedErrorDetails
    ) {
      setAdditionalRoutedErrorDetails(
        (location.state as any).additionalRoutedErrorDetails,
      )
    }
  }, [location])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '40px',
        // height: '100vh',
        // border: '1px solid black',
        // width: '70vw',
      }}
    >
      <div>
        <img alt="Logo" src={OfflineLogo} height="50px" />
      </div>
      <div>
        <img alt="Error Page" src={DataNotFoundImage} height="150px"></img>
      </div>
      <STypography variant="bodybig_regular" sx={{ textAlign: 'center' }}>
        {t('somethingWentWrong')}
      </STypography>
      {additionalRoutedErrorDetails && (
        <STypography variant="bodybig_regular">
          {t('application:additionalDetails')} {additionalRoutedErrorDetails}
        </STypography>
      )}
      {additionalDetails && (IS_DEV() || IS_NATIVE()) && (
        <STypography variant="bodybig_regular">
          {t('application:additionalDetails')} {additionalDetails}
        </STypography>
      )}
      {!isSsoLogin && (
        <div style={{ marginTop: '20px', fontFamily: 'Proxima Nova,Roboto' }}>
          <SButton
            size="small"
            noCaps
            onClick={() => {
              let targetUrl = REG_ROUTE_LOGIN
              let versionParam = undefined
              //check if given to component in case of context level errors
              if (mobileVersionToRedirectTo) {
                versionParam = mobileVersionToRedirectTo
                //otherwise check if its in context in case of component level errors
              } else if (mobileVersion && typeof mobileVersion === 'string') {
                versionParam = mobileVersion
              }
              if (versionParam) {
                targetUrl =
                  targetUrl +
                  '?' +
                  URL_PARAM_MOBILE_APP_VERSION +
                  '=' +
                  versionParam
              }

              ;(window as Window).location = targetUrl as any
            }}
          >
            {t('homePage')}
          </SButton>
        </div>
      )}

      {isSsoLogin && (
        <div>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <STypography
                sx={{ textAlign: 'center', paddingTop: '20px' }}
                variant="bodybig_regular"
              >
                Please try your request again. If the issue persists, please
                contact support.
              </STypography>
            </Grid>
            <Grid item>
              <SButton
                size="small"
                noCaps
                onClick={() => {
                  history.push(ROUTE_COMPLETE_LOGIN)
                }}
              >
                {t('homePage')}
              </SButton>
            </Grid>
          </Grid>
        </div>
      )}

      <div
        style={{
          fontSize: '1.5rem',
          marginTop: '20px',
          fontFamily: 'Proxima Nova,Roboto',
        }}
      >
        <MemberSupportFooter
          variant="single-line"
          additionalSingleLineText={<span>Need help?</span>}
        />
      </div>
    </div>
  )
}
