import { Grid } from '@mui/material'
import { useMemberAppContext } from 'src/app/MemberAppContext'
import { useSColors } from 'src/app/styles/scripta-theme'
import CardContainer from 'src/app/system/CardContainer'
import STypography from 'src/app/system/customcomponents/STypography'
import { SPACING } from 'src/app/system/theme2'
import { ROUTE_NEW_SEARCH_OPPORTUNITY } from 'src/app/v2/NewMemberPortalMain'
import { NewSearchSuggester } from 'src/app/v2/NewSearchSuggester'
import { ThemedSVGRemote } from 'src/app/v2/ThemedSVGRemote'
import searchMedsImageUrl from '../images/search_meds.svg'

// TODO: internalize the texts
export default function SearchCard() {
  const { COLORS } = useSColors()
  const { history, setSelectedSuggestion } = useMemberAppContext()

  return (
    <CardContainer
      sx={{
        padding: SPACING._1,
        backgroundColor: COLORS.PRIMARY_10,
        borderColor: COLORS.PRIMARY_40,
        height: "100%"
      }}
    >
      <Grid
        container
        alignItems={'center'}
        direction={'column'}
        gap={SPACING._15}
        justifyContent={"space-between"}
      >
        <Grid item>
          <STypography
            variant={'bodybig_bold'}
            sx={{ color: COLORS.TEXT_BRAND, textAlign: 'center' }}
          >
            Search for meds and start saving!
          </STypography>
        </Grid>
        <Grid item>
          <ThemedSVGRemote
            importUrl={searchMedsImageUrl}
            width={142}
            height={120}
            alt="deductible"
          />
        </Grid>
        <Grid item sx={{ width: '100%' }}>
          <NewSearchSuggester
            onSuggestionPicked={(suggestion) => {
              setSelectedSuggestion(suggestion)
              history.push(ROUTE_NEW_SEARCH_OPPORTUNITY)
            }}
          />
        </Grid>
        <Grid item>
          <STypography variant="bodysmall_regular" sx={{ textAlign: 'center' }}>
            Search for medications, get your pricing and find drug information.
            You can and discuss with your doctor to find the best alternative
            for you and your pocket.
          </STypography>
        </Grid>
      </Grid>
    </CardContainer>
  )
}
