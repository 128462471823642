import { Link } from '@mui/material'
import { getOS, IS_NATIVE } from '../apiexec/utils'
import { usePortalAppConfig } from '../config/usePortalAppConfig'

export default function EmailLink({
  shouldShowUnderline,
  customLinkSx = {}
}: {
  shouldShowUnderline?: boolean
  customLinkSx?: any
}) {
  const PORTAL_APP_CONFIG = usePortalAppConfig()
  const email = PORTAL_APP_CONFIG.MEMBER_SUPPORT_EMAIL
  const isNative: boolean = IS_NATIVE()
  const isAndroid: boolean = getOS() === 'Android'

  return (
    <Link
      href={`mailto:${email}`}
      onClick={
        isNative && isAndroid
          ? () => {
              window.open(`mailto:${email}`)
            }
          : undefined
      }
      style={{
        textDecoration: shouldShowUnderline ? undefined : 'none',
        cursor: 'pointer',
        color: '#2D8DFF',
        textDecorationColor: '#2D8DFF',
        ...customLinkSx
      }}
    >
      {email}
    </Link>
  )
}
