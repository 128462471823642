import { Box, CssBaseline, Grid } from '@mui/material'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { JwtDetails, useMemberAppContext } from '../MemberAppContext'
import {
  getMonthNameFromSavingsReport,
  getYearFromSavingReport,
  isZeroSavingsReport,
} from '../saving/utils'
import couponsNoResultsImage from './../system/images/coupons_not_found.svg'
import couponsNoLocationImage from './../system/images/current_location_illustration.svg'
// import talkToPharmacistImage from './../system/images/talk_to_pharmacist_illustration.svg'
import talkToPharmacistImageSrc from './../system/images/talk_to_pharmacist_illustration.svg'

import '@fortawesome/pro-light-svg-icons'
import { faLocationDot, faThumbsUp } from '@fortawesome/pro-regular-svg-icons'
import { useEffect, useState } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router'
import { PortalFooter } from '../application/PortalFooter'
import DetailedCouponCard, {
  DetailedCouponCardWithAdditionalInfo,
} from '../coupons/getcoupon/DetailedCouponCard'
import {
  BannerCardHelpYouSave,
  BannerCardMySavingsPageCarousel,
} from '../system/BannerCard'
import STypography from '../system/customcomponents/STypography'
import InfoCard from '../system/InfoCard'
import {
  SavingOpportunityListItem,
  SavingOpportunityListItemProps,
} from '../system/ListItems'
import { NavBar } from '../system/NavBar'
import SavingsSummaryCard from '../system/SavingsSummaryCard'
import {
  // COLORS,
  MARGIN,
  RADIUS,
  SPACING,
} from '../system/theme2'
import WaysToSave from '../system/WaysToSave'
import { DrugElipsisMenu, ElipsisOps } from '../widgets/DrugElipsisMenu'
import { BottomNavigationBar } from './BottomNavigationBar'
import {
  BackgroundContainer,
  DesktopCentralContentContainer,
  LeftDesktopNavbar,
  MobileCentralContentContainer,
  RightDesktopContent,
} from './NewPageContainer'
import {
  filterItemsPerOriginal,
  getAllWaysToSaveForReport,
  getOpportunitiesFromSavingReport,
  isValidZipLength,
  SavingGuideData,
  SavingOpportunityDataModel,
  toTwoDecimalNumberStr,
} from './saving-utils'
import {
  ROUTE_OPPORTUNITY_ROOT,
  ROUTE_OPPORTUNITY_SAME_MED_OR_NEW_MED,
  SavingGuide,
} from './SavingGuide'
import { useSizes, useXServiceTodoBettername } from './useNewHooks'

// import { MockReportDataSelect } from './mockdata/MockSelectorWidget'
import { useAmazon } from 'src/app/hooks/useAmazon'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import {
  getMemberTrackingDataService,
  getPreferencesService,
} from 'src/app/member/getPreferencesService'
import { EKSMemberProfile } from 'src/app/member/types'
import { REG_ROUTE_SERVER_ERROR } from 'src/app/registration/RegistrationSubRoutes'
import OnboardingContainer from 'src/app/system/onboarding/OnboardingContainer'
import { MemberSavingReport } from 'src/types'
import {
  checkMicroServiceResponseStatus,
  getUrlParameter,
  IS_NATIVE,
  nativeApi_setBackButtonVisible,
  nativeApi_setCanGoBack,
} from '../apiexec/utils'
import { LogoutTimer } from '../application/LogoutTimer'
import { MemberLoginDisabledDialog } from '../application/MemberLoginDisabledDialog'
import { PreregFirstTimeAccessWelcomeDialog } from '../application/PreregFirstRealAccessWelcomeDialog'
import { UserFeedback } from '../application/UserFeedback'
import { MyDependentsManagementContainer } from '../member/MyDependentsManagement'
import { useMemberService } from '../member/useMemberService'
import { ROUTE_APP_ROOT } from '../PortalRoutes'
import { DownloadSavingsReportLink } from '../saving/DownloadSavingsReportLink'
import { useSColors } from '../styles/scripta-theme'
import { CircleWrappedFontIcon } from '../system/CircleWrappedFontIcon'
import SButton from '../system/customcomponents/SButton'
import { SDialog } from '../system/customcomponents/SDialog'
import STextField from '../system/customcomponents/STextfield'
import HowToSaveUsingCoupons from '../system/HowToSaveUsingCoupons'
import PharmaciesWithCoupons from '../system/PharmaciesWithCoupons'
import { TagLabel } from '../system/TagLabel'
import { LOG } from './applog'
import {
  MyAccountFaq,
  MyAccountMyMeds,
  MyAccountPage,
  MyAccountSettings,
} from './MyAccountPage'
import { NewSearchStartPage, SearchOpportunityPage } from './NewSearchPage'
import {
  NewSettingsDependentsPage,
  NewSettingsPasswordPage,
  NewSettingsPreferencesPage,
} from './NewSettingsSubpages'
import { NoSavingPage } from './NoSavingsPage'
import { ThemedSVGRemote } from './ThemedSVGRemote'

//know app pages

export enum MAIN_APP_SECTIONS {
  my_opportunities = 'my_opportunities',
  my_account = 'my_account',
  my_coupons = 'my_coupons',
  search = 'search',
  provider_patients = 'provider_patients',
}

export type MainAppSectionName = keyof typeof MAIN_APP_SECTIONS

//NOTE - the routes for individual saving opportunity guides are in the SavingGuide.tsx

export const ROUTE_NEW_SAVING_REPORT_OPPORTUNITIES_MAIN =
  '/my_report_opportunities'
export const ROUTE_NO_SAVINGS = '/no_savings'

export const ROUTE_NEW_COUPONS_MAIN = '/my_coupons'
export const ROUTE_NEW_MY_ACCOUNT_MAIN = '/my_account'
export const ROUTE_NEW_SEARCH = '/search'
export const ROUTE_NEW_SEARCH_OPPORTUNITY = '/search_opportunity'

export const ROUTE_LEARN_ABOUT_WAYS_TO_SAVE = '/learn_ways_to_save'
export const ROUTE_LEARN_ABOUT_COUPONS = '/learn_coupons'
export const ROUTE_LEARN_ABOUT_DISCOUNT_CARD = '/learn_discount_card'

export const ROUTE_NEW_MY_ACCOUNT_SETTINGS_PRIVACY_POLICY =
  '/myaccount/settings/privacypolicy'
export const ROUTE_NEW_MY_ACCOUNT_SETTINGS_TERMS_OF_USE =
  '/myaccount/settings/termsofuse'

export function NewMemberPortalMain() {
  const {
    jwt,
    currentMemberProfile,
    savingReport,
    showFeedbackModal,
    renderDepInviteUponLoginAfterRegistration,
    setRenderDepInviteUponLoginAfterRegistration,
    isFirstTimePreregMemberAccess,
    renderPreregFirstAccessWelcomeDialog,
    setRenderPreregFirstAccessWelcomeDialog,
    setBookmarkedOpportunities,
    featureConfig,
    jwtDetails,
  } = useMemberAppContext()
  const location = useLocation()

  const { getBookmarkedOpportunitiesFromStorage } = useXServiceTodoBettername()

  useEffect(() => {
    if (jwtDetails && savingReport) {
      const loadBookmarkedOpps = async () => {
        const books = await getBookmarkedOpportunitiesFromStorage(jwtDetails)
        setBookmarkedOpportunities(books)
      }
      loadBookmarkedOpps()

      if (currentMemberProfile !== undefined) {
        try {
          const zohoValue = (window as any).$zoho
          if (zohoValue && zohoValue.salesiq && zohoValue.salesiq.visitor) {
            zohoValue.salesiq.visitor.email(currentMemberProfile.email)
            zohoValue.salesiq.visitor.name(
              currentMemberProfile['first-name'] +
                ' ' +
                currentMemberProfile['last-name'],
            )
            zohoValue.salesiq.visitor.contactnumber(currentMemberProfile.phone)
            zohoValue.salesiq.visitor.info({
              'Member ID': jwtDetails?.id_key,
              'Company ID': jwtDetails?.tenant_key,
            })

            // for clearing chat history if member changes
            const { getMemberTrackingData } = getMemberTrackingDataService()
            const memberTrackingData = getMemberTrackingData()
            if (memberTrackingData) {
              if (memberTrackingData.memberId !== currentMemberProfile.id) {
                zohoValue.salesiq.reset()
                // @ts-ignore
                window.shouldReloadSalesIqChat = true
              }
            }
          }
        } catch (error) {
          console.error('SalesIQ visitor API error occurred:', error)
        }
      }

      // store latest member tracking data in localStorage
      const { setMemberTrackingData } = getMemberTrackingDataService()
      if (currentMemberProfile !== undefined) {
        setMemberTrackingData({
          memberId: currentMemberProfile.id,
          tenantId: Number(jwtDetails?.tenant_key),
          tenantName: jwtDetails?.company_name_key ?? '',
          firstName: currentMemberProfile['first-name'] ?? '',
          lastName: currentMemberProfile['last-name'] ?? '',
        })
      }
    }

    // clearStorageBookmarks()
  }, [currentMemberProfile, savingReport])

  // useEffect(() => {
  //
  //   if (savingReport && currentMemberProfile) {
  //
  //     // history.push(ROUTE_NEW_SEARCH)
  //     // history.push(ROUTE_NEW_SAVING_REPORT_OPPORTUNITIES_MAIN)
  //     history.push(ROUTE_NEW_COUPONS_MAIN)
  //   }
  // }, [savingReport, currentMemberProfile])
  const { isMobile } = useSizes()

  useEffect(() => {
    if (IS_NATIVE()) {
      nativeApi_setBackButtonVisible(false)
    }
  }, [])

  //loading is done in the complete login handler that fetches this data
  if (!currentMemberProfile || !savingReport) {
    return null
  }
  if (location && location.pathname === REG_ROUTE_SERVER_ERROR) {
    return null
  }
  return (
    <>
      <CssBaseline />
      <LogoutTimer />
      {/* //disable till new app is published */}
      {/* <ForceUpgradeDialog /> */}
      {/* disable till we have new apis for this not to rely on EBS as it hangs often for Amazon */}
      {/* <PolicyOrTermsChangedDialogContainer /> */}
      {/* disable concurrent session till we get the new api for that */}
      {/* <ConcurrentSessionHanlder /> */}
      <MemberLoginDisabledDialog />
      {showFeedbackModal && <UserFeedback />}
      {renderDepInviteUponLoginAfterRegistration && (
        <MyDependentsManagementContainer
          renderAsRegistrationDialogIfHasDeps={true}
          onDialogClose={() => {
            setRenderDepInviteUponLoginAfterRegistration(false)
          }}
        />
      )}

      {renderPreregFirstAccessWelcomeDialog && (
        <PreregFirstTimeAccessWelcomeDialog
          onClose={() => {
            setRenderPreregFirstAccessWelcomeDialog(false)
          }}
        />
      )}

      {/* <MockReportDataSelect /> */}
      {isMobile && (
        <>
          <MobileCentralContentContainer>
            <MainPages />
          </MobileCentralContentContainer>
          {/* below is just the mobile actions bar only in the mobile */}
          <BottomNavigationBar />
        </>
      )}
      {!isMobile && (
        <>
          <BackgroundContainer variant={'purple_curved_elipsis'}>
            <DesktopCentralContentContainer
              leftSidePanel={<LeftDesktopNavbar />}
              rightSidePanel={<RightDesktopContent />}
            >
              <MainPages />
            </DesktopCentralContentContainer>
          </BackgroundContainer>

          {/* below is portal footer only in desktop */}
          <Box sx={{ paddingTop: '20px' }}>
            <PortalFooter />
          </Box>
        </>
      )}
    </>
  )
}

function MainPages() {
  const { savingReport } = useMemberAppContext()

  let isCopay =
    savingReport && savingReport.reportType === 'COPAY' ? true : false

  // POC - prevent mobile app from going back on any
  // of the main tab pages to make it feel more native
  const location = useLocation()
  const MAIN_PAGE_ROUTE = new Set([ROUTE_NEW_SAVING_REPORT_OPPORTUNITIES_MAIN])

  useEffect(() => {
    if (IS_NATIVE()) {
      if (MAIN_PAGE_ROUTE.has(location.pathname)) {
        nativeApi_setCanGoBack(false)
      } else {
        nativeApi_setCanGoBack(true)
      }
      nativeApi_setBackButtonVisible(false)
    }
  }, [location.pathname])

  useEffect(() => {
    if (IS_NATIVE()) {
      nativeApi_setBackButtonVisible(false)
    }
  }, [])
  // POC end

  //TODO - is this redirect needed?
  // useEffect(() => {
  //   if (savingReport && currentMemberProfile) {
  //     // history.push(ROUTE_NEW_SEARCH)
  //     history.push(ROUTE_NEW_SAVING_REPORT_OPPORTUNITIES_MAIN)
  //   }
  // }, [savingReport, currentMemberProfile])

  // if (isLoadingMainReport || !savingReport) {
  //   return <LoadingScreen />
  // }

  const { savingGuide, setSavingGuide } = useMemberAppContext()

  return (
    <Switch>
      <Route path={ROUTE_APP_ROOT} exact>
        {/* to prevent the root blank page, if we ever reach it (by back clicks) */}
        <RootPageToOpportunitiesHomepageRedirect />
      </Route>
      <Route path={ROUTE_NEW_SAVING_REPORT_OPPORTUNITIES_MAIN}>
        <MyReportOpportunitiesPage />
      </Route>
      <Route path={ROUTE_LEARN_ABOUT_WAYS_TO_SAVE}>
        <LearnAboutWaysToSavePage />
      </Route>
      <Route path={ROUTE_NO_SAVINGS}>
        <NoSavingPage />
      </Route>
      <Route path={ROUTE_OPPORTUNITY_ROOT}>
        <Grid container>
          <Grid item xs={12}>
            {savingGuide && <SavingGuide savingGuideData={savingGuide} />}
          </Grid>
        </Grid>
      </Route>
      <Route path={ROUTE_NEW_COUPONS_MAIN}>
        <MyCouponsPage />
      </Route>
      <Route path={ROUTE_LEARN_ABOUT_COUPONS}>
        <LearnAboutCouponsPage />
      </Route>
      <Route path={ROUTE_LEARN_ABOUT_DISCOUNT_CARD}>
        <LearnAboutDiscountCardPage />
      </Route>
      <Route path={ROUTE_NEW_MY_ACCOUNT_MAIN}>
        <MyAccountPage />
      </Route>
      <Route path={ROUTE_NEW_SEARCH}>
        <NewSearchStartPage />
      </Route>
      <Route path={ROUTE_NEW_SEARCH_OPPORTUNITY}>
        <SearchOpportunityPage isCopay={isCopay} />
      </Route>

      <Route path={ROUTE_NEW_MY_ACCOUNT_MY_MEDS}>
        <MyAccountMyMeds />
      </Route>
      <Route path={ROUTE_NEW_MY_ACCOUNT_SETTINGS} exact>
        <MyAccountSettings />
      </Route>
      {/* START SETTINGS SUBPAGES */}
      <Route path={ROUTE_NEW_MY_ACCOUNT_SETTINGS_PASSWORD}>
        <NewSettingsPasswordPage />
      </Route>
      <Route path={ROUTE_NEW_MY_ACCOUNT_SETTINGS_PREFERENCES}>
        <NewSettingsPreferencesPage />
      </Route>
      <Route path={ROUTE_NEW_MY_ACCOUNT_SETTINGS_DEPENDENTS}>
        <NewSettingsDependentsPage />
      </Route>

      {/* END SETTINGS SUBPAGES */}
      <Route path={ROUTE_NEW_MY_ACCOUNT_FAQ}>
        <MyAccountFaq />
      </Route>
    </Switch>
  )
}

function RootPageToOpportunitiesHomepageRedirect() {
  return <Redirect to={ROUTE_NEW_SAVING_REPORT_OPPORTUNITIES_MAIN} />
}
export const URL_PARAM_WAYS_TO_SAVE_ACCORDION = 'asAccordion'
function LearnAboutWaysToSavePage() {
  let accordionParam = getUrlParameter(URL_PARAM_WAYS_TO_SAVE_ACCORDION)
  let isAccordion: boolean = false
  if (accordionParam === 'true') {
    isAccordion = true
  }

  return (
    <>
      <NavBar variant="bodybig_title_center_white" title="Ways To Save" />
      <BackgroundContainer variant={'clear_white'}>
        <Grid container>
          <Grid item xs={12}>
            <Box>
              <WaysToSave isAccordionMode={isAccordion} />
            </Box>
          </Grid>
        </Grid>
      </BackgroundContainer>
    </>
  )
}

function LearnAboutCouponsPage() {
  return (
    <>
      <NavBar variant="bodybig_title_center_white" title="Ways To Save" />
      <BackgroundContainer variant={'clear_white'}>
        <Grid container>
          <Grid item xs={12}>
            <Box>
              <HowToSaveUsingCoupons />
            </Box>
          </Grid>
        </Grid>
      </BackgroundContainer>
    </>
  )
}

export function LearnAboutDiscountCardPage() {
  const { t } = useSTranslate('howToSaveUsingCoupons')
  const { COLORS } = useSColors()

  return (
    <>
      <NavBar
        variant="bodybig_title_center_white"
        title="How to use your discount card"
      />
      <BackgroundContainer variant={'clear_white'}>
        <Grid container>
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingBottom: '20px',
                paddingTop: '20px',
              }}
            >
              <DetailedCouponCard disableDownload={true} />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ marginTop: '20px', color: COLORS.PRIMARY_BRAND }}
          >
            <STypography variant="bodybig_bold">
              {t('discountCardIntro')}
            </STypography>
          </Grid>
          <Grid item xs={12}>
            <ul>
              <li>
                <STypography variant="body_regular">
                  {t('discountCardBullet1')}
                </STypography>
              </li>
              <li>
                <STypography variant="body_regular">
                  {t('discountCardBullet2')}
                </STypography>
                <Grid
                  container
                  justifyContent={'center'}
                  gap={SPACING._1}
                  sx={{ paddingTop: SPACING._05, paddingBottom: SPACING._05 }}
                >
                  <Grid item>
                    <TagLabel size={'big'} color={'blue'} text={'Coupon'} />
                  </Grid>
                  <Grid item>
                    <TagLabel
                      size={'big'}
                      color={'blue'}
                      text={'Coupons available'}
                    />
                  </Grid>
                </Grid>
              </li>
              <li>
                <STypography variant="body_regular">
                  {t('discountCardBullet3')}
                </STypography>
              </li>
              <li>
                <STypography variant="body_regular">
                  {t('discountCardBullet4')}
                </STypography>
              </li>
              <li>
                <STypography variant="body_regular">
                  {t('discountCardBullet5')}
                </STypography>
              </li>
              <li>
                <STypography variant="body_regular">
                  {t('discountCardBullet6')}
                </STypography>
              </li>
            </ul>
          </Grid>
          <Grid item>
            <Box
              sx={{
                border: '1px solid grey',
                borderRadius: RADIUS.sm,
                padding: '10px',
              }}
            >
              <STypography
                component="span"
                variant="bodybig_bold"
                sx={{ color: COLORS.PRIMARY_BRAND }}
              >
                {t('step4Reminder')}
              </STypography>
              <STypography component="span" variant="bodybig_regular">
                {' '}
                {t('step4DetailedDescription')}
              </STypography>
            </Box>
          </Grid>
          <Grid container alignItems={'center'} justifyContent={'center'}>
            <Grid item sx={{ marginTop: SPACING._1 }}>
              {/* <img
                src={talkToPharmacistImage}
                alt={`Talk to pharmacist`}
                height={'160px'}
              /> */}
              <ThemedSVGRemote
                importUrl={talkToPharmacistImageSrc}
                height={160}
                width={261}
                alt={`Talk to pharmacist`}
              />
            </Grid>
          </Grid>

          <Grid item width={'100%'}>
            <PharmaciesWithCoupons />
          </Grid>
        </Grid>
      </BackgroundContainer>
    </>
  )
}

function MyReportOpportunitiesPage() {
  const {
    history,
    setSavingGuide,
    savingReport,
    currentMemberProfile,
    setIsReportOpportunitiesPageFirstLoad,
    jwtDetails,
  } = useMemberAppContext()
  const [renderGreeting, setRenderGreeting] = useState(true)
  const { COLORS } = useSColors()
  const { isAmazon } = useAmazon()
  const { isMobile, isDesktop } = useSizes()
  const [isOnboardingCollapsed, setIsOnboardingCollapsed] = useState<
    boolean | undefined
  >(undefined)

  useEffect(() => {
    document.title = 'My Saving Opportunities'
  }, [])

  useEffect(() => {
    if (savingReport) {
      setIsReportOpportunitiesPageFirstLoad(false)
    }

    if (savingReport !== undefined && isZeroSavingsReport(savingReport)) {
      history.push(ROUTE_NO_SAVINGS)
    }
  }, [savingReport])

  useEffect(() => {
    if (jwtDetails) {
      const { getPrefWithSavingsOnboardingCollapsed } =
        getPreferencesService(jwtDetails)

      const isAlreadyCollapsed = getPrefWithSavingsOnboardingCollapsed()

      if (isAlreadyCollapsed !== undefined) {
        setIsOnboardingCollapsed(true)
      } else {
        setIsOnboardingCollapsed(false)
      }
    }
  }, [jwtDetails])

  function handleOnboardingCollapse() {
    setIsOnboardingCollapsed(true)

    if (jwtDetails) {
      const { setPrefWithSavingsOnboardingCollapsed } =
        getPreferencesService(jwtDetails)

      setPrefWithSavingsOnboardingCollapsed(true)
    } else {
      LOG.error(
        'generic_error',
        'Attempted to collapse onboarding component but currentMemberProfile is undefined',
      )
    }
  }

  return (
    <Grid container>
      {isMobile && (
        <Grid item xs={12}>
          <NavBar variant="logo_purple" backButtonHidden />
        </Grid>
      )}

      <BackgroundContainer
        variant={isMobile ? 'purple_curved_elipsis' : 'clear_white'}
      >
        {renderGreeting && (
          <>
            <Grid item xs={12}>
              <HeroGreeting
                userFirstName={currentMemberProfile?.['first-name']}
                savingReport={savingReport}
                isLoadingMainReport={savingReport === undefined ? true : false}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ paddingTop: SPACING._1, paddingBottom: SPACING._075 }}
            >
              <InfoCard
                onClick={() => {
                  history.push(ROUTE_LEARN_ABOUT_WAYS_TO_SAVE)
                }}
                disableTheming={isAmazon}
                title="Learn about Scripta's ways to save"
                variant="navigation_primary"
              />
            </Grid>
          </>
        )}
        <Route path={ROUTE_NEW_SAVING_REPORT_OPPORTUNITIES_MAIN} exact>
          <Grid item xs={12} sx={{ paddingTop: SPACING._1 }}>
            <STypography
              variant="title3_bold"
              sx={{ color: COLORS.TEXT_BRAND }}
            >
              Savings Available
            </STypography>
          </Grid>
          <Grid item xs={12} sx={{ paddingTop: SPACING._05 }}>
            <InfoCard
              variant="default_primary"
              title={''}
              body="Savings estimates do not include manufacturer copay assistance or savings from other programs you may already be using outside of your insurance benefits."
            />
          </Grid>
          <Grid item xs={12} sx={{ paddingTop: SPACING._1 }}>
            {savingReport && (
              <SavingOpportunitiesInTheReportList
                savingReport={savingReport}
                onClickSavingOpportunity={(s) => {
                  if (s) {
                    const originalId = s.drugId
                    //all ways to save for this report opportunity
                    let allInReport = getAllWaysToSaveForReport(savingReport!)
                    let waysToSaveForThisOriginal = filterItemsPerOriginal(
                      originalId,
                      allInReport,
                    )

                    const savingGuideData: SavingGuideData = {
                      selectedOpportunity: s,
                      source: 'saving_report',
                      waysToSaveForOpportunity: waysToSaveForThisOriginal,
                    }
                    setSavingGuide(savingGuideData)
                    history.push(ROUTE_OPPORTUNITY_SAME_MED_OR_NEW_MED)
                  }
                }}
              />
            )}
          </Grid>
          {isDesktop && !IS_NATIVE() && (
            <Grid item xs={12} sx={{ paddingTop: SPACING._05 }}>
              <DownloadSavingsReportLink />
            </Grid>
          )}
          {isOnboardingCollapsed !== undefined ? (
            <Grid
              item
              xs={12}
              container
              justifyContent={'center'}
              sx={{
                paddingTop: SPACING._1,
                paddingBottom: SPACING._1,
              }}
            >
              <OnboardingContainer
                isCollapsible={true}
                isCollapsed={isOnboardingCollapsed}
                onCollapse={handleOnboardingCollapse}
              />
            </Grid>
          ) : null}
          <Grid item xs={12} sx={{ paddingBottom: SPACING._1 }}>
            <BannerCardMySavingsPageCarousel
              onViewCouponsClick={() => {
                history.push(ROUTE_NEW_COUPONS_MAIN)
              }}
            />
          </Grid>
        </Route>
      </BackgroundContainer>
    </Grid>
  )
}

function MyCouponsPage() {
  const { COLORS } = useSColors()
  const { savingReport, currentMemberProfile, bookmarkedOpportunities } =
    useMemberAppContext()
  const { setSavingGuide, history } = useMemberAppContext()
  const { isMobile } = useSizes()
  let processBookmarks = false

  //get all ways to save for this report and filter all (original or drug B) that are coupons
  const allWaysToSave = getAllWaysToSaveForReport(savingReport!)

  //for now the originals
  const allCoupons = allWaysToSave.filter((s) => s.type === 'coupon')

  // const COUPON_CTRLS:React.ReactElement[] = []
  // allCoupons.forEach((c) => {
  //

  // }
  // )

  // const allSavingOps = getOpportunitiesFromSavingReport(
  //   isCopay,
  //   savingReport!,
  //   bookmarkedOpportunities,
  //   processBookmarks,
  // )
  // const couponOnOriginalOps = allSavingOps.filter((s) => s.hasCouponsOnOriginal)

  //we want only one edge case to show at a time
  const hasValidZipCode = isValidZipLength(currentMemberProfile?.zip)

  const hasCoupons = allCoupons.length > 0

  let edgeCaseToShow = undefined
  let edgeCaseImage = undefined
  let edgeCaseText = undefined

  //if we dont have zip code, always show that first regardless of coupons
  if (!hasValidZipCode) {
    edgeCaseToShow = 'no_location'
    edgeCaseImage = couponsNoLocationImage
    edgeCaseText =
      'Input your ZIP Code so we can find coupons at nearby pharmacies and include them in your next monthly savings report.'
    //if we have zip code, show coupons if we have them, otehrwise no coupons edge case
  } else if (hasCoupons) {
    edgeCaseToShow = undefined
    edgeCaseImage = undefined
    edgeCaseText = undefined
  } else {
    //zip code is valid but no coupons
    edgeCaseToShow = 'no_coupons'
    edgeCaseImage = couponsNoResultsImage
    edgeCaseText =
      'It looks like we don’t have any coupons available for your prescriptions this month. Check back next month for new savings opportunities.'
  }

  const { t: couponT } = useSTranslate('howToSaveUsingCoupons')

  return (
    <Grid container>
      <Grid item xs={12}>
        <NavBar
          variant={
            isMobile
              ? 'title2_title_center_purple'
              : 'title3_title_center_white'
          }
          backButtonHidden
          title="Easy Way To Save"
        />
      </Grid>
      <BackgroundContainer
        variant={isMobile ? 'purple_curved_elipsis' : 'clear_white'}
      >
        <Grid container gap={SPACING._1}>
          <Grid item xs={12}>
            <DetailedCouponCardWithAdditionalInfo />
          </Grid>
          <Grid item xs={12}>
            <STypography
              variant="title3_bold"
              sx={{ color: COLORS.TEXT_BRAND }}
            >
              Your available coupons
            </STypography>
          </Grid>
          <Grid item xs={12}>
            {/* //on the first page we want to instruct the user that changing will zip does not have any immediate effect on what they see
            //i.e. if they see 2 originals with coupons - even if they change zip, that wont be refreshed, its coming from backend for this month
            //next month when the new rep is generated, it whould consider the new zip */}

            <MemberZipCodeLocationField
              showChangeWillReflectNextMonthDialog={true}
            />
          </Grid>
          <Grid item xs={12}>
            <InfoCard
              title="Learn about how to save using coupons"
              variant="navigation_primary"
              onClick={() => {
                history.push(ROUTE_LEARN_ABOUT_COUPONS)
              }}
            />
          </Grid>
          {edgeCaseToShow && (
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <img alt="Coupons Update" src={edgeCaseImage} />
              <Grid item xs={12}>
                <STypography variant="body_regular">{edgeCaseText}</STypography>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            {allCoupons.map((c, idx) => {
              const isOriginalCoupon = c.savingStrategy === 'Coupons'
              const isSameMed =
                c.sameOrNewMedChoice === 'same_med' ? true : false

              const customTags = []
              if (isOriginalCoupon) {
                customTags.push(
                  <TagLabel
                    key="no_new_rx_needed"
                    text="No New Rx needed"
                    color="green"
                    size={'small'}
                  />,
                )
              } else {
                customTags.push(
                  <TagLabel
                    key="alternative_coupon"
                    text="Alternative Coupon"
                    color="purple"
                    size={'small'}
                  />,
                )
              }

              const itemProps: SavingOpportunityListItemProps = {
                drugId: c.originalId,
                customTags: customTags,
                // customCouponsAvailableTagText: isOriginalCoupon
                //   ? undefined
                //   : '* Coupons available on alternative for ' + c.originalName,
                drugName: isOriginalCoupon ? c.originalName : c.altName,
                drugDosage: isOriginalCoupon ? c.originalDosage : c.altDosage,
                drugForm: isOriginalCoupon ? c.originalForm : c.altForm,
                drugCount: isOriginalCoupon
                  ? c.originalCount
                  : c.altCountQtcStr,

                //bc downstream controls use that to decide which price to show
                // priceCopay: isCopay
                //   ? `${highestWayToSaveItem.originalPriceCopay}`
                //   : undefined,
                // priceBeforeDed: !highestWayToSaveItem.isCopay
                //   ? `${highestWayToSaveItem.originalPriceBeforeDed}`
                //   : undefined,
                // priceAfterDed: !highestWayToSaveItem.isCopay
                //   ? `${highestWayToSaveItem.originalPriceAfterDed}`
                //   : undefined,

                upToAmount: c.saveAmountResolved
                  ? `$${toTwoDecimalNumberStr(c.saveAmountResolved)}/mo`
                  : 'NA',

                upToPrefix: 'Save Up To',
                ellipsisMenu: undefined,
                onClick: function (): void {
                  //prepare the data as if the

                  const originalId = c.originalId

                  //we never process bookmarks from coupons bc thats a conflict - they are both shorcuts in a sense
                  let processBookmarks = false
                  let bookmarks: any[] = []
                  const opportunityObjectForThisCoupon =
                    getOpportunitiesFromSavingReport(
                      savingReport!,
                      bookmarks,
                      processBookmarks,
                    ).find((s) => s.drugId === originalId)
                  if (!opportunityObjectForThisCoupon) {
                    LOG.error(
                      'coupons',
                      'could not find opportunity for clicked coupon card,way to save item',
                      c,
                    )
                    return
                  }

                  //all ways to save for this report opportunity
                  let allInReport = getAllWaysToSaveForReport(savingReport!)
                  let waysToSaveForThisOriginal = filterItemsPerOriginal(
                    originalId,
                    allInReport,
                  )

                  const savingGuideData: SavingGuideData = {
                    selectedOpportunity: opportunityObjectForThisCoupon,
                    source: 'saving_report',
                    waysToSaveForOpportunity: waysToSaveForThisOriginal,
                    autoSelectCouponItem: c,
                  }

                  setSavingGuide(savingGuideData)
                  history.push(ROUTE_OPPORTUNITY_SAME_MED_OR_NEW_MED)
                },
              }
              return (
                <Box
                  key={idx}
                  sx={{
                    marginTop: idx !== 0 ? MARGIN.sm : undefined,
                    cursor: 'pointer',
                  }}
                >
                  <SavingOpportunityListItem
                    key={idx}
                    {...itemProps}
                    hasCoupons={true}
                    isBookmarked={false}
                  />
                </Box>
              )
            })}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ paddingTop: SPACING._1, paddingBottom: SPACING._1 }}
          >
            <BannerCardHelpYouSave
              onButtonClick={() =>
                history.push(ROUTE_NEW_SAVING_REPORT_OPPORTUNITIES_MAIN)
              }
            />
          </Grid>
        </Grid>
      </BackgroundContainer>
    </Grid>
  )
}

export function MemberZipCodeLocationField({
  showChangeWillReflectNextMonthDialog,
}: {
  showChangeWillReflectNextMonthDialog: boolean
}) {
  const { COLORS } = useSColors()

  const { currentMemberProfile, setCurrentMemberProfile, jwtDetails } =
    useMemberAppContext()
  const memberService = useMemberService()
  let [zipCodeToUse, setZipCodeToUse] = useState<string | undefined>('')
  const [zipValid, setZipValid] = useState<boolean>(false)
  //only validate after they have touched the field
  const [zipTouched, setZipTouched] = useState<boolean>(false)
  const [renderZipUpdatedDialog, setRenderZipUpdatedDialog] = useState(false)

  useEffect(() => {
    let memberZip = currentMemberProfile?.zip
    // let memberZip = ''
    if (isValidZipLength(memberZip)) {
      setZipValid(true)
    } else {
      setZipValid(false)
    }
    setZipCodeToUse(memberZip)
  }, [currentMemberProfile])

  if (!currentMemberProfile) {
    return null
  }

  async function persistZipCodeToProfile(
    jwtDetails: JwtDetails,
    zipCodeInput: string,
  ) {
    try {
      const saveResp = await memberService.updateMemberZip(
        zipCodeInput,
        jwtDetails.id_key,
      )
      const undefinedRespIsValid = true
      const saveStatus = checkMicroServiceResponseStatus(
        saveResp,
        undefinedRespIsValid,
      )
      if (!saveStatus.isError) {
        const profileWithZip: EKSMemberProfile = {
          ...currentMemberProfile!,
          zip: zipCodeInput,
        }
        setCurrentMemberProfile(profileWithZip)
        setRenderZipUpdatedDialog(true)

        // setIsOpen(false)
        // if (onZipUpdated) {
        //   onZipUpdated()
        // }
      } else {
        //TODO

        setZipValid(false)
      }
    } catch (error) {
      LOG.error('coupons', 'error updating member zip', error)
      // setIsNewZipCodeValid(false)
    }
  }

  return (
    <>
      <STextField
        caption={
          zipTouched && !zipValid
            ? 'Pleaes provide a valid zip code'
            : "We're searching for coupons at nearby pharmacies; update your ZIP code if needed."
        }
        icon={faLocationDot}
        label="Location"
        // onEnter={()=>{}}
        value={zipCodeToUse}
        error={zipTouched && !zipValid}
        placeholder="ZIP Code"
        onChange={async (e) => {
          if (!jwtDetails) {
            return
          }
          if (isValidZipLength(e.target.value)) {
            setZipValid(true)
            setZipCodeToUse(e.target.value)
            setZipTouched(true)
            await persistZipCodeToProfile(jwtDetails, e.target.value)
          } else {
            setZipValid(false)
            setZipCodeToUse(e.target.value)
            setZipTouched(true)
          }
        }}
        onBlur={() => {
          // vaidateZipWithDelay(zipCodeToUse)
        }}
      />
      {showChangeWillReflectNextMonthDialog && renderZipUpdatedDialog && (
        <SDialog
          open={renderZipUpdatedDialog}
          onClose={() => setRenderZipUpdatedDialog(false)}
          upperRightX={false}
          maxWidth="xs"
        >
          <Grid container justifyContent={'center'} gap={SPACING._15}>
            <Grid item xs={12}>
              <STypography
                variant="bodybig_bold"
                sx={{ color: COLORS.TEXT_BRAND, textAlign: 'center' }}
              >
                Zip Code Updated
              </STypography>
            </Grid>
            <Grid item xs={12} container justifyContent={'center'}>
              <CircleWrappedFontIcon
                icon={
                  <FontAwesomeIcon
                    icon={faThumbsUp}
                    color={COLORS.TEXT_BRAND}
                  />
                }
              ></CircleWrappedFontIcon>
            </Grid>
            <Grid item xs={12}>
              <STypography variant="body_regular">
                Your zip code has been updated. You will see the change
                reflected in your next monthly savings report.
              </STypography>
            </Grid>
            <Grid item container justifyContent={'center'} xs={12}>
              <SButton
                onClick={() => setRenderZipUpdatedDialog(false)}
                sx={{ textTransform: 'none' }}
              >
                Okay
              </SButton>
            </Grid>
          </Grid>
        </SDialog>
      )}
    </>
  )
}

//TODO - where to incude these subroutes
export const ROUTE_NEW_MY_ACCOUNT_MY_MEDS = '/myaccount/mymeds'
export const ROUTE_NEW_MY_ACCOUNT_SETTINGS = '/myaccount/settings'
export const ROUTE_NEW_MY_ACCOUNT_FAQ = '/myaccount/faq'
// export const ROUTE_NEW_MY_ACCOUNT_PRIVACY_POLICY = '/newprivacypolicy'

//new subroutes for various settings pages
export const ROUTE_NEW_MY_ACCOUNT_SETTINGS_PASSWORD =
  '/myaccount/settings/password'
export const ROUTE_NEW_MY_ACCOUNT_SETTINGS_PREFERENCES =
  '/myaccount/settings/perferences'
export const ROUTE_NEW_MY_ACCOUNT_SETTINGS_DEPENDENTS =
  '/myaccount/settings/dependents'

export function SavingOpportunitiesInTheReportList({
  onClickSavingOpportunity,
  savingReport,
}: {
  onClickSavingOpportunity: (savingItem?: SavingOpportunityDataModel) => void
  savingReport: MemberSavingReport
}) {
  const {
    // savingReport,
    // isCopay,
    bookmarkedOpportunities,
  } = useMemberAppContext()

  let processBookmarks = true

  let savingOpps = getOpportunitiesFromSavingReport(
    savingReport!,
    bookmarkedOpportunities,
    processBookmarks,
  )

  //in case we get here from clicking back, update the page to purple design for this component
  useEffect(() => {
    //reset selection when loading
    onClickSavingOpportunity(undefined)
  }, [])

  return (
    <Box>
      {savingOpps.map((savingOpp, idx) => {
        //prepare UI item props
        let oppProps = { ...savingOpp }
        const ellipsisMenu = (
          <ElipsisActionMenuForSelectedOpportunity
            opportunity={savingOpp}
            savingGuideCurrentSource="saving_report"
            // onClick={() => {
            //
            // }}
          ></ElipsisActionMenuForSelectedOpportunity>
        )

        //never tag the outter layer of the opportunity list
        //its just in the coupons view
        const showCouponTag = false
        return (
          <Box
            key={idx}
            sx={{
              marginTop: idx !== 0 ? MARGIN.sm : undefined,
              cursor: 'pointer',
            }}
          >
            <SavingOpportunityListItem
              {...oppProps}
              drugId={savingOpp.drugId}
              ellipsisMenu={ellipsisMenu}
              upToPrefix={'Save Up To'}
              onClick={() => {
                onClickSavingOpportunity(savingOpp)
              }}
              hasCoupons={showCouponTag}
              isBookmarked={savingOpp.isBookmarked}
            />
          </Box>
        )
      })}
    </Box>
  )
}

export function HeroGreeting({
  userFirstName,
  savingReport,
  isLoadingMainReport,
  isNoSavingsMode = false,
}: {
  userFirstName?: string
  savingReport?: MemberSavingReport
  isLoadingMainReport: boolean
  isNoSavingsMode?: boolean
}) {
  const RING_SIZE = 140
  const IS_MOBILE = true
  // const { currentMemberProfile, savingReport, isLoadingMainReport } =
  //   useMemberAppContext()
  //start with current month
  let monthToUse = new Date().toLocaleString('default', { month: 'long' })
  let yearToUse: string | number | undefined = new Date()
    .getFullYear()
    .toString()

  // if (!savingReport) {
  //   return null
  // }

  const firstName = capitalizeFirstName(userFirstName)

  //if we have a report, use that month
  if (savingReport && savingReport.reportMonth) {
    monthToUse = getMonthNameFromSavingsReport(savingReport.reportMonth)!
    yearToUse = getYearFromSavingReport(savingReport.reportMonth)!
  }

  const maxSavings = savingReport?.maxSavings
    ? savingReport?.maxSavings?.toFixed(0)
    : undefined

  //should we give this hero a hight
  // const HEIGHT = 143

  return (
    <SavingsSummaryCard
      firstName={firstName}
      monthToUse={monthToUse}
      yearToUse={yearToUse}
      maxSavings={maxSavings}
      isNoSavingsMode={isNoSavingsMode}
    />
  )
}

export function capitalizeFirstName(str?: string) {
  if (!str) return ''
  //handle case where first name also contians sapaces, i..e A BRADLEY

  try {
    if (str.indexOf(' ') > -1) {
      const nameParts = str.split(' ')
      return nameParts
        .map((n) => {
          return n.charAt(0).toUpperCase() + n.toLowerCase().slice(1)
        })
        .join(' ')
    }

    return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1)
  } catch (e) {
    console.error('unable to parse string, returning default')
    return str
  }
}

export function ElipsisActionMenuForSelectedOpportunity({
  opportunity,
  savingGuideCurrentSource,
}: // onClick,
{
  opportunity: SavingOpportunityDataModel
  savingGuideCurrentSource: SavingGuideData['source']
  // onClick: () => void
}) {
  const drugId = Number(opportunity.drugId)
  const menuOps: ElipsisOps = {
    drugContext: 'original',
    actions: {
      showDrugProfile: true,
      showMyPricing: false,
      //no hide drug in search - we dont know if they are currently taking it as one of their originals
      showHideDrug: savingGuideCurrentSource === 'saving_report' ? true : false,
    },
    drugIdParams: {
      drugId: drugId,
      // pricingQuantityDefault: 30,
      // useLatestForLabelOnDrugProfile: true,
    },
    // pricingQuantityDefault: 30,
    // useLatestForLabelOnDrugProfile: true,
  }

  return (
    <>
      <Box
        sx={{
          // backgroundColor: '#EDE5F3',
          borderRadius: RADIUS.sm,
          padding: SPACING._025,
        }}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <DrugElipsisMenu ops={menuOps}></DrugElipsisMenu>
      </Box>
    </>
  )
}
