import { Grid, Link, Typography, useMediaQuery, useTheme } from '@mui/material'
import TextField from '@mui/material/TextField'
import { useEffect, useState } from 'react'
import {
  checkMicroServiceResponseStatus,
  parseUiError,
} from 'src/app/apiexec/utils'
import {
  EKS_CODE_NO_SUCH_USER,
  EKS_CODE_TOO_MANY_OTP_REQUESTED_NEED_TO_WAIT,
  EKS_LOGIN_MEMBER_NOT_REGISTERED,
} from 'src/app/EKSStatuCodes'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import SButton from 'src/app/system/customcomponents/SButton'
import STypography from 'src/app/system/customcomponents/STypography'
import BaseErrorCard from 'src/app/system/error/BaseErrorCard'
import { LOG } from 'src/app/v2/applog'
import { GAService } from '../application/ga/GAService'
import { useNotifier } from '../hooks/useNotify'
import { useRegistration } from './RegistrationContext'
import {
  REG_ROUTE_FORGOT_USERNAME,
  REG_ROUTE_RESET_PASSWORD,
} from './RegistrationSubRoutes'
import { RegistrationToolbar } from './RegistrationToolbar'
import { useRegistrationService } from './useRegistrationService'
import { URL_PARAM_RESET_PASSWORD_USERNAME } from 'src/app/app-root-types'
import { useSColors } from 'src/app/styles/scripta-theme'

//need a bit more details to properly handle same error response code indifferent flows
//i.e. registration vs forgot password
export const UI_RESPONSE_CODE_DECORATION_NO_MATCHING_MEMBER_FORGOT_PWD_FLOW =
  'nomatchingmemberforgotpwdflow'
export function ForgotPassword() {
  const { COLORS } = useSColors()
  const gaService = new GAService()
  const [noSuchUser, setNoSuchUser] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [tooManyOTPRequested, setTooManyOTPRequested] = useState(false)
  const regService = useRegistrationService()
  const { history, setApiResponse, pwdResetData, setPwdResetData } =
    useRegistration()
  const { sendMsg } = useNotifier()
  const { t } = useSTranslate('register')
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    document.title = 'Forgot Password - Scripta Insights'
  })

  useEffect(() => {
    try {
      const queryString = window.location.search
      // Find the username parameter manually
      const usernameMatch = queryString.match(/[?&]username=([^&]*)/)

      if (usernameMatch && usernameMatch[1]) {
        const username = usernameMatch[1]
        const decodedUsername = decodeURIComponent(username)

        console.log('decodedUsername from raw query:', decodedUsername)
        setPwdResetData({ userName: decodedUsername })
        asyncForgotPassword(decodedUsername)
      }
    } catch (e) {
      LOG.error(
        'forgot_password',
        'Unable to extract the username from the URL and call the API automatically for forgot password, will let user do it manually',
        parseUiError(e),
      )
    }
  }, [])

  async function asyncForgotPassword(userName: string) {
    try {
      setIsLoading(true)
      const resp = await regService.forgotPassword_EKS(userName)

      setIsLoading(false)
      let allowUndefined = true
      const respCheck = checkMicroServiceResponseStatus(resp, allowUndefined)
      if (!respCheck.isError) {
        //
        sendMsg('Your one-time passcode has been sent.', 'success')
        //updated password data for the next step
        // setPwdResetData(resp)
        history.push(REG_ROUTE_RESET_PASSWORD)
      } else {
        //here 404 is a valid response - meaning the user does not exist
        if (
          resp.httpStatusCode === 404 &&
          (resp.scriptaErrorCode === EKS_CODE_NO_SUCH_USER ||
            resp.scriptaErrorCode === EKS_LOGIN_MEMBER_NOT_REGISTERED)
        ) {
          setNoSuchUser(true)
        } else if (
          resp.scriptaErrorCode === EKS_CODE_TOO_MANY_OTP_REQUESTED_NEED_TO_WAIT
        ) {
          setTooManyOTPRequested(true)
        } else {
          LOG.error(
            'forgot_password',
            'Unexpected error response retruend by the forgot-password api',
            resp,
          )
        }

        // setApiResponse(resp)
        // history.push(REG_ROUTE_ERROR_HANDLER)
      }
    } catch (e) {
      LOG.error(
        'forgot_password',
        'Exception trying to call API for forgot password',
        parseUiError(e),
      )
      setIsLoading(false)
    }
  }

  if (noSuchUser) {
    return (
      <BaseErrorCard
        headerTitle="No such username"
        content={
          <>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <Typography sx={{ fontSize: '16px' }}>
                We have no records of a username matching the one you provided.
                Please check your username and try again.
              </Typography>
            </Grid>
            {/* or tell the user to request forgotten username */}
            <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '20px' }}>
              <Typography sx={{ fontSize: '16px' }}>
                If you need to recover your username, please click{' '}
                <Link
                  component="button"
                  onClick={() => history.push(REG_ROUTE_FORGOT_USERNAME)}
                  sx={{
                    color: COLORS.TEXT_BRAND,
                    // textDecoration: 'none',
                    // marginTo
                    fontWeight: 700,
                    fontSize: '16px',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                >
                  Forgot Username
                </Link>{' '}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '20px' }}>
              <SButton
                onClick={() => {
                  setPwdResetData({ userName: '' })
                  setNoSuchUser(false)
                }}
              >
                Try Again
              </SButton>
            </Grid>
          </>
        }
      />
    )
  }

  if (tooManyOTPRequested) {
    return (
      <BaseErrorCard
        headerTitle="Too many password reset requests"
        content={
          <STypography variant="body_regular">
            You have too many recent attempts to reset your password. Please
            wait additional 10 minutes and try again.
          </STypography>
        }
      />
    )
  }

  return (
    <Grid
      item
      xs={12}
      container
      gap={isSmall ? '16px' : '32px'}
      style={{ padding: '30px' }}
    >
      <Grid item xs={12}>
        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: 700,
            textAlign: 'center',
          }}
        >
          {t('forgotPasswordTitle')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          style={{
            fontSize: '18px',
            fontWeight: 400,
            textAlign: 'center',
            lineHeight: 'normal',
          }}
        >
          Please enter your username
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          autoComplete="off"
          variant="outlined"
          id="email"
          fullWidth
          name="email"
          placeholder="Enter your username"
          label="Enter your username"
          value={pwdResetData.userName}
          required
          onChange={(e) => {
            setPwdResetData({ userName: e.target.value })
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <RegistrationToolbar
          onClickNext={async () => {
            if (!pwdResetData.userName) {
              return
            }
            await asyncForgotPassword(pwdResetData.userName)
            gaService.trackEvent(
              gaService.eventMap.forgot_password_submit_email,
            )
          }}
          nextDisabled={!pwdResetData.userName || isLoading}
          nextLoading={isLoading}
          useBackLabelForCancel={false}
          sidePadding={isSmall ? undefined : '20%'}
        />
      </Grid>
    </Grid>
  )
}
