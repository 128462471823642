import { Grid, Typography } from '@mui/material'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import EmailLink from '../widgets/EmailLink'
import PhoneLink from '../widgets/PhoneLink'
import { LOG } from 'src/app/v2/applog'
import { useEffect, useState } from 'react'
import { getUrlParameter } from '../apiexec/utils'
import { useAmazon } from 'src/app/hooks/useAmazon'
import {
  SSO_URL_PARAM_ERROR_CODE,
  SSO_URL_PARAM_SHORTCODE,
} from './SamlLoginHandler'
import { ErrorCardSSODataNotFound } from 'src/app/system/error/BaseErrorCard'
export const GENERIC_SSO_UKNOWN_CLIENT = 'UNKNOWN'
export default function SamlLoginError() {
  const [errorCode, setErrorCode] = useState<string | undefined>(undefined)
  const [clientShortCode, setClientShortCode] = useState<string | undefined>(
    undefined,
  )

  const { setIsAmazon } = useAmazon()
  useEffect(() => {
    const shortCode = getUrlParameter(SSO_URL_PARAM_SHORTCODE)
    if (
      shortCode &&
      (shortCode.indexOf('amazon') > -1 ||
        shortCode.indexOf('okta') > -1 ||
        shortCode.indexOf('amzn') > -1)
    ) {
      console.log(
        'amazon (or okta) shortcode detected, setting isAmazon to true',
      )
      setIsAmazon(true)
    }
  }, [setIsAmazon])

  useEffect(() => {
    const clientShortCode = getUrlParameter(SSO_URL_PARAM_SHORTCODE)
    if (clientShortCode) {
      setClientShortCode(clientShortCode)
    }
  }, [])

  useEffect(() => {
    const errorCode = getUrlParameter(SSO_URL_PARAM_ERROR_CODE)
    if (errorCode) {
      setErrorCode(errorCode)
    }
  }, [])

  useEffect(() => {
    if (
      getUrlParameter(SSO_URL_PARAM_SHORTCODE) === GENERIC_SSO_UKNOWN_CLIENT
    ) {
      LOG.warn(
        'sso',
        'Detect a request to show the generic SSO login error page. This means user was not able to SSO in. Invoked params?:  ',
        window.location.search,
      )
    } else {
      LOG.error(
        'sso',
        'Detect a client-specific request to show the SSO login error page. This means user was not able to SSO in. Invoked params?:  ',
        window.location.search,
      )
    }
  }, [])

  return <ErrorCardSSODataNotFound clientShortCode={clientShortCode} />
}
