import { Divider, Grid, Link, useMediaQuery, useTheme } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react'
import { checkMicroServiceResponseStatus } from 'src/app/apiexec/utils'
import {
  EKS_INVALID_OTP_ERROR_CODE,
  EKS_INVALID_OTP_EXPIRED,
} from 'src/app/EKSStatuCodes'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { EksValueOnFile } from 'src/app/registration/IdentityValidationEKS'
import { OneTimePassCode } from '../../types'
import { useNotifier } from '../hooks/useNotify'
import { toDigitsOnly } from '../member/phone-field-utils'
import { useMemberAppContext } from '../MemberAppContext'
import { useSColors } from '../styles/scripta-theme'
import { LOG } from '../v2/applog'
import { CustomTextField } from './components/CustomTextField'
import { RegistrationStepCard } from './components/RegistrationStepCard'
import { DualBottomButtonToolbar } from './DualBottomButtonActionToolbar'
import { useRegistration } from './RegistrationContext'
import {
  REG_ROUTE_COMPLETE_PREREG_PROFILE,
  REG_ROUTE_EDIT_CONTACT_INFO_EKS,
  REG_ROUTE_FIND_TENANT,
} from './RegistrationSubRoutes'
import { useRegistrationService } from './useRegistrationService'

export function ConfirmOtpEKS() {
  const { COLORS } = useSColors()
  const {
    history,
    // member,
    eksMember,
    matchedTenant,
    setApiResponse,
    setRegistrationJwt,
    eksOtpType,
  } = useRegistration()
  const service = useRegistrationService()
  const { t } = useSTranslate('register')

  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  const [otpString, setOtpString] = useState<string>('')
  const [fullOtpCode, setFullOtpCode] = useState<OneTimePassCode>({} as any)

  const [isCodeInvalid, setIsCodeInvalid] = useState<boolean>(false)

  const { isFirstTimePreregMemberAccess } = useMemberAppContext()

  const [nextLoading, setNextLoading] = useState(false)

  //use notifier
  const { sendMsg } = useNotifier()

  // useEffect(() => {
  //   setFullOtpCode({
  //     ...fullOtpCode,
  //     tenantId: member.tenantId,
  //     memberId: member.id,
  //     passcode: otpString,
  //   })
  // }, [otpString, member])

  //actual legacy otp verificaiton - commented out for final removal
  // async function asyncVerifyOtp(
  //   tenantUuid: string,
  //   encryptedMemberId: string,
  //   eventType: EksOtpType,
  //   otp: string,
  // ) {
  //   try {
  //     const resp = await service.verifyRegistrationOtpApi(
  //       tenantUuid,
  //       encryptedMemberId,
  //       eventType,
  //       otp,
  //     )

  //     if (isServerErrorResponse<Member>(resp)) {
  //       history.push(REG_ROUTE_SERVER_ERROR)
  //       return
  //     }

  //     if (isValidResponse<VerifyRegistrationIdentityResponse>(resp)) {
  //       const registrationJwt = resp.registrationJwt
  //       setRegistrationJwt(registrationJwt)
  //       setApiResponse(resp)
  //       if (isFirstTimePreregMemberAccess) {
  //         history.push(REG_ROUTE_COMPLETE_PREREG_PROFILE)
  //       } else {
  //         history.push(REG_ROUTE_EDIT_CONTACT_INFO)
  //       }
  //     } else {
  //       //check if it is very specific error coce indicating invalid code
  //       if (
  //         (resp && resp.code === RESPONSE_CODE_INVALID_PASSCODE) ||
  //         (resp && resp.code === RESPONSE_CODE_PASSCODE_EXPIRED)
  //       ) {
  //         LOG.error('registration', 'invalid or expird passcode resp', resp)
  //         setIsCodeInvalid(true)
  //       } else {
  //         //its a non-standard error, should not happen, so send to generic error handler
  //         LOG.error('registration', 'unexpected error in verify otp', resp)
  //         setApiResponse(resp)
  //         history.push(REG_ROUTE_ERROR_HANDLER)
  //       }
  //     }
  //   } catch (e) {
  //     LOG.error('registration', 'Error verifying otp', e)
  //     throw new Error(t('errorInApiExecution'))
  //   }
  // }

  useEffect(() => {
    if (!eksMember || !matchedTenant) {
      LOG.error(
        'registration',
        'Missing eksMember or matchedTenant, sending back to find tenant',
      )
      history.push(REG_ROUTE_FIND_TENANT)
    }
  }, [eksMember, matchedTenant])

  return (
    <RegistrationStepCard
      registrationType="registration"
      progressBarStep={4}
      //hide the standard toolbar, we are using custom one here with submit buttons
      disableToolbar={true}
      customToolbarComponent={
        <DualBottomButtonToolbar
          noText={t('register:btnBack')}
          yesText={t('register:btnSubmit')}
          onClickNo={() => {
            history.goBack()
          }}
          onClickYes={async () => {
            setNextLoading(true)

            if (!matchedTenant?.clientUuid) {
              LOG.error('registration', 'no matchedTenant.tenant uuid')
              history.push(REG_ROUTE_FIND_TENANT)
              return
            }
            if (!eksMember?.id) {
              LOG.error('registration', 'no eksMember id')
              history.push(REG_ROUTE_FIND_TENANT)
              return
            }
            if (!eksOtpType) {
              LOG.error('registration', 'no eksO')
              history.push(REG_ROUTE_FIND_TENANT)
              return
            }

            try {
              let otpType = eksOtpType
              //need special handling for first time prereg member access and otp type events
              if (isFirstTimePreregMemberAccess) {
                if (eksOtpType === 'MEMBER_PHONE_VERIFICATION_EVENT') {
                  otpType = 'MEMBER_PHONE_SECURITY_CLEARANCE_EVENT'
                } else if (eksOtpType === 'MEMBER_EMAIL_VERIFICATION_EVENT') {
                  otpType = 'MEMBER_EMAIL_SECURITY_CLEARANCE_EVENT'
                }
              }
              const validateOtpResp = await service.validateOtp_EKS(
                matchedTenant!.clientUuid,
                eksMember?.id,
                otpType,
                otpString,
              )

              setNextLoading(false)

              let allowUndefined = true
              const respStatus = checkMicroServiceResponseStatus(
                validateOtpResp,
                allowUndefined,
              )

              if (!respStatus.isError) {
                const regJwt = validateOtpResp.token
                console.log('updated reg jwt as part of otp validation')
                setRegistrationJwt(regJwt)

                if (isFirstTimePreregMemberAccess) {
                  console.log(
                    'isFirstTimePreregMemberAccess is true, sending to completeprereg profile',
                  )

                  history.push(REG_ROUTE_COMPLETE_PREREG_PROFILE)
                } else {
                  history.push(REG_ROUTE_EDIT_CONTACT_INFO_EKS)
                }
              } else {
                if (
                  (validateOtpResp as any).scriptaErrorCode ===
                    EKS_INVALID_OTP_ERROR_CODE ||
                  (validateOtpResp as any).scriptaErrorCode ===
                    EKS_INVALID_OTP_EXPIRED
                ) {
                  setIsCodeInvalid(true)
                } else {
                  //still mark it as invalid, maybe they can try again and get lucky
                  setIsCodeInvalid(true)
                  LOG.error(
                    'registration',
                    'OTP validation failed with the response with unrecognized resp code',
                    validateOtpResp,
                  )
                }
              }
            } catch (e) {
              setNextLoading(false)
              LOG.error(
                'registration',
                'ERROR cought in validate OTP api processing=',
                e,
              )
            }
            // doVerifyOtp()
          }}
          yesLoading={nextLoading}
        />
      }
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography
            data-testid="confirm-otp-page-title"
            sx={{
              textAlign: 'center',
              fontSize: isSmall ? '1.125rem' : '1.5rem',
              fontWeight: 700,
              paddingBottom: '20px',
              paddingTop: '0px',
              // marginTop: isSmall ? '-15px' : '0px',
              lineHeight: '21.92px',
            }}
          >
            {t('enter4DigitCode')}X
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ paddingTop: '10px' }}>
          <Typography
            sx={{
              fontSize: '1rem',
              fontWeight: 400,
              lineHeight: '20px',
              textAlign: 'left',
              paddingLeft: isSmall ? '10px' : undefined,
              // paddingRight: isSmall ? '20px' : undefined,
            }}
          >
            {eksOtpType === 'MEMBER_PHONE_VERIFICATION_EVENT'
              ? t('acodeWasSentToNumber')
              : t('acodeWasSentToEmail')}
          </Typography>
        </Grid>
        {eksOtpType && eksMember && (
          <Grid
            item
            xs={12}
            sx={{
              paddingTop: '20px',
              paddingLeft: isSmall ? '10px' : undefined,
            }}
          >
            <EksValueOnFile eksOtpType={eksOtpType} eksMember={eksMember} />
          </Grid>
        )}

        <Grid
          item
          xs={12}
          sx={{
            paddingTop: '20px',
            paddingLeft: isSmall ? '10px' : undefined,
            paddingRight: isSmall ? '10px' : undefined,
          }}
        >
          <CustomTextField
            data-testid="verify-otp-input"
            autoComplete="off"
            id="otp"
            name="otp"
            // type="number"
            placeholder={t('enterYourCode')}
            label={t('verificationCode')}
            value={otpString}
            // inputProps={{ maxLength: 4 }}
            onChange={(e) => {
              const numOnly = toDigitsOnly(e.target.value)
              if (numOnly.length > 4) {
                setOtpString(numOnly.substring(0, 4))
              } else {
                setOtpString(e.target.value)
              }
            }}
            error={isCodeInvalid}
            helperText={isCodeInvalid ? t('invalidOtpCode') : ''}
          />
        </Grid>
        <Grid item xs={12} sx={{ paddingTop: '20px' }}>
          <Typography
            sx={{
              fontSize: '1rem',
              fontWeight: 400,
              lineHeight: '20px',
              textAlign: 'left',
              paddingLeft: isSmall ? '10px' : undefined,
              paddingRight: isSmall ? '10px' : undefined,
            }}
          >
            {t('codeExpires')}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            marginTop: '30px',
            marginBottom: '20px',
            paddingRight: isSmall ? '20px' : undefined,
            paddingLeft: isSmall ? '20px' : undefined,
          }}
        >
          <Divider
            sx={{
              width: '100%',
              minWidth: '100%',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Link
            // component="button"
            variant="body2"
            sx={{
              paddingLeft: isSmall ? '20px' : undefined,
              cursor: 'pointer',
              fontSize: '1rem',
              fontWeight: 600,
              color: COLORS.TEXT_SECONDARY,
              textDecoration: 'none',
            }}
            onClick={async () => {
              setNextLoading(true)
              if (!matchedTenant?.clientUuid || !eksMember?.id) {
                LOG.error(
                  'registration',
                  'cannot request another OTP as matchedTenat or expected member id are no longer in context',
                )
                setNextLoading(false)
                return
              }

              const eventType =
                eksOtpType === 'MEMBER_PHONE_VERIFICATION_EVENT'
                  ? 'MEMBER_PHONE_VERIFICATION_EVENT'
                  : 'MEMBER_EMAIL_VERIFICATION_EVENT'

              try {
                const getOtpResp = await service.generateOtp_EKS(
                  matchedTenant!.clientUuid,
                  eksMember!.id,
                  eventType,
                )
                setNextLoading(false)
                let allowUndefined = true
                const otpStatus = checkMicroServiceResponseStatus(
                  getOtpResp,
                  allowUndefined,
                )
                if (!otpStatus.isError) {
                  sendMsg('New code has been sent!', 'success')
                } else {
                  sendMsg(
                    'There was a problem sending your code. Please contact our member support.',
                    'error',
                  )
                }
              } catch (e) {
                setNextLoading(false)
                LOG.error('registration', 'Error generating OTP', e)
              }
            }}
          >
            {t('requestAnotherCode')}
          </Link>
        </Grid>

        {/* <Grid item xs={12} sx={{ minHeight: '10px' }}></Grid> */}
      </Grid>
    </RegistrationStepCard>
  )
}
