import { Grid, Link } from '@mui/material'
import STypography from './customcomponents/STypography'
import { RADIUS, SPACING } from './theme2'
import { useState } from 'react'
import { SavingStrategy } from '../strategies/types'
import { SDialog } from './customcomponents/SDialog'
import { VideoWithPoster } from '../strategies/VideoWithPoster'
import {
  useSTranlateForObjects_waitUntilReady,
  useSTranslate,
} from 'src/app/hooks/useSTranslate'
import { getIconForSavingStrategy } from './utils'
import PharmaciesWithCoupons from './PharmaciesWithCoupons'
import { PharmacyType } from '../v2/saving-utils'
import { ModalPharmacyChange, ModalPharmacyTypeInfo } from './modals/Modals'
import { useSColors } from '../styles/scripta-theme'
import { ThemedSVGRemote } from '../v2/ThemedSVGRemote'
export function SavingMethodModalLink({
  strategy,
  pharmacyType = undefined,
  is90DaysSupply = false,
  isMailOrder = false,
  isCashPharmacy = false,
}: {
  strategy?: SavingStrategy
  pharmacyType?: PharmacyType
  is90DaysSupply?: boolean
  isMailOrder?: boolean
  isCashPharmacy?: boolean
}) {
  const { COLORS } = useSColors()

  const { t } = useSTranslate('newComponents')
  const [open, setOpen] = useState(false)

  const modalButtonText = t('savingMethod')

  return (
    <>
      <Link
        component={'button'}
        sx={{
          color: COLORS.TEXT_SECONDARY,
          textDecorationColor: COLORS.TEXT_SECONDARY,
        }}
        onClick={() => setOpen(true)}
      >
        <STypography
          variant="bodysmall_semibold"
          sx={{
            color: COLORS.TEXT_SECONDARY,
            textDecorationColor: COLORS.TEXT_SECONDARY,
          }}
        >
          {modalButtonText}
        </STypography>
      </Link>

      <SavingMethodModal
        open={open}
        onClose={() => setOpen(false)}
        strategy={strategy}
        pharmacyType={pharmacyType}
        isMailOrder={isMailOrder}
        is90DaysSupply={is90DaysSupply}
        isCashPharmacy={isCashPharmacy}
      />
    </>
  )
}

export default function SavingMethodModal({
  strategy,
  open,
  onClose,
  pharmacyType = undefined,
  is90DaysSupply = false,
  isMailOrder = false,
  isCashPharmacy = false,
}: {
  strategy?: SavingStrategy
  open: boolean
  onClose: () => void
  pharmacyType?: PharmacyType
  is90DaysSupply?: boolean
  isMailOrder?: boolean
  isCashPharmacy?: boolean
}) {
  const { COLORS } = useSColors()

  const { t, ready } = useSTranlateForObjects_waitUntilReady('strategies')
  if (!ready) return null

  const isPharmacyInfoContent =
    pharmacyType !== undefined || is90DaysSupply || isMailOrder

  let strategyConfig: any = strategy
    ? t(strategy, {
        returnObjects: true,
      })
    : {}

  let couponStrategyConfig: any = t(SavingStrategy.Coupons, {
    returnObjects: true,
  })

  const icon = strategy ? getIconForSavingStrategy(strategy) : undefined

  if (strategy === SavingStrategy.PharmacySwitch) {
    return <ModalPharmacyChange open={open} onClose={onClose} />
  }

  const renderNonLegacyStrategyContent = () => {
    if (strategy === SavingStrategy.Coupons) {
      return (
        <>
          <Grid item>
            <ThemedSVGRemote
              importUrl={getIconForSavingStrategy(SavingStrategy.Coupons)}
              alt={SavingStrategy.Coupons + ' icon'}
              width={72}
              height={72}
            />
          </Grid>
          <Grid item sx={{ marginBottom: SPACING._025 }}>
            <STypography
              variant="title3_bold"
              sx={{ color: COLORS.TEXT_BRAND }}
            >
              {SavingStrategy.Coupons}
            </STypography>
          </Grid>
          <Grid item sx={{ marginBottom: SPACING._1 }}>
            <STypography variant="body_regular">
              {couponStrategyConfig['scripta'].description}
            </STypography>
          </Grid>
          <Grid item sx={{ width: '100%' }}>
            <PharmaciesWithCoupons />
          </Grid>
        </>
      )
    }

    return (
      <>
        <br />
        <ModalPharmacyTypeInfo
          renderContentOnly
          type={pharmacyType}
          isMailOrder={isMailOrder}
          is90DaysSupply={is90DaysSupply}
          isCashPharmacy={isCashPharmacy}
          open={open}
        />
      </>
    )
  }

  return (
    <SDialog open={open} onClose={onClose}>
      <Grid
        container
        justifyContent={'center'}
        alignItems={'center'}
        direction={'column'}
      >
        {strategy && strategy !== SavingStrategy.Coupons ? (
          <>
            <Grid item>
              {icon && (
                <ThemedSVGRemote
                  importUrl={icon}
                  alt={strategy + ' icon'}
                  width={72}
                  height={72}
                />
              )}

              {/* <img src={icon} alt={strategy + ' icon'} width={72} /> */}
            </Grid>
            <Grid item sx={{ marginBottom: SPACING._025 }}>
              <STypography
                variant="title3_bold"
                sx={{ color: COLORS.TEXT_BRAND }}
              >
                {strategy}
              </STypography>
            </Grid>
            <Grid item sx={{ marginBottom: SPACING._1 }}>
              <STypography variant="body_regular">
                {strategyConfig['scripta'].description}
              </STypography>
            </Grid>
            {strategyConfig['scripta'].youtubeId ? (
              <Grid
                item
                sx={{
                  borderRadius: RADIUS.sm,
                  overflow: 'hidden',
                  width: '95%',
                }}
              >
                <VideoWithPoster
                  isSmallScreen={true}
                  youtubeId={strategyConfig['scripta'].youtubeId}
                  title={strategy}
                />
              </Grid>
            ) : null}
          </>
        ) : null}
        {(strategy && strategy === SavingStrategy.Coupons) ||
        isPharmacyInfoContent
          ? renderNonLegacyStrategyContent()
          : null}
      </Grid>
    </SDialog>
  )
}
