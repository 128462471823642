import {
  Checkbox,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import optOutDialogHeaderImage from '../../images/optout_confirm_dialog_header.svg'
import { usePortalAppConfig } from '../config/usePortalAppConfig'
import { PrevValuesCache, useRegistration } from './RegistrationContext'
import {
  REG_ROUTE_CREATE_PROFILE_EKS,
  REG_ROUTE_IDENTITY_VALIDATION_EKS,
} from './RegistrationSubRoutes'

import { useRegistrationService } from './useRegistrationService'
// import { CustomTextField } from '../registration/components/CustomTextField'
import { useMemberAppContext } from '../MemberAppContext'
import { NewPortalDialogDesign } from '../application/NewDialogs'
import { GAService } from '../application/ga/GAService'
import {
  isDisplayFriendlyStringValidNumber,
  toFullNumberFromDisplayFriendlyString,
} from '../member/phone-field-utils'
import { useSColors } from '../styles/scripta-theme'
import { RegistrationFormFieldsBox } from './RegistrationContainer'
import { SMSTermsOfUseContent } from './SMSTermsOfUseContent'
import {
  CustomTextField,
  CustomTextFieldLabelNode,
} from './components/CustomTextField'
import { RegistrationStepCard } from './components/RegistrationStepCard'
import { v4 as uuidv4 } from 'uuid'
import { TargetRoute } from '../v2/SavingGuide'
import STypography from 'src/app/system/customcomponents/STypography'

export function validateEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}
export function EditContactInfoEKS() {
  const gaService = new GAService()
  const service = useRegistrationService()
  const { t } = useSTranslate(['register', 'account'])
  const { t: tSettings } = useSTranslate('settings')
  // const { t: tlogin } = useTranslation('login')
  const PORTAL_APP_CONFIG = usePortalAppConfig()

  const {
    history,
    setRegSetupData,
    setApiResponse,
    setPrevValuesCache,
    prevValuesCache,
    eksContactEmail,
    setEksContactEmail,
    eksContactPhone,
    setEksContactPhone,
    eksContactSmsOptIn,
    setEksContactSmsOptIn,
  } = useRegistration()

  const { isFirstTimePreregMemberAccess } = useMemberAppContext()

  const { quickLinkCode } = useMemberAppContext()

  const [targetRoute, setTargetRoute] = useState<TargetRoute>()
  function pushToSubrouteViaEffect(subroute: string) {
    //we use the object, not a simple string, to ensure useEffect triggers
    const route: TargetRoute = {
      //todo - this guid is probably not needed anymore, we use a object in state
      uuid: uuidv4(),
      route: subroute,
    }

    setTargetRoute(route)
  }
  //here we watch for the target route to be set and then we push to it
  useEffect(() => {
    if (targetRoute) {
      history.push(targetRoute.route)
    }
  }, [targetRoute])

  const theme = useTheme()

  const [providedEmail, setProvidedEmail] = useState<string>('')
  const [isEmailValidAndAvailable, setIsEmailValidAndAvailable] =
    useState<boolean>(false)
  //small track not to trigger validation at least untill user typed something in
  const [isEmailTouched, setIsEmailTouched] = useState(false)
  const [emailErrorMsg, setEmailErrorMsg] = useState<string>('')

  //confirmed email
  const [confirmedEmail, setConfirmedEmail] = useState<string>('')
  const [isConfirmedEmailTouched, setIsConfirmedEmailTouched] =
    useState<boolean>(false)

  const [userOptedIn, setUserOptedIn] = useState<boolean>(false)

  const [isPhoneValidAndAvailable, setIsPhoneValidAndAvailable] =
    useState<boolean>(false)
  //entered phone including the input mask
  const [providedPhoneUiSyntax, setProvidedPhoneUiSyntax] = useState<string>('')
  const [isPhoneTouched, setIsPhoneTouched] = useState(false)

  const [phoneErrorMsg, setPhoneErrorMsg] = useState<string>('')

  const [isNextDisabled, setIsNextDisabled] = useState<boolean>(true)

  //on change of email, check if its valid syntax and available its available
  useEffect(() => {
    if (validateEmail(providedEmail)) {
      //if its a valid email, make sure its also been confirmed first

      // //its a valid email, lets make sure its also available
      // const checkAvailable = async (emailToCheck: string) => {
      //   const isAvalable = await service.checkEmailAvailableApi(
      //     member?.tenantId!,
      //     member?.id!,
      //     emailToCheck,
      //   )
      //   if (!isAvalable) {
      //     setIsEmailValidAndAvailable(false)
      //     setEmailErrorMsg(
      //       'This email is already registered. Please use a different email.',
      //     )
      //   } else {
      //     setIsEmailValidAndAvailable(true)
      //     setEmailErrorMsg('')
      //   }
      // }
      setIsEmailValidAndAvailable(true)
      setEmailErrorMsg('')
      // checkAvailable(providedEmail)
    } else {
      setIsEmailValidAndAvailable(false)
      setEmailErrorMsg('Please provide a valid email')
    }
  }, [providedEmail, confirmedEmail])

  //effect to process change on phone number - if provided, make sure its available
  useEffect(() => {
    if (isDisplayFriendlyStringValidNumber(providedPhoneUiSyntax)) {
      //its a valid email, lets make sure its also available
      // const checkAvailable = async (phoneToCheck: string) => {
      //   const isAvalable = await service.checkPhoneAvailableApi(
      //     member?.tenantId!,
      //     member?.id!,
      //     phoneToCheck,
      //   )
      //   if (!isAvalable) {
      //     setIsPhoneValidAndAvailable(false)
      //     setPhoneErrorMsg(
      //       'This phone is already registered. Please use a different phone.',
      //     )
      //   } else {
      //     setIsPhoneValidAndAvailable(true)
      //     setPhoneErrorMsg('')
      //   }
      // }

      const phone = toFullNumberFromDisplayFriendlyString(providedPhoneUiSyntax)

      setIsPhoneValidAndAvailable(true)
      setPhoneErrorMsg('')

      // checkAvailable(phone)
    } else {
      setIsPhoneValidAndAvailable(false)
      setPhoneErrorMsg('Please provide a valid US phone number')
    }
  }, [providedPhoneUiSyntax])

  // effect to enable to next button
  useEffect(() => {
    if (isEmailValidAndAvailable) {
      if (providedEmail !== confirmedEmail) {
        setIsNextDisabled(true)
        return
      }
      //if phone has been touched,make sure its valid
      if (isPhoneTouched) {
        if (!isPhoneValidAndAvailable) {
          setIsNextDisabled(true)
          return
        }
      }

      //if they opted in, make sure they provided a valid phone
      if (userOptedIn) {
        if (!isPhoneValidAndAvailable) {
          setIsNextDisabled(true)
          return
        }
      }

      setIsNextDisabled(false)
    } else {
      setIsNextDisabled(true)
    }
  }, [
    providedEmail,
    confirmedEmail,
    isEmailValidAndAvailable,
    providedPhoneUiSyntax,
    isPhoneValidAndAvailable,
    userOptedIn,
  ])

  // effect to automatically opt-in the user when they provide a valid email and phone

  // useEffect(() => {
  //   if (isEmailValidAndAvailable && isPhoneValidAndAvailable) {
  //     //FIX - unless they opted out before then clicked back
  //     if (prevValuesCache && prevValuesCache.concatSmsOptIn) {
  //       setUserOptedIn(true)
  //     } else if (prevValuesCache && prevValuesCache.concatSmsOptIn === false) {
  //       setUserOptedIn(false)
  //     } else {
  //       setUserOptedIn(true)
  //       //update previous cache values
  //       setPrevValuesCache({
  //         ...prevValuesCache,
  //         concatSmsOptIn: true,
  //       })
  //     }
  //   } else {
  //     setUserOptedIn(false)
  //   }
  // }, [
  //   providedEmail,
  //   isEmailValidAndAvailable,
  //   providedPhoneUiSyntax,
  //   isPhoneValidAndAvailable,
  //   prevValuesCache,
  // ])

  const isMedium = useMediaQuery(theme.breakpoints.down('md'))
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  // const [agreed, setAgreed] = useState<boolean>(true)
  const [renderSmsTermsDialog, setRenderSmsTermsDialog] =
    useState<boolean>(false)
  const [renderOptoutConfirmDialog, setRenderOptoutConfirmDialog] =
    useState<boolean>(false)
  //to be set via new confirmed optout dialog

  useEffect(() => {
    document.title = 'Edit Contact Information - Scripta Insights'
  }, [])

  //update from cache if any
  useEffect(() => {
    if (prevValuesCache && prevValuesCache.contactEmail) {
      setProvidedEmail(prevValuesCache.contactEmail)
      setEksContactEmail(prevValuesCache.contactEmail)
    }
    if (prevValuesCache && prevValuesCache.contactPhone) {
      setProvidedPhoneUiSyntax(prevValuesCache.contactPhone)
      setEksContactPhone(prevValuesCache.contactPhone)
    }
    if (prevValuesCache && prevValuesCache.concatSmsOptIn) {
      setUserOptedIn(true)
      setEksContactSmsOptIn(true)
    } else {
      setUserOptedIn(false)
      setEksContactSmsOptIn(false)
    }
    if (prevValuesCache && prevValuesCache.contactConfirmEmail) {
      setConfirmedEmail(prevValuesCache.contactConfirmEmail)
    }
  }, [prevValuesCache])

  // const onEmailContactChanged = (newEmail: string) => {
  //   setRegSetupData({
  //     ...regSetupData,
  //     tenantId: member!.tenantId,
  //     memberId: member!.id,
  //     email: newEmail,
  //   })
  // }

  // useEffect(() => {
  //   if (!!email) setIsInvalidEmail(!validateEmail(email))
  //   else setIsInvalidEmail(false)
  // }, [email])

  // useEffect(() => {
  //   if (invalidEmail) handleTooltipOpen()
  //   else handleTooltipClose()
  // }, [invalidEmail])

  // async function asyncCaptureSmsOptin(
  //   tenantId: number,
  //   memberId: number,
  //   mobileToCapture: string,
  // ) {
  //
  //   try {
  //     const resp = await service.captureSmsOptInApi(tenantId, memberId, mobile)

  //     //check if code 247 - email already used and handle it here
  //     if (isServerErrorResponse<any>(resp)) {
  //       if (resp && resp.code === RESPONSE_CODE_EMAIL_ALREADY_USED) {
  //         setEmailAlreadyUse(true)
  //       } else {
  //         setEmailAlreadyUse(false)
  //         history.push(REG_ROUTE_SERVER_ERROR)
  //       }
  //       return
  //     } else {
  //       setEmailAlreadyUse(false)
  //     }
  //   } catch (e) {
  //     throw new Error('error in api execution')
  //   }
  // }

  // const handleTooltipClose = () => {
  //   setOpen(false)
  // }

  // const handleTooltipOpen = () => {
  //   setOpen(true)
  // }

  // async function asyncCaptureEmail(
  //   tenantId: number,
  //   memberId: number,
  //   email: string,
  // ): Promise<boolean> {
  //   try {
  //     const resp = await service.captureEmailApi(tenantId, memberId, email)

  //     if (isServerErrorResponse<void>(resp)) {
  //       history.push(REG_ROUTE_SERVER_ERROR)
  //       return false
  //     }

  //     if (isValidResponse<void>(resp)) {
  //       // history.push(REG_ROUTE_CREATE_PROFILE)
  //       return true
  //     } else {
  //       // console.error('not a valid response,full resp = ', resp)
  //       setApiResponse(resp)
  //       return false
  //       // history.push(REG_ROUTE_ERROR_HANDLER)
  //     }
  //   } catch (e) {
  //     throw new Error('error in api execution')
  //   }
  // }

  // async function asyncCaptureSms(
  //   tenantId: number,
  //   memberId: number,
  //   mobilePhone: string,
  // ) {
  //   try {
  //     const resp = await service.captureSmsOptInApi(
  //       tenantId,
  //       memberId,
  //       mobilePhone,
  //     )

  //     if (isServerErrorResponse<void>(resp)) {
  //       history.push(REG_ROUTE_SERVER_ERROR)
  //       return
  //     }

  //     //TODO - email already used
  //     if (isValidResponse<void>(resp)) {
  //       // history.push(REG_ROUTE_CREATE_PROFILE)
  //     } else {
  //       // console.error('not a valid response,full resp = ', resp)
  //       setApiResponse(resp)
  //       history.push(REG_ROUTE_ERROR_HANDLER)
  //     }
  //   } catch (e) {
  //     throw new Error('error in api execution')
  //   }
  // }

  // const doCaptureSmsOptIn = useCallback(() => {
  //   if (!regSetupData || !regSetupData.smsOptIn) {
  //     history.push(REG_ROUTE_CREATE_PROFILE)
  //     gaService.trackEvent(gaService.eventMap.step_6_reg_next_opt_out)
  //     return
  //   }
  //   if (regSetupData && regSetupData.smsOptIn) {
  //     asyncSmsOptIn(regSetupData)
  //     gaService.trackEvent(gaService.eventMap.step_6_reg_next_opt_in)
  //   }
  // }, [regSetupData])

  //main callback clicked when user clicks next
  //by this time provided email and phone have been validated and are available
  // const doNextButtonProcessing = useCallback(async () => {
  //   //email we always capture regardless of opt in or out, its required for registration

  //   const isOk = await asyncCaptureEmail(
  //     member?.tenantId!,
  //     member?.id!,
  //     providedEmail,
  //   )

  //   if (!isOk) {
  //     history.push(REG_ROUTE_ERROR_HANDLER)
  //     // history.push(REG_ROUTE_SERVER_ERROR)
  //     return
  //   }

  //   gaService.trackEvent(gaService.eventMap.step_5_reg_next)

  //   if (userOptedIn) {
  //     //if opted in, capture it and then continue
  //     gaService.trackEvent(gaService.eventMap.step_6_reg_next_opt_in)
  //     const serverPhone = toFullNumberFromDisplayFriendlyString(
  //       providedPhoneUiSyntax,
  //     )
  //     await asyncCaptureSms(member?.tenantId!, member?.id!, serverPhone)
  //   }

  //   //cache the data in case they come back from the next step

  //   const updatedPartOfCache: PrevValuesCache = {
  //     ...prevValuesCache,
  //     contactEmail: providedEmail,
  //     contactConfirmEmail: confirmedEmail,
  //     contactPhone: providedPhoneUiSyntax,
  //     concatSmsOptIn: userOptedIn,
  //   }
  //   setPrevValuesCache(updatedPartOfCache)

  //   history.push(REG_ROUTE_CREATE_PROFILE)
  // }, [
  //   providedEmail,
  //   providedPhoneUiSyntax,
  //   userOptedIn,
  //   member,
  //   confirmedEmail,
  // ])

  const preventCopyPaste = (e: any) => {
    e.preventDefault()
  }

  return (
    <RegistrationStepCard
      registrationType="registration"
      progressBarStep={5}
      // step={t('step', { current: 5, total: 7 })}
      // title={t('howShouldWeContactYou')}
      // message={t('contactStepDescription')}
      cancelButtonTitle={t('common:back')}
      //need to capture both email and sms opt in, if they opted in
      onClickNextButton={async () => {
        setEksContactEmail(providedEmail)
        setEksContactPhone(providedPhoneUiSyntax)
        setEksContactSmsOptIn(userOptedIn)
        //update cache value
        const updatedPartOfCache: PrevValuesCache = {
          ...prevValuesCache,
          contactEmail: providedEmail,
          contactConfirmEmail: confirmedEmail,
          contactPhone: providedPhoneUiSyntax,
          concatSmsOptIn: userOptedIn,
        }
        setPrevValuesCache(updatedPartOfCache)

        pushToSubrouteViaEffect(REG_ROUTE_CREATE_PROFILE_EKS)
      }}
      onClickCancelButton={() =>
        history.push(REG_ROUTE_IDENTITY_VALIDATION_EKS)
      }
      // onClickCancelButton={() => history.push(REG_ROUTE_CHALLENGE_QUESTIONS)}
      nextButtonDisabled={isNextDisabled}
      backHidden={quickLinkCode !== undefined}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography
            data-testid="contact-info-page-title"
            sx={{
              textAlign: 'center',
              fontSize: isSmall ? '1.125rem' : '1.5rem',
              fontWeight: 700,
              paddingBottom: '20px',
              paddingTop: '0px',
              // marginTop: isSmall ? '-15px' : '0px',
              lineHeight: '21.92px',
            }}
          >
            {t('howShouldWeContactYou')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: isSmall ? '1rem' : '1.125rem',
              fontWeight: 300,
              lineHeight: '20px',
              textAlign: 'center',
              paddingLeft: isSmall ? '20px' : undefined,
              paddingRight: isSmall ? '20px' : undefined,
            }}
          >
            {t('contactStepDescription')}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ minHeight: '30px' }}></Grid>
      </Grid>
      <RegistrationFormFieldsBox>
        <Grid item xs={12}>
          <CustomTextField
            data-testid="contact-info-email-input"
            autoComplete="off"
            id="emailContact"
            fullWidth
            name="passwordCemailContact"
            placeholder={t('account:emailContact')}
            label={t('account:emailContact')}
            value={providedEmail}
            required
            error={isEmailTouched && !isEmailValidAndAvailable ? true : false}
            helperText={isEmailTouched && emailErrorMsg ? emailErrorMsg : ''}
            onChange={(e) => {
              setIsEmailTouched(true)
              setProvidedEmail(e.target.value)
              // if (onEmailContactChanged) {
              //   onEmailContactChanged(e.target.value)
              // }
            }}
          />
        </Grid>
        <Grid item xs={12} sx={{ paddingTop: '10px' }}>
          <CustomTextField
            data-testid="contact-info-confirm-email-input"
            autoComplete="off"
            id="emailContactConfirm"
            fullWidth
            name="passwordCemailContactConfirm"
            placeholder={t('register:confirmEmail')}
            label={t('register:confirmEmail')}
            value={confirmedEmail}
            onCut={preventCopyPaste}
            onCopy={preventCopyPaste}
            onPaste={preventCopyPaste}
            required
            error={
              isConfirmedEmailTouched && providedEmail !== confirmedEmail
                ? true
                : false
            }
            helperText={
              isConfirmedEmailTouched && providedEmail !== confirmedEmail
                ? t('register:confirmEmailError')
                : ''
            }
            onChange={(e) => {
              setIsConfirmedEmailTouched(true)
              setConfirmedEmail(e.target.value)
              // if (onEmailContactChanged) {
              //   onEmailContactChanged(e.target.value)
              // }
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomTextField
            data-testid="contact-info-phone-input"
            containerSx={{
              marginTop: '20px',
              marginBottom: '20px',
            }}
            customLabelNode={
              <CustomTextFieldLabelNode
                label={t('register:mobileNumberLabel')}
                instructions={t('register:mobileNumberFormat')}
              />
            }
            placeholder={t('register:enterYourMobileNumber')}
            value={providedPhoneUiSyntax}
            onChange={(event) => {
              //in case they cleared it
              if (event.target.value === '') {
                setIsPhoneTouched(false)
              } else {
                setIsPhoneTouched(true)
              }
              //setMobileNumber(event.target.value)
              const input = event.target.value
                .replace(/\D/g, '')
                .substring(0, 10)
              const areaCode = input.substring(0, 3)
              const middle = input.substring(3, 6)
              const last = input.substring(6, 10)

              if (input.length > 6) {
                event.target.value = `(${areaCode}) ${middle} - ${last}`
              } else if (input.length > 3) {
                event.target.value = `(${areaCode}) ${middle}`
              } else if (input.length > 0) {
                event.target.value = `(${areaCode}`
              }
              setProvidedPhoneUiSyntax(event.target.value)
              // setMobileNumberInput(event.target.value)
            }}
            error={isPhoneTouched && !isPhoneValidAndAvailable ? true : false}
            helperText={isPhoneTouched && phoneErrorMsg ? phoneErrorMsg : ''}
            // disabled={acceptTextSignup === 'no'}
          />
        </Grid>
      </RegistrationFormFieldsBox>

      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            // backgroundColor: 'red',
            paddingLeft: isSmall ? '10px' : '80px',
            paddingRight: isSmall ? '10px' : '80px',
            // textAlign: 'right',
            display: 'flex',
            // alignItems: 'center',
            // paddingTop: '25px',
          }}
        >
          <Checkbox
            data-testid="contact-info-sms-opt-in-checkbox"
            sx={{
              float: 'left',
              padding: isSmall ? '4px' : '0px',
              margin: '0px',
              //  color: grey[400],
              '&.Mui-checked': {
                //  color: grey[500],
              },
              '& .MuiSvgIcon-root': { fontSize: 28 },
            }}
            checked={userOptedIn}
            onChange={(e) => {
              //render the confirmation dialog, then process the opt out from it
              if (e.target.checked) {
                setRenderOptoutConfirmDialog(false)
                setUserOptedIn(true)
              } else {
                setRenderOptoutConfirmDialog(true)
              }
            }}
            // onKeyPress={handleEnterPressedForAll3}
          />
          <Typography
            sx={{
              fontSize: isSmall ? '11px' : '13px',
              lineHeight: '15px',
              paddingLeft: !isSmall ? '5px' : undefined,
            }}
          >
            {t('idLikeToReceive')}
            <Link
              variant="body2"
              onClick={(e: any) => {
                setRenderSmsTermsDialog(true)
              }}
              // className={classes.baseLink}
              sx={{
                marginLeft: '5px',
                fontSize: isSmall ? '11px' : '13px',
                lineHeight: '15px',
                cursor: 'pointer',
              }}
            >
              {t('smsTermsOfUseLink')}
            </Link>
          </Typography>
        </Grid>
        {renderOptoutConfirmDialog && (
          <ConfirmOptoutDialog
            onOptOutClick={() => {
              setUserOptedIn(false)
              setRenderOptoutConfirmDialog(false)
            }}
            onStayConnectedClick={() => {
              setUserOptedIn(true)
              setRenderOptoutConfirmDialog(false)
            }}
          />
        )}
        {renderSmsTermsDialog && (
          <NewPortalDialogDesign
            open={renderSmsTermsDialog}
            onClose={() => {
              setRenderSmsTermsDialog(false)
            }}
            title={tSettings('smsTermsDialogTitle')}
          >
            <SMSTermsOfUseContent />
          </NewPortalDialogDesign>
        )}
      </Grid>
    </RegistrationStepCard>
  )
}

export function ConfirmOptoutDialog({
  onOptOutClick,
  onStayConnectedClick,
}: {
  onOptOutClick: () => void
  onStayConnectedClick: () => void
}) {
  const { COLORS } = useSColors()
  const { t } = useSTranslate('register')
  const theme = useTheme()

  const isSmall = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <NewPortalDialogDesign
      title={t('stayConnectedDialogTitle')}
      customTitleSx={{
        color: COLORS.TEXT_BLACK,
        paddingTop: '20px',
        paddingLeft: '0px',
        paddingRight: '0px',
        lineHeight: '29.23px',
      }}
      customPaperSx={{
        width: isSmall ? '90%' : 550,
        minWidth: isSmall ? '90%' : 550,
      }}
      customContentSx={{
        paddingLeft: '30px',
        paddingRight: '30px',
      }}
      open={true}
      headerImageImport={optOutDialogHeaderImage}
      upperRightX={false}
      dualActionProps={{
        onYes: () => {
          onStayConnectedClick()
        },
        yesButtonSx: {
          width: '187px',
          minWidth: '187px',
        },
        noButtonSx: {
          width: '117px',
          minWidth: '117px',
        },
        // yesDisabled: enableValidation && !hasProvidedAtLeastOne,

        onNo: () => {
          onOptOutClick()
          // onClickBack()
        },
        yesText: t('stayConnected'),
        noText: t('optOut'),
      }}
    >
      <Grid container>
        <Grid item xs={12} sx={{ paddingTop: '20px' }}>
          <Typography
            sx={{
              textAlign: 'center',
              // fontWeight: COMMON_BOLD_WEIGHT,
              fontSize: '1rem',
              lineHeight: '19.49px',
            }}
          >
            {t('stayConnectedDialgDesc')}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ paddingTop: '20px' }}>
          <Typography
            sx={{
              textAlign: 'center',

              // fontWeight: COMMON_BOLD_WEIGHT,
              fontSize: '1rem',
              lineHeight: '19.49px',
              fontStyle: 'italic',
            }}
          >
            {t('areYouSureToMissOut')}
          </Typography>
        </Grid>
      </Grid>
    </NewPortalDialogDesign>
  )
}

export function EditEmailData({
  initEmailValue,
  onEmailContactChanged,
  onIsEmailValidChanged,
}: {
  initEmailValue: string
  onEmailContactChanged: (newEmail: string) => void
  onIsEmailValidChanged: (isValid: boolean) => void
}) {
  const { t } = useSTranslate('account')
  const [email, setEmail] = useState<string>(initEmailValue)
  useEffect(() => {
    setEmail(initEmailValue)
  }, [initEmailValue])
  useEffect(() => {
    // setEmail(email)
    onEmailContactChanged(email)
    if (validateEmail(email)) {
      onIsEmailValidChanged(true)
    } else {
      onIsEmailValidChanged(false)
    }
  }, [email])

  const [isValidEmail, setIsValidEmail] = useState(false)

  useEffect(() => {
    if (email) {
      if (validateEmail(email)) {
        setIsValidEmail(true)
      } else {
        setIsValidEmail(false)
      }
    }
  }, [email])

  return (
    <>
      <Grid item xs={12}>
        <STypography variant="bodybig_regular">
          {t('emailParagraph')}
        </STypography>
      </Grid>
      <Grid item xs={12} style={{ marginTop: '10px' }}>
        <CustomTextField
          autoComplete="off"
          id="emailContact"
          fullWidth
          name="passwordCemailContact"
          placeholder={t('emailContact')}
          label={t('emailContact')}
          value={email}
          error={email && !isValidEmail ? true : false}
          required
          // helperText={email && !isValidEmail ? t('validateEmail') : ''}
          onChange={(e) => {
            setEmail(e.target.value)
            if (onEmailContactChanged) {
              onEmailContactChanged(e.target.value)
            }
          }}
        />
      </Grid>
    </>
  )
}
