import { Link } from '@mui/material'
import { getOS, IS_NATIVE } from '../apiexec/utils'
import { usePortalAppConfig } from '../config/usePortalAppConfig'

export default function PhoneLink({
  shouldShowUnderline,
  customLinkSx = {}
}: {
  shouldShowUnderline?: boolean
  customLinkSx?: any
}) {
  const PORTAL_APP_CONFIG = usePortalAppConfig()
  const phoneNumber = PORTAL_APP_CONFIG.MEMBER_SUPPORT_NUMBER
  const isNative: boolean = IS_NATIVE()
  const isAndroid: boolean = getOS() === 'Android'

  return (
    <Link
      href={`tel:+${phoneNumber}`}
      onClick={
        isNative && isAndroid
          ? () => {
              window.open(`tel:+${phoneNumber}`)
            }
          : undefined
      }
      style={{
        textDecoration: shouldShowUnderline ? undefined : 'none',
        cursor: 'pointer',
        color: '#2D8DFF',
        textDecorationColor: '#2D8DFF',
        ...customLinkSx
      }}
    >
      {phoneNumber}
    </Link>
  )
}
