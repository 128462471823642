import { CircularProgress, Grid, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useMemberAppContext } from '../MemberAppContext'
import { useRegistration } from './RegistrationContext'
import { REG_ROUTE_LOGIN } from './RegistrationSubRoutes'
import MemberSupportFooter from './MemberSupportFooter'
import { LOG } from '../v2/applog'

export function CompletePreregFirstTimeProfile() {
  console.log('CompletePreregFirstTimeProfile')

  const { registrationJwt, eksMember, history } = useRegistration()
  const { preRegCredentials } = useMemberAppContext()
  const [isLoading, setIsLoading] = useState(true)
  const [completeProfileError, setCompleteProfileError] = useState<
    string | undefined
  >(undefined)

  async function completePreregMemberProfile(preRegCredentials: any) {
    console.log('routing and auto login prepreg member')
    setIsLoading(false)
    try {
      const autoLogin: any = {
        justRegisteredUserName: preRegCredentials.userName,
        justRegisteredPassword: preRegCredentials.password,
      }

      history.push(REG_ROUTE_LOGIN, { autoLogin: autoLogin })
    } catch (e) {
      console.error(e)
      console.error('Unable to complete preregistered member profile')
      LOG.error(
        'preregistration',
        'Complete Prereg First Time profile, Unable to complete,Error=',
        e,
      )
      setCompleteProfileError(
        'Unfortunatelly, we are unable to validate your identity at this moment. Please contact our member support.',
      )
    }
  }
  useEffect(() => {
    if (eksMember && preRegCredentials) {
      completePreregMemberProfile(preRegCredentials)
    } else {
      console.log('No eksMember or preRegCredentials')
    }
  }, [eksMember, preRegCredentials])

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{
          padding: '20px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '200px', // Added to ensure vertical centering has space
        }}
      >
        {isLoading && <CircularProgress />}
      </Grid>
      <Grid item xs={12} container sx={{ padding: '20px' }}>
        {completeProfileError && (
          <>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontColor: 'red',
                  textAlign: 'center',
                  fontSize: '1.2rem',
                }}
              >
                {completeProfileError}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ paddingTop: '20px' }}>
              <MemberSupportFooter
                customFontSx={{ fontSize: '1.1rem', color: '#201F22' }}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  )
}
