import { string } from 'prop-types'
import { useEffect, useState } from 'react'
import { useMemberService } from '../member/useMemberService'
import { useMemberAppContext } from '../MemberAppContext'
import { checkMicroServiceResponseStatus } from '../apiexec/utils'
import { ZIP_CODE_LENGTH } from './utils'
import { LOG } from '../v2/applog'
import { EKS_CODE_RXSENSE_CONFIG_MISSING } from '../EKSStatuCodes'
export interface MemberCouponParams {
  rxBin: string
  rxPcn: string
  rxGrp: string
  rxAuthNum: string
  //if undefined, prompt the user
  zipCode?: string
  //if undefined, use scripta logo
  useCustomLogoForSavingsCard: boolean
  customLogoForSavingsCard?: string
}

export function useMemberCouponParams() {
  const memberService = useMemberService()

  const { currentMemberProfile } = useMemberAppContext()

  const [couponParams, setCouponParams] = useState<MemberCouponParams>()
  const [isLoadingParams, setIsLoadingParams] = useState<boolean>()
  const [isErrorLoadingParams, setIsErrorLoadingParams] = useState<boolean>()
  const [errorMsg, setErrorMsg] = useState<string>()

  async function loadSavingsCardParams() {
    setIsLoadingParams(true)
    try {
      const resp = await memberService.getSavingsCard()
      const status = checkMicroServiceResponseStatus(resp)
      if (status.isError || !resp['savings-card']) {
        setIsErrorLoadingParams(true)
        //specific missing rx-sesnse config
        if (
          resp &&
          (resp as any).scriptaErrorCode === EKS_CODE_RXSENSE_CONFIG_MISSING
        ) {
          setErrorMsg(
            'Missing or invalid RxSense configuration for the client. Cannot retrieve member savings card.',
          )
        } else {
          setErrorMsg(status.errorMsg)
        }
        //this is the error case where savings-card threw an error (i.e. rxsense credentials not configured for client)
        //lets at least give back the zip so its not misleading the user that zip is missing
        const paramsWithZipOnly: any = { zipCode: currentMemberProfile?.zip }

        setCouponParams(paramsWithZipOnly)

        LOG.error('coupons', 'Missing RX-Sense configuration for savings card')
      } else {
        //just in case zip is more than ZIP_CODE_LENGTH when returned in backend i.e. 60616-234234
        let cleanedZip = currentMemberProfile?.zip
        if (cleanedZip && cleanedZip.length > ZIP_CODE_LENGTH) {
          cleanedZip = cleanedZip.substring(0, ZIP_CODE_LENGTH)
        }
        const uiCouponParams: MemberCouponParams = {
          //these are from the backend savings ard
          rxBin: resp['savings-card'].bin,
          rxPcn: resp['savings-card'].pcn,
          rxGrp: resp['savings-card'].grp,
          rxAuthNum: resp['savings-card'].authenticationNumber,

          //these are on the member profile
          zipCode: cleanedZip,
          useCustomLogoForSavingsCard: resp['savings-card'][
            'coupon-card-custom-logo-url'
          ]
            ? true
            : false,
          customLogoForSavingsCard:
            resp['savings-card']['coupon-card-custom-logo-url'],
        }
        setCouponParams(uiCouponParams)
      }
    } catch (error) {
      setIsErrorLoadingParams(true)
    }

    setIsLoadingParams(false)
  }

  useEffect(() => {
    loadSavingsCardParams()
  }, [])

  return {
    couponParams,
    isLoadingParams,
    isErrorLoadingParams,
    errorMsg,
  }
}
