import { ApiUrl, StatusCode } from '../../types'
import {
  PortalApiEx,
  getMicroserviceApiDetails,
} from '../apiexec/portalapiexec'
import { parseJwt } from '../MemberAppContext'

//update (complete) sso member profile when accessing for the first time
export const completeSsoProfileEndpoint: ApiUrl = {
  endpoint: '/member-service/api/sso-members',
  //member-service/api/sso-members/26378
  apiType: 'member_micro',
  apiVersion: '1.0',
}

export interface CompleteSsoPayload {
  email: string
  phone?: string
  'sms-opt-in'?: boolean
}

export function useSsoService() {
  async function submitCompleteSsoProfile(
    jwt: string,
    // memberId: number,
    ssoCompleteProfileSignup: CompleteSsoPayload,
  ): Promise<any> {
    let { url, ops } = getMicroserviceApiDetails(completeSsoProfileEndpoint)
    let memberId = parseJwt(jwt).id_key
    let finalUrl = `${url}/${memberId}`
    const api = new PortalApiEx(jwt)

    return api.putMicro<any | StatusCode>(
      finalUrl,
      ssoCompleteProfileSignup,
      ops,
    )
  }
  return {
    submitCompleteSsoProfile,
  }
}
