import { Box, CircularProgress, Grid } from '@mui/material'
import { useMemberAppContext } from '../MemberAppContext'
import { SPACING } from '../system/theme2'
import { BackgroundContainer, MOBILE_HEADER_HEIGHT } from './NewPageContainer'
import { useCallback, useEffect, useState } from 'react'
import STypography from '../system/customcomponents/STypography'
import InfoCard from '../system/InfoCard'
import { NavBar } from '../system/NavBar'
import searchImgSrc from './images/howtosave/step_pharmacy_hero.svg'

import {
  OriginalDrugCard,
  ROUTE_OPPORTUNITY_SAME_MED_OR_NEW_MED,
} from './SavingGuide'
import {
  BookmarkedOpportunity,
  getAllWaysToSaveForSearchedDrug,
  getOpportunityModelFromSearchedOriginalDrug,
  SavingGuideData,
  SavingOpportunityDataModel,
} from './saving-utils'
import { useSizes } from './useNewHooks'
import { checkMicroServiceResponseStatus } from '../apiexec/utils'
import { AlternativeDrugsResponse } from 'src/types'
import { LOG } from './applog'
import { DrugIdParams } from '../drugprofile/types'
// import { MyPricingDrugFilters } from '../search/MyPricingDrugFilters'
import { DosageOptionsResp } from '../search/types'
import {
  OriginalPricingResp,
  useDrugSearchService,
} from '../search/useDrugSearchService'
import { SummarHeroImageBox } from './HowToSaveSummary'
import { ROUTE_NEW_SEARCH_OPPORTUNITY } from './NewMemberPortalMain'
import { NewSearchSuggester } from './NewSearchSuggester'
import { NewSearchDrugPricingFilters } from './NewSearchDrugPricingFilters'
import { getDrugIdToUseForUpdatedSearch } from './utils'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { useLocation } from 'react-router'
import DrugInfoModal from '../system/DrugInfoModal'
import { useMemberService } from '../member/useMemberService'
import { useSColors } from '../styles/scripta-theme'
import { EKSMemberProfile } from 'src/app/member/types'

// //const TODO
export const DEFAULT_QUANTITY = 30
export function NewSearchStartPage() {
  //
  const { t } = useSTranslate('newSearch')
  //set title
  useEffect(() => {
    document.title = t('searchPageTitle')
  }, [t])

  const { history, setSelectedSuggestion } = useMemberAppContext()

  const { isMobile } = useSizes()

  return (
    <Grid container>
      <Grid item xs={12}>
        <NavBar
          variant={
            isMobile ? 'title2_title_left_purple' : 'title3_title_center_white'
          }
          title={t('search')}
          backButtonHidden
        />
      </Grid>
      {/* header is fixed in mobile */}
      {isMobile && <Box sx={{ minHeight: MOBILE_HEADER_HEIGHT }}></Box>}

      <BackgroundContainer
        variant={isMobile ? 'purple_curved_elipsis' : 'clear_white'}
      >
        {/* {isMobile && ( */}
        <Grid item xs={12}>
          <SummarHeroImageBox
            imgDataTestId="search-hero-image"
            svgImageImportUrl={searchImgSrc}
            alt={t('searchPageTitle')}
            width={isMobile ? 200 : 250}
            height={isMobile ? 160 : 200}
          />
        </Grid>
        {/* )} */}

        <Grid
          item
          xs={12}
          sx={{ marginTop: SPACING._25, marginBottom: SPACING._25 }}
        >
          <Box sx={{ maxWidth: '400px', margin: 'auto' }}>
            <NewSearchSuggester
              onSuggestionPicked={(suggestion) => {
                setSelectedSuggestion(suggestion)
                history.push(ROUTE_NEW_SEARCH_OPPORTUNITY)
              }}
            />
          </Box>
        </Grid>
        {/* min height is to make sure when the dropdown is open, it does not get cut off */}
        <Grid
          item
          xs={12}
          sx={{
            textAlign: 'center',
            minHeight: isMobile ? '200px' : undefined,
          }}
        >
          <Box sx={{ maxWidth: '680px', margin: 'auto' }}>
            <STypography variant="body_regular">{t('searchText')}</STypography>
          </Box>
        </Grid>
      </BackgroundContainer>
    </Grid>
  )
}
// //TODO - startingDrugIdParams is not used yet - they are to enable override
// //of the most popular drug id and quantity in the filters by a specific one we know
// //this is for ex. if we want to call it from my meds - in that case we want exactly the same regardless of whats popular
export function SearchOpportunityPage({ isCopay }: { isCopay: boolean }) {
  const { COLORS } = useSColors()

  const { t } = useSTranslate('newSearch')
  //TODO - bookmarked search opportunities? do we ever have those?
  const { isMobile } = useSizes()

  const [dosagesResp, setDosagesResp] = useState<DosageOptionsResp>()
  const [isLoadingDosages, setIsLoadingDosages] = useState<boolean>(false)

  //updated dosage/form/quantity as picked by user during interaction wiht filters
  const [updatedForm, setUpdatedForm] = useState<string>()
  const [updatedDose, setUpdatedDose] = useState<string>()
  const [updatedQuant, setUpdatedQuant] = useState<number>()

  //main drug id to use - resolved from dosages response
  const [resolvedDrugIdParamsToUse, setResolvedDrugIdParamsToUse] =
    useState<DrugIdParams>()
  const [resolvedQuantityToUse, setResolvedQuantityToUse] = useState<number>()

  const service = useDrugSearchService()
  //to recompute the price of original if quantity changes?
  async function loadDosageOptions(drugIdParams: DrugIdParams) {
    try {
      setIsLoadingDosages(true)
      const resp = await service.getDosageOptions(drugIdParams)
      setDosagesResp(resp)
      setIsLoadingDosages(false)
    } catch (e) {
      console.error('e in loadDosageOptions=', e)
    }
  }
  const { selectedSuggestion, setSelectedSuggestion } = useMemberAppContext()

  //first effect is to fetch the dosages response so we can get the original
  //details using the most popular dosage variancte out of them
  useEffect(() => {
    if (selectedSuggestion) {
      const drugIdParams: DrugIdParams = {
        drugId: selectedSuggestion.id,
      }
      loadDosageOptions(drugIdParams)
    }
  }, [selectedSuggestion])

  //passed via location state from the my meds in case they want to see pricing from there
  // const [drugIdFromMyMeds, setDrugIdFromMyMeds] = useState<number>()
  const [isMyMedsPricingOnlyVariant, setIsMyMedsPricingOnlyVariant] =
    useState<boolean>(false)
  //hide the search update controls when showing my pricing for my meds
  const [hideSearchUpdateControls, setHideSearchUpdateControls] =
    useState<boolean>(false)

  const routedLocation = useLocation()
  useEffect(() => {
    const locationState: any = routedLocation.state
    if (locationState && locationState.drugIdParams) {
      setInitialMostPopularDrugId(locationState.drugIdParams.drugId)
      //if we get zero for quantityt, log it
      if (locationState.drugIdParams.pricingQuantityDefault === 0) {
        LOG.warn(
          'my_meds',
          'detect 0 value for quanity in locationState when reaching my pricing. quantity dispensed is 0, will use default of 30',
          { drugIdParams: locationState.drugIdParams },
        )
        setInitialMostPopularQuantity(DEFAULT_QUANTITY)
      } else {
        setInitialMostPopularQuantity(
          locationState.drugIdParams.pricingQuantityDefault,
        )
      }
      setIsMyMedsPricingOnlyVariant(true)
      setHideSearchUpdateControls(true)
      // loadDosageOptions(locationState.drugIdParams)
    }
  }, [routedLocation])

  const [initialMostPopularQuantity, setInitialMostPopularQuantity] = useState<
    number | undefined
  >()
  //same for the initial drug id?
  const [initialMostPopularDrugId, setInitialMostPopularDrugId] = useState<
    number | undefined
  >()

  const { currentMemberProfile } = useMemberAppContext()

  //TODO - using/watching member profile bc of zip code updares are stored on ot at themoment
  //once the initialMostPopularDrugId and initialMostPopularQuantity are resolved (by form/dosage/quant filters copoennt),
  // we can load the original pricing
  useEffect(() => {
    if (
      initialMostPopularDrugId &&
      //REMIDER: quantity of 0 is a valid quantity value according to backend
      (initialMostPopularQuantity || initialMostPopularQuantity === 0)
    ) {
      const initialDrugId: DrugIdParams = {
        drugId: initialMostPopularDrugId,
        pricingQuantityDefault: initialMostPopularQuantity,
      }

      setResolvedDrugIdParamsToUse(initialDrugId)
      setResolvedQuantityToUse(initialMostPopularQuantity)
      //loadOriginalPricing(initialDrugId, initialMostPopularQuantity)

      //we dont want to do this here - instead, if/when they click find cheaper alterantives,
      //get the alts model, process them to waytosaveitems,setup saving guide cotext and redirect to the guide
      // loadAltPricingResults(
      //   initialDrugId,
      //   initialMostPopularQuantity,
      //   currentMemberProfile,
      // )
    } else {
      //
    }
  }, [
    initialMostPopularDrugId,
    initialMostPopularQuantity,
    currentMemberProfile,
  ])

  const handleUpdatePricingClick = useCallback(() => {
    //reset previous for nicer loading screens
    //TODO - not here, this is when they click find cheaper alternatives in new design
    // setAltData(undefined)
    let updateId = getDrugIdToUseForUpdatedSearch(
      dosagesResp,
      updatedForm,
      updatedDose,
    )

    //major error case- no dosages reponse from backend and therefor no known most popular id
    //in this case we will fallback to origina drug id.
    //filters accordingly updates the mostPopularId correctly in that case
    //filters also enable freechoise quantity selector with some values
    if (!updateId) {
      updateId = initialMostPopularDrugId
    }

    //store this in state, todo - may not be needed later
    if (updateId && (updatedQuant || updatedQuant === 0)) {
      const updatedIdParams: DrugIdParams = {
        drugId: updateId,
      }

      setResolvedDrugIdParamsToUse(updatedIdParams)
      setResolvedQuantityToUse(updatedQuant)
    } else {
    }
  }, [updatedForm, updatedDose, updatedQuant, currentMemberProfile])

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <NavBar
            variant={
              isMobile
                ? 'title3_title_center_purple'
                : 'title3_title_center_white'
            }
            title={
              !isMyMedsPricingOnlyVariant ? t('search') : t('titleMyPricing')
            }
          />
        </Grid>
      </Grid>
      <BackgroundContainer
        variant={isMobile ? 'purple_curved_elipsis' : 'clear_white'}
      >
        {!hideSearchUpdateControls && (
          <Grid container gap={SPACING._1}>
            <Grid
              item
              xs={12}
              sx={{ paddingTop: SPACING._1, paddingBottom: SPACING._1 }}
            >
              <NewSearchSuggester
                preinitPlaceholderToSelectedSuggestion={true}
                onSuggestionPicked={(suggestion) => {
                  setSelectedSuggestion(suggestion)
                  // setShowResultsSubpage(true)
                }}
                customLabelSx={
                  isMobile
                    ? { color: COLORS.TEXT_LIGHT_PRIMARY_WHITE, fontSize: 22 }
                    : undefined
                }
                customLabelVariant={'bodysmall_semibold'}
              />
            </Grid>
            <Grid item xs={12}>
              {dosagesResp && (
                <NewSearchDrugPricingFilters
                  dosagesRespData={dosagesResp}
                  onInitialMostPopularDrugIdResolved={(drugId) => {
                    setInitialMostPopularDrugId(drugId)
                  }}
                  onInitialMostPopularQuantityResolved={(quant) => {
                    setInitialMostPopularQuantity(quant)
                  }}
                  onUpdateClick={handleUpdatePricingClick}
                  onFormUpdated={(f) => {
                    //
                    setUpdatedForm(f)
                  }}
                  onDoseUpdated={(d) => {
                    //
                    setUpdatedDose(d)
                  }}
                  onQuantityUpdated={(q) => {
                    //
                    setUpdatedQuant(q)
                  }}
                  alertDebugOriginalDrugId={selectedSuggestion?.id}
                />
              )}
            </Grid>
            <Grid item xs={12} sx={{ marginBottom: SPACING._1 }}>
              <hr />
            </Grid>
          </Grid>
        )}
        {resolvedDrugIdParamsToUse && resolvedQuantityToUse !== undefined && (
          <OriginalPricingAndAlternatives
            drugIdParamsToUse={resolvedDrugIdParamsToUse}
            quantityToUse={resolvedQuantityToUse}
            isCopay={isCopay}
          />
        )}
      </BackgroundContainer>
    </>
  )
}

function OriginalPricingAndAlternatives({
  drugIdParamsToUse,
  quantityToUse,
  isCopay,
}: {
  drugIdParamsToUse: DrugIdParams
  quantityToUse: number
  isCopay: boolean
}) {
  const { setSavingGuide, history } = useMemberAppContext()
  const BOOKMARKED_SEARCH_OPPS: BookmarkedOpportunity[] = []

  const [originalResp, setOriginalResp] = useState<OriginalPricingResp>()
  const [isLoadingOriginal, setIsLoadingOriginal] = useState<boolean>(true)
  const [isErrorOriginalResp, setIsErrorOriginalResp] = useState<boolean>(false)
  const [originalErrorCode, setOriginalErrorCode] = useState<
    number | undefined
  >()

  //alternatives - processed here on click of view alts info card
  //before redirecting to the savings guide
  const [altData, setAltData] = useState<AlternativeDrugsResponse>()
  const [isLoadingAlt, setIsLoadingAlt] = useState<boolean>(false)
  const [isErrorLoadingAlts, setIsErrorLoadingAlts] = useState<boolean>(false)
  const [altsErrorCode, setAltsErrorCode] = useState<number | undefined>()

  const [searchedDrugAsOpporunity, setSearchedDrugAsOpportunity] =
    useState<SavingOpportunityDataModel>()

  const service = useDrugSearchService()
  const memberService = useMemberService()

  useEffect(() => {
    loadOriginalPricing(drugIdParamsToUse, quantityToUse)
  }, [drugIdParamsToUse, quantityToUse])

  //to recompute the price of original if quantity changes?
  async function loadOriginalPricing(
    drugIdParams: DrugIdParams,
    quantity: number,
  ) {
    try {
      setIsLoadingOriginal(true)
      const resp = await service.getOriginalPricing(drugIdParams, quantity)
      const { isError } = checkMicroServiceResponseStatus(resp)
      if (!isError) {
        setOriginalResp(resp)
        setIsErrorOriginalResp(false)
        setOriginalErrorCode(undefined)
      } else {
        setIsErrorOriginalResp(true)
        if (resp && (resp as any).scriptaErrorCode) {
          setOriginalErrorCode((resp as any).scriptaErrorCode)
        }
        //log if its not the expected two codes
        if (
          resp &&
          (resp as any).scriptaErrorCode !==
            ORIGINAL_PRICE_ERROR_NOT_COMPATIBLE_WITH_PLAN &&
          (resp as any).scriptaErrorCode !==
            ORIGINAL_PRICE_ERROR_DRUG_SUPPRESSED_BY_CLIENT
        ) {
          LOG.error('search_results_data', 'error in original pricing', resp)
        } else {
          LOG.warn('search_results_data', 'error in original pricing', resp)
        }
      }
      setIsLoadingOriginal(false)
    } catch (e) {
      console.error('e in micro=', e)
    }
  }

  useEffect(() => {
    if (originalResp && drugIdParamsToUse) {
      //0 quantity is ok?
      let qct =
        quantityToUse || quantityToUse === 0 ? quantityToUse : DEFAULT_QUANTITY
      //up to amount is undefined for the searched drug UNTILL we resolve the alternatives
      let upToAmount = undefined
      const searchOpp = getOpportunityModelFromSearchedOriginalDrug(
        quantityToUse,
        `${upToAmount}`,
        originalResp,
        BOOKMARKED_SEARCH_OPPS,
      )
      setSearchedDrugAsOpportunity(searchOpp)
    }
  }, [originalResp, drugIdParamsToUse, quantityToUse])

  async function loadAltPricingResults(
    drugIdParams: DrugIdParams,
    quantity: number,
    memberProfile?: EKSMemberProfile,
  ) {
    try {
      setIsLoadingAlt(true)
      const zipCode =
        memberProfile && memberProfile.zip ? memberProfile.zip : undefined
      const resp = await service.getAlternativePricingResults(
        drugIdParams,
        quantity,
        zipCode,
      )
      const { isError } = checkMicroServiceResponseStatus(resp)
      if (!isError) {
        //NEEd to post process this result and fetch additional details for each NPI
        //bc backend doesnt return it
        const allNpis = resp['alternative-drugs']
          ? resp['alternative-drugs']
              .map((d) => (d['pharmacy-npi'] ? d['pharmacy-npi'] : undefined))
              .filter((d) => d)
          : []

        const distinctNpis = [...Array.from(new Set(allNpis))] as string[] //unique NPIs

        //resolve them all to get full pharma details reposnse
        for (const npi of distinctNpis) {
          if (npi) {
            const npiDetailsResp = await memberService.getPharmacyDetails(npi)

            const status = checkMicroServiceResponseStatus(resp)
            if (!status.isError) {
              resp['alternative-drugs'].forEach((d) => {
                if (d['pharmacy-npi'] === npi) {
                  d['pharmacyDetailsResolvedByNpi'] = npiDetailsResp
                }
              })
            } else {
              LOG.error(
                'search_results_data',
                'MS error response getting additional pharmacy details by NPI,resp=',
                resp,
              )
            }
          }
        }

        setAltData(resp)
        setIsErrorLoadingAlts(false)
      } else {
        LOG.error(
          'search_results_data',
          'MS error response finding cheaper alternatives,resp=',
          resp,
        )
        setIsErrorLoadingAlts(true)
        if (resp && (resp as any).scriptaErrorCode) {
          setAltsErrorCode((resp as any).scriptaErrorCode)
        }
      }

      setIsLoadingAlt(false)
    } catch (e) {
      console.error('e in micro=', e)
      setIsErrorLoadingAlts(true)
    }
  }

  //final effect to redirect to the savings guide if user clicked view cheaper alternatives
  useEffect(() => {
    if (altData) {
      const allWaysToSave = getAllWaysToSaveForSearchedDrug(
        isCopay,
        quantityToUse || DEFAULT_QUANTITY,
        originalResp!,
        altData,
      )
      if (allWaysToSave && allWaysToSave.length > 0) {
        const highestAlt = allWaysToSave[0]
        const upToAmount = highestAlt.saveAmountResolved
        const searchOpp = getOpportunityModelFromSearchedOriginalDrug(
          quantityToUse || DEFAULT_QUANTITY,
          `${upToAmount}`,
          originalResp!,
          BOOKMARKED_SEARCH_OPPS,
        )
        if (!searchOpp) {
          LOG.error(
            'search_results_ui',
            'cannot construct search opportunity model from updated search,atl data=',
            { altData },
          )
          return
        }

        const savingGuideData: SavingGuideData = {
          selectedOpportunity: searchOpp,
          source: 'search_results',
          waysToSaveForOpportunity: allWaysToSave,
        }
        setSavingGuide(savingGuideData)
        history.push(ROUTE_OPPORTUNITY_SAME_MED_OR_NEW_MED)
      }
    }
  }, [altData])

  useEffect(() => {
    setAltData(undefined)
    setIsLoadingAlt(false)
    setIsErrorLoadingAlts(false)
  }, [drugIdParamsToUse, quantityToUse])

  const { isMobile } = useSizes()
  const [renderDrugInfoModal, setRenderDrugInfoModal] = useState<boolean>(false)

  const { t } = useSTranslate('newSearch')

  if (isLoadingOriginal) {
    return (
      <Grid container justifyContent={'center'}>
        <Grid item>
          <CircularProgress sx={{ width: '100px' }} />
        </Grid>
      </Grid>
    )
  }

  //if we have error on original, end there
  if (isErrorOriginalResp) {
    return <OriginalDrugPricingErrorCard errorCode={originalErrorCode} />
  }

  return (
    <Grid
      item
      container
      // border="1px solid black"
      // gap={SPACING._1}
      alignItems={'stretch'}
      xs={12}
    >
      <Grid item xs={isMobile ? 12 : 6} sx={{ padding: SPACING._05 }}>
        {searchedDrugAsOpporunity && (
          <OriginalDrugCard
            variant="card"
            savingOpportunity={searchedDrugAsOpporunity}
            expanded={true}
            setExpanded={() => {}}
          />
        )}
        {/* {isLoadingOriginal && <CircularProgress />} */}
      </Grid>

      <Grid
        item
        container
        xs={isMobile ? 12 : 6}
        sx={{ padding: SPACING._05 }}

        // justifyContent={'space-between'}
      >
        <Grid item xs={12} sx={{ paddingBottom: SPACING._05 }}>
          <InfoCard
            title={
              isLoadingAlt
                ? t('searchingAlts')
                : altData && altData['alternative-drugs'].length == 0
                ? t('noCheaper')
                : t('findCheaper')
            }
            body={
              isLoadingAlt
                ? t('waitMoment')
                : 'Savings estimates do not include manufacturer copay assistance or savings from other programs you may already be using outside of your insurance benefits.'
            }
            variant={
              altData && altData['alternative-drugs'].length == 0
                ? 'default_secondary'
                : 'navigation_primary'
            }
            onClick={() => {
              loadAltPricingResults(drugIdParamsToUse, quantityToUse)
            }}
            customBodyNode={
              isLoadingAlt ? (
                <Grid item sx={{ padding: SPACING._025 }}>
                  <Box sx={{ marginTop: '5px' }}>
                    <CircularProgress size={20} />
                  </Box>
                </Grid>
              ) : undefined
            }
          />

          {/* {!isLoadingAlt &&
            && (
              <STypography variant="bodysmall_regular">
                No cheaper alternatives found
              </STypography>
            )} */}
        </Grid>
        {isErrorLoadingAlts && (
          <Grid item xs={12} sx={{ padding: '20px' }}>
            <STypography variant="body_bold">
              Unable to perform search for cheaper alternatives at the moment.
              Please try again later.
            </STypography>
          </Grid>
        )}
        <Grid item xs={12} sx={{ marginTop: !isMobile ? 'auto' : SPACING._1 }}>
          <InfoCard
            onClick={() => {
              // history.push({
              //   pathname: ROUTE_NEW_DRUG_PROFILE,
              //   state: { drugIdParams: drugIdParamsToUse },
              // })
              setRenderDrugInfoModal(true)
            }}
            title={t('viewDrugInfo')}
            variant="navigation_primary"
          />
        </Grid>
      </Grid>
      {renderDrugInfoModal && (
        <DrugInfoModal
          onClose={() => setRenderDrugInfoModal(false)}
          drugIdParams={drugIdParamsToUse}
          open={renderDrugInfoModal}
        />
      )}
    </Grid>
  )
}

//TODO - check with backend team what messages to show for some of these original pricing codes
const ORIGINAL_PRICE_ERROR_NOT_COMPATIBLE_WITH_PLAN = 210
const ORIGINAL_PRICE_ERROR_DRUG_SUPPRESSED_BY_CLIENT = 224

export function OriginalDrugPricingErrorCard({
  errorCode,
}: {
  errorCode?: number
}) {
  const { t } = useSTranslate('newSearch')
  let adjustText = t('adjustText')
  let title = t('sorryProblem')

  let text = ''
  if (errorCode === ORIGINAL_PRICE_ERROR_NOT_COMPATIBLE_WITH_PLAN) {
    text = t('errorNotCompat') + ' ' + adjustText
  } else if (errorCode === ORIGINAL_PRICE_ERROR_DRUG_SUPPRESSED_BY_CLIENT) {
    title = 'We apologize for the inconvenience'
    text =
      'Your plan configuration has disabled recommendations for this medication.  '
  } else {
    text = adjustText
  }

  return (
    <InfoCard
      title={title}
      variant="default_secondary"
      body={text}
      // onClick={function noRefCheck() {}}
    />
  )
}
