import { StatusCode, TermsOfUse } from '../../types'
import {
  getModulesApiEndpoint,
  getMicroserviceApiDetails,
} from '../apiexec/portalapiexec'
import { usePublicPortalApi } from '../apiexec/usePortalApi'
import {
  backendConfigModule,
  getPrivacyPolicy,
  getTermsOfUse,
  getdisableFeature,
} from './module-config'
import {
  PrivacyPolicy,
  DisabledFeaturesReqData,
  FindTenantEKSResp,
} from '../../types'
import { findTenantEKS } from 'src/app/registration/useRegistrationService'
import { checkMicroServiceResponseStatus } from 'src/app/apiexec/utils'
import { LOG } from 'src/app/v2/applog'
import { SavingStrategy } from 'src/app/strategies/types'

//this one is public to get the strategy details
export function useConfigService() {
  const { portalPublicApi } = usePublicPortalApi()

  async function getPrivacyPolicyApi(): Promise<PrivacyPolicy | StatusCode> {
    const url = getModulesApiEndpoint(backendConfigModule, getPrivacyPolicy)
    return portalPublicApi.get<PrivacyPolicy | StatusCode>(url)
  }
  async function getTermsOfUseApi(): Promise<TermsOfUse | StatusCode> {
    const url = getModulesApiEndpoint(backendConfigModule, getTermsOfUse)
    return portalPublicApi.get<TermsOfUse | StatusCode>(url)
  }
  //new api to get a list of features from search-clients EKS API
  async function getDisabledFeaturesViaClientUuid(
    clientUuid: string,
  ): Promise<any[] | StatusCode> {
    let features: string[] = []

    let { url, ops } = getMicroserviceApiDetails(findTenantEKS)
    //here need the filter as search in the headers
    //filter: {"company-name":"Plante Moran"}
    const filterObj: Record<string, string> = {}
    filterObj['uuid'] = clientUuid
    const filter =
      Object.keys(filterObj).length > 0 ? JSON.stringify(filterObj) : ''
    ops = {
      ...ops,
      customHeaders: { ...ops?.customHeaders, filter },
    }
    const resp = await portalPublicApi.getMicro<FindTenantEKSResp>(url, ops)
    const respCheck = checkMicroServiceResponseStatus(resp)
    if (!respCheck.isError) {
      if (resp.clients.length > 0) {
        features = resp.clients[0]['disabled-features']
      } else {
        LOG.error(
          'registration',
          'Cannot determine feature flags bc no matching client found for cliend uuid',
          {
            clientUuid,
          },
        )
      }
    } else {
      LOG.error(
        'registration',
        'Cannot determine feature flags bc of error response from search-clients EKS API',
        resp,
      )
    }

    return features
  }

  async function getExcludedStrategiesViaClientUuid(
    clientUuid: string,
  ): Promise<SavingStrategy[] | StatusCode> {
    let disabledStrategies: SavingStrategy[] = []

    let { url, ops } = getMicroserviceApiDetails(findTenantEKS)
    //here need the filter as search in the headers
    //filter: {"company-name":"Plante Moran"}
    const filterObj: Record<string, string> = {}
    filterObj['uuid'] = clientUuid
    const filter =
      Object.keys(filterObj).length > 0 ? JSON.stringify(filterObj) : ''
    ops = {
      ...ops,
      customHeaders: { ...ops?.customHeaders, filter },
    }
    const resp = await portalPublicApi.getMicro<FindTenantEKSResp>(url, ops)
    const respCheck = checkMicroServiceResponseStatus(resp)
    if (!respCheck.isError) {
      if (resp.clients.length > 0) {
        disabledStrategies =
          resp.clients[0]['excluded-strategies'] &&
          resp.clients[0]['excluded-strategies'].length > 0
            ? resp.clients[0]['excluded-strategies']
            : []
      } else {
        LOG.error(
          'registration',
          'Cannot determine excluded-strategies flags bc no matching client found for cliend uuid',
          {
            clientUuid,
          },
        )
      }
    } else {
      LOG.error(
        'registration',
        'Error cought trying to determine excluded strategies via client uuid',
        resp,
      )
    }

    return disabledStrategies
  }

  return {
    getPrivacyPolicyApi,
    getTermsOfUseApi,
    getDisabledFeaturesViaClientUuid,
    getExcludedStrategiesViaClientUuid,
  }
}
