import { Box, Typography } from '@mui/material'
import { useRef, useState } from 'react'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded'
import PointerEventBlocker from '../shared/PointerEventBlocker'
import { RenderingLocation } from '../saving/saving-types'
import { CLASS_NAMES } from '../shared/constants'
import { useSColors } from '../styles/scripta-theme'
import { isSafari } from '../apiexec/utils'

export function VideoWithPoster({
  isSmallScreen,
  title,
  renderLocation,
  youtubeId,
}: {
  isSmallScreen: boolean
  title: string
  renderLocation?: RenderingLocation
  youtubeId: string
}) {
  const [showVideo, setShowVideo] = useState<boolean>(false)
  const { COLORS } = useSColors()
  const { t } = useSTranslate('strategies')

  if (showVideo) {
    return (
      <Box
        sx={{
          position: 'relative',
          cursor: 'pointer',
          width: '100%',
        }}
      >
        <PointerEventBlocker>
          <Box
            sx={{
              cursor: 'pointer',
              borderRadius: '5px',
              aspectRatio: '16 / 9',
              padding: '5px',
              height: '100%',
            }}
            display="flex"
            flexWrap={'wrap'}
            justifyContent={'center'}
            alignContent="center"
          >
            <Typography
              color={'rgba(255, 255, 255, 0)'}
              fontSize={isSmallScreen ? 20 : 30}
              fontWeight={'bold'}
              sx={{
                opacity: '.6',
              }}
            >
              {t('savingsStrgy')}
            </Typography>
            <Typography
              color={'rgba(255, 255, 255, 0)'}
              fontSize={isSmallScreen ? 30 : 40}
              fontWeight={'bold'}
              width={'100%'}
              textAlign={'center'}
              sx={{
                opacity: '.6',
              }}
            >
              {title.toUpperCase()}
            </Typography>
          </Box>
        </PointerEventBlocker>
        <iframe
          src={`https://www.youtube-nocookie.com/embed/${youtubeId}?autoplay=1&rel=0`}
          allow="autoplay"
          allowFullScreen
          title="video"
          style={{
            aspectRatio: '16 / 9',
            width: '100%',
            border: 'none',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 2,
          }}
        />
      </Box>
    )
  }

  return (
    <Box
      id={`${CLASS_NAMES.savingsStrategyVideoOverlay}-${title}`}
      className={`${CLASS_NAMES.savingsStrategyVideoOverlay} ${title} ${renderLocation}`}
      onClick={() => setShowVideo(true)}
      sx={{
        position: 'relative',
        cursor: 'pointer',
        width: '100%',
      }}
    >
      <PointerEventBlocker>
        <Box
          sx={{
            cursor: 'pointer',
            borderRadius: '5px',
            aspectRatio: '16 / 9',
          }}
          display="flex"
          flexWrap={'wrap'}
          justifyContent={'center'}
          alignContent="center"
        >
          <img
            src={`https://i.ytimg.com/vi/${youtubeId}/maxresdefault.jpg`}
            alt={title}
            width={'100%'}
          />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.15)',
          }}
        />
        <PlayArrowRoundedIcon
          sx={{
            position: 'absolute',
            color: 'white',
            left: '50%',
            top: '50%',
            fontSize: '5rem',
            marginLeft: '-2.5rem',
            marginTop: '-2.5rem',
          }}
        />
      </PointerEventBlocker>
    </Box>
  )
}
