import {
  Grid,
  InputAdornment,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useEffect, useState } from 'react'
import {
  checkMicroServiceResponseStatus,
  parseUiError,
} from 'src/app/apiexec/utils'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { EKSMemberProfile } from 'src/app/member/types'
import { LOG } from 'src/app/v2/applog'
import { useNotifier } from '../hooks/useNotify'
import { JwtDetails, useMemberAppContext } from '../MemberAppContext'
import { EditEmailData } from '../registration/EditContactInfoEKS'
import { CLASS_NAMES } from '../shared/constants'
import SButton from '../system/customcomponents/SButton'
import STypography from '../system/customcomponents/STypography'
import { SPACING } from '../system/theme2'
import { useSizes } from '../v2/useNewHooks'
import { CommunicationPreferences } from './CommunicationPreferences'
import {
  isDisplayFriendlyStringValidNumber,
  toDigitsOnly,
  toDisplayFriendlyString,
  toFullNumberFromDisplayFriendlyString,
} from './phone-field-utils'
import { useMemberService } from './useMemberService'

export function MyAccount({
  forceMobileMode = false,
  variant,
}: {
  forceMobileMode?: boolean
  variant: 'preferences'
}) {
  const service = useMemberService()
  const { sendMsg } = useNotifier()
  const { currentMemberProfile, setCurrentMemberProfile, history, jwtDetails } =
    useMemberAppContext()
  if (!currentMemberProfile)
    throw Error('cannot update, profile not found in context')
  const { t } = useSTranslate('account')
  const [newMobileNumberDisplayed, setNewMobileNumberDisplayed] = useState(
    //from current member profile it comes with country code 12223334444
    //so change it for ui display purposes to (NNN)NNN-NNNN
    currentMemberProfile?.phone
      ? toDisplayFriendlyString(currentMemberProfile.phone)
      : '',
  )
  const [newEmail, setNewEmail] = useState(
    currentMemberProfile?.email ? currentMemberProfile.email : '',
  )
  //allow user to trigger depes dialog manually
  const [isLoading, setIsLoading] = useState(false)
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm')) || forceMobileMode
  const isMobileMode: boolean = isSmall
  const { isDesktop } = useSizes()

  const [isNewEmailValid, setIsNewEmailValid] = useState(true)
  const [isNewPhoneValid, setIsNewPhoneValid] = useState(true)

  async function saveProfileAsync(
    jwtDetails: JwtDetails,
    newPhone?: string,
    newEmail?: string,
  ) {
    if (!newPhone && !newEmail) {
      return
    }
    setIsLoading(true)

    try {
      const updateProfilePayload: Partial<EKSMemberProfile> = {}
      if (newPhone) {
        const serverPhone = toFullNumberFromDisplayFriendlyString(newPhone)
        updateProfilePayload.phone = serverPhone
      }
      if (newEmail) {
        updateProfilePayload.email = newEmail
      }
      const saveResponse = await service.updateMemberProfile_EKS(
        jwtDetails.id_key,
        updateProfilePayload,
      )
      setIsLoading(false)

      let allowUndefined = true
      const respCheck = checkMicroServiceResponseStatus(
        saveResponse,
        allowUndefined,
      )
      if (!respCheck.isError) {
        sendMsg('Your profile has been updated successfully.', 'success')
        const updatedProfile = await service.getMemberProfile_EKS(
          jwtDetails.id_key,
        )
        setCurrentMemberProfile(updatedProfile)
      } else {
        LOG.error('member_profile', 'profile update failed', saveResponse)
      }
    } catch (error) {
      LOG.error(
        'member_profile',
        'Error cought trying to update member profile.' + parseUiError(error),
      )
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    document.title = 'My Account - Scripta Insights'
  })

  if (variant === 'preferences') {
    return (
      <Grid
        container
        spacing={SPACING._1}
        justifyContent="center"
        direction={'column'}
      >
        <Grid item>
          <STypography variant="body_bold"> {t('titleContact')}</STypography>
        </Grid>
        <Grid item>
          <EditEmailData
            initEmailValue={newEmail}
            onEmailContactChanged={(newEmail: string) => {
              setNewEmail(newEmail)
            }}
            onIsEmailValidChanged={(valid) => {
              setIsNewEmailValid(valid)
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            autoComplete="off"
            placeholder={t('mobile')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">+1</InputAdornment>
              ),
            }}
            fullWidth
            label={t('mobile')}
            value={newMobileNumberDisplayed}
            onChange={(e) => {
              //process and format input to display friendly form
              //up to 10 digits
              if (toDigitsOnly(e.target.value).length <= 10) {
                let displayNumberValue = toDisplayFriendlyString(e.target.value)
                setNewMobileNumberDisplayed(displayNumberValue)
                const isValid = isDisplayFriendlyStringValidNumber(
                  e.target.value,
                )
                setIsNewPhoneValid(isValid)
              }
            }}
          />
        </Grid>
        <Grid item>
          <Grid item container justifyContent="center">
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <SButton
                className={CLASS_NAMES.updateContactInfoButton}
                variant="contained"
                fullWidth
                disabled={!isNewEmailValid || !isNewPhoneValid || isLoading}
                onClick={async (e) => {
                  if (!jwtDetails) {
                    LOG.error(
                      'member_profile',
                      'jwtDetails not found trying to update my account',
                    )
                    return null
                  }
                  await saveProfileAsync(
                    jwtDetails,
                    newMobileNumberDisplayed,
                    newEmail,
                  )
                }}
                noCaps
                sx={isDesktop ? { maxWidth: '300px' } : undefined}
              >
                {t('saveProfile')}
              </SButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <CommunicationPreferences variant="saving_report" />
        </Grid>
        <Grid item>
          <CommunicationPreferences variant="marketing" />
        </Grid>
      </Grid>
    )
  }

  //only used one left is variant='preferences' with the new portal design
  return null
}
