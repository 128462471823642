import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Radio,
  RadioGroup,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react'
import { FindMemberEKSResp, RegistrationChallenge } from '../../types'
// import { usePortalStyles } from '../styles/portal-styles'
import { checkMicroServiceResponseStatus } from 'src/app/apiexec/utils'
import { STrans, useSTranslate } from 'src/app/hooks/useSTranslate'
import { useSColors } from 'src/app/styles/scripta-theme'
import { SDialog } from 'src/app/system/customcomponents/SDialog'
import STypography from 'src/app/system/customcomponents/STypography'
import { v4 as uuidv4 } from 'uuid'
import { useMemberAppContext } from '../MemberAppContext'
import { GAService } from '../application/ga/GAService'
import { TargetRoute } from '../v2/SavingGuide'
import { LOG } from '../v2/applog'
import { DualBottomButtonToolbar } from './DualBottomButtonActionToolbar'
import { useRegistration } from './RegistrationContext'
import {
  REG_ROUTE_ANSWER_QUESTIONS_EKS,
  REG_ROUTE_REGISTER_TENANT_ACCOUNT,
  REG_ROUTE_VALIDATE_OTP_EKS,
} from './RegistrationSubRoutes'
import { RegistrationStepCard } from './components/RegistrationStepCard'
import { EksOtpType, useRegistrationService } from './useRegistrationService'
import MemberSupportFooter from 'src/app/registration/MemberSupportFooter'
import { ErrorCardUnableToRegister } from '../system/error/BaseErrorCard'
import {
  EKS_ACCOUNT_LOCKED_CANNOT_GET_QUESTIONS_AGAIN,
  EKS_ACCOUNT_LOCKED_ERROR_CODE_ANSWERS_WRONG_LOCK,
  EKS_GENERIC_400_CODE,
} from 'src/app/EKSStatuCodes'
// export type OtpMethod = 'phone' | 'email'

export function IdentityValidationEKSSubflow() {
  const {
    member,
    // registrationData,
    eksMember,
    history,
    setMember,
    setApiResponse,
    setRegChallenge,
    matchedTenant,
    setEksOtpType,
    eksQuestions,
    setEksQuestions,
    // setIdentityValidationOtpMethodUsed,
  } = useRegistration()
  const { isFirstTimePreregMemberAccess } = useMemberAppContext()

  const [targetRoute, setTargetRoute] = useState<TargetRoute>()
  function pushToSubrouteViaEffect(subroute: string) {
    //we use the object, not a simple string, to ensure useEffect triggers
    const route: TargetRoute = {
      //todo - this guid is probably not needed anymore, we use a object in state
      uuid: uuidv4(),
      route: subroute,
    }

    setTargetRoute(route)
  }
  //here we watch for the target route to be set and then we push to it
  useEffect(() => {
    if (targetRoute) {
      history.push(targetRoute.route)
    }
  }, [targetRoute])

  const { quickLinkCode } = useMemberAppContext()
  const gaService = new GAService()
  const service = useRegistrationService()
  const { t } = useSTranslate(['register', 'common', 'prereg'])
  const [nextLoading, setNextLoading] = useState(false)

  const [otpApiError, setOtpApiError] = useState<boolean>(false)

  const [validationMethod, setValidationMethod] = useState<
    'email' | 'phone' | 'security_questions' | undefined
  >(undefined)

  const { COLORS } = useSColors()
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('md'))

  // const [isEmailDeliveryEnabled, setIsEmailDeliveryEnabled] = useState(
  //   !isNullOrEmpty(eksMember?.email),
  // )
  // useEffect(() => {
  //   console.log('eksMember', eksMember)
  //   setIsEmailDeliveryEnabled(!isNullOrEmpty(eksMember?.email))
  // }, [eksMember])

  // const [isPhoneDeliveryEnabled, setIsPhoneDeliveryEnabled] = useState(
  //   !isNullOrEmpty(eksMember?.phone),
  // )
  // useEffect(() => {
  //   setIsPhoneDeliveryEnabled(!isNullOrEmpty(eksMember?.phone))
  // }, [eksMember?.phone])

  const [isLoadingSecurityQuestions, setIsLoadingSecurityQuestions] = useState<
    boolean | undefined
  >(undefined)

  // const [securityQuestions, setSecurityQuestions] = useState<
  //   EKS | undefined
  // >(undefined)

  // const [otpMethod, setOtpMethod] = useState<OtpMethod | undefined>(
  //   isEmailDeliveryEnabled
  //     ? 'email'
  //     : isPhoneDeliveryEnabled
  //     ? 'phone'
  //     : undefined,
  // )

  const [isSecurityQuestionLocked, setIsSecurityQuestionLocked] =
    useState(false)
  const [lockedMinutesRemaining, setLockedMinutesRemaining] = useState(30)

  //wait for security questions to be loaded bc then we know the final status if there is not even a way for this member to be identified
  // const [isIdentificationNotPossible, setIsIdentificationNotPossible] =
  //   useState(false)

  //automatically send to use security quetisons if neitehr is available
  // useEffect(() => {
  //   if (isLoadingSecurityQuestions) return
  //   if (!isEmailDeliveryEnabled && !isPhoneDeliveryEnabled) {
  //     // setOtpMethod('security_questions')
  //     if (isSecurityQuestionsEnabled) {
  //       // doMoveToAnswerSecurityQuestions()
  //       setIsIdentificationNotPossible(true)
  //     }
  //   }
  // }, [eksMember])

  async function asyncLoadSecurityQuestions(
    tenantUuid: string,
    encryptedMemberId: string,
  ) {
    try {
      setIsLoadingSecurityQuestions(true)
      const resp = await service.loadSecurityQuestionsApi_EKS(
        tenantUuid,
        encryptedMemberId,
      )
      const msResp = checkMicroServiceResponseStatus(resp)
      setIsLoadingSecurityQuestions(false)

      if (msResp.isError) {
        setIsLoadingSecurityQuestions(false)

        //check if account locked
        if (
          resp &&
          (resp.scriptaErrorCode ===
            EKS_ACCOUNT_LOCKED_CANNOT_GET_QUESTIONS_AGAIN ||
            resp.scriptaErrorCode ===
              EKS_ACCOUNT_LOCKED_ERROR_CODE_ANSWERS_WRONG_LOCK)
        ) {
          setIsSecurityQuestionLocked(true)

          //try to extract the minutes remininag form the message
          //sample msg:  Please try again after 25

          try {
            const minutesMatch = resp.message.match(
              /Please try again after (\d+)/,
            )
            const minutesRemaining = minutesMatch
              ? parseInt(minutesMatch[1])
              : 30
            setLockedMinutesRemaining(minutesRemaining)
          } catch (e) {
            //failed to parse minutes, just use remaining default of 30 minutes...
          }
        } else if (resp && resp.scriptaErrorCode === EKS_GENERIC_400_CODE) {
          //not an alert - it happens often bc we don thave claims
        }

        //check if no questions available

        return
      }

      // setApiResponse(resp)
      // setRegChallenge(resp)

      // setSecurityQuestions(resp)
      setEksQuestions(resp)
      // setIsSecurityQuestionsEnabled(true)
      setIsLoadingSecurityQuestions(false)
    } catch (e) {
      LOG.error(
        'registration',
        'exception cought in load security questions api execution',
        e,
      )
      // setIsSecurityQuestionsEnabled(false)
      setIsLoadingSecurityQuestions(false)
    }
  }
  //need to load security questions for member in order to enable/disable radio choice
  //if they are not found
  useEffect(() => {
    if (!eksMember || !matchedTenant) return
    asyncLoadSecurityQuestions(matchedTenant.clientUuid, eksMember.id)
  }, [eksMember, matchedTenant])

  function isNullOrEmpty(val?: string) {
    return val === undefined || val == null || val.length <= 0 ? true : false
  }

  if (isLoadingSecurityQuestions) {
    return (
      <Box sx={{ padding: '50px' }}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            <CircularProgress variant="indeterminate" size={30} />
          </Grid>
          <Grid item xs={12}>
            <STypography
              variant="body_regular"
              sx={{ textAlign: 'center', marginTop: '20px' }}
            >
              Please wait, initializing security validation....
            </STypography>
          </Grid>
        </Grid>
      </Box>
    )
  }

  // if (isSecurityQuestionLocked) {
  //   return (
  //     <Box sx={{ padding: '50px' }}>
  //       <Grid container justifyContent="center" alignItems="center">
  //         <Grid item xs={12}>
  //           <STypography variant="body_regular" sx={{ textAlign: 'center' }}>
  //             You have exceeded the maximum number of attempts to answer the
  //             security questions. Please try again in 30 minutes or contact our
  //             member support for assistance.
  //           </STypography>
  //         </Grid>
  //       </Grid>
  //     </Box>
  //   )
  // }

  if (
    isNullOrEmpty(eksMember?.phone) &&
    isNullOrEmpty(eksMember?.email) &&
    eksQuestions === undefined &&
    isLoadingSecurityQuestions === false &&
    isLoadingSecurityQuestions !== undefined &&
    isSecurityQuestionLocked === false
  ) {
    LOG.warn(
      'registration',
      'Identification not possible - no contact info or enough questions data available.',
    )
    return <ErrorCardUnableToRegister />
    //   <SDialog
    //     // title="Registration is not possible at the moment"
    //     open={true}
    //     upperRightX={false}
    //   >
    //     <Grid container>
    //       <Grid item xs={12} sx={{ textAlign: 'center' }}>
    //         <STypography variant="title3_bold">Unable to register</STypography>
    //       </Grid>
    //       <Grid item xs={12} sx={{ textAlign: 'center', paddingTop: '20px' }}>
    //         <STypography variant="body_regular">
    //           {t('noIdentificationMethodAvailable')}
    //         </STypography>
    //       </Grid>
    //     </Grid>
    //   </SDialog>
    // )
  }

  return (
    <RegistrationStepCard
      registrationType="registration"
      disableToolbar={true}
      progressBarStep={3}
      customToolbarComponent={
        <DualBottomButtonToolbar
          noText={t('register:back')}
          yesText={t('register:continue')}
          onClickNo={() => {
            history.push(REG_ROUTE_REGISTER_TENANT_ACCOUNT)
            // history.goBack()
          }}
          onClickYes={async () => {
            if (validationMethod === 'security_questions') {
              pushToSubrouteViaEffect(REG_ROUTE_ANSWER_QUESTIONS_EKS)
            } else if (
              validationMethod === 'phone' ||
              validationMethod === 'email'
            ) {
              if (!matchedTenant || !eksMember) {
                LOG.error(
                  'registration',
                  'got buton click to generate OTP but no tenant or member.Sending back to first step',
                )
                history.push(REG_ROUTE_REGISTER_TENANT_ACCOUNT)
                return
              }
              const eventType =
                validationMethod === 'phone'
                  ? 'MEMBER_PHONE_VERIFICATION_EVENT'
                  : 'MEMBER_EMAIL_VERIFICATION_EVENT'

              try {
                setNextLoading(true)
                const getOtpResp = await service.generateOtp_EKS(
                  matchedTenant.clientUuid,
                  eksMember.id,
                  eventType,
                )
                setNextLoading(false)
                let allowUndefined = true
                const otpStatus = checkMicroServiceResponseStatus(
                  getOtpResp,
                  allowUndefined,
                )
                if (!otpStatus.isError) {
                  pushToSubrouteViaEffect(REG_ROUTE_VALIDATE_OTP_EKS)
                } else {
                  LOG.error(
                    'registration',
                    'MS api in generate otp api',
                    getOtpResp,
                  )
                  setOtpApiError(true)
                }
              } catch (e) {
                LOG.error(
                  'registration',
                  'ERROR cought in generate OTP api processing',
                  e,
                )
                setOtpApiError(true)
              } finally {
                setNextLoading(false)
              }
            }
          }}
          noHidden={quickLinkCode !== undefined}
          yesDisabled={!validationMethod}
          yesLoading={nextLoading}
        />
        // </Box>
      }
    >
      <Grid item xs={12}>
        <Typography
          data-testid="identity-validation-page-title"
          sx={{
            textAlign: 'center',
            fontSize: isSmall ? '1.125rem' : '1.5rem',
            fontWeight: 700,
            paddingBottom: '20px',
            paddingTop: '0px',
            // marginTop: isSmall ? '-15px' : '0px',
            lineHeight: '21.92px',
          }}
        >
          Identity Validation
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography
          sx={{
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: '20px',
            textAlign: 'center',
            paddingLeft: isSmall ? '20px' : '20px',
          }}
        >
          For the protection of your account, we want to verify and make sure
          it’s really you. Please choose how to verify your identity:
        </Typography>
      </Grid>

      <Grid item xs={12} sx={{ paddingLeft: '20px', marginTop: '20px' }}>
        <RadioGroup
          style={{ marginTop: '0px' }}
          aria-label="Verify By"
          name="otpMethod"
          value={validationMethod || ''}
          onChange={(e) => {
            console.log(
              'validation method changed, e.target.value',
              e.target.value,
            )
            setValidationMethod(
              e.target.value as 'email' | 'phone' | 'security_questions',
            )
            if (e.target.value === 'security_questions') {
              // setEksOtpType(undefined)
            } else if (e.target.value === 'phone') {
              setEksOtpType('MEMBER_PHONE_VERIFICATION_EVENT')
            } else if (e.target.value === 'email') {
              setEksOtpType('MEMBER_EMAIL_VERIFICATION_EVENT')
            }
          }}
        >
          {!isNullOrEmpty(eksMember?.phone) && (
            <FormControlLabel
              value="phone"
              sx={{ padding: '0px' }}
              control={
                <Radio
                  data-testid="phone-verification-radio-button"
                  color="primary"
                  sx={{ padding: '7px' }}
                />
              }
              disabled={isNullOrEmpty(eksMember?.phone)}
              label={
                <Typography>
                  Send me a text message with security code
                </Typography>
              }
            />
          )}
          {!isNullOrEmpty(eksMember?.email) && (
            <FormControlLabel
              value="email"
              control={
                <Radio
                  data-testid="email-verification-radio-button"
                  color="primary"
                  sx={{ padding: '7px' }}
                />
              }
              disabled={isNullOrEmpty(eksMember?.email)}
              label={
                <Typography>Send me an email with security code</Typography>
              }
              // placeholder={t('methodEmail')}
            />
          )}

          {eksQuestions && (
            <FormControlLabel
              value="security_questions"
              control={
                <Radio
                  data-testid="security-questions-verification-radio-button"
                  color="primary"
                  sx={{ padding: '7px' }}
                />
              }
              disabled={isSecurityQuestionLocked}
              label={
                <Typography
                  sx={{
                    color: isSecurityQuestionLocked
                      ? COLORS.ERROR_RED
                      : undefined,
                  }}
                >
                  Answer security questions
                </Typography>
              }
            />
          )}

          {isSecurityQuestionLocked && (
            <Grid item xs={12} sx={{ marginTop: '20px', paddingLeft: '20px' }}>
              <Grid item xs={12}>
                <STypography
                  variant="body_regular"
                  sx={{
                    color: COLORS.ERROR_RED,
                    fontWeight: 600,
                    marginBottom: '8px',
                  }}
                >
                  Security Questions Temporarily Locked
                </STypography>
              </Grid>
              <Grid item xs={12}>
                <STypography variant="body_regular">
                  For your security, this verification method has been locked
                  for remaining {lockedMinutesRemaining} minutes due to too many
                  incorrect attempts.
                </STypography>
              </Grid>
              {(!isNullOrEmpty(eksMember?.phone) ||
                !isNullOrEmpty(eksMember?.email)) && (
                <Grid item xs={12} sx={{ marginTop: '12px' }}>
                  <STypography variant="body_regular">
                    You can still verify your identity using:
                    {!isNullOrEmpty(eksMember?.phone) && (
                      <span
                        style={{
                          display: 'block',
                          marginLeft: '8px',
                          marginTop: '4px',
                        }}
                      >
                        • Text message verification code
                      </span>
                    )}
                    {!isNullOrEmpty(eksMember?.email) && (
                      <span
                        style={{
                          display: 'block',
                          marginLeft: '8px',
                          marginTop: '4px',
                        }}
                      >
                        • Email verification code
                      </span>
                    )}
                  </STypography>
                </Grid>
              )}
              <Grid item xs={12} sx={{ marginTop: '12px' }}>
                <STypography variant="body_regular">
                  Need help? Reach out to our friendly support via the chat on
                  the bottom right or give us a call at:
                </STypography>
              </Grid>
              <Grid item xs={12} sx={{ marginTop: '12px' }}>
                <MemberSupportFooter
                  variant="single-line"
                  customFontSx={{ color: COLORS.TEXT_BLACK }}
                />
              </Grid>
            </Grid>
          )}
        </RadioGroup>
      </Grid>
      {otpApiError && (
        <Grid item xs={12} sx={{ marginTop: '20px' }}>
          <STypography variant="body_regular" sx={{ color: COLORS.ERROR_RED }}>
            Unable to request OTP using your existing data on file. Please try
            an alternative method or contact our member support so we can help
            you register.
          </STypography>
        </Grid>
      )}
      {validationMethod &&
        (validationMethod === 'email' || validationMethod === 'phone') &&
        eksMember && (
          <Grid
            item
            container
            xs={12}
            sx={{
              paddingTop: '10px',
              paddingLeft: '20px',
              textAlign: 'left',
              display: 'flex',
            }}
          >
            <EksValueOnFile
              eksOtpType={
                validationMethod === 'phone'
                  ? 'MEMBER_PHONE_VERIFICATION_EVENT'
                  : 'MEMBER_EMAIL_VERIFICATION_EVENT'
              }
              eksMember={eksMember}
            />
          </Grid>
        )}
      <Grid item xs={12} sx={{ marginTop: '30px', marginBottom: '30px' }}>
        <Divider
          sx={{
            width: '100%',
            minWidth: '100%',
          }}
        />
      </Grid>
      {validationMethod && validationMethod === 'phone' && (
        <Grid item xs={12} sx={{ paddingLeft: '10px' }}>
          <Typography
            sx={{
              textAlign: 'left',
              lineHeight: '20px',
              paddingLeft: isSmall ? '10px' : undefined,
              paddingRight: isSmall ? '10px' : undefined,
              fontSize: isSmall ? '0.8125rem' : '1rem',
            }}
          >
            <STrans
              namespace={'register'}
              i18nKey={'importantNoticeVerification'}
            />
          </Typography>
        </Grid>
      )}

      <Grid item xs={12} sx={{ minHeight: '10px' }}></Grid>
    </RegistrationStepCard>
  )
}

export function EksValueOnFile({
  eksOtpType,
  eksMember,
}: {
  eksOtpType: EksOtpType
  eksMember: FindMemberEKSResp
}) {
  const { t } = useSTranslate(['register', 'common'])

  return (
    <Grid container>
      {eksOtpType === 'MEMBER_PHONE_VERIFICATION_EVENT' && (
        <>
          <Grid item>
            <Typography data-testid="phone-on-file-label">
              {t('phoneOnFile')}
            </Typography>
            &nbsp;
          </Grid>
          <Grid item>
            <Typography sx={{ fontWeight: 700, paddingLeft: '5px' }}>
              +{eksMember?.phone}
            </Typography>
          </Grid>
        </>
      )}
      {eksOtpType === 'MEMBER_EMAIL_VERIFICATION_EVENT' && (
        <>
          <Grid item>
            <Typography data-testid="email-on-file-label" noWrap>
              {t('emailOnFile')}
            </Typography>
            &nbsp;
          </Grid>
          <Grid>
            <Typography sx={{ fontWeight: 700, paddingLeft: '5px' }}>
              {eksMember?.email}
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  )
}
