import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Divider,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { CustomTextField } from '../registration/components/CustomTextField'
import { validateEmail } from '../registration/EditContactInfoEKS'
import { CLASS_NAMES } from '../shared/constants'
import SButton from '../system/customcomponents/SButton'
import STypography from '../system/customcomponents/STypography'
import { SPACING } from '../system/theme2'
import {
  isDisplayFriendlyStringValidNumber,
  toDigitsOnly,
  toDisplayFriendlyString,
} from '../member/phone-field-utils'
import { DependentEks } from 'src/app/member/useMemberService'

function validatePhone(phone: string) {
  if (!phone) return false
  const phoneDigits = toDigitsOnly(phone)
  return isDisplayFriendlyStringValidNumber(phoneDigits)
}

export function DepsInviteCell({
  dep,
  inviteDep,
}: {
  dep: DependentEks
  inviteDep: (depId: number, email: string, phone: string) => void
}) {
  const initialEmail = dep.email ? dep.email : ''
  const { t } = useSTranslate('deps')
  const [finalEmail, setFinalEmail] = useState(initialEmail)
  const [isEditMode, setIsEditMode] = useState(false)
  const [showEmailEdit, setShowEmailEdit] = useState(false)
  const [showPhoneEdit, setShowPhoneEdit] = useState(false)
  const [isValidEmail, setIsValidEmail] = useState<boolean>()
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const isMobileMode: boolean = isSmall
  const initialPhone = dep.phone || ''
  const [finalPhone, setFinalPhone] = useState(initialPhone)
  const [isValidPhone, setIsValidPhone] = useState<boolean>()
  const [isInviteEnabled, setIsInviteEnabled] = useState(false)

  //auto enable edit mode when no email exist
  useEffect(() => {
    if (!initialEmail) {
      setIsEditMode(true)
    }
  }, [initialEmail])

  useEffect(() => {
    if (validateEmail(finalEmail)) {
      setIsValidEmail(true)
    } else {
      setIsValidEmail(false)
    }
  }, [finalEmail, initialEmail])

  useEffect(() => {
    if (validatePhone(finalPhone)) {
      setIsValidPhone(true)
    } else {
      setIsValidPhone(false)
    }
  }, [finalPhone])

  useEffect(() => {
    const emailProvided = Boolean(finalEmail)
    const phoneProvided = Boolean(finalPhone)

    // If neither email nor phone provided, disable invite
    if (!emailProvided && !phoneProvided) {
      setIsInviteEnabled(false)
      return
    }

    // email is required and it must be valid
    if (!emailProvided || !isValidEmail) {
      setIsInviteEnabled(false)
      return
    }

    // If phone provided, it must be valid
    if (phoneProvided && !isValidPhone) {
      setIsInviteEnabled(false)
      return
    }

    setIsInviteEnabled(true)
  }, [finalEmail, isValidEmail, finalPhone, isValidPhone])

  const displayName = `${dep['first-name']} ${dep['last-name']}`

  return (
    <Grid container>
      <Grid item xs={12} container>
        <Grid item xs={12}>
          <STypography variant="body_bold" sx={{ textTransform: 'capitalize' }}>
            {displayName.toLowerCase()}
          </STypography>
        </Grid>

        {/* Email Row */}
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {!showEmailEdit ? (
              <>
                <Typography fontSize={13} fontWeight={400}>
                  {finalEmail || 'No email available (required)'}
                </Typography>
                <Typography
                  onClick={() => setShowEmailEdit(true)}
                  fontSize={13}
                  sx={{
                    marginLeft: '20px',
                    fontWeight: 600,
                    color: '#2D8DFF',
                    fontStyle: 'italic',
                    cursor: 'pointer',
                  }}
                >
                  Click to provide updated email
                </Typography>
              </>
            ) : (
              <Box
                sx={{ display: 'flex', width: '100%', alignItems: 'center' }}
              >
                <CustomTextField
                  type="text"
                  id="email"
                  name="email"
                  placeholder={t('validateEmail')}
                  label={'Email address (required)'}
                  value={finalEmail}
                  onChange={(e) => setFinalEmail(e.target.value)}
                  sx={{
                    flex: 1,
                    marginBottom: 0,
                    height: '48px',
                  }}
                />
                <Typography
                  onClick={() => {
                    if (isValidEmail) setShowEmailEdit(false)
                  }}
                  fontSize={13}
                  sx={{
                    ml: 2,
                    paddingTop: '10px',
                    fontWeight: 600,
                    color: isValidEmail ? '#2D8DFF' : '#999999',
                    fontStyle: 'italic',
                    cursor: isValidEmail ? 'pointer' : 'default',
                  }}
                >
                  Save
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>

        {/* Phone Row */}
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            {!showPhoneEdit ? (
              <>
                <Typography fontSize={13} fontWeight={400}>
                  {finalPhone || 'No phone available (optional)'}
                </Typography>
                <Typography
                  onClick={() => setShowPhoneEdit(true)}
                  fontSize={13}
                  sx={{
                    marginLeft: '20px',
                    fontWeight: 600,
                    color: '#2D8DFF',
                    fontStyle: 'italic',
                    cursor: 'pointer',
                  }}
                >
                  Click to provide updated phone number
                </Typography>
              </>
            ) : (
              <Box
                sx={{ display: 'flex', width: '100%', alignItems: 'center' }}
              >
                <CustomTextField
                  type="tel"
                  id="phone"
                  name="phone"
                  placeholder={'Phone number'}
                  label={'Phone number'}
                  value={finalPhone}
                  onChange={(e) => {
                    if (toDigitsOnly(e.target.value).length <= 10) {
                      let displayNumberValue = toDisplayFriendlyString(
                        e.target.value,
                      )
                      setFinalPhone(displayNumberValue)
                    }
                  }}
                  sx={{
                    flex: 1,
                    marginBottom: 0,
                    height: '48px',
                  }}
                />
                <Typography
                  onClick={() => {
                    if (isValidPhone) setShowPhoneEdit(false)
                  }}
                  fontSize={13}
                  sx={{
                    ml: 2,
                    paddingTop: '10px',
                    fontWeight: 600,
                    color: isValidPhone ? '#2D8DFF' : '#999999',
                    fontStyle: 'italic',
                    cursor: isValidPhone ? 'pointer' : 'default',
                  }}
                >
                  Save
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>

        {/* Invite Button */}
        <Grid item xs={12}>
          <SButton
            size="small"
            className={CLASS_NAMES.inviteDepButton}
            disabled={!isInviteEnabled}
            onClick={() => inviteDep(dep.id, finalEmail, finalPhone)}
            noCaps
          >
            {t('invite')}
          </SButton>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Divider sx={{ padding: '5px' }} />
      </Grid>
    </Grid>
  )
}
