//simple prefrences based on local browser storage

import { LOG } from 'src/app/v2/applog'
import { MemberTrackingData } from '../../types'
import { EKSMemberProfile } from 'src/app/member/types'
import { JwtDetails } from 'src/app/MemberAppContext'

const PREF_KEY_PREFERRED_LANG = 'ScriptaPrefLangCode'
const PREF_KEY_WITH_SAVINGS_ONBOARDING_VISIBILITY =
  'ScriptaPrefWithSavingsOnboardingVisibility'
const PREF_KEY_MEMBER_TRACKING_DATA = 'ScriptaPrefMemberTrackingData'

export function getBrowserStorageService(jwtDetails: JwtDetails) {
  if (!jwtDetails) {
    throw new Error('Cannot use browser session without member profile')
  }
  const storeItem = (key: string, value: any) => {
    if (localStorage) {
      return localStorage.setItem(getKeyName(key, jwtDetails), value)
    } else return () => {}
  }
  const getItem = (key: string): string | undefined => {
    //return undefined instead of null
    if (localStorage) {
      const item = localStorage.getItem(getKeyName(key, jwtDetails))
      return item !== null ? item : undefined
    } else return undefined
  }
  return { storeItem, getItem }
}
function getKeyName(key: string, jwtDetails: JwtDetails) {
  return `${jwtDetails.tenant_key}_${jwtDetails.id_key}_${key}`
}
export function getPreferencesService(jwtDetails: JwtDetails) {
  const { storeItem, getItem } = getBrowserStorageService(jwtDetails)

  //this is now properly persisted in the backend
  const setPrefLanguage = (langCode: string | undefined) => {
    storeItem(PREF_KEY_PREFERRED_LANG, langCode)
  }
  const getPrefLanguage = () => {
    return getItem(PREF_KEY_PREFERRED_LANG)
  }

  const setPrefWithSavingsOnboardingCollapsed = (collapsed = true) => {
    storeItem(PREF_KEY_WITH_SAVINGS_ONBOARDING_VISIBILITY, collapsed)
  }
  const getPrefWithSavingsOnboardingCollapsed = () => {
    return getItem(PREF_KEY_WITH_SAVINGS_ONBOARDING_VISIBILITY)
  }

  return {
    setPrefLanguage,
    getPrefLanguage,
    setPrefWithSavingsOnboardingCollapsed,
    getPrefWithSavingsOnboardingCollapsed,
  }
}

export function getMemberTrackingDataService() {
  const storeItem = (key: string, value: any) => {
    if (localStorage) {
      return localStorage.setItem(key, value)
    } else return () => {}
  }

  const getMemberTrackingData = (): MemberTrackingData | undefined => {
    const getItemSafely = (key: string): MemberTrackingData | null => {
      try {
        const item = localStorage.getItem(key)
        if (!item) return null
        const parsedItem = JSON.parse(item)
        return parsedItem as MemberTrackingData
      } catch (error) {
        LOG.error('generic_error', `Error parsing localStorage item: ${error}`)
        return null
      }
    }

    const item = getItemSafely(PREF_KEY_MEMBER_TRACKING_DATA)

    if (item) {
      return item
    }

    return undefined
  }

  const setMemberTrackingData = (memberTrackingData: MemberTrackingData) => {
    try {
      const dataString = JSON.stringify(memberTrackingData)
      storeItem(PREF_KEY_MEMBER_TRACKING_DATA, dataString)
    } catch (error) {
      LOG.error('generic_error', `Error storing member tracking data: ${error}`)
    }
  }

  return { getMemberTrackingData, setMemberTrackingData }
}
