import { Box, Grid, IconButton, Link, Typography } from '@mui/material'
import SaveUpTo from './SaveUpTo'
import CardContainer from './CardContainer'
import DrugDetails from './DrugDetails'
import STypography, { STypographyProps } from './customcomponents/STypography'
import { TagLabel } from './TagLabel'
import { RADIUS, SPACING } from './theme2'
import preferredPharmacyLogoImgSrc from './images/preferred_pharmacy_logo.svg'
import couponIconImgSrc from './images/coupon_icon.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookmark } from '@fortawesome/free-solid-svg-icons'
import { useSColors } from '../styles/scripta-theme'
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons'
import {
  ModalCashAndInsurance,
  ModalPharmacyChange,
  ModalPharmacyStay,
  ModalPharmacyTypeInfo,
  ModalWhatIsDed,
} from './modals/Modals'
import { useEffect, useState } from 'react'
import { PharmacyType } from '../v2/saving-utils'
import SavingMethodModal from './SavingMethodModal'
import { InfoIconButton } from './InfoIconButton'
import couponsStrategyImgSrc from './images/strategies/Scripta_Coupons.svg'
import cashPaymentIconImgSrc from './images/cash_payment2.svg'
import insurancePaymentIconImgSrc from './images/preferred_pharmacy_logo.svg'
import pharmacySwitchIconImgSrc from './images/pharmacy_switch_icon.svg'

import { SavingStrategy } from '../strategies/types'
import { ThemedSVGRemote } from '../v2/ThemedSVGRemote'
import { useMemberAppContext } from 'src/app/MemberAppContext'
import { LOG } from 'src/app/v2/applog'

const VERTICAL_ITEMS_GAP = '8px'

export function CouponsAvailableTag({
  customTagText,
}: {
  customTagText?: string
}) {
  const { COLORS } = useSColors()
  return (
    <STypography
      variant="bodysmall_bold"
      sx={{
        color: COLORS.BACKGROUND_SECONDARY_ACTIVE,
        backgroundColor: COLORS.BACKGROUND_SECONDARY_10,
        borderRadius: '0 4px 4px 0',
        padding: '4px 12px',
        width: 'fit-content',
      }}
    >
      {customTagText ? customTagText : 'Coupons Available'}
    </STypography>
  )
}

//FIRST PAGE - list of originals
export interface SavingOpportunityListItemProps {
  drugId: string | number
  drugName: string
  drugDosage: string
  drugForm: string
  drugCount: string
  upToAmount: string // i.e. $34.69/mo
  upToPrefix: string //i.e. "Save up to"
  hasCoupons?: boolean //if true, show "coupons available" tag
  isBookmarked?: boolean //if true, show "bookmarked" indicator
  ellipsisMenu: React.ReactNode
  onClick: () => void
  customCouponsAvailableTagText?: string
  customTags?: any[]
}

export function SavingOpportunityListItem({
  drugId,
  drugName,
  drugDosage,
  drugForm,
  drugCount,
  upToAmount,
  upToPrefix,
  hasCoupons = false,
  isBookmarked = false,
  ellipsisMenu,
  onClick,
  customCouponsAvailableTagText,
  customTags,
}: SavingOpportunityListItemProps) {
  const { COLORS } = useSColors()
  return (
    <CardContainer onClick={onClick} sx={{ padding: SPACING._1 }}>
      <Grid
        //two test ids - one when testing against QC - we know the original drug id
        //one for now based on drug name bc in print vendor we only have drug name
        data-drug-id={`${drugId}`}
        data-drug-name={`${drugName}`}
        item
        container
        direction="column"
        sx={{
          color: COLORS.TEXT_BLACK,
          gap: VERTICAL_ITEMS_GAP,
          position: 'relative',
        }}
      >
        {isBookmarked ? (
          <FontAwesomeIcon
            icon={faBookmark}
            color={COLORS.PRIMARY_BRAND}
            fontSize={16}
            style={{ position: 'absolute', top: -20 }}
          />
        ) : null}
        <Grid
          item
          container
          gap={1}
          justifyContent={'space-between'}
          alignItems={hasCoupons ? 'center' : 'flex-start'}
        >
          <Grid item container xs={9} direction={'column'}>
            {hasCoupons ? (
              <Grid item sx={{ ml: -2 }}>
                <CouponsAvailableTag
                  customTagText={customCouponsAvailableTagText}
                />
              </Grid>
            ) : (
              <Grid item>
                <STypography variant="bodybig_bold">{drugName}</STypography>
              </Grid>
            )}
          </Grid>
          <Grid item>{ellipsisMenu}</Grid>
        </Grid>
        {hasCoupons ? (
          <Grid item>
            <STypography variant="bodybig_bold">{drugName}</STypography>
          </Grid>
        ) : null}
        <Grid
          item
          container
          gap={1}
          justifyContent={'space-between'}
          flexWrap={'nowrap'}
          alignItems={'flex-end'}
        >
          <Grid item xs={6}>
            <DrugDetails
              drugDosage={drugDosage}
              drugForm={drugForm}
              drugCount={drugCount}
            />
          </Grid>
          <Grid item textAlign={'end'}>
            <SaveUpTo
              upToPrefix={upToPrefix}
              upToAmount={upToAmount}
              isBestPrice={false}
            />
          </Grid>
        </Grid>
        {customTags ? (
          <Grid item xs={12}>
            {customTags}
          </Grid>
        ) : null}
      </Grid>
    </CardContainer>
  )
}
// export function SavingListItem(props: SavingOpportunityListItemProps) { }

//SECOND PAGE - grouping of alternatives
//2 possible groups - theraputic alternative or same medicine
//(each original could contain both groups or just one  each)
export interface SaveUpToCardOptionItemProps {
  dataTestId: string
  variant?: 'cash' | 'insurance'
  upToPrefix: string //i.e. "Save up to"
  upToAmount: string // i.e. $34.69/mo
  upToSuffix: string //i.e. "by switching to a proven alt..." or "by staying on your med"
  //if true, it shows tag label in the middle
  //if true, color of upToAmount = pruple
  isBestPrice: boolean
  onClick: () => void
}

export function SaveUpToCardOptionItem({
  dataTestId,
  upToPrefix,
  upToAmount,
  upToSuffix,
  isBestPrice,
  onClick,
  variant,
}: SaveUpToCardOptionItemProps) {
  return (
    <CardContainer onClick={onClick}>
      <Box
        data-testid={dataTestId}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {isBestPrice ? (
          <Box mt={'-22.5px'} pb={'10px'}>
            <TagLabel size={'small'} color={'green'} text={'Best Price'} />
          </Box>
        ) : null}
        {variant === 'cash' ? (
          <ThemedSVGRemote
            importUrl={cashPaymentIconImgSrc}
            width={40}
            height={40}
            alt="cash payment"
            data-testid={`${dataTestId}-cash-payment-icon`}
          />
        ) : // <CashPaymentIcon width={40} height={40} />
        variant === 'insurance' ? (
          <ThemedSVGRemote
            importUrl={insurancePaymentIconImgSrc}
            width={40}
            height={40}
            alt="insurance payment"
            data-testid={`${dataTestId}-insurance-payment-icon`}
          />
        ) : // <InsurancePaymentIcon width={40} height={40} />
        null}
        <SaveUpTo
          upToPrefix={upToPrefix}
          upToAmount={upToAmount}
          upToSuffix={upToSuffix}
          isBestPrice={isBestPrice}
          isOptionMode={true}
        />
      </Box>
    </CardContainer>
  )
}

// export function SavingGroupItem(props: GroupedSavingItemProps) {}

//THIRD PAGE - list of drug in the group
//NOTE - this particular page would be skipped in case of "same medicine" group
//we jump direclty to fourth page - strategy list
export interface DrugListItemProps {
  upToPrefix: string //i.e. "Save up to"
  upToAmount: string // i.e. $34.69/mo
  drugName: string
  isBestPrice: boolean //if true, use purple color for upToAmount
  //possible tag labels to be applied to the item
  //should be rendred as <>{tags}</>
  tags?: React.ReactNode
  ellipsisMenu: React.ReactNode
  onClick: () => void
}

export function DrugListItem({
  upToPrefix,
  upToAmount,
  drugName,
  tags,
  isBestPrice,
  ellipsisMenu,
  onClick,
}: DrugListItemProps) {
  const { COLORS } = useSColors()
  return (
    <CardContainer onClick={onClick}>
      <Grid
        item
        container
        direction="column"
        sx={{
          color: COLORS.TEXT_BLACK,
          gap: VERTICAL_ITEMS_GAP,
        }}
      >
        <Grid item container justifyContent="space-between">
          <Grid item container direction="column" gap={SPACING._05} xs={8}>
            <Grid item>
              <SaveUpTo
                upToPrefix={upToPrefix}
                upToAmount={upToAmount}
                isBestPrice={isBestPrice}
              />
            </Grid>
            <Grid item container sx={{ gap: SPACING._025 }}>
              {tags}
            </Grid>
          </Grid>
          <Grid item>{ellipsisMenu}</Grid>
        </Grid>
        <Grid item>
          <STypography variant="bodybig_bold">{drugName}</STypography>
        </Grid>
      </Grid>
    </CardContainer>
  )
}

//FOURTH PAGE - for each drug, multiple options
//normal saving strategy, coupons, pharmacy change
export interface StrategyListItemProps {
  upToPrefix: string //i.e. "Save up to"
  upToAmount: string // i.e. $34.69/mo
  upToSuffix: string //'using your insurance','using a coupon', 'by switching pharmacies'
  infoMenu: React.ReactNode
  onClick: () => void //click of whole item
  onClickInfoMenu: () => void //click of info menu icon

  drugName: string
  drugDosage: string
  drugForm: string
  drugCount: string

  isBestPrice: boolean

  //strategyName below is not directly rendered in the UI (will be done via tags)
  //passing here in casd we need to show more details about a strategy later
  strategyName: string //"Related",'Pill Split

  //possible tag labels to be applied to the item
  //should be rendred as <>{tags}</>
  tags?: React.ReactNode
}

export function StrategyListItem({
  upToPrefix,
  upToAmount,
  upToSuffix,
  infoMenu,
  onClick,
  onClickInfoMenu,
  drugName,
  drugDosage,
  drugForm,
  drugCount,
  isBestPrice,
  strategyName,
  tags,
}: StrategyListItemProps) {
  const { COLORS } = useSColors()
  return (
    <CardContainer onClick={onClick}>
      <Grid
        item
        container
        direction="column"
        sx={{
          color: COLORS.TEXT_BLACK,
          gap: VERTICAL_ITEMS_GAP,
        }}
      >
        <Grid item container justifyContent="space-between">
          <Grid
            item
            container
            direction="column"
            gap={VERTICAL_ITEMS_GAP}
            xs={9}
          >
            <Grid item>
              <SaveUpTo
                upToPrefix={upToPrefix}
                upToAmount={upToAmount}
                upToSuffix={upToSuffix}
                isBestPrice={isBestPrice}
              />
            </Grid>
            <Grid item container sx={{ gap: SPACING._025 }}>
              {tags}
            </Grid>
          </Grid>
          <Grid item>{infoMenu}</Grid>
        </Grid>
        <Grid item>
          <STypography variant="bodybig_bold">{drugName}</STypography>
          <DrugDetails
            drugDosage={drugDosage}
            drugForm={drugForm}
            drugCount={drugCount}
          />
        </Grid>
      </Grid>
    </CardContainer>
  )
}

export interface CouponCardItemProps {
  pharmacyName: string
  pharmacyLogoUrl: string
  tags?: React.ReactNode //Best Price, Coupon
  distance: string //i.e.
  price: string //i.e. $34.69/mo
  onClick: () => void //click of whole item
  dataTestId: string
}

// TODO: internationalize price with coupon
export function CouponCardItem({
  pharmacyName,
  pharmacyLogoUrl,
  tags,
  distance,
  price,
  onClick,
  dataTestId,
}: CouponCardItemProps) {
  const { COLORS } = useSColors()
  const { featureConfig } = useMemberAppContext()

  useEffect(() => {
    if (
      featureConfig &&
      featureConfig.isInitialized &&
      featureConfig.isCouponDisabled
    ) {
      LOG.warn(
        'coupons',
        'CouponCardItem is rendered even though feature is disabled',
      )
    }
  }, [featureConfig])

  return (
    <CardContainer
      data-testid={dataTestId}
      onClick={onClick}
      sx={{ padding: SPACING._075 }}
    >
      <Grid item container direction="column" gap={VERTICAL_ITEMS_GAP}>
        <Grid item container alignItems="center" gap={SPACING._05}>
          <Grid
            item
            sx={{
              width: 'fit-content',
              maxHeight: 56,
              maxWidth: 144,
              minHeight: 32,
            }}
          >
            <img
              src={pharmacyLogoUrl}
              alt={`${pharmacyName} logo`}
              style={{ maxHeight: 56, maxWidth: 144, minHeight: 32 }}
            />
          </Grid>
          <Grid item style={{ display: 'flex', gap: SPACING._025 }}>
            {tags}
          </Grid>
        </Grid>
        <Grid item>
          <Box display="flex" flexWrap={'wrap'}>
            <STypography
              variant="body_semibold"
              sx={{ color: COLORS.TEXT_BLACK, paddingRight: SPACING._1 }}
            >
              {pharmacyName}
            </STypography>
            <STypography
              variant="body_regular"
              sx={{ color: COLORS.TEXT_GREY_MEDIUM }}
            >
              {distance}
            </STypography>
          </Box>
        </Grid>
        <Grid
          item
          sx={{
            backgroundColor: COLORS.BACKGROUND_GREY_LIGHTER,
            padding: `${SPACING._05} ${SPACING._1}`,
            borderRadius: RADIUS.sm,
          }}
        >
          <STypography component={'span'} variant="title3_bold">
            {price}
          </STypography>
          <STypography component={'span'} variant="body_regular">
            {' '}
            Price w/ coupon
          </STypography>
        </Grid>
      </Grid>
    </CardContainer>
  )
}

export interface PharmacyCardItemProps {
  pharmacyName?: string
  pharmacyLogoUrl?: string
  tags?: React.ReactNode //Online, Cash Price Pharmacy

  priceBeforeDed?: string
  priceAfterDed?: string
  priceCopay?: string //i.e. $8.30
  priceCoupon?: string
  priceCash?: string

  pricingShipAndTaxNote?: string //for mccpd

  physicalAddress?: string
  distance?: string
  onClickFindAnotherLocation?: () => void // not null = show find another location button

  daysSupply?: number
  isOnline?: boolean
  isAnyPharmacy?: boolean
  isSpecificPharmacy?: boolean
  isWallmartCustomVariant?: boolean
}

// TODO: internationalize hardcoded display strings
export function PharmacyCardItem({
  pharmacyName,
  pharmacyLogoUrl,
  tags,
  priceBeforeDed,
  priceAfterDed,
  priceCopay,
  priceCoupon,
  priceCash,
  pricingShipAndTaxNote,
  physicalAddress,
  distance,
  isAnyPharmacy = false,
  isSpecificPharmacy = false,
  isWallmartCustomVariant = false,
  isOnline = false,
  daysSupply,
  onClickFindAnotherLocation = undefined,
}: PharmacyCardItemProps) {
  const { COLORS } = useSColors()

  const buyOnlineText = 'Buy your prescription online'
  const yourMedicationDeliveredText =
    'Your medications will be delivered straight to your door in just a few days.'
  const findAnotherLocationText = 'Find another location'
  const anyPharmacyDisclaimer =
    '*Average drug cost (monthly) based on your insurance plan. Price at the pharmacy may vary.'

  const [isWhatIsDedModalOpen, setIsWhatIsDedModalOpen] = useState(false)

  function generatePriceElement(
    price: string | undefined,
    label: string,
    priceVariant: STypographyProps['variant'] = 'bodybig_bold',
    labelVariant: STypographyProps['variant'] = 'body_regular',
    showDedInfoButton: boolean = false,
  ): React.ReactElement | undefined {
    if (!price) return undefined

    return (
      <>
        <STypography component={'span'} variant={priceVariant}>
          {price}
        </STypography>{' '}
        <STypography component={'span'} variant={labelVariant}>
          {label}
        </STypography>{' '}
        {showDedInfoButton ? (
          <>
            <ModalWhatIsDed
              open={isWhatIsDedModalOpen}
              onClose={() => setIsWhatIsDedModalOpen(false)}
            />
            <FontAwesomeIcon
              icon={faCircleQuestion}
              color={COLORS.TEXT_GREY_DARK}
              style={{ cursor: 'pointer' }}
              onClick={() => setIsWhatIsDedModalOpen(true)}
            />
          </>
        ) : null}
      </>
    )
  }

  function getPriceElement(): React.ReactElement | undefined {
    if (priceCoupon !== undefined) {
      return generatePriceElement(priceCoupon, 'Price with coupon')
    } else if (priceCash !== undefined) {
      return generatePriceElement(
        priceCash,
        pricingShipAndTaxNote ? 'Paying by cash*' : 'Paying by cash',
      )
    } else if (isAnyPharmacy || isSpecificPharmacy) {
      if (priceCopay) {
        return generatePriceElement(priceCopay, 'Copay')
      } else {
        return (
          <Grid item container direction={'column'}>
            <Grid item>
              {generatePriceElement(
                priceBeforeDed,
                `before deductible${isAnyPharmacy ? '*' : ''}`,
                'body_bold',
                'bodysmall_regular',
              )}
            </Grid>
            <Grid item>
              {generatePriceElement(
                priceAfterDed,
                `after deductible${isAnyPharmacy ? '*' : ''}`,
                'body_bold',
                'bodysmall_regular',
                true,
              )}
            </Grid>
          </Grid>
        )
      }
    }

    return undefined
  }

  function getLogoElement(): React.ReactElement | undefined {
    if (pharmacyLogoUrl && pharmacyName) {
      return (
        <img
          src={pharmacyLogoUrl}
          alt={`${pharmacyName} logo`}
          style={{ maxHeight: 56, maxWidth: '100%', minHeight: 32 }}
        />
      )
    } else if (isAnyPharmacy) {
      return (
        <Grid
          container
          item
          alignItems="center"
          gap={SPACING._05}
          flexWrap={'nowrap'}
        >
          <Grid item>
            <ThemedSVGRemote
              importUrl={preferredPharmacyLogoImgSrc}
              alt={
                isWallmartCustomVariant
                  ? 'Walmart pharmacy'
                  : `your in-network retail pharmacy`
              }
              width={32}
              height={32}
              // style={{
              //   height: 56,
              //   width: 'auto',
              // }}
            />
            {/* <img
              src={preferredPharmacyImage}
              alt={
                isWallmartCustomVariant
                  ? 'Walmart pharmacy'
                  : `your in-network retail pharmacy`
              }
              style={{
                height: 56,
                width: 'auto',
              }}
            /> */}
          </Grid>
          <Grid item>
            <STypography
              variant="bodybig_regular"
              sx={{ color: COLORS.TEXT_GREY_MEDIUM }}
            >
              {isWallmartCustomVariant
                ? 'Go to Walmart pharmacy'
                : 'Go to your in-network retail pharmacy*'}
            </STypography>
          </Grid>
        </Grid>
      )
    }

    return undefined
  }

  return (
    <CardContainer sx={{ padding: SPACING._075 }}>
      <Grid item container direction="column" gap={VERTICAL_ITEMS_GAP}>
        <Grid item display={'flex'} alignSelf={'start'}>
          {getLogoElement()}
        </Grid>
        {pharmacyName ? (
          <Grid>
            <STypography
              variant="body_semibold"
              sx={{
                color: COLORS.TEXT_GREY_DARK,
                paddingRight: SPACING._1,
              }}
            >
              {pharmacyName}
            </STypography>
          </Grid>
        ) : null}
        <Grid item container gap={SPACING._05} xs={6.5}>
          {tags}
        </Grid>
        {daysSupply ? (
          <Grid>
            <STypography variant={'bodysmall_regular'}>
              For a <strong>{daysSupply} days supply</strong> you will pay:
            </STypography>
          </Grid>
        ) : null}
        <Grid
          item
          sx={{
            backgroundColor: COLORS.BACKGROUND_GREY_LIGHTER,
            padding: `${SPACING._05} ${SPACING._1}`,
            borderRadius: RADIUS.sm,
          }}
        >
          {getPriceElement()}
        </Grid>
        {physicalAddress || distance ? (
          <Grid
            item
            sx={{
              backgroundColor: COLORS.BACKGROUND_GREY_LIGHTER,
              padding: `${SPACING._05} ${SPACING._1}`,
              borderRadius: RADIUS.sm,
            }}
          >
            <>
              {physicalAddress ? (
                <STypography
                  component={'div'}
                  variant="body_semibold"
                  sx={{ color: COLORS.TEXT_GREY_DARK }}
                >
                  {physicalAddress}
                </STypography>
              ) : null}
              {distance ? (
                <STypography
                  component={'div'}
                  variant="bodysmall_regular"
                  sx={{ color: COLORS.TEXT_GREY_DARK }}
                >
                  {distance}
                </STypography>
              ) : null}
              {onClickFindAnotherLocation !== undefined ? (
                <Link
                  component="button"
                  onClick={onClickFindAnotherLocation}
                  sx={{ marginTop: SPACING._05 }}
                >
                  <STypography component={'div'} variant="bodysmall_semibold">
                    {findAnotherLocationText}
                  </STypography>
                </Link>
              ) : null}
            </>
          </Grid>
        ) : null}
        {isOnline ? (
          <Grid
            item
            sx={{
              backgroundColor: COLORS.BACKGROUND_GREY_LIGHTER,
              padding: `${SPACING._05} ${SPACING._1}`,
              borderRadius: RADIUS.sm,
            }}
          >
            <STypography
              component={'div'}
              variant="body_semibold"
              sx={{ color: COLORS.TEXT_GREY_DARK }}
            >
              {buyOnlineText}
            </STypography>
          </Grid>
        ) : null}
        {isAnyPharmacy ? (
          <Grid item>
            <STypography
              variant="bodysmall_regular"
              sx={{ color: COLORS.TEXT_GREY_MEDIUM }}
            >
              {anyPharmacyDisclaimer}
            </STypography>
          </Grid>
        ) : null}
        {pricingShipAndTaxNote && (
          <Box
            sx={{
              backgroundColor: COLORS.BACKGROUND_GREY_LIGHTER,
              padding: `${SPACING._05} ${SPACING._1}`,
              borderRadius: RADIUS.sm,
            }}
          >
            <STypography
              variant="bodysmall_semibold"
              sx={{ color: COLORS.TEXT_GREY_MEDIUM }}
            >
              {pricingShipAndTaxNote}
            </STypography>
          </Box>
        )}
        {priceCash !== undefined && (
          <Box
            sx={{
              backgroundColor: COLORS.BACKGROUND_GREY_LIGHTER,
              padding: `${SPACING._05} ${SPACING._1}`,
              borderRadius: RADIUS.sm,
            }}
          >
            <STypography
              variant="bodysmall_semibold"
              sx={{ color: COLORS.TEXT_GREY_MEDIUM }}
            >
              Remember that prescriptions purchased with cash do not use your
              insurance, so they don’t count towards your deductible.
            </STypography>
          </Box>
        )}
      </Grid>
    </CardContainer>
  )
}

export interface PharmacyListCardItemProps {
  dataTestId: string
  pharmacyName?: string
  pharmacyLogoUrl?: string
  tags?: React.ReactNode

  strategy?: SavingStrategy

  isAnyPharmacy?: boolean
  isMailOrder?: boolean
  isCashPharmacy?: boolean
  isCoupon?: boolean
  is90DaysSupply?: boolean

  upToPrefix: string
  upToAmount: string
  upToSuffix: string

  pricingShipAndTaxNote?: string

  pharmacyType?: PharmacyType

  drugName: string
  drugDosage: string
  drugForm: string
  drugCount: string

  onClick?: () => void
  isWalmartVariantFor49Strategy?: boolean
}

export function PharmacyListCardItem(props: PharmacyListCardItemProps) {
  const { COLORS } = useSColors()

  const {
    pharmacyName,
    pharmacyLogoUrl,
    tags,
    strategy,
    isAnyPharmacy = false,
    isCoupon = false,
    isCashPharmacy = false,
    isMailOrder = false,
    is90DaysSupply = false,
    upToPrefix,
    upToAmount,
    upToSuffix,
    pricingShipAndTaxNote,
    pharmacyType,
    drugName,
    drugDosage,
    drugForm,
    drugCount,
    onClick,
    isWalmartVariantFor49Strategy,
    dataTestId,
  } = props
  //
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)

  function getLogoElement(): React.ReactElement | undefined {
    if (pharmacyLogoUrl && pharmacyName) {
      return (
        <img
          src={pharmacyLogoUrl}
          alt={`${pharmacyName} logo`}
          style={{ height: 32, width: 'auto' }}
        />
      )
    } else if (isAnyPharmacy) {
      return (
        <Grid
          container
          item
          alignItems="center"
          gap={SPACING._05}
          flexWrap={'nowrap'}
        >
          <Grid item>
            <ThemedSVGRemote
              importUrl={preferredPharmacyLogoImgSrc}
              alt={
                isWalmartVariantFor49Strategy
                  ? `Walmart pharmacy`
                  : `your in-network retail pharmacy`
              }
              width={32}
              height={32}
              // style={{
              //   height: 32,
              //   width: 'auto',
              // }}
            />
            {/* <img
              src={preferredPharmacyImage}
              alt={
                isWalmartVariantFor49Strategy
                  ? `Walmart pharmacy`
                  : `your in-network retail pharmacy`
              }
              style={{
                height: 32,
                width: 'auto',
              }}
            /> */}
          </Grid>
          <Grid item>
            <STypography
              variant="bodysmall_regular"
              sx={{ color: COLORS.TEXT_GREY_MEDIUM }}
            >
              {isWalmartVariantFor49Strategy
                ? 'Go to Walmart pharmacy'
                : 'Go to your in-network retail pharmacy*'}
            </STypography>
          </Grid>
        </Grid>
      )
    } else if (isCoupon) {
      return (
        <Grid
          container
          item
          alignItems="center"
          gap={SPACING._05}
          flexWrap={'nowrap'}
        >
          <Grid item>
            <ThemedSVGRemote
              importUrl={couponIconImgSrc}
              alt={`coupon icon`}
              width={32}
              height={32}
              // style={{
              //   height: 32,
              //   width: 'auto',
              // }}
            />
            {/* <img
              src={couponIcon}
              alt={`coupon icon`}
              style={{
                height: 32,
                width: 'auto',
              }}
            /> */}
          </Grid>
          <Grid item>
            <STypography
              variant="bodysmall_regular"
              sx={{ color: COLORS.TEXT_GREY_MEDIUM }}
            >
              Select pharmacy on next step
            </STypography>
          </Grid>
        </Grid>
      )
    }

    return undefined
  }

  function onInfoModalClose() {
    setIsInfoModalOpen(false)
  }

  return (
    <CardContainer sx={{ padding: SPACING._075 }}>
      <SavingMethodModal
        strategy={
          strategy ? strategy : isCoupon ? SavingStrategy.Coupons : undefined
        }
        open={isInfoModalOpen}
        onClose={onInfoModalClose}
        pharmacyType={
          pharmacyType ? pharmacyType : isAnyPharmacy ? 'RETAIL' : undefined
        }
        isMailOrder={isMailOrder}
        is90DaysSupply={is90DaysSupply}
        isCashPharmacy={isCashPharmacy}
      />
      <Grid
        item
        container
        direction="column"
        gap={VERTICAL_ITEMS_GAP}
        onClick={onClick}
        sx={{ cursor: 'pointer' }}
        data-testid={dataTestId}
      >
        <Grid item alignSelf={'start'} width={'100%'}>
          <Grid
            item
            container
            justifyContent={'space-between'}
            direction={'row'}
            width={'100%'}
          >
            <Grid item xs={9} sx={{ height: '32px' }}>
              {getLogoElement()}
            </Grid>
            <Grid item>
              <InfoIconButton
                onClick={(e: any) => {
                  setIsInfoModalOpen(true)
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        {pharmacyName ? (
          <Grid>
            <STypography
              variant="bodysmall_bold"
              sx={{
                color: COLORS.TEXT_GREY_DARK,
                paddingRight: SPACING._1,
              }}
            >
              {pharmacyName}
            </STypography>
          </Grid>
        ) : null}
        <Grid item container gap={SPACING._05} xs={6.5}>
          {tags}
        </Grid>
        <Grid item>
          <SaveUpTo
            upToPrefix={upToPrefix}
            upToAmount={upToAmount}
            upToSuffix={upToSuffix}
            isBestPrice={false}
            upToAmountTextVariant="bodysmall_bold"
          />
        </Grid>
        {pricingShipAndTaxNote && (
          <Grid item sx={{ marginTop: '-5px' }} spacing={SPACING._0}>
            <Typography sx={{ fontSize: '11px' }}>
              {pricingShipAndTaxNote}
            </Typography>
          </Grid>
        )}
        <Grid
          item
          sx={{
            backgroundColor: COLORS.BACKGROUND_GREY_LIGHTER,
            padding: `${SPACING._05} ${SPACING._05}`,
            borderRadius: RADIUS.sm,
          }}
        >
          <STypography variant="bodysmall_semibold">{drugName}</STypography>
          <DrugDetails
            drugDosage={drugDosage}
            drugForm={drugForm}
            drugCount={drugCount}
            textVariant="body_bold"
          />
        </Grid>
      </Grid>
    </CardContainer>
  )
}

export interface MyMedsListItemProps {
  drugName?: string

  drugDosage?: string
  drugForm?: string
  drugCount?: string

  isNotPrescribed?: boolean

  infoMenu: React.ReactNode

  hiddenReason?: string
  daysSupplied?: string
  refillDate?: string
  lastFilledDate?: string

  //possible tag labels to be applied to the item
  //should be rendred as <>{tags}</>
  tags?: React.ReactNode
}

export function MyMedsListItem({
  drugName,
  drugDosage,
  drugForm,
  drugCount,
  isNotPrescribed = false,
  infoMenu,
  hiddenReason,
  daysSupplied,
  refillDate,
  lastFilledDate,
  tags,
}: MyMedsListItemProps) {
  const { COLORS } = useSColors()

  const notPrescribedText = 'Not prescribed'
  const hiddenReasonText = 'Hidden reason'
  const daysSuppliedText = 'Days Supplied'
  const refillDateText = 'Refill Date'
  const lastFilledText = 'Last Filled'
  const naText = 'N/A'

  return (
    <CardContainer sx={{ border: 'none', padding: '16px' }}>
      <Box>
        <Grid container justifyContent={'space-between'} flexWrap={'nowrap'}>
          <Grid item>
            <STypography variant={'body_bold'}>{drugName}</STypography>
            {isNotPrescribed ? (
              <STypography variant={'bodysmall_semibold'}>
                {notPrescribedText}
              </STypography>
            ) : (
              <DrugDetails
                drugDosage={drugDosage}
                drugForm={drugForm}
                drugCount={drugCount}
              />
            )}
          </Grid>
          <Grid item>{infoMenu}</Grid>
        </Grid>

        <Grid
          container
          direction={'column'}
          sx={{ marginTop: SPACING._1, color: COLORS.TEXT_GREY_DARK }}
          gap={SPACING._025}
        >
          {hiddenReason ? (
            <Grid item>
              <STypography variant={'bodysmall_regular'}>
                {hiddenReasonText}: {hiddenReason}
              </STypography>
            </Grid>
          ) : null}
          <Grid item>
            <STypography variant={'bodysmall_regular'}>
              {daysSuppliedText}: {daysSupplied ?? naText}
            </STypography>
          </Grid>
          <Grid item>
            <STypography variant={'bodysmall_regular'}>
              {refillDateText}: {refillDate ?? naText}
            </STypography>
          </Grid>

          <Grid
            item
            container
            justifyContent={'space-between'}
            alignItems={'flex-start'}
            gap={SPACING._05}
          >
            <Grid item>
              <STypography variant={'bodysmall_regular'}>
                {lastFilledText}: {lastFilledDate ?? naText}
              </STypography>
            </Grid>
            <Grid item sx={{ marginTop: '-3.5px' }}>
              {tags}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </CardContainer>
  )
}

export interface MyMedsSavedMedsListItemProps {
  drugName: string
  drugDescription: string
  onBookMarkButtonClick: () => void
  onItemClick: () => void
}

export function MyMedsSavedMedsListItem({
  drugName,
  drugDescription,
  onBookMarkButtonClick,
  onItemClick,
}: MyMedsSavedMedsListItemProps) {
  const { COLORS } = useSColors()

  return (
    <CardContainer sx={{ padding: SPACING._1 }}>
      <Grid container direction={'column'} gap={SPACING._05}>
        <Grid
          item
          container
          justifyContent={'space-between'}
          alignItems={'center'}
          gap={SPACING._025}
          onClick={() => {
            if (onItemClick) {
              onItemClick()
            }
          }}
        >
          <Grid
            item
            onClick={() => {
              if (onItemClick) {
                onItemClick()
              }
            }}
          >
            <STypography variant="bodybig_bold">{drugName}</STypography>
          </Grid>
          <Grid item>
            <IconButton
              sx={{
                width: 32,
                height: 32,
                backgroundColor: COLORS.PRIMARY_10,
                borderRadius: '8px',
              }}
              onClick={(e) => {
                e.stopPropagation()
                onBookMarkButtonClick()
              }}
            >
              <FontAwesomeIcon
                icon={faBookmark}
                color={COLORS.PRIMARY_BRAND}
                width={'12px'}
              />
            </IconButton>
          </Grid>
        </Grid>
        <Grid
          item
          onClick={() => {
            if (onItemClick) {
              onItemClick()
            }
          }}
        >
          <STypography
            sx={{ whiteSpace: 'pre-wrap' }}
            variant="bodysmall_regular"
          >
            {drugDescription}
          </STypography>
        </Grid>
      </Grid>
    </CardContainer>
  )
}

export interface PaymentOptionStrategyListItemProps {
  variant:
    | 'cash_coupons'
    | 'cash_pharmacies'
    | 'insurance_stay_pharmacy'
    | 'insurance_change_pharmacy'
  dataTestId: string
  isBestPrice?: boolean
  upToAmount: string
  onClick?: () => void
}

export function PaymentOptionStrategyListItem({
  variant,
  dataTestId,
  isBestPrice = false,
  upToAmount,
  onClick,
}: PaymentOptionStrategyListItemProps) {
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const { featureConfig } = useMemberAppContext()

  useEffect(() => {
    if (
      featureConfig &&
      featureConfig.isInitialized &&
      featureConfig.isCouponDisabled &&
      variant === 'cash_coupons'
    ) {
      LOG.warn(
        'coupons',
        'PaymentOptionStrategyListItem is rendered as coupon option even though feature is disabled',
      )
    }
  }, [featureConfig])

  function getUpToSuffix(): string {
    switch (variant) {
      case 'cash_coupons':
        return 'by using a coupon'
      case 'cash_pharmacies':
        return 'by changing to a cash price pharmacy'
      case 'insurance_stay_pharmacy':
        return 'staying on your pharmacy'
      case 'insurance_change_pharmacy':
        return 'changing your pharmacy'
      default:
        return ''
    }
  }

  function getTags(): React.ReactNode[] {
    const tags: React.ReactNode[] = []

    if (isBestPrice) {
      tags.push(
        <TagLabel
          key="best_price"
          size="small"
          color="green"
          text="Best Price"
        />,
      )
    }

    switch (variant) {
      case 'cash_coupons':
        tags.push(
          <TagLabel key="coupon" size="small" color="blue" text="Coupon" />,
        )
        break
      case 'cash_pharmacies':
        tags.push(
          <TagLabel
            key="change_pharmacy"
            size="small"
            color="blue"
            text="Pharmacy Change"
          />,
        )
        break
      case 'insurance_stay_pharmacy':
        break
      case 'insurance_change_pharmacy':
        tags.push(
          <TagLabel
            key="change_pharmacy"
            size="small"
            color="blue"
            text="Pharmacy Change"
          />,
        )
        break
    }

    return tags
  }

  function onInfoModalClose() {
    setIsInfoModalOpen(false)
  }

  return (
    <CardContainer sx={{ padding: SPACING._075, cursor: 'pointer' }}>
      {variant == 'cash_coupons' ? (
        <SavingMethodModal
          // variant="coupon"
          strategy={SavingStrategy.Coupons}
          open={isInfoModalOpen}
          onClose={onInfoModalClose}
        />
      ) : variant == 'cash_pharmacies' ? (
        <ModalPharmacyTypeInfo
          isCashPharmacy={true}
          open={isInfoModalOpen}
          onClose={onInfoModalClose}
        />
      ) : variant == 'insurance_change_pharmacy' ? (
        <ModalPharmacyChange
          open={isInfoModalOpen}
          onClose={onInfoModalClose}
        />
      ) : variant == 'insurance_stay_pharmacy' ? (
        <ModalPharmacyStay open={isInfoModalOpen} onClose={onInfoModalClose} />
      ) : null}
      <Grid
        container
        gap={SPACING._05}
        onClick={onClick}
        data-testid={dataTestId}
      >
        <Grid item xs>
          <Grid container gap={SPACING._05} flexWrap={'nowrap'}>
            <Grid
              item
              sx={{ marginLeft: '-4px', width: '40px', height: '40px' }}
            >
              {variant === 'cash_coupons' ? (
                <ThemedSVGRemote
                  importUrl={couponsStrategyImgSrc}
                  width={40}
                  height={40}
                  alt="Coupons"
                />
              ) : (
                // <CouponsStrategyIcon width={40} height={40} />
                // <img src={PharmacySwitchIcon} alt="pharmacy switch" width={72} />
                <ThemedSVGRemote
                  importUrl={pharmacySwitchIconImgSrc}
                  width={40}
                  height={40}
                  alt="Pharmacy Switch"
                />

                // <ThemedSVG
                //   SVGComponent={PharmacyChangeIcon}
                //   width={40}
                //   height={40}
                //   alt="Pharmacy Change"
                // />
                // <PharmacyChangeIcon width={40} height={40} />
              )}
            </Grid>
            <Grid item alignSelf={'center'} gap={SPACING._05}>
              {getTags().length > 0 ? (
                <Grid
                  container
                  gap={SPACING._025}
                  sx={{ marginBottom: SPACING._05 }}
                >
                  {getTags()}
                </Grid>
              ) : null}
              <SaveUpTo
                upToPrefix={'Save up to'}
                upToAmount={upToAmount}
                upToSuffix={getUpToSuffix()}
                isBestPrice={isBestPrice}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <InfoIconButton
            onClick={(e: any) => {
              e.stopPropagation()
              setIsInfoModalOpen(true)
            }}
          />
        </Grid>
      </Grid>
    </CardContainer>
  )
}

export interface TAGS {
  //NOTE: these tags should be rendered in order listed here
  //the calling co
  tagWithStrategy?: boolean //if true, use strategy as tag label
  tagAsBestPrice?: boolean
  tagAsCoupon?: boolean
  tagAsAnyPharmacy?: boolean
  tagAsPharmacySwitch?: boolean
  tagAsOnlinePharmacy?: boolean
  tagAsCashPricePharmacy?: boolean
  tagWithNoNewRxNeeded?: boolean
}
