import Box from '@mui/material/Box'
import { useState, useEffect } from 'react'
import { useMemberAppContext } from 'src/app/MemberAppContext'
import { IS_PROD } from 'src/app/apiexec/utils'
import {
  getMemberTrackingDataService,
  getPreferencesService,
} from 'src/app/member/getPreferencesService'
import { GRADIENT_LONG_SCRIPTA } from 'src/app/styles/theme-config-scripta'
import { SDialog } from 'src/app/system/customcomponents/SDialog'
import STypography from 'src/app/system/customcomponents/STypography'
import { SPACING } from 'src/app/system/theme2'
import { LOG } from 'src/app/v2/applog'

const NON_PROD_SURVEY_LINK = 'https://survey.zohopublic.com/zs/WKDHg6'
const PROD_SURVEY_LINK = 'https://survey.zohopublic.com/zs/ZGDH5S'

export default function ZohoSurveyContainer() {
  const SURVEY_LINK = IS_PROD() ? PROD_SURVEY_LINK : NON_PROD_SURVEY_LINK
  const { currentMemberProfile, savingReport, jwtDetails } =
    useMemberAppContext()
  const [isSalesIqSurveyOpen, setIsSalesIqSurveyOpen] = useState(false)
  const [isSalesIqReady, setIsSalesIqReady] = useState(false)
  const [visitId, setVisitId] = useState<number | undefined>()

  useEffect(() => {
    const checkZoho = () => {
      try {
        const zohoValue = (window as any).$zoho

        if (zohoValue && zohoValue.salesiq && zohoValue.salesiq.chat) {
          setIsSalesIqReady(true)
          clearInterval(intervalId)
        }
      } catch (error) {
        LOG.error('salesiq', 'Zoho chat ready check error: ', error)
      }
    }

    const intervalId = setInterval(checkZoho, 500)

    return () => clearInterval(intervalId)
  }, [])

  useEffect(() => {
    try {
      const zohoValue = (window as any).$zoho
      if (zohoValue && zohoValue.salesiq && isSalesIqReady) {
        zohoValue.salesiq.chat.complete(function (visitId: number, data: any) {
          const convertedVisitId = Number(visitId)
          if (!isNaN(convertedVisitId)) {
            setVisitId(convertedVisitId)
          } else {
            LOG.warn('salesiq', 'Invalid visitId: ', visitId)
          }
          setIsSalesIqSurveyOpen(true)
        })
      }
    } catch (error) {
      LOG.error('salesiq', 'Zoho chat complete check error: ', error)
    }
  }, [isSalesIqReady])

  function addMemberInfoIfAvail(surveyUrl: string) {
    const { getMemberTrackingData } = getMemberTrackingDataService()
    const memberTrackingData = getMemberTrackingData()
    let newUrl = surveyUrl

    // Not logged in  = has visit id and no mem profile
    // Logged in = has visit id and mem profile
    // checking for visitId before adding is just for Typescript safety, should always be
    // defined at this point of the code
    if ((currentMemberProfile && savingReport) || memberTrackingData) {
      let memberId
      let clientId
      let email
      let firstName
      let lastName
      let companyName

      if (currentMemberProfile && savingReport) {
        memberId = currentMemberProfile.id
        clientId = jwtDetails?.tenant_key
        email = currentMemberProfile.email
        firstName = currentMemberProfile['first-name']
        lastName = currentMemberProfile['last-name']
        companyName = jwtDetails?.company_name_key
      } else if (memberTrackingData) {
        memberId = memberTrackingData?.memberId
        clientId = memberTrackingData?.tenantId
        firstName = memberTrackingData?.firstName
        lastName = memberTrackingData?.lastName
        companyName = memberTrackingData?.tenantName
      }

      newUrl = `${surveyUrl}?memId=${memberId}&clientId=${clientId}&firstName=${firstName}&lastName=${lastName}&companyName=${companyName}`

      if (email) {
        newUrl += `&email=${email}`
      }

      if (typeof visitId === 'number' && !isNaN(visitId)) {
        newUrl += `&visitId=${visitId}`
      }
    } else if (typeof visitId === 'number' && !isNaN(visitId)) {
      newUrl = `${surveyUrl}?visitId=${visitId}`
    }

    return newUrl
  }

  return (
    <SDialog
      open={isSalesIqSurveyOpen}
      onClose={() => {
        setIsSalesIqSurveyOpen(false)
      }}
      customContentSx={{ overflow: 'hidden', padding: '0px', margin: '0px' }}
      customPaperSx={{
        padding: 0,
        paddingBottom: '0px',
      }}
      customXButtonSx={{ color: 'white' }}
      headerContent={
        <Box
          sx={{
            background: GRADIENT_LONG_SCRIPTA,
            color: 'white',
            padding: SPACING._1,
            textAlign: 'center',
            paddingRight: '50px',
          }}
        >
          <STypography variant="title3_regular">
            We care what you think! Your insights help us better support you
          </STypography>
        </Box>
      }
    >
      <iframe
        src={addMemberInfoIfAvail(SURVEY_LINK)}
        height={'100%'}
        style={{
          position: 'relative',
          height: '80vh',
          width: '100%',
          border: 'none',
        }}
      >
        Browser not compatible.
      </iframe>
    </SDialog>
  )
}
