import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { MemberSavingReport } from '../../types'
import {
  checkMicroServiceResponseStatus,
  parseUiError,
  roundAllSavingsAmountsToTwoDecimals,
} from '../apiexec/utils'
import { useMemberService } from '../member/useMemberService'
import {
  isProvider,
  JwtDetails,
  useMemberAppContext,
} from '../MemberAppContext'
import { ROUTE_APP_ROOT, ROUTE_PROVIDER_ROOT } from '../PortalRoutes'

import { Box, CircularProgress, Grid } from '@mui/material'
import { usePortalAppConfig } from 'src/app/config/usePortalAppConfig'
import STypography from 'src/app/system/customcomponents/STypography'
import { LOG } from '../v2/applog'
import { ErrorCardUnknownLogin } from 'src/app/system/error/BaseErrorCard'
import { EKS_CODE_NO_SAVINGS_REPORT_FOR_MEMBER } from 'src/app/EKSStatuCodes'
import { EKSMemberProfile } from 'src/app/member/types'

export function CompleteLoginHandler() {
  const history = useHistory()
  const PORTAL_CONFIG = usePortalAppConfig()

  const {
    jwt,
    setCurrentMemberProfile,
    setSavingReport,
    jwtDetails,
    isSsoLogin,
    mainDataLoading,
    setMainDataLoading,
  } = useMemberAppContext()
  const memberService = useMemberService()

  // const [mainDataLoading, setMainDataLoading] = useState(false)
  const [mainDataLoadError, setMainDataLoadError] = useState(false)

  //WARNING - please node that this function also calls the asyncLoadSavingsReport function where we fetch additional
  //details from the microservice, and update the current member profile with the latest zip code
  //TODO - also update here to use additional fields like marketing preferences etc
  async function asyncLoadProfileAndMemberReport() {
    setMainDataLoading(true)
    try {
      if (!jwtDetails?.id_key) {
        LOG.error(
          'registration',
          'FATAL error - no member id key found in the JWT details',
        )
        setMainDataLoading(false)
        return
      }
      const eksMemberProfileResp = await memberService.getMemberProfile_EKS(
        jwtDetails?.id_key,
      )

      const profileCheck = checkMicroServiceResponseStatus(eksMemberProfileResp)

      if (profileCheck.isError) {
        setMainDataLoadError(true)
        //custom event handler for sso related users
        if (isSsoLogin) {
          LOG.error(
            'sso',
            'server error response, Unable to fetch member profile for the incoming sso user,resp=',
            eksMemberProfileResp,
          )
        } else {
          //cannot fetch member profile - but not sso related
          LOG.error(
            'registration',
            'server error response Unable to fetch member profile for the incoming user',
            eksMemberProfileResp,
          )
        }
        //do not push to error handler anymore - these are core errors to be handled here
        // history.push(REG_ROUTE_SERVER_ERROR)
        setMainDataLoading(false)
        return
      }

      setCurrentMemberProfile(eksMemberProfileResp)

      LOG.initMember(Number(jwtDetails.id_key), Number(jwtDetails.tenant_key))
      await asyncLoadSavingsReportAndSetReportTypeFromProfile(
        jwtDetails,
        eksMemberProfileResp,
      )
    } catch (e) {
      setMainDataLoading(false)
      setMainDataLoadError(true)
      let errorDetails =
        e && (e as any).message !== undefined ? (e as any).message : undefined
      const logMessage = isSsoLogin
        ? 'Network / server level error in asyncLoadSavingsReportAndSetReportTypeFromProfile execution for SSO user. Request timed out / failed to reach the API server.'
        : 'Network / server level error in apasyncLoadSavingsReportAndSetReportTypeFromProfilei execution. Request timed out / failed to reach the API server.'
      if (isSsoLogin) {
        LOG.error('sso', logMessage, errorDetails)
      } else {
        LOG.error('registration', logMessage, errorDetails)
      }
    }
  }

  async function asyncInitProviderProfile() {
    //TODO - init the provider profile
    history.push(ROUTE_PROVIDER_ROOT)
  }

  async function asyncLoadSavingsReportAndSetReportTypeFromProfile(
    jwtDetails: JwtDetails,
    currentMemberProfile: EKSMemberProfile,
  ) {
    try {
      // const resp = await memberService.getMemberSavingsReportApi()
      const resp = await memberService.getMemberSavingsReport_EKS(
        jwtDetails.id_key,
      )
      const respCheck = checkMicroServiceResponseStatus(resp)

      // if (isValidResponse<MemberSavingReport>(resp)) {
      if (!respCheck.isError) {
        //update to amounts to two rounded decimal places

        let roundedUpReport = roundAllSavingsAmountsToTwoDecimals(resp)
        roundedUpReport.reportType = currentMemberProfile['plan-type']
        setSavingReport(roundedUpReport)

        setMainDataLoading(false)
        setMainDataLoadError(false)

        // setCurrentMemberProfile(memberProfileWithReportAndZip)

        history.push(ROUTE_APP_ROOT)
      } else {
        //for new EKS apis - the 404 error code + scriptaErrorCode 234 = valid no savings case
        if (
          resp &&
          (resp as any).httpStatusCode === 404 &&
          (resp as any).scriptaErrorCode ===
            EKS_CODE_NO_SAVINGS_REPORT_FOR_MEMBER
        ) {
          console.log('user with no savings')
          const inMemoryZeroSavingsReport: MemberSavingReport = {
            member: currentMemberProfile,
            maxSavings: 0,
            reportType: currentMemberProfile['plan-type'],
          }
          setSavingReport(inMemoryZeroSavingsReport)
          setMainDataLoading(false)
          setMainDataLoadError(false)
          history.push(ROUTE_APP_ROOT)
        } else {
          setMainDataLoadError(true)

          //custom event handler for sso related users
          if (isSsoLogin) {
            LOG.error(
              'sso',
              'Unable to fetch member savings report for the sso user',
              resp,
            )
          } else {
            //cannot fetch savings profile - but not sso related
            LOG.error(
              'saving_report_data',
              'Unable to fetch savings report data for the member',
              resp,
            )
          }

          setMainDataLoading(false)
          setMainDataLoadError(true)
        }
      }
    } catch (e) {
      setMainDataLoading(false)
      setMainDataLoadError(true)
      LOG.error(
        'saving_report_data',
        'error in complete login - load savings report handler',
        parseUiError(e),
      )
    }
  }

  useEffect(() => {
    //this may happen in case of sso login
    if (jwtDetails === undefined) {
      return
    }
    if (isProvider(jwtDetails)) {
      asyncInitProviderProfile()
    } else {
      asyncLoadProfileAndMemberReport()
    }
  }, [jwtDetails])

  //add a custom loader for sso users because we dont have a normal login component
  if (
    mainDataLoading &&
    isSsoLogin &&
    //only show this additional loader for those who have  been registered
    //the other flow - where we create a new user will end up showing the normal loader, so this is to prevent double loader
    jwtDetails !== undefined &&
    jwtDetails.registered_key === true
  ) {
    return (
      <Box sx={{ padding: '50px' }}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            <CircularProgress variant="indeterminate" size={30} />
          </Grid>
          <Grid item xs={12}>
            <STypography
              variant="body_regular"
              sx={{ textAlign: 'center', paddingTop: '20px' }}
            >
              Please wait, loading your savings report....
            </STypography>
          </Grid>
        </Grid>
      </Box>
    )
  }

  //add a custom error handler for sso users
  if (mainDataLoadError) {
    return (
      <Box
        sx={{
          height: '95vh',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
        }}
      >
        <Box>
          <ErrorCardUnknownLogin />
        </Box>
      </Box>
    )
  }
  return null
}
