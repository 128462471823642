import { useEffect, useState } from 'react'
import { useMemberAppContext } from '../MemberAppContext'
import { usePortalThemeContext } from 'src/app/styles/PortalThemeContext'
import { useRegistration } from 'src/app/registration/RegistrationContext'
//should we rely on hooks? or just get it via vindow?
//probably both bc for ex. sso error case will not know jwtdetails

const AMAZON_INT_UUID = '671c2967-8ccf-4c06-b391-c93f3ff453b1'
const UAT_MIRZAS_INT_UUID = '3e4bccf8-acec-412e-88ce-212d3ce2c3b6'
const AMAZON_PROD_UUID = 'f8024918-cdbe-4e17-98d4-d73d66307d40'
const AMAZON_MOCK_UUID = 'd26e1c37-97cd-4031-9917-57efae38f020'
export function useAmazon() {
  const { jwtDetails, isAmazon, setIsAmazon } = useMemberAppContext()
  const { setSelectedThemeByName } = usePortalThemeContext()
  //processed in first page of registration
  const { matchedTenant } = useRegistration()
  useEffect(() => {
    if (
      matchedTenant?.clientUuid &&
      (matchedTenant.clientUuid === AMAZON_INT_UUID ||
        matchedTenant.clientUuid === AMAZON_PROD_UUID ||
        matchedTenant.clientUuid === AMAZON_MOCK_UUID ||
        matchedTenant.clientUuid === UAT_MIRZAS_INT_UUID)
    ) {
      setIsAmazon(true)
      setSelectedThemeByName('amazon-blue')
    }
  }, [matchedTenant])

  useEffect(() => {
    if (
      jwtDetails &&
      jwtDetails.tenant_short_code_key &&
      (jwtDetails.tenant_short_code_key.indexOf('amazon') > -1 ||
        jwtDetails.tenant_short_code_key.indexOf('amzn') > -1 ||
        jwtDetails.tenant_short_code_key.indexOf('uatmirzas') > -1)
    ) {
      setIsAmazon(true)
      setSelectedThemeByName('amazon-blue')
    }
  }, [jwtDetails])

  //can be set by saml login based on passed in short code
  useEffect(() => {
    if (isAmazon) {
      setSelectedThemeByName('amazon-blue')
    }
  }, [isAmazon])

  return { isAmazon, setIsAmazon }
}
