import {
  Checkbox,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  List,
  ListItem,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useNotifier } from 'src/app/hooks/useNotify'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { checkMicroServiceResponseStatus } from '../apiexec/utils'
import { GAService } from '../application/ga/GAService'
import { useConfigService } from '../config/useConfigService'
import { useMemberAppContext } from '../MemberAppContext'
import { LOG } from '../v2/applog'
import { All3LicensesAgreement } from './All3Licenses'
import { CustomTextField } from './components/CustomTextField'
import { RegistrationStepCard } from './components/RegistrationStepCard'
import { checkPasswordMatchesRequirements } from './PreReg'
import { RegistrationFormFieldsBox } from './RegistrationContainer'
import { useRegistration } from './RegistrationContext'
import {
  REG_ROUTE_EDIT_CONTACT_INFO_EKS,
  REG_ROUTE_LOGIN,
  REG_ROUTE_IDENTITY_VALIDATION_EKS,
} from './RegistrationSubRoutes'
import { useRegistrationService } from './useRegistrationService'
import { WelcomePreregDialog } from './WelcomePreregDialog'
import STypography from 'src/app/system/customcomponents/STypography'
import { useSColors } from 'src/app/styles/scripta-theme'
import SButton from 'src/app/system/customcomponents/SButton'
import { EKS_CREATE_PROFILE_MINOR_NOT_ALLOWED } from 'src/app/EKSStatuCodes'
export type CreateProfileFieldErrors = {
  emailAlreadyUsed: boolean
  phoneAlreadyUsed: boolean
  usernameAlreadyUsed: boolean
  registrationJwtExpired: boolean
  minorNotAllowed: boolean
}
export function CreateProfileEKS() {
  const gaService = new GAService()

  const { setRenderDepInviteUponLoginAfterRegistration } = useMemberAppContext()

  const [userName, setUserName] = useState('')
  const [isUsernameError, setIsUserNameError] = useState(false)
  const [isUsernameTouched, setIsUsernameTouched] = useState(false)
  const [usernameErrorMsg, setUsernameErrorMsg] = useState('')

  const [password, setPassword] = useState('')
  const [isPasswordError, setIsPasswordError] = useState(false)
  const [isPasswordTouched, setIsPasswordTouched] = useState(false)
  const [passwordErrorMsg, setPasswordErrorMsg] = useState('')

  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [isPasswordConfirmTouched, setIsPasswordConfirmTouched] =
    useState(false)

  const [acceptedAll3, setAcceptedAll3] = useState(false)

  const configService = useConfigService()
  const [isMainSubmitDisabled, setIsMainSubmitDisabled] =
    useState<boolean>(true)

  const [profileCreated, setProfileCreated] = useState(false)

  //main button effect to determine if enabled
  useEffect(() => {
    if (
      password &&
      passwordConfirm &&
      userName &&
      acceptedAll3 &&
      !isPasswordError &&
      !isUsernameError
    ) {
      setIsMainSubmitDisabled(false)
    } else {
      setIsMainSubmitDisabled(true)
    }
  }, [password, userName, acceptedAll3, isPasswordError, isUsernameError])

  const { t } = useSTranslate(['common', 'settings', 'register'])
  const theme = useTheme()
  const isMedium = useMediaQuery(theme.breakpoints.down('md'))
  //need a bit smaller font size for showing the 3 licenses text in very small screens
  const isExtraSmall = useMediaQuery(theme.breakpoints.down(360))
  const { COLORS } = useSColors()

  useEffect(() => {
    document.title = 'Create Profile - Scripta Insights'
  }, [])

  const {
    history,
    setApiResponse,
    member,
    registrationJwt,
    eksContactEmail,
    eksContactPhone,
    eksContactSmsOptIn,
    matchedTenant,
    eksMember,
  } = useRegistration()
  const { setClientUuidInRegistration } = useMemberAppContext()

  const { sendMsg } = useNotifier()

  const service = useRegistrationService()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  const [missingRegistrationJwt, setMissingRegistrationJwt] = useState(false)
  const [nextLoading, setNextLoading] = useState(false)

  const [errorCreatingProfile, setErrorCreatingProfile] = useState(false)

  const [createProfileFieldErrors, setCreateProfileFieldErrors] = useState<
    CreateProfileFieldErrors | undefined
  >(undefined)

  function checkResponseErrorFields(
    createProfileResp: any,
  ): CreateProfileFieldErrors {
    // Initialize flags for each field
    let emailAlreadyUsed = false
    let phoneAlreadyUsed = false
    let usernameAlreadyUsed = false
    let registrationJwtExpired = false
    let minorNotAllowed = false

    // Check if createProfileResp contains fieldErrors
    if (
      createProfileResp &&
      createProfileResp['fieldErrors'] &&
      createProfileResp['fieldErrors'].length > 0
    ) {
      // Check if any fieldError contains 'email'
      emailAlreadyUsed = createProfileResp['fieldErrors'].some(
        (fe: any) => fe.propertyName && fe.propertyName.indexOf('email') !== -1,
      )
      // Check if any fieldError contains 'phone'
      phoneAlreadyUsed = createProfileResp['fieldErrors'].some(
        (fe: any) => fe.propertyName && fe.propertyName.indexOf('phone') !== -1,
      )
      // Check if any fieldError contains 'userName'
      usernameAlreadyUsed = createProfileResp['fieldErrors'].some(
        (fe: any) =>
          fe.propertyName && fe.propertyName.indexOf('userName') !== -1,
      )
      // Check if any fieldError contains 'expired session'
      registrationJwtExpired = createProfileResp['fieldErrors'].some(
        (fe: any) =>
          fe.propertyName &&
          fe.propertyName.indexOf('registration-token') !== -1,
      )
    }

    if (
      createProfileResp['scriptaErrorCode'] ===
      EKS_CREATE_PROFILE_MINOR_NOT_ALLOWED
    ) {
      minorNotAllowed = true
    }

    return {
      usernameAlreadyUsed,
      emailAlreadyUsed,
      phoneAlreadyUsed,
      registrationJwtExpired,
      minorNotAllowed,
    }
  }

  function isKnownFieldError(
    fieldError: CreateProfileFieldErrors | undefined,
  ): boolean {
    if (!fieldError) {
      return false
    }
    return (
      fieldError.emailAlreadyUsed ||
      fieldError.phoneAlreadyUsed ||
      fieldError.usernameAlreadyUsed ||
      fieldError.registrationJwtExpired ||
      fieldError.minorNotAllowed
    )
  }

  async function asyncCreateProfile_EKS({
    registrationJwt,
    userName,
    password,
    email,
    phone,
    smsOptInt,
  }: {
    registrationJwt?: string
    userName: string
    password: string
    email: string
    phone?: string
    smsOptInt?: boolean
  }) {
    setNextLoading(true)
    //track if we are missing the registraiton JWT for whatever reason
    if (!registrationJwt) {
      LOG.error(
        'registration',
        'Missing expected registration JWT from context. Will have to reroute the user to revalidate the security."',
      )
      setMissingRegistrationJwt(true)
      setErrorCreatingProfile(true)
      setNextLoading(false)
      return
    }
    try {
      const resp = await service.createRegistration_EKS({
        registrationJwt,
        userName,
        password,
        email,
        phone,
        smsOptInt,
      })
      const allowUndefined = true
      const rspCheck = checkMicroServiceResponseStatus(resp, allowUndefined)
      setNextLoading(false)

      if (!rspCheck.isError) {
        setErrorCreatingProfile(false)
        //profile created ok
        console.log('profile created ok')
        //add a flag so we show deps invite once the user logs in after registration
        setRenderDepInviteUponLoginAfterRegistration(true)

        //dont push immediatelly, tell user they are created and give link
        setProfileCreated(true)
      } else {
        setErrorCreatingProfile(true)
        LOG.error('registration', 'EKS Error creating member account.', resp)
        const fieldErrors = checkResponseErrorFields(resp)
        setCreateProfileFieldErrors(fieldErrors)
        return
      }
    } catch (e) {
      LOG.error(
        'registration',
        'Error cought in asyncCreateProfile execution',
        e,
      )
      setNextLoading(false)
      setErrorCreatingProfile(true)
    }
  }

  //handle the case where the registration JWT is expired, they need to go back to the security question screen....
  if (
    createProfileFieldErrors &&
    createProfileFieldErrors.registrationJwtExpired
  ) {
    return (
      <Grid container sx={{ padding: '20px' }}>
        <Grid item xs={12}>
          <STypography variant="bodybig_bold" sx={{ textAlign: 'center' }}>
            The registration session has expired.
          </STypography>
        </Grid>
        <Grid item xs={12} sx={{ padding: '20px' }}>
          <STypography
            variant="bodybig_regular"
            sx={{ textAlign: 'center', color: 'red' }}
          >
            This identity validation has expired. You must validate your
            identity again.
          </STypography>
        </Grid>
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          sx={{ padding: '20px' }}
        >
          <SButton
            onClick={() => {
              setCreateProfileFieldErrors(undefined)
              history.push(REG_ROUTE_IDENTITY_VALIDATION_EKS)
            }}
          >
            Click here to validate your identity again.
          </SButton>
        </Grid>
      </Grid>
    )
  }

  if (profileCreated) {
    return (
      <WelcomePreregDialog
        onGoHomeFromThankYouClicked={() => {
          //wipe out the client uuid to remove the logo from the client container
          //TODO - maybe we should do this on the login screen
          const autoLogin: any = {
            justRegisteredUserName: userName,
            justRegisteredPassword: password,
          }
          setClientUuidInRegistration(undefined)
          history.push(REG_ROUTE_LOGIN, { autoLogin: autoLogin })
        }}
        mode="register"
      />
    )
  }

  return (
    <RegistrationStepCard
      registrationType="registration"
      progressBarStep={6}
      // message={t('createAUsernameDescription')}
      nextButtonTitle={t('register:btnSubmit')}
      cancelButtonTitle={t('common:back')}
      onClickNextButton={() => {
        //add checks here for missing values
        if (!eksContactEmail) {
          //TODO - show error message
          return
        }

        asyncCreateProfile_EKS({
          registrationJwt,
          userName,
          password,
          email: eksContactEmail,
          phone: eksContactPhone,
          smsOptInt: eksContactSmsOptIn,
        })
      }}
      onClickCancelButton={() => history.push(REG_ROUTE_EDIT_CONTACT_INFO_EKS)}
      nextButtonDisabled={isMainSubmitDisabled}
      nextLoading={nextLoading}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography
            data-testid="create-profile-page-title"
            sx={{
              textAlign: 'center',
              fontSize: isSmall ? '1.125rem' : '1.5rem',
              fontWeight: 700,
              paddingBottom: '20px',
              paddingTop: '0px',
              // marginTop: isSmall ? '-15px' : '0px',
              lineHeight: '21.92px',
            }}
          >
            {t('register:createProfileStepTitle')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: isSmall ? '1rem' : '1.125rem',
              fontWeight: 300,
              lineHeight: '20px',
              textAlign: 'center',
              paddingLeft: isSmall ? '20px' : undefined,
              paddingRight: isSmall ? '20px' : undefined,
            }}
          >
            {t('register:createProfileStepDesc')}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ minHeight: '30px' }}></Grid>
      </Grid>
      <RegistrationFormFieldsBox>
        <Grid item xs={12}>
          <CustomTextField
            data-testid="create-profile-username-input"
            label={t('userName')}
            value={userName}
            onChange={(e) => {
              setIsUsernameTouched(true)
              setUserName(e.target.value)
              if (e.target.value.length < 8) {
                setIsUserNameError(true)
                setUsernameErrorMsg(
                  'Username must be at least 8 characters long',
                )
              } else {
                setIsUserNameError(false)
                setUsernameErrorMsg('')
              }
            }}
            error={isUsernameTouched && isUsernameError ? true : false}
            helperText={
              isUsernameTouched && isUsernameError ? usernameErrorMsg : ''
            }
            // error={userName.length < 8}
            // sx={{ marginBottom: 3.8 }}
          />
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '20px' }}>
          <CustomTextField
            data-testid="create-profile-password-input"
            label={t('password')}
            type={'password'}
            value={password}
            onChange={(e) => {
              setIsPasswordTouched(true)
              setPassword(e.target.value)
              if (!checkPasswordMatchesRequirements(e.target.value)) {
                setIsPasswordError(true)
                setPasswordErrorMsg('Password does not meet requirements')
              } else {
                setIsPasswordError(false)
                setPasswordErrorMsg('')
              }
            }}
            error={isPasswordTouched && isPasswordError ? true : false}
            helperText={
              isPasswordTouched && isPasswordError ? passwordErrorMsg : ''
            }
          />
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '20px' }}>
          <CustomTextField
            data-testid="create-profile-password-confirm-input"
            label={t('settings:confirmPassword')}
            value={passwordConfirm}
            // error={passwordsDontMatch}
            type={'password'}
            error={
              isPasswordConfirmTouched && password !== passwordConfirm
                ? true
                : false
            }
            helperText={
              isPasswordConfirmTouched && password !== passwordConfirm
                ? t('register:confirmPasswordNotMatched')
                : ''
            }
            onChange={(e) => {
              setIsPasswordConfirmTouched(true)
              setPasswordConfirm(e.target.value)
            }}
          />
        </Grid>
      </RegistrationFormFieldsBox>

      <Grid
        item
        container
        xs={12}
        sx={{ lineHeight: '15px', marginTop: '20px' }}
        justifyContent={!isExtraSmall ? 'center' : undefined}
        // sx={{ textAlign: 'left', display: 'flex', alignItems: 'center' }}
      >
        <Grid item xs="auto">
          <Checkbox
            data-testid="create-profile-all-3-licenses-checkbox"
            checked={acceptedAll3}
            onChange={(e) => {
              setAcceptedAll3(e.target.checked)
              // setRegForm({
              //   ...regForm,
              //   acceptedFinalTermsAndConditions: e.target.checked,
              // })
            }}
            sx={{
              //   float: 'left',
              fontSize: '1rem',
              padding: '2px 0px 0px 0px',
              margin: '2px',
              paddingRight: '5px',
              '& .MuiSvgIcon-root': { fontSize: 28 },
            }}
          />
        </Grid>
        <Grid
          item
          sx={{
            width: isSmall ? '260px' : '300px',
            minWidth: isSmall ? '260px' : '300px',
            maxWidth: isSmall ? '260px' : '300px',
          }}
        >
          <All3LicensesAgreement customFontSize={isSmall ? '11px' : '13px'} />
        </Grid>
        {errorCreatingProfile && (
          <Grid container>
            {/* if we have known field errors, show them to the user */}
            {createProfileFieldErrors &&
              isKnownFieldError(createProfileFieldErrors) && (
                <>
                  <Grid item xs={12} sx={{ marginTop: '20px' }}>
                    <STypography
                      variant="body_bold"
                      sx={{ color: COLORS.ERROR_RED, textAlign: 'center' }}
                    >
                      Unfortunately, we cannot create your account at the
                      moment. Please review the following issues and try again:
                    </STypography>
                    <ul
                      style={{
                        listStylePosition: 'inside',
                        textAlign: 'left',
                        color: COLORS.ERROR_RED,
                        paddingLeft: '20px',
                      }}
                    >
                      {createProfileFieldErrors.emailAlreadyUsed && (
                        <li>
                          <STypography
                            variant="body_regular"
                            component="span"
                            sx={{ color: COLORS.ERROR_RED }}
                          >
                            Email address is already in use.
                          </STypography>
                        </li>
                      )}
                      {createProfileFieldErrors.phoneAlreadyUsed && (
                        <li>
                          <STypography
                            variant="body_regular"
                            component="span"
                            sx={{ color: COLORS.ERROR_RED }}
                          >
                            Phone number is already in use.
                          </STypography>
                        </li>
                      )}
                      {createProfileFieldErrors.usernameAlreadyUsed && (
                        <li>
                          <STypography
                            variant="body_regular"
                            component="span"
                            sx={{ color: COLORS.ERROR_RED }}
                          >
                            Username is already in use.
                          </STypography>
                        </li>
                      )}
                      {createProfileFieldErrors.minorNotAllowed && (
                        <li>
                          <STypography
                            variant="body_regular"
                            component="span"
                            sx={{
                              color: COLORS.ERROR_RED,
                              whiteSpace: 'pre-line',
                            }}
                          >
                            {`Minor Logins are disabled for this client. Scripta complies with all HIPAA protections, state regulations, and client preferences. Therefore, due to privacy concerns, information may be limited for dependents under the age of 18 or minors may not be eligible for an electronic Scripta account.`}
                          </STypography>
                        </li>
                      )}
                    </ul>
                  </Grid>
                </>
              )}
            {/* if we have generic error, show the message to the user */}
            {!isKnownFieldError(createProfileFieldErrors) && (
              <Grid item xs={12} sx={{ marginTop: '20px' }}>
                <STypography
                  variant="body_bold"
                  sx={{ color: COLORS.ERROR_RED, textAlign: 'center' }}
                >
                  Unfortunately, we cannot create your account at the moment.
                  Please contact our member support for assistance.
                </STypography>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </RegistrationStepCard>
  )
}
