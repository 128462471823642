import { Box, Grid, Link } from '@mui/material'
import MemberSupportFooter from 'src/app/registration/MemberSupportFooter'
import CardContainer from 'src/app/system/CardContainer'
import STypography from 'src/app/system/customcomponents/STypography'
import { SPACING } from 'src/app/system/theme2'
import ssoDataNotFoundImg from '../images/sammy_line_service_support_3-5.svg'
import noConnectionImg from '../images/sammy_line_no_connection_1.svg'
import accountImg from '../images/account.svg'
import { GENERIC_SSO_UKNOWN_CLIENT } from 'src/app/sso/SamlLoginError'
import { MailOrder } from '../stories/PharmacyListCardItem.stories'
import {
  REG_ROUTE_FORGOT_USERNAME,
  REG_ROUTE_REGISTER_TENANT_ACCOUNT,
} from '../../registration/RegistrationSubRoutes'
import { useHistory } from 'react-router'
import { useMemberAppContext } from 'src/app/MemberAppContext'
import SButton from 'src/app/system/customcomponents/SButton'

export interface BaseErrorCardProps {
  headerImage?: string
  headerTitle: string
  content: any
  hideMemberSupportFooter?: boolean
}

// TODO: Revisit if the header image and title need to be client-specific themed
// it is currently hardcoded to the default theme based on figma - 12/3/24
export default function BaseErrorCard({
  headerImage,
  headerTitle,
  content,
  hideMemberSupportFooter = false,
}: BaseErrorCardProps) {
  return (
    <CardContainer
      sx={{
        background: 'linear-gradient(180deg, #CADFEF 0%, #FFF 100%)',
        boxShadow: '0px 4px 12px 0px rgba(35, 35, 35, 0.15)',
        textAlign: 'center',
        padding: SPACING._25,
      }}
    >
      <Grid container direction={'column'} alignItems={'center'}>
        <img
          //just use account image as default for now if no custom one is provided
          src={headerImage ? headerImage : accountImg}
          alt={headerTitle}
          style={{ maxHeight: 160, width: '100%' }}
        />
        <STypography variant="title3_bold" sx={{ color: '#4D008C' }}>
          {headerTitle}
        </STypography>
        <Box sx={{ marginTop: SPACING._15, marginBottom: SPACING._1 }}>
          {content}
        </Box>
        {!hideMemberSupportFooter && (
          <MemberSupportFooter variant="single-line" />
        )}
      </Grid>
    </CardContainer>
  )
}

export function ErrorCardSSODataNotFound({
  clientShortCode,
}: {
  clientShortCode?: string
}) {
  const { history } = useMemberAppContext()
  return (
    <BaseErrorCard
      // hideMemberSupportFooter={
      //   clientShortCode === GENERIC_SSO_UKNOWN_CLIENT ? true : false
      // }
      headerImage={ssoDataNotFoundImg}
      headerTitle={
        clientShortCode === GENERIC_SSO_UKNOWN_CLIENT
          ? "Ooops! SSO Login Didn't Work"
          : 'Hmmm…We are still searching for you.'
      }
      content={
        clientShortCode === GENERIC_SSO_UKNOWN_CLIENT ? (
          <>
            <Grid container gap={SPACING._15}>
              <Grid item xs={12}>
                <STypography variant="bodybig_regular">
                  We encountered an issue with your SSO login. No worries! You
                  can still set up your account directly with Scripta. It's
                  quick and easy, and you'll be up and running in no time.
                  <br />
                </STypography>
              </Grid>
              <Grid item xs={12}>
                <SButton
                  sx={{
                    fontSize: '1rem',
                    fontWeight: 400,
                    cursor: 'pointer',
                  }}
                  noCaps
                  onClick={() => {
                    history.push(REG_ROUTE_REGISTER_TENANT_ACCOUNT)
                  }}
                >
                  Go to Scripta's registration
                </SButton>
              </Grid>
            </Grid>

            <STypography
              variant="bodybig_regular"
              sx={{ marginTop: SPACING._1 }}
            >
              For additional help, please reach out to our amazing Member
              Support Team:
            </STypography>
          </>
        ) : (
          <STypography variant="bodybig_regular">
            Please connect with our amazing Member Support Team so we can help
            get you savings! <br /> <br /> They can be reached by the following
            ways:
          </STypography>
        )
      }
    />
  )
}

export function ErrorCardUnknownLogin() {
  return (
    <BaseErrorCard
      headerImage={noConnectionImg}
      headerTitle={'We are sorry, there was an error completing your login'}
      content={
        <STypography variant="bodybig_regular">
          Please connect with our amazing Member Support Team so we can help get
          you savings! <br /> <br /> They can be reached by the following ways:
        </STypography>
      }
    />
  )
}

export function ErrorCardUnableToRegister() {
  return (
    <BaseErrorCard
      headerImage={ssoDataNotFoundImg}
      headerTitle={'Unable to register'}
      content={
        <Grid container direction={'column'} gap={SPACING._15}>
          <STypography variant="bodybig_regular">
            Please contact your HR Benefits contact to ensure your information
            is updated.
          </STypography>
        </Grid>
      }
      hideMemberSupportFooter
    />
  )
}

export function InfoCardAutoSSOLoggedOut() {
  return (
    <BaseErrorCard
      headerImage={accountImg}
      headerTitle={'This session has expired due to inactivity'}
      content={
        <STypography variant="bodybig_regular">
          You’ve been logged out automatically to protect your privacy. Please
          log back in as you normally would.
        </STypography>
      }
      hideMemberSupportFooter
    />
  )
}

export function InfoCardManualSSOLoggedOut() {
  return (
    <BaseErrorCard
      headerImage={accountImg}
      headerTitle={"You've successfully logged out"}
      content={
        <STypography variant="bodybig_regular">
          Come back soon to keep seeing ways to save!
        </STypography>
      }
      hideMemberSupportFooter
    />
  )
}
