import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import {
  Autocomplete,
  AutocompleteRenderOptionState,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Grid,
  InputAdornment,
  InputBase,
  Link,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { ChangeEvent, useEffect, useState } from 'react'
import {
  IS_DEV,
  checkMicroServiceResponseStatus,
  getUrlParameter,
} from '../apiexec/utils'
import { URL_PARAM_PREREGISTRATION_CODE } from '../app-root-types'
import {
  autoformatDob,
  isDOBIncorrectWithDashForwardOrBackSlash,
  isValidZipCode,
} from '../helpmeswitch/types'
import { useSColors } from '../styles/scripta-theme'

import { LoadingButton } from '@mui/lab'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { useHistory, useLocation } from 'react-router'
import { useMemberAppContext } from '../MemberAppContext'
import { usePortalAppConfig } from '../config/usePortalAppConfig'
import {
  isDisplayFriendlyStringValidNumber,
  toDigitsOnly,
  toDisplayFriendlyString,
} from '../member/phone-field-utils'
import EmailLink from '../widgets/EmailLink'
import PhoneLink from '../widgets/PhoneLink'
import { All3LicensesAgreement } from './All3Licenses'
import { ConfirmOptoutDialog, validateEmail } from './EditContactInfoEKS'
import { RegistrationFormFieldsBox } from './RegistrationContainer'
import { RegToPreregJumpState, useRegistration } from './RegistrationContext'
import { SMSTermsOfUseContent } from './SMSTermsOfUseContent'
import { WelcomePreregDialog } from './WelcomePreregDialog'
import {
  CustomTextField,
  CustomTextFieldLabelNode,
  CustomTextFieldProps,
} from './components/CustomTextField'
import { useRegistrationService } from './useRegistrationService'

import checkGif from '../../images/prereg/check.gif'
import rocketEmoji from '../../images/prereg/rocket.svg'
import smileEmoji from '../../images/smile_emoji.svg'

import { RegistrationData } from 'src/types'
import { NewPortalDialogDesign } from '../application/NewDialogs'
import { RegistrationStepCard } from './components/RegistrationStepCard'
import SButton from '../system/customcomponents/SButton'
import { LOG } from '../v2/applog'

//need to remove SSN fields and replace with 999 if client is sonic
export function isSSNRequired(clientUuid: string | undefined) {
  let isRequired = true
  if (!clientUuid) {
    return true
  }
  const PROD_SONIC_CLIENT_UUID = 'b1812609-57a1-11ee-a15c-0acd7ab8ec77'

  let clientsThatDontNeedSSN = [PROD_SONIC_CLIENT_UUID]

  if (clientsThatDontNeedSSN.indexOf(clientUuid) > -1) {
    isRequired = false
  }

  return isRequired
}

export enum FLOW_STEPS {
  STEP_INIT_CODE = 0,
  STEP_NAME_DOB_ADDRESS = 1,
  STEP_IDENTIFICATION = 2,
  STEP_RELATIONSHIP = 3,
  STEP_CONTACT = 4,
  STEP_USERNAME_PASSWORD = 5,
  STEP_THANK_YOU = 6,
}

export function PreReg({ onClickCancel }: { onClickCancel?: () => void }) {
  const history = useHistory()

  //in case we are coming from normal registration, we want to update the uuid in member context for logo
  //as it is wiped out in the code init as part of the normal prereqigstration
  const { setClientUuidInRegistration } = useMemberAppContext()

  const [isComingFromNormalReg, setIsComingFromNormalReg] = useState(false)

  const [activeStep, setActiveStep] = useState(FLOW_STEPS.STEP_INIT_CODE)

  const [regForm, setRegForm] = useState<PreRegForm>(
    IS_DEV() ? ({} as PreRegForm) : ({} as PreRegForm),
  )
  const location = useLocation()

  //new effect to process the case when we jump here from normal registration
  //the state will be given to use via react router state
  useEffect(() => {
    if (location.state) {
      const s = location.state as RegToPreregJumpState
      if (s.clientUuid) {
        setIsComingFromNormalReg(true)
        //TODO
        setRegForm({
          ...regForm,
          'client-uuid': s.clientUuid,
          'first-name': s.firstName ? s.firstName : '',
          'last-name': s.lastName ? s.lastName : '',
          'dob-ui-format': s.dob,
        })
        //TODO - if no uuid for whatever reason, go to the first step
        if (s.clientUuid) {
          setActiveStep(FLOW_STEPS.STEP_NAME_DOB_ADDRESS)
        } else {
          setActiveStep(FLOW_STEPS.STEP_INIT_CODE)
        }

        setClientUuidInRegistration(s.clientUuid)
      }
    }
  }, [location])

  //dont enable validation of the step until at first click next
  const [enableStepValidation, setEnableStepValidation] = useState<any>({})

  const codeViaUrl = getUrlParameter(URL_PARAM_PREREGISTRATION_CODE)

  const [validationResult, setValidationResult] =
    useState<RegFormValidationResult>({} as RegFormValidationResult)

  const handleNext = () => {
    setEnableStepValidation({ ...enableStepValidation, [activeStep]: true })
    const validationResult = validateRegForm(regForm)
    setValidationResult(validationResult)
    if (isValidPerStep(activeStep, validationResult)) {
      //new change - if currently on Identification step, and they chose relationship as self, skip relationship step
      //as they are the primary subscriber
      if (
        activeStep === FLOW_STEPS.STEP_IDENTIFICATION &&
        regForm['relationship-to-subscriber'] === 'MEMBER'
      ) {
        setActiveStep(FLOW_STEPS.STEP_CONTACT)
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
      }

      // setActiveStep((prevActiveStep) => prevActiveStep + 1)
      window.scrollTo(0, 0)
    }
  }
  const handleBack = () => {
    //edge case - came in via url and on the first page
    if (activeStep === FLOW_STEPS.STEP_NAME_DOB_ADDRESS && codeViaUrl) {
      history.goBack()
    } else {
      //in case we have skipped the relatnionship step (i.e. they chose self), make sure to skip it on back
      if (
        activeStep === FLOW_STEPS.STEP_CONTACT &&
        regForm['relationship-to-subscriber'] === 'MEMBER'
      ) {
        setActiveStep(FLOW_STEPS.STEP_IDENTIFICATION)
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
      }
    }
  }

  useEffect(() => {
    if (enableStepValidation[activeStep]) {
      const validationResult = validateRegForm(regForm)
      setValidationResult(validationResult)
    }
  }, [regForm, enableStepValidation])

  return (
    <>
      {activeStep === FLOW_STEPS.STEP_INIT_CODE && (
        <CodeInit
          codeViaUrl={codeViaUrl}
          onCodeInitialized={(resp) => {
            const clientUuid = resp['client-id']

            setRegForm({ ...regForm, 'client-uuid': clientUuid })
            setActiveStep(1)
          }}
          onClickCancel={() => {
            if (onClickCancel) {
              onClickCancel()
            }
          }}
        />
      )}
      {activeStep === FLOW_STEPS.STEP_NAME_DOB_ADDRESS && (
        <NameDobAddresStep
          onClickNext={handleNext}
          onClickBack={() => {
            if (isComingFromNormalReg) {
              history.goBack()
            } else {
              handleBack()
            }
          }}
          regForm={regForm}
          setRegForm={setRegForm}
          enableValidation={enableStepValidation[activeStep]}
          validationResult={validationResult}
        />
      )}
      {activeStep === FLOW_STEPS.STEP_IDENTIFICATION && (
        <IdentificationStep
          onClickNext={handleNext}
          onClickBack={handleBack}
          regForm={regForm}
          setRegForm={setRegForm}
          enableValidation={enableStepValidation[activeStep]}
          validationResult={validationResult}
        />
      )}
      {activeStep === FLOW_STEPS.STEP_RELATIONSHIP && (
        <RelationshipStep
          onClickNext={handleNext}
          onClickBack={handleBack}
          regForm={regForm}
          setRegForm={setRegForm}
          enableValidation={enableStepValidation[activeStep]}
          validationResult={validationResult}
        />
      )}
      {activeStep === FLOW_STEPS.STEP_CONTACT && (
        <ContactStep
          onClickNext={handleNext}
          onClickBack={handleBack}
          regForm={regForm}
          setRegForm={setRegForm}
          enableValidation={enableStepValidation[activeStep]}
          validationResult={validationResult}
        />
      )}
      {activeStep === FLOW_STEPS.STEP_USERNAME_PASSWORD && (
        <UsernameAndPasswordStep
          onClickNext={handleNext}
          onClickBack={handleBack}
          regForm={regForm}
          setRegForm={setRegForm}
          enableValidation={enableStepValidation[activeStep]}
          validationResult={validationResult}
        />
      )}
      {activeStep === FLOW_STEPS.STEP_THANK_YOU && (
        <ThankYouStep
          onClickNext={handleNext}
          onClickBack={handleBack}
          regForm={regForm}
          setRegForm={setRegForm}
          enableValidation={enableStepValidation[activeStep]}
          validationResult={validationResult}
          onClickGoBackToHomePage={() => {
            //if we have a cancel function, call it to leave the wizard
            //this is for the case when we are in the wizard from the home page
            //esp. needd for mobile app - i.e. they downloaded the APP then
            //went to preregistration
            if (onClickCancel) {
              onClickCancel()
            } else {
              //otherwise redirect back to home page
              history.push('/')
            }
          }}
        />
      )}
    </>
  )
}
function StepContent({
  headerLabel,
  headerText,
  children,
  onClickNext,
  onClickBack,
  hideActionButtons = false,
  nextButtonDisabled = false,
  customHeaderTextSx,
}: {
  headerLabel: string
  headerText?: string
  children: any
  onClickNext: any
  onClickBack: any
  hideActionButtons?: boolean
  nextButtonDisabled?: boolean
  customHeaderTextSx?: any
}) {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('md'))
  //reuse existing Registtration Step Card
  return (
    <RegistrationStepCard
      registrationType="preregistration"
      onClickNextButton={onClickNext}
      onClickCancelButton={onClickBack}
      disableToolbar={hideActionButtons}
      nextButtonDisabled={nextButtonDisabled}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: isSmall ? '1.25rem' : '1.5rem',
              fontWeight: '700',

              textAlign: 'center',
              marginBottom: '20px',
            }}
          >
            {headerLabel}
          </Typography>
        </Grid>
        {headerText && (
          <Grid item xs={12}>
            <Typography
              sx={{
                textAlign: 'center',
                fontWeight: 300,
                lineHeight: isSmall ? '19.49px' : '21.92px',
                fontSize: isSmall ? '1rem' : '1.125rem',
                //again pull the text up a bit on mobile
                marginTop: isSmall ? '-10px' : '-10px',
                marginBottom: '20px',
                paddingLeft: isSmall ? '10px' : '20px',
                paddingRight: isSmall ? '10px' : '20px',
                ...customHeaderTextSx,
              }}
            >
              {headerText}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
    </RegistrationStepCard>
  )
}

function IdentificationStep({
  regForm,
  setRegForm,
  onClickNext,
  onClickBack,
  validationResult,
  enableValidation,
}: {
  regForm: PreRegForm
  setRegForm: (regForm: PreRegForm) => void
  onClickNext: any
  onClickBack: any
  validationResult: RegFormValidationResult
  enableValidation: boolean
}) {
  const { t } = useSTranslate('prereg')
  const { clientUuidInRegistration } = useMemberAppContext()

  const isSsnRequired = isSSNRequired(clientUuidInRegistration)

  const [saneAsNenberChecked, setSameAsMemberChecked] = useState<boolean>(
    regForm['is-same-as-member-id-ui-check'] ? true : false,
  )
  const [previousSubId, setPreviousSubId] = useState<string | null>(
    regForm['subscriber-id'],
  )

  useEffect(() => {
    setSameAsMemberChecked(
      regForm['is-same-as-member-id-ui-check'] ? true : false,
    )
  }, [regForm])

  const [relValue, setRelValue] = useState<any | null>(null)
  const relationshipOps = [
    { value: 'MEMBER', label: t('relSelf') },
    { value: 'SPOUSE', label: t('relSpouse') },
    { value: 'DEPENDENT', label: t('relDep') },
  ]
  useEffect(() => {
    if (regForm['relationship-to-subscriber']) {
      const rel = relationshipOps.find(
        (r) => r.value === regForm['relationship-to-subscriber'],
      )
      setRelValue(rel)
    }
  }, [regForm])

  const { setProgressBarState } = useRegistration()
  useEffect(() => {
    setProgressBarState({
      disabled: false,
      type: 'preregistration',
      currentStep: 2,
    })
  }, [])

  return (
    <>
      <StepContent
        onClickNext={onClickNext}
        onClickBack={onClickBack}
        headerLabel={t('idStepLabel')}
        headerText={t('idStepText')}
      >
        <RegistrationFormFieldsBox>
          <Grid item xs={12}>
            {isSsnRequired && (
              <PortalTextField
                dataTestId="prereg-ssn-input"
                customLabelNode={
                  <CustomTextFieldLabelNode
                    label={t('ssnLabel')}
                    instructions={t('ssnFormatInstructions')}
                  />
                }
                placeholder={t('ssnPlaceholder')}
                //prevent browser autofill for this field
                inputProps={{
                  autoComplete: 'new-password',
                }}
                value={regForm['ssn'] ? regForm['ssn'] : ''}
                onChange={(e) => {
                  const ssn = e.target.value
                    ? e.target.value.replace(/\D/g, '')
                    : ''
                  let updatedForm: PreRegForm = { ...regForm }
                  if (!ssn) {
                    //could be completelly cleared up by user
                    updatedForm.ssn = ''
                  } else if (ssn && ssn.length > 0 && ssn.length <= 9) {
                    updatedForm.ssn = ssn
                  }
                  //if on next screen they chose relationship as Self, then update subscriber ssn
                  if (regForm['relationship-to-subscriber'] === 'MEMBER') {
                    updatedForm['subscriber-ssn'] = updatedForm.ssn
                  }
                  setRegForm(updatedForm)
                }}
                error={
                  enableValidation && !validationResult.validSsn
                    ? true
                    : undefined
                }
                helperText={
                  enableValidation && !validationResult.validSsn
                    ? t('ssnError')
                    : ''
                }
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <PortalTextField
              dataTestId="prereg-member-id-input"
              placeholder={t('memberIdPlaceholder')}
              //prevent browser autofill for this field
              inputProps={{
                autoComplete: 'new-password',
              }}
              customLabelNode={
                <CustomTextFieldLabelNode
                  label={t('memberIdLabel')}
                  instructions={t('memberIdInstructions')}
                  toolTipText={t('memberIdTooltip')}
                />
              }
              value={regForm['member-id'] ? regForm['member-id'] : ''}
              onChange={(e) => {
                const updatedForm = { ...regForm, 'member-id': e.target.value }
                if (saneAsNenberChecked) {
                  updatedForm['subscriber-id'] = e.target.value
                }
                setRegForm(updatedForm)
              }}
              error={
                enableValidation && !validationResult.validMemberId
                  ? true
                  : undefined
              }
              helperText={
                enableValidation && !validationResult.validMemberId
                  ? t('memberIdError')
                  : ''
              }
            ></PortalTextField>
          </Grid>
          <Grid item xs={12}>
            <PortalTextField
              dataTestId="prereg-subscriber-id-input"
              disabled={saneAsNenberChecked}
              //below this is checkbox "same as member id" so reduce the default bottom padding
              wrappingBoxSx={{ paddingBottom: '0px' }}
              //prevent browser autofill for this field
              inputProps={{
                autoComplete: 'new-password',
              }}
              customLabelNode={
                <CustomTextFieldLabelNode
                  label={t('subscriberIdLabel')}
                  toolTipText={t('subscriberTooltip')}
                />
              }
              placeholder={t('subscriberIdPlaceholder')}
              value={regForm['subscriber-id'] ? regForm['subscriber-id'] : ''}
              onChange={(e) => {
                const updatedForm = {
                  ...regForm,
                  'subscriber-id': e.target.value,
                }
                if (saneAsNenberChecked) {
                  updatedForm['member-id'] = e.target.value
                }
                setRegForm(updatedForm)
              }}
            ></PortalTextField>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              textAlign: 'left',
              // marginTop: '-15px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Checkbox
              checked={saneAsNenberChecked}
              onChange={(e) => {
                if (e.target.checked) {
                  setPreviousSubId(regForm['subscriber-id'])

                  setRegForm({
                    ...regForm,
                    'subscriber-id': regForm['member-id'],
                    'is-same-as-member-id-ui-check': e.target.checked,
                  })
                } else {
                  setRegForm({
                    ...regForm,
                    'subscriber-id': previousSubId ?? '',
                    'is-same-as-member-id-ui-check': e.target.checked,
                  })
                }

                setSameAsMemberChecked(e.target.checked)
              }}
              sx={{ float: 'left', padding: '0px', margin: '2px' }}
            />
            <Typography sx={{ fontSize: '0.8rem', fontStyle: 'italic' }}>
              {t('subSameAsMemberIdCheck')}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ paddingTop: '30px' }}>
            <Autocomplete
              disablePortal
              options={relationshipOps}
              fullWidth
              id="my-dependent"
              value={relValue}
              onChange={(e, v) => {
                setRelValue(v)
                if (v?.value === 'MEMBER') {
                  const updatedForm = {
                    ...regForm,
                    'relationship-to-subscriber': v?.value,
                    'subscriber-ssn': regForm['ssn'],
                  }
                  setRegForm(updatedForm)
                } else {
                  const updatedForm = {
                    ...regForm,
                    'relationship-to-subscriber': v?.value,
                    'subscriber-ssn': '',
                  }
                  setRegForm(updatedForm)
                }
              }}
              // sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                  label={t('relFieldLabel')}
                  value={
                    regForm['relationship-to-subscriber']
                      ? regForm['relationship-to-subscriber']
                      : ''
                  }
                  error={
                    enableValidation &&
                    !validationResult.validRelationshipToSubscriber
                      ? true
                      : undefined
                  }
                  helperText={
                    enableValidation &&
                    !validationResult.validRelationshipToSubscriber
                      ? t('relFieldError')
                      : ''
                  }
                />
              )}
              disableClearable={true}
              isOptionEqualToValue={(option: any, value: any) => {
                return option.value === value.value
              }}
              renderOption={(
                props: any,
                option: any,
                state: AutocompleteRenderOptionState,
              ) => (
                <Typography
                  {...props}
                  sx={{ fontWeight: state && state.selected ? 600 : 400 }}
                >
                  {option.label}
                </Typography>
              )}
            />
          </Grid>
        </RegistrationFormFieldsBox>
      </StepContent>
    </>
  )
}

function RelationshipStep({
  regForm,
  setRegForm,
  onClickNext,
  onClickBack,
  validationResult,
  enableValidation,
}: {
  regForm: PreRegForm
  setRegForm: (regForm: PreRegForm) => void
  onClickNext: any
  onClickBack: any
  validationResult: RegFormValidationResult
  enableValidation: boolean
}) {
  const { t } = useSTranslate('prereg')
  const { clientUuidInRegistration } = useMemberAppContext()

  const isSsnRequired = isSSNRequired(clientUuidInRegistration)

  const { setProgressBarState } = useRegistration()
  useEffect(() => {
    setProgressBarState({
      disabled: false,
      type: 'preregistration',
      currentStep: 3,
    })
  }, [])

  return (
    <StepContent
      onClickNext={onClickNext}
      onClickBack={onClickBack}
      headerLabel={t('relStepLabel')}
      headerText={t('relStepText')}
    >
      <RegistrationFormFieldsBox>
        <Grid item xs={12}>
          <PortalTextField
            dataTestId="prereg-subscriber-first-name-input"
            label={t('subscriberFirstNameLabel')}
            placeholder={t('subscriberFirstNamePlaceholder')}
            value={
              regForm['subscriber-first-name']
                ? regForm['subscriber-first-name']
                : ''
            }
            onChange={(e) => {
              setRegForm({
                ...regForm,
                'subscriber-first-name': e.target.value,
              })
            }}
            error={
              enableValidation && !validationResult.validSubscriberFirstName
                ? true
                : undefined
            }
            helperText={
              enableValidation && !validationResult.validSubscriberFirstName
                ? t('subscriberFirstNameError')
                : ''
            }
          ></PortalTextField>
        </Grid>
        <Grid item xs={12}>
          <PortalTextField
            dataTestId="prereg-subscriber-last-name-input"
            label={t('subscriberLastNameLabel')}
            placeholder={t('subscriberLastNamePlaceholder')}
            value={
              regForm['subscriber-last-name']
                ? regForm['subscriber-last-name']
                : ''
            }
            onChange={(e) => {
              setRegForm({
                ...regForm,
                'subscriber-last-name': e.target.value,
              })
            }}
            error={
              enableValidation && !validationResult.validSubscriberLastName
                ? true
                : undefined
            }
            helperText={
              enableValidation && !validationResult.validSubscriberLastName
                ? t('subscriberLastNameError')
                : ''
            }
          ></PortalTextField>
        </Grid>
        {isSsnRequired && (
          <Grid item xs={12}>
            <PortalTextField
              dataTestId="prereg-subscriber-ssn-input"
              customLabelNode={
                <CustomTextFieldLabelNode
                  label={t('subSsnLabel')}
                  instructions={t('subSsnInstructions')}
                  toolTipText={t('subSsnTooltip')}
                />
              }
              // label={t('subSsnLabel')}

              //disabled flag is not needed anymore, bc we skip the whole step if they chose self
              // disabled={regForm['relationship-to-subscriber'] === 'MEMBER'}
              placeholder={t('subSsnPlaceholder')}
              value={regForm['subscriber-ssn'] ? regForm['subscriber-ssn'] : ''}
              onChange={(e) => {
                const ssn = e.target.value
                  ? e.target.value.replace(/\D/g, '')
                  : ''

                if (!ssn) {
                  //could be completelly cleared up by user
                  setRegForm({ ...regForm, 'subscriber-ssn': '' })
                } else if (ssn && ssn.length > 0 && ssn.length <= 9) {
                  setRegForm({ ...regForm, 'subscriber-ssn': e.target.value })
                }
              }}
              error={
                enableValidation && !validationResult.validSubscriberSsn
                  ? true
                  : undefined
              }
              helperText={
                enableValidation && !validationResult.validSubscriberSsn
                  ? t('subSsnError')
                  : ''
              }
            ></PortalTextField>
          </Grid>
        )}
      </RegistrationFormFieldsBox>
    </StepContent>
  )
}

function ContactStep({
  regForm,
  setRegForm,
  onClickNext,
  onClickBack,
  validationResult,
  enableValidation,
}: {
  regForm: PreRegForm
  setRegForm: (regForm: PreRegForm) => void
  onClickNext: any
  onClickBack: any
  validationResult: RegFormValidationResult
  enableValidation: boolean
}) {
  const { t } = useSTranslate('prereg')

  const [showTermsDialog, setShowTermsDialog] = useState<boolean>(false)

  //custom control for next button in contat, they want to now force users to opt-in :(
  const [customNextDisabled, setCustomNextDisabled] = useState<boolean>(true)

  const [renderOptoutConfirmDialog, setRenderOptoutConfirmDialog] =
    useState<boolean>(false)

  //make sure they opted in and added email and phone before they can click next
  useEffect(() => {
    if (
      regForm['sms-opt-in'] &&
      regForm['email'] &&
      validateEmail(regForm['email']) &&
      regForm['mobile'] &&
      isDisplayFriendlyStringValidNumber(regForm['mobile'])
    ) {
      setCustomNextDisabled(false)
    } else {
      setCustomNextDisabled(true)
    }
  }, [regForm, validationResult])

  // effect to automatically opt-in the user when they provide a valid email and phone

  useEffect(() => {
    //FIX - if once opted out, in case they click back,
    //dont opt out automatically again
    if (regForm.specificallyConfirmedOptOutInDialog) {
      return
    } else {
      if (
        regForm['email'] &&
        validateEmail(regForm['email']) &&
        regForm['mobile'] &&
        isDisplayFriendlyStringValidNumber(regForm['mobile'])
      ) {
        setRegForm({ ...regForm, 'sms-opt-in': true })
      } else {
        setRegForm({ ...regForm, 'sms-opt-in': false })
      }
    }
    // }, [regForm['email'], regForm['mobile']])
  }, [regForm])

  const { setProgressBarState } = useRegistration()
  useEffect(() => {
    setProgressBarState({
      disabled: false,
      type: 'preregistration',
      currentStep: 4,
    })
  }, [])

  const preventCopyPaste = (e: any) => {
    e.preventDefault()
  }

  return (
    <StepContent
      onClickNext={onClickNext}
      onClickBack={onClickBack}
      headerLabel={t('contactStepLabel')}
      headerText={t('contactStepText')}
      // customNextDisabled={customNextDisabled}
    >
      <RegistrationFormFieldsBox>
        <Grid item xs={12}>
          <PortalTextField
            dataTestId="prereg-email-input"
            label={t('emailLabel')}
            placeholder={t('emailPlaceholder')}
            value={regForm['email'] ? regForm['email'] : ''}
            onChange={(e) => {
              setRegForm({ ...regForm, email: e.target.value })
            }}
            error={
              enableValidation && !validationResult.validEmail
                ? true
                : undefined
            }
            helperText={
              enableValidation && !validationResult.validEmail
                ? t('emailError')
                : ''
            }
          />
        </Grid>
        <Grid item xs={12}>
          <PortalTextField
            dataTestId="prereg-confirm-email-input"
            label={t('confirmEmailLabel')}
            placeholder={t('confirmEmailPlaceholder')}
            value={regForm['confirm-email'] ? regForm['confirm-email'] : ''}
            onChange={(e) => {
              setRegForm({ ...regForm, 'confirm-email': e.target.value })
            }}
            onCut={preventCopyPaste}
            onCopy={preventCopyPaste}
            onPaste={preventCopyPaste}
            error={
              enableValidation && !validationResult.validConfirmEmail
                ? true
                : undefined
            }
            helperText={
              enableValidation && !validationResult.validConfirmEmail
                ? t('confirmEmailError')
                : ''
            }
          />
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '10px' }}>
          <PortalTextField
            dataTestId="prereg-mobile-input"
            customLabelNode={
              <CustomTextFieldLabelNode
                label={t('mobileLabel')}
                instructions={t('mobileLabelInstructions')}
              />
            }
            placeholder={t('mobilePlaceholder')}
            value={regForm['mobile'] ? regForm['mobile'] : ''}
            onChange={(e) => {
              //process and format input to display friendly form
              //up to 10 digits
              if (toDigitsOnly(e.target.value).length <= 10) {
                let displayNumberValue = toDisplayFriendlyString(e.target.value)
                setRegForm({ ...regForm, mobile: displayNumberValue })
              }
            }}
            error={
              enableValidation && !validationResult.validMobile
                ? true
                : undefined
            }
            helperText={
              enableValidation && !validationResult.validMobile
                ? t('mobileError')
                : ''
            }
          />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{ textAlign: 'left', display: 'flex', alignItems: 'center' }}
        >
          <Checkbox
            checked={regForm['sms-opt-in'] ? true : false}
            onChange={(e) => {
              //render the confirmation dialog, then process the opt out from it
              if (e.target.checked) {
                setRenderOptoutConfirmDialog(false)
                setRegForm({ ...regForm, 'sms-opt-in': true })
              } else {
                setRenderOptoutConfirmDialog(true)
              }
            }}
            sx={{ float: 'left', padding: '0px', margin: '2px' }}
          />
          <Typography sx={{ fontSize: '0.7rem' }}>
            {t('contactOptinText')}
            <span>
              <Link
                onClick={(e: any) => {
                  setShowTermsDialog(true)
                }}
                // className={styles.radioLabels}
                underline={'always'}
                style={{ cursor: 'pointer' }}
              >
                {' '}
                {t('contactTermsOfUseLink')}{' '}
              </Link>
            </span>
          </Typography>
          <NewPortalDialogDesign
            open={showTermsDialog}
            onClose={() => {
              setShowTermsDialog(false)
            }}
            title={t('contactSmsTermsOfUseTitle')}
          >
            <SMSTermsOfUseContent />
          </NewPortalDialogDesign>
        </Grid>
        {renderOptoutConfirmDialog && (
          <ConfirmOptoutDialog
            onOptOutClick={() => {
              setRegForm({
                ...regForm,
                'sms-opt-in': false,
                specificallyConfirmedOptOutInDialog: true,
              })

              setRenderOptoutConfirmDialog(false)
            }}
            onStayConnectedClick={() => {
              setRegForm({
                ...regForm,
                'sms-opt-in': true,
                specificallyConfirmedOptOutInDialog: false,
              })

              setRenderOptoutConfirmDialog(false)
            }}
          />
        )}
      </RegistrationFormFieldsBox>
    </StepContent>
  )
}

function ThankYouStep({
  regForm,
  setRegForm,
  onClickNext,
  onClickBack,
  validationResult,
  enableValidation,
  onClickGoBackToHomePage,
}: {
  regForm: PreRegForm
  setRegForm: (regForm: PreRegForm) => void
  onClickNext: any
  onClickBack: any
  validationResult: RegFormValidationResult
  enableValidation: boolean
  onClickGoBackToHomePage: () => void
}) {
  const { t } = useSTranslate('prereg')

  const regService = useRegistrationService()
  const { setClientUuidInRegistration } = useMemberAppContext()

  const [showFinalWelcomePage, setShowFinalWelcomePage] =
    useState<boolean>(false)

  const [isSaveProcessing, setIsSaveProcessing] = useState<boolean>(false)
  const [saveError, setSaveError] = useState<string | undefined>('')

  const [errorLevelCode, setErrorLevelCode] = useState<
    undefined | 'NET-1' | 'MS-1'
  >(undefined)

  const [duplicateUsernameError, setDuplicateUsernamError] =
    useState<boolean>(false)

  const [duplicateEmailError, setDuplicateEmailError] = useState<boolean>(false)
  const [duplicateMobileError, setDuplicateMobileError] =
    useState<boolean>(false)

  function isFieldError(result: any, fieldName: string) {
    return (
      result &&
      result['fieldErrors'] &&
      result['fieldErrors'].length > 0 &&
      result['fieldErrors'].some((fe: any) => fe.propertyName === fieldName)
    )
  }

  async function savePrereg(regForm: PreRegForm) {
    try {
      setIsSaveProcessing(true)
      const result: any = await regService.savePreregistrationForm(regForm)
      // const result: any = MOCK_EMAIL_ERROR

      //if no response at all, we didnt even get to our MS
      if (!result) {
        LOG.error('preregistration', 'No result from savePreregistrationForm')
        setErrorLevelCode('NET-1')
        setSaveError('Error processing preregistration ')
        return
      }

      const respCheck = checkMicroServiceResponseStatus(result)
      if (!respCheck.isError) {
        setSaveError(undefined)
        setDuplicateEmailError(false)
        setDuplicateMobileError(false)
        setShowFinalWelcomePage(true)
        setErrorLevelCode(undefined)
      } else {
        //at least we got a microservice response, but it is an error
        LOG.error(
          'preregistration',
          'Error processing preregistration,result=',
          {
            result,
          },
        )
        setErrorLevelCode('MS-1')

        setSaveError('Error processing preregistration ')
        if (isFieldError(result, 'email')) {
          setDuplicateEmailError(true)
        }
        if (isFieldError(result, 'userName')) {
          setDuplicateUsernamError(true)
        }
        if (isFieldError(result, 'mobile')) {
          setDuplicateMobileError(true)
        }
      }
    } catch (e) {
      //generic error, means we didnt even get our ms response
      setErrorLevelCode('NET-1')

      setSaveError('Error processing preregistration ')
      LOG.error('preregistration', 'Error processing preregistration', {
        error: e,
      })
    } finally {
      setIsSaveProcessing(false)
    }
  }
  useEffect(() => {
    savePrereg(regForm)
  }, [])

  const delay = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms))

  let hasfieldErrors =
    duplicateEmailError || duplicateMobileError || duplicateUsernameError

  return (
    <StepContent
      hideActionButtons={true}
      onClickNext={onClickNext}
      onClickBack={onClickBack}
      headerLabel=""
      headerText={isSaveProcessing ? t('tsavingReg') : ''}
    >
      {/* <RegistrationFormFieldsBox> */}
      <Box>
        {isSaveProcessing && (
          <Grid
            item
            container
            justifyContent={'center'}
            alignItems={'center'}
            justifyItems={'center'}
            xs={12}
            sx={{ marginBottom: '50px' }}
          >
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        )}

        {saveError && (
          <>
            <Grid
              item
              container
              justifyContent={'center'}
              alignItems={'center'}
              justifyItems={'center'}
              xs={12}
              sx={{ marginTop: '20px' }}
            >
              <Grid item>
                <ReportProblemIcon
                  style={{ fontSize: 60, textAlign: 'center' }}
                />
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sx={{ marginBottom: '20px' }}
              spacing={2}
            >
              <Grid item xs={12}>
                <Typography sx={{ textAlign: 'center' }}>
                  We are sorry, there was a problem saving your registration.
                </Typography>
              </Grid>
              {errorLevelCode && (
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                  <Typography>Error Code:{errorLevelCode}</Typography>
                </Grid>
              )}
              {!hasfieldErrors &&
                errorLevelCode &&
                errorLevelCode === 'NET-1' && (
                  <>
                    <Grid item xs={12}>
                      <Typography sx={{ textAlign: 'center' }}>
                        Please ensure that you have an active internet
                        connection. If you're connected through your work
                        network, try switching to your mobile data if it's
                        accessible.
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography sx={{ textAlign: 'center' }}>
                        Click resubmit to try again. If the problem persists,
                        please contact our member support.
                      </Typography>
                    </Grid>
                  </>
                )}
              {!hasfieldErrors &&
                errorLevelCode &&
                errorLevelCode === 'MS-1' && (
                  <>
                    <Grid item xs={12}>
                      <Typography sx={{ textAlign: 'center' }}>
                        Click resubmit to try again. If the problem persists,
                        please contact our member support.
                      </Typography>
                    </Grid>
                  </>
                )}
            </Grid>
            {duplicateEmailError && (
              <>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: '0.8rem', color: 'red' }}>
                    This email you provided is already registered with us.
                    Please use a different email.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <PortalTextField
                    dataTestId="prereg-email-duplicate-error-input"
                    label={t('emailLabel')}
                    placeholder={t('emailPlaceholder')}
                    value={regForm['email'] ? regForm['email'] : ''}
                    onChange={(e) => {
                      setRegForm({ ...regForm, email: e.target.value })
                    }}
                    error={
                      enableValidation && !validationResult.validEmail
                        ? true
                        : undefined
                    }
                    helperText={
                      enableValidation && !validationResult.validEmail
                        ? t('emailError')
                        : ''
                    }
                  />
                </Grid>
              </>
            )}
            {duplicateMobileError && (
              <>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontSize: '0.8rem',
                      color: 'red',
                      paddingTop: '10px',
                    }}
                  >
                    This mobile number is already registered with us. Please use
                    a different mobile number.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <PortalTextField
                    dataTestId="prereg-mobile-duplicate-error-input"
                    label={t('mobileLabel')}
                    placeholder={t('mobilePlaceholder')}
                    value={regForm['mobile'] ? regForm['mobile'] : ''}
                    onChange={(e) => {
                      //process and format input to display friendly form
                      //up to 10 digits
                      if (toDigitsOnly(e.target.value).length <= 10) {
                        let displayNumberValue = toDisplayFriendlyString(
                          e.target.value,
                        )
                        setRegForm({ ...regForm, mobile: displayNumberValue })
                      }
                    }}
                    error={
                      enableValidation && !validationResult.validMobile
                        ? true
                        : undefined
                    }
                    helperText={
                      enableValidation && !validationResult.validMobile
                        ? t('mobileError')
                        : ''
                    }
                  />
                </Grid>
              </>
            )}
            {duplicateUsernameError && (
              <>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: '0.8rem', color: 'red' }}>
                    This username is already used. Please choose a different
                    one.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <PortalTextField
                    dataTestId="prereg-username-input"
                    inputProps={{
                      autoComplete: 'new-password',
                    }}
                    label={t('usernameLabel')}
                    placeholder={t('usernamePlaceholder')}
                    value={regForm['username'] ? regForm['username'] : ''}
                    onChange={(e) => {
                      if (e.target.value && e.target.value.length <= 20) {
                        setRegForm({ ...regForm, username: e.target.value })
                      }
                      if (!e.target.value || e.target.value == '') {
                        setRegForm({ ...regForm, username: '' })
                      }
                    }}
                    error={
                      enableValidation && !validationResult.validUsername
                        ? true
                        : undefined
                    }
                    helperText={
                      enableValidation && !validationResult.validUsername
                        ? t('usernameError')
                        : ''
                    }
                  />
                </Grid>
              </>
            )}
            {(duplicateEmailError ||
              duplicateMobileError ||
              saveError ||
              duplicateUsernameError) && (
              <Grid
                item
                xs={12}
                container
                //center
                justifyContent={'center'}
                alignItems={'center'}
                justifyItems={'center'}
                sx={{
                  marginBottom: '50px',
                }}
              >
                <Grid item sx={{ paddingTop: '20px' }}>
                  <LoadingButton
                    variant="contained"
                    onClick={async () => {
                      setSaveError(undefined)

                      //clear out all the other errors
                      setDuplicateEmailError(false)
                      setDuplicateMobileError(false)
                      setDuplicateUsernamError(false)
                      setErrorLevelCode(undefined)

                      setIsSaveProcessing(true)
                      await delay(3000)
                      savePrereg(regForm)
                    }}
                    loading={isSaveProcessing}
                  >
                    Resubmit
                  </LoadingButton>
                </Grid>
              </Grid>
            )}
          </>
        )}
      </Box>
      {/* </RegistrationFormFieldsBox> */}
      {!isSaveProcessing && !saveError && showFinalWelcomePage && (
        <WelcomePreregDialog
          onGoHomeFromThankYouClicked={() => {
            //clear out client uuid so we dont show the logo on the login page
            setClientUuidInRegistration(undefined)
            onClickGoBackToHomePage()
          }}
        />
      )}
    </StepContent>
  )
}

export function LearnMoreBtn() {
  const { t } = useSTranslate('prereg')

  return (
    <SButton
      fullWidth
      size="small"
      variant="contained"
      onClick={() => {
        window.open('https://www.scriptainsights.com/members', '_blank')
      }}
    >
      {t('learnMoreBtn')}
    </SButton>
  )
}
function UsernameAndPasswordStep({
  regForm,
  setRegForm,
  onClickNext,
  onClickBack,
  validationResult,
  enableValidation,
}: {
  regForm: PreRegForm
  setRegForm: (regForm: PreRegForm) => void
  onClickNext: any
  onClickBack: any
  validationResult: RegFormValidationResult
  enableValidation: boolean
}) {
  const { t } = useSTranslate('prereg')
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('md'))

  const { setProgressBarState } = useRegistration()
  useEffect(() => {
    setProgressBarState({
      disabled: false,
      type: 'preregistration',
      currentStep: 5,
    })
  }, [])

  return (
    <StepContent
      onClickNext={onClickNext}
      onClickBack={onClickBack}
      nextButtonDisabled={
        regForm['acceptedFinalTermsAndConditions'] ? false : true
      }
      // customNextBtnLabel={t('completeRegBtn')}
      headerLabel={t('usernameStepLabel')}
      headerText={t('usernameStepText')}
      // need a bit more padding on this step
      customHeaderTextSx={{
        paddingLeft: isSmall ? '10px' : '5px',
        paddingRight: isSmall ? '10px' : '5px',
      }}
    >
      <RegistrationFormFieldsBox>
        <Grid item xs={12}>
          <PortalTextField
            dataTestId="prereg-username-input"
            inputProps={{
              autoComplete: 'new-password',
            }}
            label={t('usernameLabel')}
            placeholder={t('usernamePlaceholder')}
            value={regForm['username'] ? regForm['username'] : ''}
            onChange={(e) => {
              if (e.target.value && e.target.value.length <= 63) {
                setRegForm({ ...regForm, username: e.target.value })
              }
              if (!e.target.value || e.target.value == '') {
                setRegForm({ ...regForm, username: '' })
              }
            }}
            error={
              enableValidation && !validationResult.validUsername
                ? true
                : undefined
            }
            helperText={
              enableValidation && !validationResult.validUsername
                ? t('usernameError')
                : ''
            }
          />
        </Grid>
        <Grid item xs={12}>
          <PortalTextField
            dataTestId="prereg-password-input"
            inputProps={{
              autoComplete: 'new-password',
            }}
            type="password"
            autoComplete="new-password"
            label={t('passwordLabel')}
            placeholder={t('passwordPlaceholder')}
            value={regForm['password'] ? regForm['password'] : ''}
            onChange={(e) => {
              setRegForm({ ...regForm, password: e.target.value })
            }}
            error={
              enableValidation && !validationResult.validPassword
                ? true
                : undefined
            }
            helperText={
              enableValidation && !validationResult.validPassword
                ? t('passwordError')
                : ''
            }
          />
        </Grid>
        <Grid item xs={12}>
          <PortalTextField
            dataTestId="prereg-confirm-password-input"
            inputProps={{
              autoComplete: 'new-password',
            }}
            autoComplete="new-password"
            type="password"
            label={t('confirmLabel')}
            placeholder={t('confirmPlaceholder')}
            value={
              regForm['confirmedPassword'] ? regForm['confirmedPassword'] : ''
            }
            onChange={(e) => {
              setRegForm({ ...regForm, confirmedPassword: e.target.value })
            }}
            error={
              enableValidation && !validationResult.validConfirmPassword
                ? true
                : undefined
            }
            helperText={
              enableValidation && !validationResult.validConfirmPassword
                ? t('confirmError')
                : ''
            }
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ textAlign: 'left', display: 'flex', alignItems: 'center' }}
        >
          <Checkbox
            data-testid="prereg-final-checkbox"
            checked={regForm['acceptedFinalTermsAndConditions'] ? true : false}
            onChange={(e) => {
              setRegForm({
                ...regForm,
                acceptedFinalTermsAndConditions: e.target.checked,
              })
            }}
            sx={{
              float: 'left',
              padding: '0px',
              margin: '2px',
              paddingRight: '5px',
            }}
          />
          <All3LicensesAgreement customFontSize={isSmall ? '11px' : '13px'} />
        </Grid>

        {enableValidation && !validationResult.validFinalTermsAndConditions && (
          <Grid item xs={12} sx={{ textAlign: 'left', marginTop: '-10px' }}>
            <Typography sx={{ fontSize: '0.7rem', color: 'red' }}>
              {t('mustAgree')}
            </Typography>
          </Grid>
        )}
      </RegistrationFormFieldsBox>
    </StepContent>
  )
}
export interface VerifyCodeResp {
  'client-id': string //this is the client uuid
  'child-company-id'?: number
  // 'org-name': string
}

function CodeInit({
  codeViaUrl,
  onCodeInitialized,
  onClickCancel,
}: {
  codeViaUrl?: string
  onCodeInitialized: (resp: VerifyCodeResp) => void
  onClickCancel: () => void
}) {
  const { COLORS } = useSColors()
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('md'))
  const [ctnDisabled, setCtnDisabled] = useState(false)
  const { t } = useSTranslate('prereg')
  const { t: tCommon } = useSTranslate('common')
  const { t: tRegister } = useSTranslate('register')
  const regService = useRegistrationService()
  //needed for officially onboarded clients, so we can jump to standard registration
  const {
    clientUuidInRegistration,
    setClientLogoChildCompanyId,
    setClientUuidInRegistration,
  } = useMemberAppContext()

  //initi the value from url param even if its manual
  const isManualCodeEntry = codeViaUrl ? false : true

  const [manuallyEnteredCode, setManuallyEnteredCode] = useState<string[]>(
    codeViaUrl && codeViaUrl.length === 4
      ? [
          codeViaUrl.charAt(0).toString(),
          codeViaUrl.charAt(1),
          codeViaUrl.charAt(2),
          codeViaUrl.charAt(3),
        ]
      : ['', '', '', ''],
  )
  const [focusedCodeChar, setFocusedCodeChar] = useState<number>(0)
  const [isCodeProcessing, setIsCodeProcessing] = useState<boolean>(false)
  const [codeError, setCodeError] = useState<string | undefined>('')

  const [inputMode, setInputMode] = useState<'code' | 'companyName'>('code')

  const [renderFoundItDialog, setRenderFoundItDialog] = useState<boolean>(false)
  //found client uuid it from company search
  const [foundClientUuid, setFoundClientUUid] = useState<string | undefined>(
    undefined,
  )
  const [companyName, setCompanyName] = useState('')
  const [companyNameError, setCompanyNameError] = useState<string | undefined>(
    '',
  )
  const [isCompanyNameProcessing, setIsCompanyNameProcessing] =
    useState<boolean>(false)

  const { setProgressBarState } = useRegistration()
  useEffect(() => {
    setProgressBarState({
      disabled: true,
      type: 'preregistration',
      currentStep: 1,
    })
  }, [])

  useEffect(() => {
    setClientUuidInRegistration(undefined)
  }, [])

  async function processCode(code: string) {
    try {
      setIsCodeProcessing(true)
      const result: VerifyCodeResp =
        await regService.processPreregistrationCode(code, isManualCodeEntry)
      const respCheck = checkMicroServiceResponseStatus(result)
      if (!respCheck.isError) {
        onCodeInitialized(result)
        setClientUuidInRegistration(result['client-id'])
        if (result['child-company-id']) {
          setClientLogoChildCompanyId(result['child-company-id'])
        }
      } else {
        setCodeError('Error processing preregistration code')
        LOG.error(
          'preregistration',
          'Error processing preregistration code = ' + code,
          result,
        )
      }
    } catch (e) {
      setCodeError('Error processing preregistration code')
      LOG.error(
        'preregistration',
        'Exception caught processing preregistration code',
        e,
      )
    } finally {
      setIsCodeProcessing(false)
    }
  }

  useEffect(() => {
    //process code if all 4 chars are entered and not empty
    if (
      manuallyEnteredCode.every((c) => c !== '') &&
      manuallyEnteredCode.length === 4
    ) {
      setCtnDisabled(false)
    } else {
      setCtnDisabled(true)

      // processCode(manuallyEnteredCode.join(''))
    }
  }, [manuallyEnteredCode])

  // reset state on input mode change
  useEffect(() => {
    setManuallyEnteredCode(['', '', '', ''])
    setFocusedCodeChar(0)
    setCodeError(undefined)

    setCompanyName('')
    setCompanyNameError(undefined)
  }, [inputMode])

  let headerLabel = ''
  let headerText = ''

  if (isCodeProcessing) {
    headerLabel = t('initializingreg')
    headerText = t('waitforcode')
  } else {
    headerLabel = t('enterCodeLabel')
    headerText = t('enterCodeText')
  }

  useEffect(() => {
    if (codeViaUrl) {
      processCode(codeViaUrl)
    }
  }, [codeViaUrl])

  // if (codeError) {
  //   headerLabel = t('invalidcode')
  //   headerText = t('invalidcodetryagain')
  // }
  const onChangeCompanyNameTextField = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setCompanyName(event.target.value)
  }

  const codeInputContainer = (
    <>
      <Grid item container xs={12}>
        <Grid
          item
          xs={12}
          container
          justifyContent={'center'}
          alignItems={'center'}
        >
          <CodeCharInput
            dataTestId="prereg-code-input-0"
            value={manuallyEnteredCode[0] ? manuallyEnteredCode[0] : ''}
            isCodeError={codeError ? true : false}
            onCodeCharEntered={(c) => {
              setManuallyEnteredCode((prev) => {
                const newCode = [...prev]
                newCode[0] = c
                return newCode
              })
              setFocusedCodeChar(1)
            }}
            onCodePastedFromClipboard={(pastedCode: string) => {
              if (pastedCode && pastedCode.length === 4) {
                setManuallyEnteredCode(pastedCode.split(''))
              }
            }}
            focused={focusedCodeChar === 0}
            onBackspace={() => {
              //backspace click in this means clear value
              setManuallyEnteredCode((prev) => {
                const newCode = [...prev]
                newCode[0] = ''
                return newCode
              })
              setFocusedCodeChar(0)
            }}
          />
          <CodeCharInput
            dataTestId="prereg-code-input-1"
            value={manuallyEnteredCode[1] ? manuallyEnteredCode[1] : ''}
            isCodeError={codeError ? true : false}
            onCodeCharEntered={(c) => {
              setManuallyEnteredCode((prev) => {
                const newCode = [...prev]
                newCode[1] = c
                return newCode
              })
              setFocusedCodeChar(2)
            }}
            focused={focusedCodeChar === 1}
            onBackspace={() => {
              //backspace click in this means also clear previous value
              setManuallyEnteredCode((prev) => {
                const newCode = [...prev]
                //need diff behavior for backspace depending on where cursor is
                if (newCode[1]) {
                  newCode[1] = ''
                  setFocusedCodeChar(1)
                } else {
                  newCode[0] = ''
                  setFocusedCodeChar(0)
                }

                return newCode
              })
              // setFocusedCodeChar(0)
            }}
          />

          <CodeCharInput
            dataTestId="prereg-code-input-2"
            value={manuallyEnteredCode[2] ? manuallyEnteredCode[2] : ''}
            isCodeError={codeError ? true : false}
            onCodeCharEntered={(c) => {
              setManuallyEnteredCode((prev) => {
                const newCode = [...prev]
                newCode[2] = c
                return newCode
              })
              setFocusedCodeChar(3)
            }}
            focused={focusedCodeChar === 2}
            onBackspace={() => {
              //backspace click in this means clear previous value
              setManuallyEnteredCode((prev) => {
                const newCode = [...prev]

                if (newCode[2]) {
                  newCode[2] = ''
                } else {
                  newCode[1] = ''
                }
                return newCode
              })
              setFocusedCodeChar(1)
            }}
          />

          <CodeCharInput
            dataTestId="prereg-code-input-3"
            value={manuallyEnteredCode[3] ? manuallyEnteredCode[3] : ''}
            isCodeError={codeError ? true : false}
            onCodeCharEntered={(c) => {
              setManuallyEnteredCode((prev) => {
                const newCode = [...prev]
                newCode[3] = c
                return newCode
              })
              const finalCode = manuallyEnteredCode.join('')
            }}
            focused={focusedCodeChar === 3}
            onBackspace={() => {
              //backspace click in this means clear previous value

              setManuallyEnteredCode((prev) => {
                const newCode = [...prev]
                newCode[3] = ''
                //if there wa
                return newCode
              })
              setFocusedCodeChar(2)
            }}
            onEnterKey={() => {
              //enter key pressed in last char
              if (manuallyEnteredCode.join('').length === 4) {
                processCode(manuallyEnteredCode.join(''))
              }
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '0.8rem',
            fontStyle: 'italic',
            color: codeError ? 'red' : 'black',
          }}
        >
          {codeError ? t('invalidcodetryagain') : t('enterCodeHere')}
        </Typography>
      </Grid>
    </>
  )

  const companyNameInputContainer = (
    <NewPortalDialogDesign
      upperRightX={true}
      customPaperSx={
        {
          //removing this - now code init is in its own dialog, so this way they will (should) match
          //widts by default
          // width: isSmall ? '90%' : 560,
        }
      }
      title={t('enterCompanyNameLabel')}
      dualActionProps={{
        onYes: async () => {
          setIsCompanyNameProcessing(true)
          const findData: RegistrationData = {
            companyName: companyName,
          }
          const findClientResp = await regService.findTenantApi(findData)

          setIsCompanyNameProcessing(false)
          if (findClientResp && (findClientResp as any).clientUuid) {
            setFoundClientUUid((findClientResp as any).clientUuid)
            setClientUuidInRegistration((findClientResp as any).clientUuid)

            setRenderFoundItDialog(true)
          } else {
            LOG.error(
              'preregistration',
              'Cannot find company by name',
              findData,
            )
            setCompanyNameError('Company not found, please try again')
          }
        },
        yesDisabled:
          isCompanyNameProcessing || companyName.length === 0 ? true : false,

        onNo: () => {
          setInputMode('code')
        },
        yesText: 'Continue',
        noText: 'Cancel',
      }}
      open={true}
      onClose={() => {
        setInputMode('code')
      }}
      customTitleSx={{
        paddingLeft: '0px',
        paddingRight: '0px',
        paddingBottom: '20px',
        color: COLORS.TEXT_BLACK,
      }}
    >
      <Grid
        item
        xs={12}
        container
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Grid
          item
          xs={12}
          style={{
            marginBottom: 24,
            paddingLeft: isSmall ? '5px' : '15%',
            paddingRight: isSmall ? '5px' : '15%',
          }}
        >
          <Box style={{ width: '100%' }}>
            <CustomTextField
              value={companyName}
              onChange={onChangeCompanyNameTextField}
              label={tRegister('companyName')}
              error={companyNameError !== undefined}
              helperText={companyNameError ?? undefined}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{
              color: '#201F22',
              textAlign: 'center',
              textOverflow: 'ellipsis',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '24px',
            }}
          >
            {t('enterCompanyNameText')}{' '}
            <span style={{ whiteSpace: 'nowrap' }}>
              <PhoneLink shouldShowUnderline={true} />
            </span>{' '}
            or{' '}
            <span style={{ whiteSpace: 'nowrap' }}>
              <EmailLink shouldShowUnderline={true} />
            </span>
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginTop: '32px' }}>
          <Typography
            style={{
              color: '#201F22',
              textAlign: 'center',
              textOverflow: 'ellipsis',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '24px',
            }}
          >
            {t('weAreAssist')}{' '}
            <img
              alt="smile emoji"
              src={smileEmoji}
              style={{ verticalAlign: 'middle', width: 24, marginTop: -1 }}
            />
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '30px', marginBottom: '20px' }}>
          <Divider sx={{ marginLeft: '20px', marginRight: '20px' }} />
        </Grid>
      </Grid>
    </NewPortalDialogDesign>
  )

  const companyFoundDialog = (
    <NewPortalDialogDesign
      customTitleSx={{ color: COLORS.TEXT_BLACK, paddingTop: '30px' }}
      // customPaperSx={{
      //   width: isSmall ? '90%' : 560,
      // }}
      upperRightX={false}
      open={renderFoundItDialog}
      singleActionProps={{
        onClick: () => {
          //set the client uuid in the registration context

          //prepare the same resp as if it was returned when looking up client uuid via code
          const resp: VerifyCodeResp = {
            'client-id': foundClientUuid as string,
          }
          onCodeInitialized(resp)
          setRenderFoundItDialog(false)
        },
        text: 'Continue',
      }}
      title={t('companyFound')}
    >
      <Box>
        <Box
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 15,
            marginTop: 15,
          }}
        >
          <img
            alt="checkmark"
            src={checkGif}
            style={{ verticalAlign: 'middle', width: 209 }}
          />
        </Box>
        <Typography
          style={{ textAlign: 'center', fontSize: 16, fontWeight: 400 }}
        >
          {t('diveIn')}{' '}
          <img
            alt="rocket emoji"
            src={rocketEmoji}
            style={{ verticalAlign: 'middle', width: 24, marginTop: -1 }}
          />
        </Typography>
      </Box>
    </NewPortalDialogDesign>
  )

  return (
    <NewPortalDialogDesign
      //lets remove all the content padding, bc we have prereg step content
      customPaperSx={{ padding: '0px' }}
      customContentSx={{
        padding: '0px',
      }}
      open={true}
      onClose={() => {
        onClickCancel()
      }}
      upperRightX={true}
    >
      {companyFoundDialog}
      <StepContent
        onClickNext={() => {}}
        onClickBack={() => {}}
        hideActionButtons={true}
        headerLabel={headerLabel}
        //implement a custom header for this step as its diff font then standard steps...:
        // headerText={headerText}
      >
        <Grid item xs={12} sx={{ paddingTop: '20px', paddingBottom: '10px' }}>
          <Typography
            sx={{
              fontSize: '1rem',
              textAlign: 'center',
              paddingLeft: isSmall ? '10px' : '50px',
              paddingRight: isSmall ? '10px' : '50px',
              // marginBottom: '20px',
              lineHeight: '20px',
            }}
            data-testid="prereg-code-init-text"
          >
            {t('enterCodeText')}
          </Typography>
        </Grid>
        {isCodeProcessing && (
          <Grid
            item
            container
            xs={12}
            sx={{
              marginBottom: '30px',
            }}
          >
            <Box sx={{ marginLeft: 'auto', marginRight: 'auto' }}>
              <Box width="100%">
                <CircularProgress />
              </Box>
            </Box>
          </Grid>
        )}
        {inputMode == 'code' ? codeInputContainer : companyNameInputContainer}
        <Grid
          item
          xs={12}
          sx={{ maringTop: '20px' }}
          // sm={12}
          container
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Grid item sx={{ marginTop: '20px' }} xs={12}>
            <Typography sx={{ fontSize: '1rem', textAlign: 'center' }}>
              {t('enterCodeDesc')}{' '}
              <Link
                sx={{
                  color: COLORS.PRIMARY_BRAND,
                  cursor: 'pointer',
                  fontWeight: 700,
                }}
                onClick={() => setInputMode('companyName')}
              >
                {tCommon('here')}
              </Link>
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ marginTop: '30px', marginBottom: isSmall ? '10px' : '20px' }}
        >
          <Divider sx={{ marginLeft: '20px', marginRight: '20px' }} />
        </Grid>
        <Grid item container xs={12} justifyContent={'flex-end'}>
          <Grid item sx={{ padding: '15px 15px 0px 15px' }}>
            <Button
              fullWidth
              // variant="outlined"
              color="secondary"
              sx={{ width: '80px' }}
              onClick={() => {
                if (inputMode == 'code') {
                  onClickCancel()
                } else {
                  setInputMode('code')
                }
              }}
            >
              {t('codeCancelBtn')}
            </Button>
          </Grid>
          <Grid item sx={{ padding: '15px 15px 0px 15px' }}>
            <Button
              fullWidth
              data-testid="prereg-code-continue-button"
              disabled={ctnDisabled}
              variant="contained"
              sx={{
                width: '120px',

                backgroundColor: COLORS.BACKGROUND_SECONDARY,
                color: COLORS.TEXT_LIGHT_PRIMARY_WHITE,
                '&:hover': {
                  backgroundColor: COLORS.BACKGROUND_SECONDARY_ACTIVE,
                },
              }}
              onClick={() => {
                processCode(manuallyEnteredCode.join(''))
                // onClickNext()
              }}
            >
              {t('codeContinueBtn')}
            </Button>
          </Grid>
        </Grid>
      </StepContent>
    </NewPortalDialogDesign>
  )
}
function CodeCharInput({
  value,
  isCodeError,
  onCodeCharEntered,
  focused,
  onCodePastedFromClipboard,
  onBackspace,
  onEnterKey,
  dataTestId,
}: {
  value: string
  isCodeError: boolean
  onCodeCharEntered: (char: string) => void
  focused: boolean
  onCodePastedFromClipboard?: (pastedCode: string) => void
  onBackspace: () => void
  onEnterKey?: () => void
  dataTestId: string
}) {
  const { COLORS } = useSColors()
  const [sx, setSx] = useState<any>()
  const [char, setChar] = useState<string>(value)
  useEffect(() => {
    setChar(value)
  }, [value])

  useEffect(() => {
    if (focused || char) {
      setSx({ border: `2px solid ${COLORS.PRIMARY_BRAND}` })
    } else {
      setSx({ border: `2px solid ${COLORS.BACKGROUND_GREY_LIGHT}` })
    }

    if (isCodeError) {
      setSx({ border: '2px solid red' })
    }
  }, [focused, char, isCodeError])

  return (
    <div
      style={{ maxWidth: '50px', margin: '10px' }}
      // onMouseEnter={() => {
      //   setSx({ border: '2px solid black' })
      // }}
      // onMouseLeave={() => {
      //   setSx({ border: '2px solid #ccc' })
      // }}
    >
      <Box
        sx={{
          borderRadius: '5px',
          width: '50px',
          height: '62px',
          ...sx,
          display: 'flex',
        }}
      >
        <InputBase
          data-testid={dataTestId}
          sx={{
            '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
              {
                display: 'none',
              },
            '& input[type=number]': {
              MozAppearance: 'textfield',
            },
          }}
          autoComplete="off"
          autoFocus={focused}
          type="number"
          // focused={focused}
          // variant="filled"
          value={char ? char : ''}
          inputRef={(input) => focused && input && input.focus()}
          inputProps={{
            // maxLength: 1,
            style: {
              textAlign: 'center',
              fontSize: '1.5rem',
              fontWeight: 'bold',
              padding: '0px',
              color: isCodeError ? 'red' : 'black',
            },
          }}
          onKeyDown={(e) => {
            if (e.key == 'Backspace') {
              onBackspace()
            }
            if (e.key == 'Enter') {
              if (onEnterKey) {
                onEnterKey()
              }
            }
          }}
          onChange={(e) => {
            //if they pasted
            if (e.target.value && e.target.value.length === 1) {
              setChar(e.target.value)
              onCodeCharEntered(e.target.value)
            } else {
              //accept paste of four characters
              if (e.target.value && e.target.value.length === 4) {
                if (onCodePastedFromClipboard) {
                  onCodePastedFromClipboard(e.target.value)
                }
              }
            }
          }}
        ></InputBase>
      </Box>
    </div>
  )
}

function NameDobAddresStep({
  regForm,
  setRegForm,
  onClickNext,
  onClickBack,
  validationResult,
  enableValidation,
}: {
  regForm: PreRegForm
  setRegForm: (regForm: PreRegForm) => void
  onClickNext: any
  onClickBack: any
  validationResult: RegFormValidationResult
  enableValidation: boolean
}) {
  const { setProgressBarState } = useRegistration()
  const { t } = useSTranslate('prereg')
  const [stateValue, setStateValue] = useState<any | null>(null)

  const statOps: any = STATES.map((state) => {
    return { value: state.abbreviation, label: state.abbreviation }
  })
  useEffect(() => {
    if (regForm.state) {
      setStateValue({ value: regForm.state, label: regForm.state })
    }
  }, [regForm])

  useEffect(() => {
    setProgressBarState({
      disabled: false,
      type: 'preregistration',
      currentStep: 1,
    })
  }, [])

  return (
    <StepContent
      // hideBackButton={false}
      onClickNext={onClickNext}
      onClickBack={onClickBack}
      headerLabel={t('nameStepLabel')}
      headerText={t('nameStepText')}
      // customDescriptionSx={{ paddingLeft: '0px', paddingRight: '0px' }}
    >
      <RegistrationFormFieldsBox>
        <Grid item xs={12}>
          <PortalTextField
            dataTestId="prereg-first-name-input"
            label={t('firstNameLabel')}
            placeholder={t('firstNamePlaceholder')}
            value={regForm['first-name'] ? regForm['first-name'] : ''}
            onChange={(e) => {
              setRegForm({ ...regForm, 'first-name': e.target.value })
            }}
            error={
              enableValidation && !validationResult.validFirstName
                ? true
                : undefined
            }
            helperText={
              enableValidation && !validationResult.validFirstName
                ? t('firstNameError')
                : ''
            }
          ></PortalTextField>
        </Grid>
        <Grid item xs={12}>
          <PortalTextField
            dataTestId="prereg-last-name-input"
            label={t('lastNameLabel')}
            placeholder={t('lastNamePlaceholder')}
            value={regForm['last-name'] ? regForm['last-name'] : ''}
            onChange={(e) => {
              setRegForm({ ...regForm, 'last-name': e.target.value })
            }}
            error={
              enableValidation && !validationResult.validLastName
                ? true
                : undefined
            }
            helperText={
              enableValidation && !validationResult.validLastName
                ? t('lastNameError')
                : ''
            }
          ></PortalTextField>
        </Grid>
        <Grid item xs={12}>
          <PortalTextField
            dataTestId="prereg-dob-input"
            startAdornment={
              <InputAdornment position="start">
                <CalendarTodayOutlinedIcon
                  sx={
                    {
                      // color: PORTAL_COLOR_GREY_BORDER,
                      // paddingRight: '5px',
                    }
                  }
                />
              </InputAdornment>
            }
            label={t('dobLabel')}
            placeholder={t('dobPlaceholder')}
            value={
              regForm['dob-ui-format'] ? regForm['dob-ui-format'] : undefined
            }
            onChange={(e) => {
              const formatted = autoformatDob(e.target.value)
              setRegForm({ ...regForm, 'dob-ui-format': formatted })
            }}
            error={
              enableValidation && !validationResult.validDob ? true : undefined
            }
            helperText={
              enableValidation && !validationResult.validDob
                ? t('dobError')
                : ''
            }
          />
        </Grid>
        <Grid item xs={12}>
          <PortalTextField
            dataTestId="prereg-street-address-input"
            label={t('streetLabel')}
            placeholder={t('streetPlaceholder')}
            value={regForm['street-address'] ? regForm['street-address'] : ''}
            onChange={(e) => {
              setRegForm({ ...regForm, 'street-address': e.target.value })
            }}
            error={
              enableValidation && !validationResult.validStreetAddress
                ? true
                : undefined
            }
            helperText={
              enableValidation && !validationResult.validStreetAddress
                ? t('streetError')
                : ''
            }
          />
        </Grid>
        <Grid item xs={12}>
          <PortalTextField
            dataTestId="prereg-street-address2-input"
            label={t('streetLabel2')}
            placeholder={t('streetPlaceholder2')}
            value={regForm['street-address2'] ? regForm['street-address2'] : ''}
            onChange={(e) => {
              setRegForm({ ...regForm, 'street-address2': e.target.value })
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <PortalTextField
            dataTestId="prereg-city-input"
            label={t('cityLabel')}
            placeholder={t('cityPlaceholder')}
            value={regForm['city'] ? regForm['city'] : ''}
            onChange={(e) => {
              setRegForm({ ...regForm, city: e.target.value })
            }}
            error={
              enableValidation && !validationResult.validCity ? true : false
            }
            helperText={
              enableValidation && !validationResult.validCity
                ? t('cityError')
                : ''
            }
          />
        </Grid>
        <Grid item container xs={12} spacing={2} alignItems={'flex-start'}>
          <Grid item xs={6} sx={{ marginTop: '10px' }}>
            <Autocomplete
              data-testid="prereg-state-select"
              disablePortal
              options={statOps}
              fullWidth
              value={stateValue}
              onChange={(event: any, newValue: any | null) => {
                setStateValue(newValue)
                setRegForm({ ...regForm, state: newValue?.value as any })
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                    'data-test-id': 'prereg-state-select',
                  }}
                  label={t('stateLabel')}
                  value={regForm['state'] ? regForm['state'] : ''}
                  error={
                    enableValidation && !validationResult.validState
                      ? true
                      : undefined
                  }
                  helperText={
                    enableValidation && !validationResult.validState
                      ? t('stateError')
                      : ''
                  }
                />
              )}
              disableClearable={true}
              isOptionEqualToValue={(option: any, value: any) => {
                return option.value === value.value
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <PortalTextField
              dataTestId="prereg-zip-input"
              wrappingBoxSx={{ paddingBottom: '0px' }}
              label={t('zipLabel')}
              placeholder={t('zipPlaceholder')}
              value={regForm['zip'] ? regForm['zip'] : ''}
              onChange={(e) => {
                const zipValue = e.target.value.replace(/\D/g, '').slice(0, 5)

                setRegForm({ ...regForm, zip: zipValue })
              }}
              error={
                enableValidation && !validationResult.validZip ? true : false
              }
              helperText={
                enableValidation && !validationResult.validZip
                  ? t('zipError')
                  : ''
              }
            />
          </Grid>
        </Grid>
      </RegistrationFormFieldsBox>
    </StepContent>
  )
}

export interface PortalTextFieldProps extends CustomTextFieldProps {
  wrappingBoxSx?: any
  dataTestId: string
}

export function PortalTextField(props: PortalTextFieldProps) {
  //remove wrapping box sx from props so we can pass it to the standard text field

  const { wrappingBoxSx, dataTestId, ...rest } = props

  return (
    <Box sx={{ width: '100%', paddingBottom: '12px', ...props.wrappingBoxSx }}>
      <CustomTextField
        data-testid={dataTestId}
        fullWidth
        {...rest}
        customLabelNode={props.customLabelNode}
      />
    </Box>
  )
}

export interface PreRegForm {
  'client-uuid': string
  'first-name': string
  'last-name': string
  'dob-ui-format': string
  'street-address': string
  'street-address2': string

  city: string
  state: string
  zip: string
  ssn: string
  'member-id': string
  'subscriber-id': string
  'is-same-as-member-id-ui-check': boolean
  'relationship-to-subscriber': 'MEMBER' | 'SPOUSE' | 'DEPENDENT'
  'subscriber-ssn'?: string
  'subscriber-first-name'?: string
  'subscriber-last-name'?: string
  email: string
  'confirm-email': string
  mobile: string
  'sms-opt-in': boolean
  username: string
  password: string
  confirmedPassword: string
  acceptedFinalTermsAndConditions: boolean
  //track when the user specifically confirms opt-out, in case of back click not to auto-opt-in
  specificallyConfirmedOptOutInDialog: boolean
}
interface RegFormValidationResult {
  validFirstName: boolean
  validLastName: boolean
  validDob: boolean
  validStreetAddress: boolean
  validCity: boolean
  validState: boolean
  validZip: boolean
  validSsn: boolean
  validMemberId: boolean
  validRelationshipToSubscriber: boolean
  validSubscriberSsn: boolean
  validSubscriberFirstName: boolean
  validSubscriberLastName: boolean
  validEmail: boolean
  validConfirmEmail: boolean
  validMobile: boolean
  validUsername: boolean
  validPassword: boolean
  validConfirmPassword: boolean
  validFinalTermsAndConditions: boolean
}

const STATES = [
  { name: 'Select State', abbreviation: 'Select State' },

  { name: 'Alabama', abbreviation: 'AL' },
  { name: 'Alaska', abbreviation: 'AK' },
  { name: 'Arizona', abbreviation: 'AZ' },
  { name: 'Arkansas', abbreviation: 'AR' },
  { name: 'California', abbreviation: 'CA' },
  { name: 'Colorado', abbreviation: 'CO' },
  { name: 'Connecticut', abbreviation: 'CT' },
  { name: 'Delaware', abbreviation: 'DE' },
  { name: 'Florida', abbreviation: 'FL' },
  { name: 'Georgia', abbreviation: 'GA' },
  { name: 'Hawaii', abbreviation: 'HI' },
  { name: 'Idaho', abbreviation: 'ID' },
  { name: 'Illinois', abbreviation: 'IL' },
  { name: 'Indiana', abbreviation: 'IN' },
  { name: 'Iowa', abbreviation: 'IA' },
  { name: 'Kansas', abbreviation: 'KS' },
  { name: 'Kentucky', abbreviation: 'KY' },
  { name: 'Louisiana', abbreviation: 'LA' },
  { name: 'Maine', abbreviation: 'ME' },
  { name: 'Maryland', abbreviation: 'MD' },
  { name: 'Massachusetts', abbreviation: 'MA' },
  { name: 'Michigan', abbreviation: 'MI' },
  { name: 'Minnesota', abbreviation: 'MN' },
  { name: 'Mississippi', abbreviation: 'MS' },
  { name: 'Missouri', abbreviation: 'MO' },
  { name: 'Montana', abbreviation: 'MT' },
  { name: 'Nebraska', abbreviation: 'NE' },
  { name: 'Nevada', abbreviation: 'NV' },
  { name: 'New Hampshire', abbreviation: 'NH' },
  { name: 'New Jersey', abbreviation: 'NJ' },
  { name: 'New Mexico', abbreviation: 'NM' },
  { name: 'New York', abbreviation: 'NY' },
  { name: 'North Carolina', abbreviation: 'NC' },
  { name: 'North Dakota', abbreviation: 'ND' },
  { name: 'Ohio', abbreviation: 'OH' },
  { name: 'Oklahoma', abbreviation: 'OK' },
  { name: 'Oregon', abbreviation: 'OR' },
  { name: 'Pennsylvania', abbreviation: 'PA' },
  { name: 'Rhode Island', abbreviation: 'RI' },
  { name: 'South Carolina', abbreviation: 'SC' },
  { name: 'South Dakota', abbreviation: 'SD' },
  { name: 'Tennessee', abbreviation: 'TN' },
  { name: 'Texas', abbreviation: 'TX' },
  { name: 'Utah', abbreviation: 'UT' },
  { name: 'Vermont', abbreviation: 'VT' },
  { name: 'Virginia', abbreviation: 'VA' },
  { name: 'Washington', abbreviation: 'WA' },
  { name: 'West Virginia', abbreviation: 'WV' },
  { name: 'Wisconsin', abbreviation: 'WI' },
  { name: 'Wyoming', abbreviation: 'WY' },
]
function validateRegForm(regForm: PreRegForm): RegFormValidationResult {
  const validFirstName = regForm['first-name'] ? true : false
  const validLastName = regForm['last-name'] ? true : false

  let validDob = false
  if (regForm['dob-ui-format']) {
    const isInvalid = isDOBIncorrectWithDashForwardOrBackSlash(
      regForm['dob-ui-format'],
    )
    validDob = !isInvalid
  }

  const validStreetAddress = regForm['street-address'] ? true : false
  const validCity = regForm['city'] ? true : false
  const validState =
    regForm['state'] && regForm['state'] !== 'Select State' ? true : false
  let validZip = false
  if (regForm['zip'] && isValidZipCode(regForm['zip'])) {
    validZip = true
  }

  let validSsn = false

  if (regForm['ssn'] && regForm['ssn'].length == 9) {
    validSsn = true
  }

  //WARNING - this is only for sonic - to hide ssn fields in the UI, and auto-replace with 999999999 in final submission payload
  if (!isSSNRequired(regForm['client-uuid'])) {
    validSsn = true
  }

  const validMemberId = regForm['member-id'] ? true : false

  const validRelationshipToSubscriber = regForm['relationship-to-subscriber']
    ? true
    : false

  //subscriber ssn is optional now
  let validSubscriberSsn = true

  //but if provided then at least make sure its valid
  if (regForm['subscriber-ssn'] && regForm['subscriber-ssn'].length != 9) {
    validSubscriberSsn = false
  }

  const validSubscriberFirstName = regForm['subscriber-first-name']
    ? true
    : false
  const validSubscriberLastName = regForm['subscriber-last-name'] ? true : false

  let validEmail = false
  if (regForm['email'] && validateEmail(regForm['email'])) {
    validEmail = true
  }

  let validConfirmEmail = false
  if (regForm['confirm-email'] == regForm['email']) {
    validConfirmEmail = true
  }

  let validMobile = false
  if (regForm['mobile']) {
    const phone = toDigitsOnly(regForm['mobile'])
    if (isDisplayFriendlyStringValidNumber(phone)) {
      validMobile = true
    }
  }

  let validUsername = false
  if (regForm['username'] && regForm.username.length >= 8) {
    validUsername = true
  }

  let validPassword = false
  if (
    regForm['password'] &&
    checkPasswordMatchesRequirements(regForm['password'])
  ) {
    validPassword = true
  }
  let validConfirmPassword = false
  if (
    regForm['password'] &&
    regForm['password'].length > 0 &&
    regForm['password'] == regForm['confirmedPassword']
  ) {
    validConfirmPassword = true
  }

  let validFinalTermsAndConditions = regForm['acceptedFinalTermsAndConditions']
    ? true
    : false

  return {
    validFirstName,
    validLastName,
    validDob,
    validStreetAddress,
    validCity,
    validState,
    validZip,
    validSsn,
    validMemberId,
    validRelationshipToSubscriber,
    validSubscriberSsn,
    validSubscriberFirstName,
    validSubscriberLastName,
    validEmail,
    validConfirmEmail,
    validMobile,
    validUsername,
    validPassword,
    validConfirmPassword,
    validFinalTermsAndConditions,
  }
}

function isValidPerStep(
  step: number,
  validationResult: RegFormValidationResult,
) {
  switch (step) {
    case FLOW_STEPS.STEP_NAME_DOB_ADDRESS:
      return (
        validationResult.validFirstName &&
        validationResult.validLastName &&
        validationResult.validDob &&
        validationResult.validStreetAddress &&
        validationResult.validCity &&
        validationResult.validState &&
        validationResult.validZip
      )
    case FLOW_STEPS.STEP_IDENTIFICATION:
      return (
        validationResult.validSsn &&
        validationResult.validMemberId &&
        validationResult.validRelationshipToSubscriber
      )
    case FLOW_STEPS.STEP_RELATIONSHIP:
      return (
        validationResult.validSubscriberFirstName &&
        validationResult.validSubscriberLastName &&
        validationResult.validSubscriberSsn
        //this is optional nowvalidationResult.validSubscriberSsn
      )
    case FLOW_STEPS.STEP_CONTACT:
      return (
        validationResult.validEmail &&
        validationResult.validMobile &&
        validationResult.validConfirmEmail
      )
    case FLOW_STEPS.STEP_USERNAME_PASSWORD:
      return (
        validationResult.validUsername &&
        validationResult.validPassword &&
        validationResult.validConfirmPassword &&
        validationResult.validFinalTermsAndConditions
      )
    default:
      return false
  }
}

export function checkPasswordMatchesRequirements(password: string) {
  // It contains at least 8 characters and at most 20 characters. It contains at
  // least one digit. It contains at least one upper case alphabet. It contains at
  //   least one lower case alphabet. It doesn’t contain any white space. It
  //  contains at least one special character which includes @!?*#$%^&+()=-
  const regex =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@!?*#$%^&+()=-])(?=.*[a-zA-Z]).{8,20}$/
  return regex.test(password)
}

// function GET_DEV_FORM_DATA(): PreRegForm | any {
//   var date = new Date()

//   return {
//     'dob-ui-format': '03/21/1981',
//     'client-uuid': undefined as any,
//     'first-name': 'M',
//     'last-name': 'F',
//     'street-address': 'Main St 123',
//     'street-address2': 'Apt 27',
//     city: 'Chicago',
//     state: 'CO',
//     zip: '60616',
//     ssn: '334965481',
//     'member-id': 'N/A',
//     'subscriber-id': 'N/A',
//     'is-same-as-member-id-ui-check': true,
//     'relationship-to-subscriber': 'MEMBER',
//     // 'subscriber-ssn': '334965481',
//     // 'subscriber-first-name': 'Sub FName',
//     // 'subscriber-last-name': 'Sub LName',
//     // email: `mirza.fazlic+pr${Math.floor(
//     //   Math.random() * (999 - 100 + 1) + 100,
//     // )}@gmail.com`,
//     // email: `mirza.fazlic@gmail.com`,
//     // 'confirm-email': 'mirza.fazlic@gmail.com',
//     // mobile: `(000) ${Math.floor(Math.random() * (999 - 100 + 1) + 100)} - 2222`,
//     // 'sms-opt-in': true,
//     // username: undefined as any,
//     username:
//       'preregtester' + date.getFullYear() + date.getHours() + date.getMinutes(),
//     password: 'Password9$$',
//     confirmedPassword: 'Password9$$',
//     acceptedFinalTermsAndConditions: false,
//   }
// }
