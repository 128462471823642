export const EKS_CODE_NO_SAVINGS_REPORT_FOR_MEMBER = 234
export const EKS_CODE_NO_DEPENDENTS = 208
export const EKS_CODE_NO_MATCHED_MEMBER = 202

export const EKS_CODE_RXSENSE_CONFIG_MISSING = 211

export const EKS_ACCOUNT_LOCKED_CANNOT_GET_QUESTIONS_AGAIN = 230
export const EKS_ACCOUNT_LOCKED_ERROR_CODE_ANSWERS_WRONG_LOCK = 231
export const EKS_INVALID_ANSWERS_ERROR_CODE = 233
//701 is actualy a generic 400 error code
export const EKS_GENERIC_400_CODE = 701
export const EKS_INVALID_OTP_ERROR_CODE = 216
export const EKS_INVALID_OTP_EXPIRED = 215

//invlaid email - both in forgot username and forgot password
export const EKS_LOGIN_MEMBER_NOT_REGISTERED = 608
export const EKS_LOGIN_INVALID_CREDENTIALS = 627

export const EKS_LOGIN_NEED_PASSWORD_RESET = 628

//forgot password codes
export const EKS_CODE_NO_SUCH_USER = 625

export const EKS_CODE_TOO_MANY_OTP_REQUESTED_NEED_TO_WAIT = 663

export const EKS_LOGIN_PREREG_MEMBER_NOT_VERIFIED_BY_BIZOPS = 622
export const EKS_LOGIN_PREREG_MEMBER_NEEDS_TO_DO_SECURITY = 629

export const EKS_LOGIN_ACCOUNT_LOCKED_DUE_TO_WRONG_PASSWORD_ATTEMPTS = 625

export const EKS_LOGIN_MINOR_NOT_ALLOWED = 209

export const EKS_CREATE_PROFILE_MINOR_NOT_ALLOWED = 209
