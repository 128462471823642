import { isValidResponse } from '../../types'
export interface FeatureConfig {
  isSearchDisabled: boolean
  isDrugProfileDisabled: boolean
  isCouponDisabled: boolean
  isBookmarksDisabled: boolean
  isVideoDisabled: boolean
  isDependentDisabled: boolean
  isHideMedsDisabled: boolean
  isLoginDisabled: boolean
  isRegistrationDisabled: boolean
  isInitialized: boolean
}
export const DEFAULT_FEATURE_CONFIG: FeatureConfig = {
  isSearchDisabled: false,
  isDrugProfileDisabled: false,
  isCouponDisabled: false,
  isBookmarksDisabled: false,
  isVideoDisabled: false,
  isDependentDisabled: false,
  isHideMedsDisabled: false,
  isLoginDisabled: false,
  isRegistrationDisabled: false,
  //adding a small flag to check if the feature config is valid
  //this is set once the member profile load is processed
  //used to prevent rendering bottom bar untill we know all disabled features
  isInitialized: false,
}
//these should match to backend /scripta-entities/src/main/java/com/scriptainsights/model/client/feature/FeatureFlag.java
const DRUG_SEARCH = 'DRUG_SEARCH'
const DRUG_PROFILE_PAGE = 'DRUG_PROFILE_PAGE'
const COUPON = 'COUPON'
const DRUG_BOOKMARK = 'COUPON'
const VIDEO_CONTENT = 'VIDEO_CONTENT'
const MANAGE_DEPENDENTS = 'MANAGE_DEPENDENTS'
const HIDE_MEDICATIONS = 'HIDE_MEDICATIONS'
const MEMBER_LOGIN = 'MEMBER_LOGIN'
const MEMBER_REGISTER = 'MEMBER_REGISTER'

interface SingleBackendFeature {
  name: string
  //not sure why there is another designated flag here again as disabled
  //it aready returns a list of "disabled" features so it should always be true???
  disabled: boolean
}

export function translateBackendFeatureConfig(
  backendFeatureResponse: string[],
): FeatureConfig {
  let featureConfig = { ...DEFAULT_FEATURE_CONFIG }
  if (
    Array.isArray(backendFeatureResponse) &&
    backendFeatureResponse.length > 0
  ) {
    //check search
    backendFeatureResponse.forEach((feature: string) => {
      //search
      if (feature === DRUG_SEARCH) {
        featureConfig.isSearchDisabled = true
      }
      //drug profile
      if (feature === DRUG_PROFILE_PAGE) {
        featureConfig.isDrugProfileDisabled = true
      }
      //coupons
      if (feature === COUPON) {
        featureConfig.isCouponDisabled = true
      }
      //bookmarks
      if (feature === DRUG_BOOKMARK) {
        featureConfig.isBookmarksDisabled = true
      }
      //video content
      if (feature === VIDEO_CONTENT) {
        featureConfig.isVideoDisabled = true
      }
      //deps
      if (feature === MANAGE_DEPENDENTS) {
        featureConfig.isDependentDisabled = true
      }
      //hidemeds
      if (feature === HIDE_MEDICATIONS) {
        featureConfig.isHideMedsDisabled = true
      }
      //member login
      if (feature === MEMBER_LOGIN) {
        featureConfig.isLoginDisabled = true
      }
      //member registration
      if (feature === MEMBER_REGISTER) {
        featureConfig.isRegistrationDisabled = true
      }
    })
  } else {
    //in case backend doesnt return array as promised we cannot do much for now

    featureConfig = { ...DEFAULT_FEATURE_CONFIG }
  }

  featureConfig.isInitialized = true

  return featureConfig
}
