import {
  Box,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { GAService } from 'src/app/application/ga/GAService'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { useMemberAppContext } from 'src/app/MemberAppContext'
import { RegistrationStepCard } from 'src/app/registration/components/RegistrationStepCard'
import { useRegistration } from 'src/app/registration/RegistrationContext'
import {
  REG_ROUTE_COMPLETE_PREREG_PROFILE,
  REG_ROUTE_REGISTER_TENANT_ACCOUNT,
} from 'src/app/registration/RegistrationSubRoutes'
import { useRegistrationService } from 'src/app/registration/useRegistrationService'
import { useSColors } from 'src/app/styles/scripta-theme'
import {
  REG_ROUTE_EDIT_CONTACT_INFO_EKS,
  REG_ROUTE_FIND_TENANT,
  REG_ROUTE_IDENTITY_VALIDATION_EKS,
} from './RegistrationSubRoutes'

import { checkMicroServiceResponseStatus } from 'src/app/apiexec/utils'
import { EKS_INVALID_ANSWERS_ERROR_CODE } from 'src/app/EKSStatuCodes'
import SButton from 'src/app/system/customcomponents/SButton'
import { LOG } from 'src/app/v2/applog'
import { ValidateQuestionsEKSInput } from './useRegistrationService'
import STypography from 'src/app/system/customcomponents/STypography'

export function RegChallengeQuestionsEKS() {
  const {
    history,
    // member,
    eksMember,
    setApiResponse,
    setRegistrationJwt,
    matchedTenant,
    setMember,
    eksQuestions,
  } = useRegistration()
  const { isFirstTimePreregMemberAccess } = useMemberAppContext()
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const [nextLoading, setNextLoading] = useState(false)
  const gaService = new GAService()
  const service = useRegistrationService()
  const [hasAnyUnanswered, setHasAnyUnanswered] = useState(true)
  const [answers, setAnswers] = useState<ValidateQuestionsEKSInput>({
    // tenantId: member?.tenantId || 0,
    // memberId: member?.id || 0,
    'address-user-answer': '',
    'drug-name-user-answer': '',
    'prescriber-user-answer': '',
  })

  //can happen during hot reloads
  useEffect(() => {
    if (!eksMember || !matchedTenant || !eksQuestions) {
      LOG.error(
        'registration',
        'Missing eksMember or matchedTenant or eksQuestions, sending back to find tenant,state = ',
        {
          eksMember,
          matchedTenant,
          eksQuestions,
        },
      )
      history.push(REG_ROUTE_FIND_TENANT)
    }
  }, [eksMember, matchedTenant, eksQuestions])

  const [incorrect, setIncorrect] = useState(false)

  const { t } = useSTranslate(['register'])

  const hasSelectedAddress = answers && answers['address-user-answer'] !== ''
  const hasSelectedDrug = answers && answers['drug-name-user-answer'] !== ''
  const hasSelectedPrescriber =
    answers && answers['prescriber-user-answer'] !== ''
  const shouldDisableNextButton =
    !hasSelectedAddress || !hasSelectedDrug || !hasSelectedPrescriber

  // if (!member || !member.tenantId || !member.id) {
  //   //this happens in local dev during restarts

  //   history.push(REG_ROUTE_REGISTER_TENANT_ACCOUNT)

  //   // history.push
  //   // throw new Error(
  //   //   'Cannot continue - member.id or member.tenantId are not set',
  //   // )
  //   //set member and tenant id for dev
  //   // if (IS_DEV()) {
  //   //   setMember({
  //   //     ...member,
  //   //     tenantId: 24,
  //   //     id: 11723,
  //   //   })
  //   // }
  // }

  useEffect(() => {
    const hasAllAnswers =
      answers['address-user-answer'] !== UNSELECTED_ANSWER_VALUE &&
      answers['drug-name-user-answer'] !== UNSELECTED_ANSWER_VALUE &&
      answers['prescriber-user-answer'] !== UNSELECTED_ANSWER_VALUE &&
      answers['address-user-answer'] !== undefined &&
      answers['drug-name-user-answer'] !== undefined &&
      answers['prescriber-user-answer'] !== undefined &&
      answers['address-user-answer'] !== '' &&
      answers['drug-name-user-answer'] !== '' &&
      answers['prescriber-user-answer'] !== ''

    setHasAnyUnanswered(!hasAllAnswers)
  }, [answers])

  useEffect(() => {
    document.title =
      'Verify Your Identity via Security Questions - Scripta Insights'
  })

  // useEffect(() => {
  //   if (hasAnyUnanswered) {
  //     setNextLoading(false)
  //   }
  // }, [hasAnyUnanswered])

  // const doVerifyQuestions = useCallback(() => {
  //   gaService.trackEvent(gaService.eventMap.step_4_reg_next)
  //   asyncVerifyResponse(challengeResponse)
  // }, [challengeResponse])

  if (incorrect) {
    return (
      <Grid container sx={{ padding: '20px' }}>
        <Grid item xs={12}>
          <STypography variant="bodybig_bold" sx={{ textAlign: 'center' }}>
            Invalid Answer
          </STypography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontWeight: 600,
              textAlign: 'center',
              color: 'red',
              padding: '20px',
            }}
          >
            One or more of your answers were incorrect. Please click the button
            below to start the identity validation process again.
          </Typography>
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <SButton
            onClick={() => {
              setIncorrect(false)
              history.push(REG_ROUTE_IDENTITY_VALIDATION_EKS)
            }}
          >
            Try Again
          </SButton>
        </Grid>
      </Grid>
    )
  }

  return (
    <RegistrationStepCard
      registrationType="registration"
      progressBarStep={4}
      // step={t('register:step', { current: 4, total: 7 })}
      // title={title}
      // message={t('register:scriptaRegistrationDescription')}
      cancelButtonTitle={t('common:back')}
      onClickNextButton={async () => {
        setNextLoading(true)
        gaService.trackEvent(gaService.eventMap.step_4_reg_next)
        const attemptId = eksQuestions?.['attempt-id']
        if (!attemptId) {
          LOG.error(
            'registration',
            'Missing attemptId, sending back to identity validation',
          )
          history.push(REG_ROUTE_IDENTITY_VALIDATION_EKS)
          return
        }

        try {
          const validateAnswersResp = await service.validateQuestions_EKS(
            matchedTenant!.clientUuid,
            eksMember!.id,
            attemptId,
            answers,
          )
          setNextLoading(false)

          let allowUndefined = true
          const respStatus = checkMicroServiceResponseStatus(
            validateAnswersResp,
            allowUndefined,
          )

          if (!respStatus.isError) {
            const regJwt = validateAnswersResp.token
            setRegistrationJwt(regJwt)

            if (isFirstTimePreregMemberAccess) {
              history.push(REG_ROUTE_COMPLETE_PREREG_PROFILE)
            } else {
              history.push(REG_ROUTE_EDIT_CONTACT_INFO_EKS)
            }
          } else {
            setIncorrect(true)
            setNextLoading(false)
            //check for a known status code
            if (
              validateAnswersResp &&
              (validateAnswersResp as any).scriptaErrorCode ===
                EKS_INVALID_ANSWERS_ERROR_CODE
            ) {
              setIncorrect(true)
            } else {
              LOG.error(
                'registration',
                'security questions validation failed with the response=',
                validateAnswersResp,
              )
            }
          }
        } catch (e) {
          LOG.error('registration', 'Error verifying answers', e)
          setNextLoading(false)
          // history.push(REG_ROUTE_IDENTITY_VALIDATION_EKS)
          // return
        }
        // await asyncVerifyResponse(answers)
      }}
      onClickCancelButton={() => {
        //EDGE CASE - if user doesnt have email or eligibility info, they will
        //be redirected here automatically
        //so if they click back they would be stuck in a loop
        //if we dont have email and phone we will go all the way back to new tenant then
        if (!eksMember?.email && !eksMember?.phone) {
          history.push(REG_ROUTE_REGISTER_TENANT_ACCOUNT)
        } else {
          history.push(REG_ROUTE_IDENTITY_VALIDATION_EKS)
        }
      }}
      nextButtonDisabled={nextLoading || hasAnyUnanswered}
      nextLoading={nextLoading}
      // enableOverflow={isSmall ? true : false}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography
            sx={(theme) => ({
              textAlign: 'center',
              fontSize: isSmall ? '1.125rem' : '1.5rem',
              fontWeight: 700,
              paddingBottom: '20px',
              paddingTop: '0px',
              // marginTop: isSmall ? '-15px' : '0px',
              lineHeight: '21.92px',
            })}
          >
            {t('register:answerQuestions')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: '1rem',
              fontWeight: 400,
              lineHeight: '20px',
              textAlign: 'center',
              paddingLeft: isSmall ? '20px' : undefined,
              paddingRight: isSmall ? '20px' : undefined,
            }}
          >
            {t('register:answerQuestionDesc')}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            marginTop: '20px',
            paddingLeft: isSmall ? '20px' : '10px',
            paddingRight: isSmall ? '20px' : undefined,
          }}
        >
          <StringArrayNumberValuedBasedRadioGroup
            isSmall={isSmall}
            questionNumber="1"
            questionText={t('regChallengeAddress')}
            data={eksQuestions?.['address-options'] || []}
            onValueChanged={(addNum: number) => {
              if (!eksQuestions) {
                console.log('missing eksQuestions')
                return
              }
              //get the address from the addresses array
              const address = eksQuestions['address-options'][addNum - 1]
              setAnswers({
                ...answers,
                'address-user-answer': address,
              })
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            marginTop: '20px',
            paddingLeft: isSmall ? '20px' : '10px',
            paddingRight: isSmall ? '20px' : undefined,
          }}
        >
          <StringArrayNumberValuedBasedRadioGroup
            isSmall={isSmall}
            questionNumber="2"
            questionText={t('regChallengeMeds')}
            data={eksQuestions?.['drug-name-options'] || []}
            onValueChanged={(drugNum: number) => {
              if (!eksQuestions) {
                console.log('missing eksQuestions')
                return
              }
              const drug = eksQuestions['drug-name-options'][drugNum - 1]
              setAnswers({
                ...answers,
                'drug-name-user-answer': drug,
              })
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            marginTop: '20px',
            paddingLeft: isSmall ? '20px' : '10px',
            paddingRight: isSmall ? '20px' : '10px',
          }}
        >
          <StringArrayNumberValuedBasedRadioGroup
            isSmall={isSmall}
            questionNumber="3"
            questionText={t('regChallengePrescriber')}
            data={eksQuestions?.['prescriber-options'] || []}
            onValueChanged={(docNum: number) => {
              if (!eksQuestions) {
                console.log('missing eksQuestions')
                return
              }
              const doc = eksQuestions['prescriber-options'][docNum - 1]
              setAnswers({
                ...answers,
                'prescriber-user-answer': doc,
              })
            }}
          />
        </Grid>

        {incorrect && (
          <Grid container>
            <Grid item xs={12}>
              <Typography sx={{ textAlign: 'center', color: 'red' }}>
                One or more of your answers were incorrect. Please click the
                button below to try again.
              </Typography>
            </Grid>
            <Grid item xs={12} container justifyContent="center">
              <SButton
                onClick={() => {
                  setIncorrect(false)
                  history.push(REG_ROUTE_IDENTITY_VALIDATION_EKS)
                }}
              >
                Try Again
              </SButton>
            </Grid>
          </Grid>
        )}
      </Grid>
    </RegistrationStepCard>
  )
}

export type UNSELECTED_ANSWER = -1

export const UNSELECTED_ANSWER_VALUE: UNSELECTED_ANSWER = -1
function StringArrayNumberValuedBasedRadioGroup({
  isSmall,
  questionNumber,
  questionText,
  data,
  onValueChanged,
}: {
  isSmall: boolean
  questionNumber: string
  questionText: string
  data: string[]
  onValueChanged: (newVal: number | UNSELECTED_ANSWER) => void
}) {
  const [val, setVal] = useState<string>('')

  useEffect(() => {
    //response numbers are not 0-based
    if (val) {
      onValueChanged(data.indexOf(val) + 1)
    } else {
      onValueChanged(UNSELECTED_ANSWER_VALUE)
    }
  }, [val, data])

  return (
    <Grid item xs={12} container>
      {/* //need to separate the question number from the rest for styling */}
      <Grid item xs="auto">
        <Typography
          sx={{
            fontSize: isSmall ? 16 : 18,
            fontWeight: '400',
            lineHeight: isSmall ? '19.49px' : '21.92px',
          }}
        >
          {`${questionNumber}.`}&nbsp;
        </Typography>
      </Grid>
      <Grid item container xs={11}>
        <Grid item xs={12}>
          <Typography
            sx={(theme) => ({
              fontSize: isSmall ? 16 : 18,
              fontWeight: '400',
              lineHeight: isSmall ? '19.49px' : '21.92px',
              paddingLeft: isSmall ? undefined : '7px',
            })}
          >
            {questionText}
          </Typography>
        </Grid>
        <Grid item container xs={12}>
          <RadioGroup
            sx={{
              marginTop: '10px',
              // paddingLeft: '20px',
              // paddingRight: '20px',
            }}
            aria-label={questionText}
            name={questionText}
            value={val}
            onChange={(e) => {
              setVal(e.target.value as any)
            }}
          >
            {data.map((item, index) => {
              return (
                <RadioOptionWithCentralAlignment
                  key={index}
                  optionValue={item}
                  optionLabel={item}
                />
                // <FormControlLabel
                //   key={index}
                //   value={item}
                //   control={<Radio sx={{ color: PORTAL_COLOR_MAIN_PURPLE }} />}
                //   label={item}
                // />
              )
            })}
          </RadioGroup>
        </Grid>
      </Grid>
    </Grid>
  )
}

//need radio option + label to be vertically aligned to the top,
//seems imposible with mui FormControlLabel for some reason

function RadioOptionWithCentralAlignment({
  optionValue,
  optionLabel,
}: {
  optionValue: string
  optionLabel: string
}) {
  const { COLORS } = useSColors()
  return (
    <Box
      // key={option.id}
      // value={option.value}
      sx={{
        // marginTop: '5px',
        padding: '5px 5px 5px 5px',
        // border: '1px solid black',
        display: 'flex',
        flexDirection: 'row',
        // alignItems: 'flex-start', //TO ALIGN TO TOP
        alignItems: 'center',
        // justifyContent: 'flex-start',
      }}
    >
      <Radio
        value={optionValue}
        sx={{
          padding: '0px',
          alignSelf: 'flext-start',
          color: COLORS.PRIMARY_BRAND,
        }}
      />
      <Typography
        align="left"
        sx={{
          paddingLeft: '5px',
          display: 'flex',
        }}
      >
        <Typography
          component="span"
          // wordWrap="break-word"
          style={{ wordWrap: 'break-word' }}
          sx={{
            fontSize: '0.95rem',
            lineHeight: '24px',
            // paddingLeft: '10px',
            paddingRight: '0px',
            wordBreak: 'break-word',
            // overflowWrap: 'break-word',
            // inlineSize: 'fit-content',
          }}
        >
          {optionLabel}
        </Typography>
      </Typography>
    </Box>
  )
}
