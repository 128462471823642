import { Box, Grid, Button } from '@mui/material'
import { SPACING } from '../system/theme2'
import { BackgroundContainer, MOBILE_HEADER_HEIGHT } from './NewPageContainer'
import myAccountHeroImgSrc from './images/my_account_hero.svg'
import { NavBar } from '../system/NavBar'
import { SummarHeroImageBox } from './HowToSaveSummary'
import { useSizes } from './useNewHooks'
import {
  ROUTE_LEARN_ABOUT_WAYS_TO_SAVE,
  ROUTE_NEW_MY_ACCOUNT_FAQ,
  ROUTE_NEW_MY_ACCOUNT_MY_MEDS,
  ROUTE_NEW_MY_ACCOUNT_SETTINGS,
  ROUTE_NEW_MY_ACCOUNT_SETTINGS_DEPENDENTS,
  ROUTE_NEW_MY_ACCOUNT_SETTINGS_PASSWORD,
  ROUTE_NEW_MY_ACCOUNT_SETTINGS_PREFERENCES,
  ROUTE_NEW_MY_ACCOUNT_SETTINGS_PRIVACY_POLICY,
  ROUTE_NEW_MY_ACCOUNT_SETTINGS_TERMS_OF_USE,
  URL_PARAM_WAYS_TO_SAVE_ACCORDION,
} from './NewMemberPortalMain'
import { MyMedsMainContainer } from '../mymeds/MyMedsMainContainer'
import { useMemberAppContext } from '../MemberAppContext'
import InfoCard from '../system/InfoCard'
import {
  IconDefinition,
  faPills,
  faGear,
  faPiggyBank,
  faCircleQuestion,
  faGlobe,
  faLock,
  faFamily,
  faListCheck,
  faFileLines,
} from '@fortawesome/pro-regular-svg-icons'
import FaqContent from '../system/FaqContent'
import { useEffect } from 'react'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { handleExternalLinkClick } from '../widgets/ExternalLink'
import { DEFAULT_PORTAL_APP_CONFIG } from '../config/appconfig'

export function MyAccountPage() {
  const { t } = useSTranslate('newMyAccount')
  const { isMobile } = useSizes()
  const { history, setShowFeedbackModal, logout, isSsoLogin } =
    useMemberAppContext()
  const logoutButtonText = t('logoutButtonText')

  //set title page
  useEffect(() => {
    document.title = t('myAccountTitle')
  }, [t])

  const navItems: {
    title: string
    body?: string
    route: string
    icon: IconDefinition
  }[] = [
    {
      title: t('myMedsTitle'),
      body: t('myMedsBody'),
      route: ROUTE_NEW_MY_ACCOUNT_MY_MEDS,
      icon: faPills,
    },
    {
      title: t('settingsTitle'),
      body: isSsoLogin ? t('settingsBodySso') : t('settingsBody'),
      route: ROUTE_NEW_MY_ACCOUNT_SETTINGS,
      icon: faGear,
    },
    {
      title: t('savingMethodsTitle'),
      route: `${ROUTE_LEARN_ABOUT_WAYS_TO_SAVE}?${URL_PARAM_WAYS_TO_SAVE_ACCORDION}=true`,
      icon: faPiggyBank,
    },
    {
      title: t('faqTitle'),
      route: ROUTE_NEW_MY_ACCOUNT_FAQ,
      icon: faCircleQuestion,
    },
    {
      title: t('feedbackTitle'),
      route: 'feedback',
      icon: faGear,
    },
  ]

  function handleNavItemClick(route: string) {
    if (route === 'feedback') {
      setShowFeedbackModal(true)
    } else if (route === ROUTE_NEW_MY_ACCOUNT_FAQ) {
      handleExternalLinkClick(DEFAULT_PORTAL_APP_CONFIG.FAQ_URL)
    } else {
      history.push(route)
    }
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <NavBar
          variant={
            isMobile ? 'title2_title_left_purple' : 'title3_title_center_white'
          }
          title={t('myAccountTitle')}
          backButtonHidden
          fixedTop={isMobile ? true : false}
        />
      </Grid>
      {/* header is fixed in mobile */}
      {isMobile && <Box sx={{ minHeight: MOBILE_HEADER_HEIGHT }}></Box>}

      <BackgroundContainer
        variant={isMobile ? 'purple_curved_elipsis' : 'clear_white'}
      >
        <Grid item container gap={SPACING._1} xs={12}>
          <Grid item xs={12} sx={{ marginTop: '5px' }}>
            <SummarHeroImageBox
              svgImageImportUrl={myAccountHeroImgSrc}
              alt={t('myAccountTitle')}
              width={isMobile ? 200 : 250}
              height={isMobile ? 160 : 200}
            />
          </Grid>
          {navItems.map((item, index) => (
            <Grid item xs={12} key={index}>
              <InfoCard
                title={item.title}
                body={item.body}
                variant={'navigation_primary'}
                onClick={() => handleNavItemClick(item.route)}
                icon={item.icon}
              />
            </Grid>
          ))}
          <Grid item xs={12} container justifyContent={'center'}>
            <Button
              sx={{
                color: 'red',
                marginTop: SPACING._05,
                textTransform: 'none',
                lineHeight: '24px',
                fontSize: '18px',
                fontWeight: 700,
                padding: `${SPACING._075} ${SPACING._25}`,
              }}
              onClick={() => logout()}
            >
              {logoutButtonText}
            </Button>
          </Grid>
        </Grid>
      </BackgroundContainer>
    </Grid>
  )
}

export function MyAccountMyMeds() {
  const { isMobile } = useSizes()
  const { t } = useSTranslate('newMyAccount')
  const titleText = t('myMedsTitle')

  return (
    <Grid container>
      <Grid item xs={12}>
        <NavBar
          variant="bodybig_title_center_white"
          title={titleText}
          fixedTop={isMobile ? true : false}
        />
      </Grid>
      <Grid item xs={12} sx={isMobile ? { marginTop: '80px' } : undefined}>
        <MyMedsMainContainer />
      </Grid>
    </Grid>
  )
}

export function MyAccountSettings() {
  const { isMobile } = useSizes()
  const { history, logout, isSsoLogin } = useMemberAppContext()
  const { t } = useSTranslate('newMyAccount')
  const settingsTitle = t('SettingsTitle')
  const logoutButtonText = t('logoutButtonText')

  const navItems: {
    title: string
    body?: string
    route: string
    icon: IconDefinition
  }[] = []

  // TODO: re-enable after spanish support is done
  // navItems.push({
  //   title: t('languagePreferenceTitle'),
  //   body: t('languagePreferenceBody'),
  //   route: 'TODO!',
  //   icon: faGlobe,
  // })

  if (!isSsoLogin) {
    navItems.push({
      title: t('managePasswordTitle'),
      body: t('managePasswordBody'),
      route: ROUTE_NEW_MY_ACCOUNT_SETTINGS_PASSWORD,
      icon: faLock,
    })
  }

  navItems.push({
    title: t('manageDependentsTitle'),
    route: ROUTE_NEW_MY_ACCOUNT_SETTINGS_DEPENDENTS,
    icon: faFamily,
  })

  navItems.push({
    title: t('memberPreferencesTitle'),
    route: ROUTE_NEW_MY_ACCOUNT_SETTINGS_PREFERENCES,
    icon: faListCheck,
  })

  navItems.push({
    title: t('privacyPolicyTitle'),
    route: ROUTE_NEW_MY_ACCOUNT_SETTINGS_PRIVACY_POLICY,
    icon: faFileLines,
  })

  navItems.push({
    title: t('termsOfUseTitle'),
    route: ROUTE_NEW_MY_ACCOUNT_SETTINGS_TERMS_OF_USE,
    icon: faFileLines,
  })

  function handleNavItemClick(route: string) {
    if (
      route === ROUTE_NEW_MY_ACCOUNT_SETTINGS_PRIVACY_POLICY ||
      route === ROUTE_NEW_MY_ACCOUNT_SETTINGS_TERMS_OF_USE
    ) {
      if (route === ROUTE_NEW_MY_ACCOUNT_SETTINGS_PRIVACY_POLICY) {
        handleExternalLinkClick(DEFAULT_PORTAL_APP_CONFIG.PRIVACY_POLICY_URL)
      } else {
        handleExternalLinkClick(
          DEFAULT_PORTAL_APP_CONFIG.TERMS_AND_CONDITIONS_URL,
        )
      }
    } else {
      history.push(route)
    }
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <NavBar variant="bodybig_title_center_white" title={settingsTitle} />
      </Grid>
      <Grid
        item
        container
        gap={SPACING._05}
        xs={12}
        sx={isMobile ? { padding: SPACING._1 } : { paddingTop: SPACING._1 }}
      >
        {navItems.map((item, index) => (
          <Grid item xs={12} key={index}>
            <InfoCard
              title={item.title}
              body={item.body}
              variant={'navigation_secondary'}
              onClick={() => handleNavItemClick(item.route)}
              icon={item.icon}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

export function MyAccountFaq() {
  const { t } = useSTranslate('newMyAccount')
  const titleText = t('faqTitle')
  return (
    <Grid container>
      <Grid item xs={12}>
        <NavBar variant="bodybig_title_center_white" title={titleText} />
      </Grid>
      <Grid item xs={12} sx={{ padding: SPACING._1 }}>
        <FaqContent />
      </Grid>
    </Grid>
  )
}
