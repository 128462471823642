import { MatchType } from 'src/app/registration/NewTenantAccount'
import {
  ApiUrl,
  CreateMemberAccountData,
  FindMemberEKSResp,
  FindTenantEKSResp,
  isValidResponse,
  Member,
  MemberPolicyAcceptData,
  MemberTOUAcceptData,
  OneTimePassCode,
  PasswordResetData,
  QuickLinkFindAcccountReponse,
  RegistrationChallenge,
  RegistrationChallengeResponse,
  RegistrationData,
  RegistrationSetupData,
  StatusCode,
  TenantIdWithOptions,
  VerifyRegistrationIdentityResponse,
} from '../../types'
import {
  ExOps,
  getMicroserviceApiDetails,
  getModulesApiEndpoint,
  ScriptaAppLocation_Header_Value_Mobile,
  ScriptaAppLocation_Header_Value_Portal,
} from '../apiexec/portalapiexec'
import { usePublicPortalApi } from '../apiexec/usePortalApi'
import { IS_NATIVE } from '../apiexec/utils'
import { toServerDate } from '../helpmeswitch/types'
import {
  acceptMemberPolicy,
  acceptMemberTOU,
  authenticateMemberEndpoint,
  memberModule,
  resetPassword,
} from '../member/module-config'
import { toDigitsOnly } from '../member/phone-field-utils'
import { useMemberAppContext } from '../MemberAppContext'
import { LOG } from '../v2/applog'
import {
  captureEmail,
  createMemberAccount,
  findAccount,
  findAccountFromQuickLink,
  registrationModule,
  sendVerificationOtp,
  smsOptIn,
  verificationChallengeQuestions,
  verifyChallengeQuestionsResponse,
  verifyOtp,
  verifyQuickLinkOtp,
} from './module-config'
import { isSSNRequired, PreRegForm, VerifyCodeResp } from './PreReg'
import {
  RESPONSE_CODE_COMPANY_NOT_FOUND,
  RESPONSE_CODE_EMAIL_ALREADY_USED,
  RESPONSE_CODE_PHONE_ALREADY_USED,
} from './RegistrationErrorHandler'
import { UNSELECTED_ANSWER } from './RegChallengeQuestionsEKS'

export const authenticateMember_EKS_Endpoint: ApiUrl = {
  endpoint: '/member-service/api/login',
  apiType: 'member_micro',
  apiVersion: '1.0',
}
export interface AuthenticateMember_EKS_Resp {
  token: string
}
export const forgotUsername_EKS_Endpoint: ApiUrl = {
  endpoint: '/member-service/api/forgot-username',
  apiType: 'member_micro',
  apiVersion: '1.0',
}
export const forgotPassword_EKS_Endpoint: ApiUrl = {
  endpoint: '/member-service/api/forgot-password',
  apiType: 'member_micro',
  apiVersion: '1.0',
}

//will also use custom header registration-token that was issued in the previous step
export const createMemberRegistrationEKS: ApiUrl = {
  endpoint: '/member-service/api/registered-members',
  apiType: 'member_micro',
  apiVersion: '1.0',
}
export interface CreateMemberRegistrationEKSInput {
  'user-name': string
  pwd: string
  email: string
  phone?: string
  'sms-opt-in': boolean
}

export const validateOtpEKS: ApiUrl = {
  endpoint: '/member-service/api/otp/validate',
  apiType: 'member_micro',
  apiVersion: '1.0',
}
export const generateOtpEKS: ApiUrl = {
  endpoint: '/member-service/api/otp/generate',
  apiType: 'member_micro',
  apiVersion: '1.0',
}

export const findTenantEKS: ApiUrl = {
  endpoint: '/member-service/api/search-clients',
  apiType: 'member_micro',
  apiVersion: '1.0',
}
const findMemberEKS: ApiUrl = {
  endpoint: '/member-service/api/search-members',
  apiType: 'member_micro',
  apiVersion: '1.0',
}

const validateQuestionsEKS: ApiUrl = {
  endpoint: '/member-service/api/security-questions',
  apiType: 'member_micro',
  apiVersion: '1.0',
}
export interface ValidateQuestionsEKSInput {
  'drug-name-user-answer': string | UNSELECTED_ANSWER
  'address-user-answer': string | UNSELECTED_ANSWER
  'prescriber-user-answer': string | UNSELECTED_ANSWER
}
const loadSecurityQuestionsEKS: ApiUrl = {
  endpoint: '/member-service/api/security-questions',
  apiType: 'member_micro',
  apiVersion: '1.0',
}

export interface LoadQuestionsEksResponse {
  'attempt-id': string
  'drug-name-options': string[]
  'address-options': string[]
  'prescriber-options': string[]
}
const clientLogoEndpoint: ApiUrl = {
  endpoint: '/member-service/api/clients', //{clientUuid}/logo',
  apiType: 'member_micro',
  apiVersion: '1.0',
}

const processPreregCodeEndpoint: ApiUrl = {
  endpoint: '/member-service/api/registration-codes',
  apiType: 'member_micro',
  apiVersion: '1.0',
}

const savePreregFormEndpoint: ApiUrl = {
  endpoint: '/member-service/api/preregistered-members',
  apiType: 'member_micro',
  apiVersion: '1.0',
}
const submitMemberSupportRequestEndpoint: ApiUrl = {
  endpoint: '/member-service/api/registration-support',
  apiType: 'member_micro',
  apiVersion: '1.0',
}

export type EksOtpType =
  | 'MEMBER_EMAIL_VERIFICATION_EVENT'
  | 'MEMBER_PHONE_VERIFICATION_EVENT'
  | 'MEMBER_PHONE_SECURITY_CLEARANCE_EVENT'
  | 'MEMBER_EMAIL_SECURITY_CLEARANCE_EVENT'
export interface ValidateOtpEKSResp {
  token: string
}
export interface ValidateQuestionsEKSResp {
  token: string
}

export interface GenerateOtpEKSInput {
  'client-id': string
  'member-id': string
  'event-type': EksOtpType
}

export interface ValidateOtpEKSInput {
  'client-id': string
  'member-id': string
  otp: string
  'event-type': EksOtpType
}

export interface ClientNotFoundSupportRequest {
  'support-type': 'Company not found'
  'company-data': {
    'search-term': string
    'user-email': string | undefined
    'user-phone': string | undefined
  }
}
export interface MemberNotFoundSupportRequest {
  'support-type': 'Member not found'
  'member-data': {
    'client-name': string
    'client-id': string
    'user-email': string
    'user-phone': string
    'identification-type':
      | 'MEMBER_ID'
      | 'EMPLOYEE_ID'
      | 'SOCIAL_SECURITY_NUMBER'
    'first-name': string
    'last-name': string
    dob: string
    'identification-value': string
  }
}

export function useRegistrationService() {
  const { portalPublicApi } = usePublicPortalApi()
  const { setJwt } = useMemberAppContext()
  const { langCodeSelectedOnLoginScreen } = useMemberAppContext()

  async function submitCompanyNotFoundSupportRequest(
    supportRequestData:
      | ClientNotFoundSupportRequest
      | MemberNotFoundSupportRequest,
  ): Promise<void | StatusCode> {
    let { url, ops } = getMicroserviceApiDetails(
      submitMemberSupportRequestEndpoint,
    )

    return portalPublicApi.postMicro<any>(url, supportRequestData, ops)
  }

  async function getClientLogoUrl(
    clientUuid: string,
    clientChildCompanyId?: number,
  ) {
    let { url, ops } = getMicroserviceApiDetails(clientLogoEndpoint)

    //if we have a child compan id, then we need to append it to the url
    ///member-service/api/clients/06eb246f-539e-11ee-b0d2-163c10fd62ef/child-companies/1/logo
    let finalUrl = `${url}/${clientUuid}/logo`

    if (clientChildCompanyId) {
      finalUrl = `${url}/${clientUuid}/child-companies/${clientChildCompanyId}/logo`
    }
    return portalPublicApi.getMicro<Blob>(finalUrl, ops)
  }

  async function processPreregistrationCode(
    code: string,
    isManualCodeEntry: boolean,
  ) {
    let { url, ops } = getMicroserviceApiDetails(processPreregCodeEndpoint)
    let finalUrl = `${url}/${code}/verify`
    //custom headers for backend analytics tracking - if it was manual vs link
    const HEADER_SOURCE = isManualCodeEntry
      ? 'MEMBER_PORTAL_ACCESS'
      : 'EMAIL_LINK'
    ops = {
      ...ops,
      customHeaders: { ...ops?.customHeaders, source: HEADER_SOURCE },
    }

    return portalPublicApi.getMicro<VerifyCodeResp>(finalUrl, ops)
  }
  async function savePreregistrationForm(regForm: PreRegForm) {
    let { url, ops } = getMicroserviceApiDetails(savePreregFormEndpoint)

    //first remove all the UI only fields
    const intermediateData: Partial<PreRegForm> = { ...regForm }
    delete intermediateData['client-uuid']
    delete intermediateData['is-same-as-member-id-ui-check']
    delete intermediateData['dob-ui-format']
    delete intermediateData['confirmedPassword']
    delete intermediateData['acceptedFinalTermsAndConditions']

    //if subscriber-ssn is empty, delete it from payload
    if (!intermediateData['subscriber-ssn']) {
      delete intermediateData['subscriber-ssn']
    }

    //if SSN is not required - it will be hidden in the UI but we need it in the backend as 999 99 9999
    if (!isSSNRequired(regForm['client-uuid'])) {
      intermediateData['subscriber-ssn'] = '999999999'
      intermediateData['ssn'] = '999999999'
    }

    delete intermediateData.specificallyConfirmedOptOutInDialog

    let backendPayload: any = {
      ...intermediateData,
    }

    function toNumberDigitsOnly(num: string) {
      if (!num) return num
      return num.replace(/\D/g, '')
    }

    // delete backendPayload.accepted
    backendPayload['dob'] = toServerDate(regForm['dob-ui-format'])
    backendPayload['mobile'] = toNumberDigitsOnly(regForm['mobile'])
    //append street2 to street1 with comma before sending to backend
    if (regForm['street-address2']) {
      backendPayload['street-address'] =
        backendPayload['street-address'] + ', ' + regForm['street-address2']
    }
    delete backendPayload['street-address2']

    const HEADER_CLIENT_ID = regForm['client-uuid']

    ops = {
      ...ops,
      customHeaders: { ...ops?.customHeaders, id: HEADER_CLIENT_ID },
    }

    // let finalUrl = `${url}/${code}`
    return portalPublicApi.postMicro<any>(url, backendPayload, ops)
    // return new Promise((resolve, reject) => {
    //   setTimeout(() => resolve({ 'client-id': 123 }), 3000)
    // })
  }

  async function findTenantApi(
    registrationData: RegistrationData,
  ): Promise<TenantIdWithOptions | StatusCode> {
    // const url = getModulesApiEndpoint(registrationModule, findTenant)
    // const legacyTenant = await portalPublicApi.post<Tenant | StatusCode>(
    //   url,
    //   registrationData,
    // )
    // console.log('legacyTenant model resolved as', legacyTenant)
    const eksFindClientResponse = await findTenantApi_EKS(registrationData)
    if (
      eksFindClientResponse &&
      (eksFindClientResponse as any).httpStatusCode === 404
    ) {
      const statusCode: StatusCode = {
        code: RESPONSE_CODE_COMPANY_NOT_FOUND,
        description: 'Company not found',
      }
      return statusCode
    }
    const eksTenant = eksToTenant(eksFindClientResponse)
    return eksTenant
  }

  function eksToTenant(eks: FindTenantEKSResp): TenantIdWithOptions {
    if (eks.clients.length > 1) {
      LOG.error(
        'registration',
        'multiple clients found in eks response.will default to first one',
        {
          eks,
        },
      )
    }
    const firstClient = eks.clients[0]
    // const clientId = uuidToClientId(firstClient.uuid)
    const tenant: TenantIdWithOptions = {
      // id: clientId, this no longer existins in EKS - we are dealing only with uuid
      clientUuid: firstClient.uuid,
      name: firstClient.name,
      ssnAvailable: firstClient['accept-ssn'],
      memberIdAvailable: firstClient['accept-member-id'],
      companyIdAvailable: firstClient['accept-company-id'],
    }
    return tenant
  }

  async function findTenantApi_EKS(
    registrationData: RegistrationData,
  ): Promise<FindTenantEKSResp> {
    let { url, ops } = getMicroserviceApiDetails(findTenantEKS)
    //here need the filter as search in the headers
    //filter: {"company-name":"Plante Moran"}
    const filterObj: Record<string, string> = {}
    if (registrationData.companyName) {
      filterObj['company-name'] = registrationData.companyName
    }
    if (registrationData.primaryEmail) {
      filterObj['email'] = registrationData.primaryEmail
    }
    // Convert to JSON string only if we have filters
    const filter =
      Object.keys(filterObj).length > 0 ? JSON.stringify(filterObj) : ''
    ops = {
      ...ops,
      customHeaders: { ...ops?.customHeaders, filter },
    }
    return portalPublicApi.getMicro<FindTenantEKSResp>(url, ops)
  }

  async function findTenantMemberFromQuickLinkApi(
    quickLinkCode: string,
  ): Promise<QuickLinkFindAcccountReponse | StatusCode> {
    const url =
      getModulesApiEndpoint(registrationModule, findAccountFromQuickLink) +
      '?quickLinkCode=' +
      quickLinkCode
    return portalPublicApi.get<QuickLinkFindAcccountReponse | StatusCode>(url)
  }

  async function validateQuickLinkOtpApi(
    quickLinkCode: string,
    quickLinkOtp: string,
  ): Promise<QuickLinkFindAcccountReponse | StatusCode> {
    const url = `${getModulesApiEndpoint(
      registrationModule,
      verifyQuickLinkOtp,
    )}?quickLinkCode=${quickLinkCode}&quickLinkOtp=${quickLinkOtp}`
    return portalPublicApi.get<QuickLinkFindAcccountReponse | StatusCode>(url)
  }

  async function authenticateMember_EKS(
    userName: string,
    password: string,
    // whatToDoWithJwt: 'initToContext' | 'returnAsString',
  ): Promise<AuthenticateMember_EKS_Resp> {
    let { url, ops } = getMicroserviceApiDetails(
      authenticateMember_EKS_Endpoint,
    )
    const payload = {
      username: userName,
      password: password,
    }
    return portalPublicApi.postMicro<any>(url, payload, ops)
  }

  async function authenticateMemberApi(
    userName: string,
    password: string,
    whatToDoWithJwt: 'initToContext' | 'returnAsString',
  ): Promise<void | StatusCode | string> {
    const url = getModulesApiEndpoint(memberModule, authenticateMemberEndpoint)

    //we haveto process separatelly this network request becase its returning both json data as well
    //as optional auth headers
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({ userName, password }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ScriptaAppLocation: IS_NATIVE()
          ? ScriptaAppLocation_Header_Value_Mobile
          : ScriptaAppLocation_Header_Value_Portal,
      },
    })
    //check http level response is ok first
    if (response.ok) {
      //extract the authorization header from the raw response
      const jwtToken =
        response && response.headers
          ? response.headers.get('Authorization')
          : undefined

      if (jwtToken) {
        if (whatToDoWithJwt === 'initToContext') {
          setJwt(jwtToken)
        } else if (whatToDoWithJwt === 'returnAsString') return jwtToken
      }
      //then return the optional json for further processing (i.e. scripta statusCodes, memberprofile etc)
      return await response.json()
    } else {
      //unforunatelly, backend mixes up http status codes but still returns json data with scripta codes...
      try {
        return await response.json()
      } catch (error) {
        //TODO - can this happen?
        console.error('Error reading json from auth call')
      }
    }
  }

  async function findAccountApi(
    registrationData: RegistrationData,
  ): Promise<Member | StatusCode> {
    const url = getModulesApiEndpoint(registrationModule, findAccount)
    return portalPublicApi.post<Member | StatusCode>(url, registrationData)
  }

  // const MOCK_QUESTIONS = {
  //   'attempt-id': '938d8f42-8252-43c2-9982-c0f98c84bd65',
  //   'drug-name-options': [
  //     'ACYCLOVIR TABLETS 400 MG',
  //     'FLUCONAZOLE 150',
  //     'ACETAMINOPHEN AND CODEINE PHOSPHATE TABLETS 300 MG-30 MG',
  //   ],
  //   'address-options': ['6856 APACHE 4', '52 ASPEN 1', '435 MILLER CREEK RD.'],
  //   'prescriber-options': [
  //     'Dr. NGUYEN',
  //     'DR. JOHN DIMARCO',
  //     'DR. GEORGE BELLER',
  //   ],
  // }

  async function createRegistration_EKS({
    registrationJwt,
    userName,
    password,
    email,
    phone,
    smsOptInt,
  }: {
    registrationJwt: string
    userName: string
    password: string
    email: string
    phone?: string
    smsOptInt?: boolean
  }) {
    let { url, ops } = getMicroserviceApiDetails(createMemberRegistrationEKS)
    const customHeaders = {
      ...ops?.customHeaders,
      'registration-token': registrationJwt,
    }
    ops = { ...ops, customHeaders }
    const payload: CreateMemberRegistrationEKSInput = {
      'user-name': userName,
      pwd: password,
      email: email,
      'sms-opt-in': smsOptInt && smsOptInt == true ? true : false,
    }
    if (phone) {
      let backendPhone = `1${toDigitsOnly(phone)}`
      payload['phone'] = backendPhone
    }
    return portalPublicApi.postMicro<any>(url, payload, ops)
  }

  async function validateQuestions_EKS(
    tenantUuid: string,
    encryptedMemberId: string,
    //this is the id returned in the load of the questions
    questionsAttemptId: string,
    answers: ValidateQuestionsEKSInput,
  ): Promise<ValidateQuestionsEKSResp> {
    let { url, ops } = getMicroserviceApiDetails(validateQuestionsEKS)
    const filterObj: Record<string, string> = {}
    filterObj['tenant-id'] = tenantUuid
    filterObj['member-id'] = encryptedMemberId
    filterObj['attempt-id'] = questionsAttemptId
    const filter =
      Object.keys(filterObj).length > 0 ? JSON.stringify(filterObj) : ''
    ops = { ...ops, customHeaders: { ...ops?.customHeaders, filter } }

    return portalPublicApi.postMicro<any>(url, answers, ops)
  }

  async function validateOtp_EKS(
    tenantUuid: string,
    encryptedMemberId: string,
    eventType: EksOtpType,
    otp: string,
  ): Promise<ValidateOtpEKSResp> {
    let { url, ops } = getMicroserviceApiDetails(validateOtpEKS)
    const payload: ValidateOtpEKSInput = {
      'client-id': tenantUuid,
      'member-id': encryptedMemberId,
      otp: otp,
      'event-type': eventType,
    }
    return portalPublicApi.postMicro<any>(url, payload, ops)
  }

  async function generateOtp_EKS(
    tenantUuid: string,
    encryptedMemberId: string,
    eventType:
      | 'MEMBER_EMAIL_VERIFICATION_EVENT'
      | 'MEMBER_PHONE_VERIFICATION_EVENT',
  ): Promise<any> {
    let { url, ops } = getMicroserviceApiDetails(generateOtpEKS)
    const payload: GenerateOtpEKSInput = {
      'client-id': tenantUuid,
      'member-id': encryptedMemberId,
      'event-type': eventType,
    }
    return portalPublicApi.postMicro<any>(url, payload, ops)
  }
  async function loadSecurityQuestionsApi_EKS(
    tenantUuid: string,
    encryptedMemberId: string,
  ): Promise<any> {
    // return new Promise((resolve) => {
    //   setTimeout(() => resolve(MOCK_QUESTIONS), 3000)
    // })
    let { url, ops } = getMicroserviceApiDetails(loadSecurityQuestionsEKS)
    const filterObj: Record<string, string> = {}
    filterObj['tenant-id'] = tenantUuid
    filterObj['member-id'] = encryptedMemberId
    const filter =
      Object.keys(filterObj).length > 0 ? JSON.stringify(filterObj) : ''
    ops = { ...ops, customHeaders: { ...ops?.customHeaders, filter } }
    return portalPublicApi.getMicro<any>(url, ops)
  }

  async function findAccount_EKS({
    firstName,
    lastName,
    dob,
    matchType,
    matchValue,
    tenantUuid,
  }: {
    firstName: string
    lastName: string
    dob: string
    matchType: MatchType
    matchValue: string
    tenantUuid: string
  }): Promise<FindMemberEKSResp> {
    let { url, ops } = getMicroserviceApiDetails(findMemberEKS)
    //here need the filter as search in the headers
    // {"tenant-id":"193", "first-name":"JAIME", "last-name":"3bf2d8bc5ec9a7792ae5ec88bdb1b630", "date-of-birth":"1976-01-01", "identification-type":"SSN", "identification-value":"9448"}
    const filterObj: Record<string, string> = {}
    filterObj['tenant-id'] = tenantUuid
    filterObj['first-name'] = firstName
    filterObj['last-name'] = lastName
    filterObj['date-of-birth'] = dob
    filterObj['identification-value'] = matchValue

    if (matchType === 'SSN') {
      filterObj['identification-type'] = 'SOCIAL_SECURITY_NUMBER'
    } else if (matchType === 'EID') {
      filterObj['identification-type'] = 'EMPLOYEE_ID'
    } else if (matchType === 'MID') {
      filterObj['identification-type'] = 'MEMBER_ID'
    }

    // Convert to JSON string only if we have filters
    const filter =
      Object.keys(filterObj).length > 0 ? JSON.stringify(filterObj) : ''
    ops = {
      ...ops,
      customHeaders: { ...ops?.customHeaders, filter },
    }

    const eksFindClientResponse =
      await portalPublicApi.getMicro<FindMemberEKSResp>(url, ops)

    return eksFindClientResponse
  }

  async function checkEmailAvailableApi(
    tenantId: number,
    memberId: number,
    email: string,
  ): Promise<boolean> {
    //lets just assume its availalbe, unless we were specifically get a dupe error code
    //this is just for ui validation as the user types
    //once we get field-validation api to be refactored
    let isAvailable = true
    try {
      const paylod: Partial<RegistrationSetupData> = {
        tenantId: tenantId,
        memberId: memberId,
        email: email,
      }
      let url = getModulesApiEndpoint(registrationModule, captureEmail)
      let availableUrl = url + '?checkAvailableOnly=true'
      const resp = await portalPublicApi.post<void | StatusCode>(
        availableUrl,
        paylod,
      )
      if (!isValidResponse(resp)) {
        if (resp && (resp as any).code === RESPONSE_CODE_EMAIL_ALREADY_USED) {
          isAvailable = false
        }
      }
    } catch (error) {
      LOG.error('registration', 'Error checking email availability', {
        error,
      })
    }
    return isAvailable
  }

  async function captureSmsOptInApi(
    tenantId: number,
    memberId: number,
    mobilePhone: string,
  ): Promise<void | StatusCode> {
    const paylod: Partial<RegistrationSetupData> = {
      tenantId: tenantId,
      memberId: memberId,
      mobilePhone: mobilePhone,
    }
    const url = getModulesApiEndpoint(registrationModule, smsOptIn)
    return portalPublicApi.post<void | StatusCode>(url, paylod)
  }

  async function checkPhoneAvailableApi(
    tenantId: number,
    memberId: number,
    phoneNumber: string,
  ): Promise<boolean> {
    //lets just assume its availalbe, unless we were specifically get a dupe error code
    //this is just for ui validation as the user types
    //once we get field-validation api to be refactored
    let isAvailable = true
    try {
      //add country code 1 if needed for server phone
      let digitsOnly = toDigitsOnly(phoneNumber)

      const finalPhoneWithCountryCode =
        digitsOnly && digitsOnly.length === 10
          ? `1${digitsOnly}`
          : `${digitsOnly}`

      const paylod: Partial<RegistrationSetupData> = {
        tenantId: tenantId,
        memberId: memberId,
        mobilePhone: finalPhoneWithCountryCode,
      }
      let url = getModulesApiEndpoint(registrationModule, smsOptIn)
      let availableUrl = url + '?checkAvailableOnly=true'
      const resp = await portalPublicApi.post<void | StatusCode>(
        availableUrl,
        paylod,
      )
      if (!isValidResponse(resp)) {
        if (resp && (resp as any).code === RESPONSE_CODE_PHONE_ALREADY_USED) {
          isAvailable = false
        }
      }
    } catch (error) {
      LOG.error('registration', 'Error checking phone availability', {
        error,
      })
    }
    return isAvailable
  }

  async function createMemberAccountApi(
    createData: CreateMemberAccountData,
  ): Promise<void | StatusCode> {
    const url = getModulesApiEndpoint(registrationModule, createMemberAccount)
    return portalPublicApi.post<void | StatusCode>(url, createData)
  }

  //NOTE here - this API is shared between both registartion flows and standard app
  //it is defined in memberModule (not registrationModule)
  async function memberAcceptPolicyApi(
    acceptPolicyData: MemberPolicyAcceptData,
  ): Promise<any | StatusCode> {
    const url = getModulesApiEndpoint(memberModule, acceptMemberPolicy)
    return portalPublicApi.post<void | StatusCode>(url, acceptPolicyData)
  }
  async function memberAcceptTOUApi(
    acceptTOUData: MemberTOUAcceptData,
  ): Promise<any | StatusCode> {
    const url = getModulesApiEndpoint(memberModule, acceptMemberTOU)
    return portalPublicApi.post<void | StatusCode>(url, acceptTOUData)
  }

  // async function forgotUsernameApi(email: string): Promise<any | StatusCode> {
  //   //NOTE below we read this one from member module,not registration
  //   //this is where it was defined by RG, but in UI we need jwt in order to instantiate useMemberService
  //   const url = getModulesApiEndpoint(memberModule, forgotUserName)
  //   //actualy payload is regsetupd data with just email
  //   const forgotUsernamePayload: Partial<RegistrationSetupData> = {
  //     email: email,
  //   }
  //   //send username now supports language header
  //   let ops: ExOps | undefined = undefined
  //   if (langCodeSelectedOnLoginScreen) {
  //     ops = {
  //       langCode: langCodeSelectedOnLoginScreen,
  //     }
  //   }
  //   return portalPublicApi.post(url, forgotUsernamePayload, ops)
  // }
  async function forgotUsername_EKS(email: string): Promise<any | StatusCode> {
    let { url, ops } = getMicroserviceApiDetails(forgotUsername_EKS_Endpoint)
    const forgotUsernamePayload = {
      email: email,
    }
    return portalPublicApi.postMicro(url, forgotUsernamePayload, ops)
  }
  async function forgotPassword_EKS(
    userName: string,
  ): Promise<any | StatusCode> {
    let { url, ops } = getMicroserviceApiDetails(forgotPassword_EKS_Endpoint)
    const forgotPasswordPayload = {
      username: userName,
    }
    return portalPublicApi.postMicro(url, forgotPasswordPayload, ops)
  }

  async function resetPassword_EKS(
    otp: string,
    newPassword: string,
    userName: string,
  ): Promise<any | StatusCode> {
    let { url, ops } = getMicroserviceApiDetails(forgotPassword_EKS_Endpoint)
    const resetPasswordPayload = {
      otp: otp,
      password: newPassword,
      username: userName,
    }
    return portalPublicApi.putMicro(url, resetPasswordPayload, ops)
  }

  // async function forgotPasswordApi(
  //   pwdResetData: PasswordResetData,
  // ): Promise<PasswordResetData | StatusCode> {
  //   //NOTE same - we read these from the memberModule
  //   //but use in in reg service whichd oesn use jwt
  //   //below we read this one from member module, not registration
  //   //this is where it was defined by RG, but in UI we need jwt in order to instantiate useMemberService
  //   const url = getModulesApiEndpoint(memberModule, forgotPassword)
  //   //send password now supports language header
  //   let ops: ExOps | undefined = undefined
  //   if (langCodeSelectedOnLoginScreen) {
  //     ops = {
  //       langCode: langCodeSelectedOnLoginScreen,
  //     }
  //   }
  //   return portalPublicApi.post<PasswordResetData>(url, pwdResetData, ops)
  // }
  // async function resetPasswordApi(
  //   pwdResetData: PasswordResetData,
  // ): Promise<void | StatusCode> {
  //   const url = getModulesApiEndpoint(memberModule, resetPassword)
  //   return portalPublicApi.post(url, pwdResetData)
  // }

  return {
    findTenantApi,
    findAccount_EKS,
    findTenantMemberFromQuickLinkApi,
    loadSecurityQuestionsApi_EKS,
    validateQuickLinkOtpApi,
    createMemberAccountApi,
    // authenticateMemberApi,
    // forgotUsernameApi,
    forgotUsername_EKS,
    // forgotPasswordApi,
    forgotPassword_EKS,
    resetPassword_EKS,
    memberAcceptPolicyApi,
    memberAcceptTOUApi,
    processPreregistrationCode,
    savePreregistrationForm,
    getClientLogoUrl,
    submitCompanyNotFoundSupportRequest,
    checkEmailAvailableApi,
    checkPhoneAvailableApi,
    generateOtp_EKS,
    validateOtp_EKS,
    validateQuestions_EKS,
    createRegistration_EKS,
    authenticateMember_EKS,
  }
}
