import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import {
  Box,
  Grid,
  InputAdornment,
  Link,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import accountImg from '../system/images/account.svg'

import { ChangeEvent, useEffect, useState } from 'react'
import { useAmazon } from 'src/app/hooks/useAmazon'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { useSColors } from 'src/app/styles/scripta-theme'
import SButton from 'src/app/system/customcomponents/SButton'
import { SDialog } from 'src/app/system/customcomponents/SDialog'
import STypography from 'src/app/system/customcomponents/STypography'
import { v4 as uuidv4 } from 'uuid'
import MemberNotFoundImage from '../../images/member_not_found_image.png'
import { RegistrationData } from '../../types'
import { useMemberAppContext } from '../MemberAppContext'
import { IS_DEV, checkMicroServiceResponseStatus } from '../apiexec/utils'
import { NewPortalDialogDesign } from '../application/NewDialogs'
import { GAService } from '../application/ga/GAService'
import { toServerDate } from '../helpmeswitch/types'
import { toDigitsOnly } from '../member/phone-field-utils'
import { TargetRoute } from '../v2/SavingGuide'
import { ThemedSVGRemote } from '../v2/ThemedSVGRemote'
import { LOG } from '../v2/applog'
import {
  CompanyOrMemberNotFoundHandler,
  GotItDialogForSupportRequest,
} from './FindTenantComponent'
import { RegistrationFormFieldsBox } from './RegistrationContainer'
import {
  PrevValuesCache,
  RegToPreregJumpState,
  useRegistration,
} from './RegistrationContext'
import { SSOAlreadyRegisteredError } from './RegistrationErrorHandler'
import {
  REG_ROUTE_ERROR_HANDLER,
  REG_ROUTE_FIND_TENANT,
  REG_ROUTE_FORGOT_PASSWORD,
  REG_ROUTE_FORGOT_USERNAME,
  REG_ROUTE_IDENTITY_VALIDATION_EKS,
  REG_ROUTE_LOGIN,
  REG_ROUTE_PREREGISTER,
} from './RegistrationSubRoutes'
import azPrimaryMemberInstructions from './az_primary_instructions.svg'
import { CustomTextField } from './components/CustomTextField'
import {
  NewTenantRadioGroup,
  NewTenantRadioGroupOptions,
} from './components/RadioGroups'
import { RegistrationStepCard } from './components/RegistrationStepCard'
import {
  MemberNotFoundSupportRequest,
  useRegistrationService,
} from './useRegistrationService'
import { dateWithYearMonthDay } from './utils/Formaters'
import BaseErrorCard from 'src/app/system/error/BaseErrorCard'
import { EKS_CODE_NO_MATCHED_MEMBER } from '../EKSStatuCodes'

export type MatchType = RegistrationData['matchType']

export function NewTenantAccount() {
  const gaService = new GAService()
  const {
    setApiResponse,
    matchedTenant,
    history,
    setMember,
    setRegistrationData,
    setPrevValuesCache,
    prevValuesCache,
    renderAmazonPrimaryMemberInstructions,
    setRenderAmazonPrimaryMemberInstructions,
    setEksMember,
    setMatchedTenant,
  } = useRegistration()

  const { clientUuidInRegistration, setClientUuidInRegistration } =
    useMemberAppContext()

  const [nextLoading, setNextLoading] = useState(false)

  const [isAlreadyRegistered, setIsAlreadyRegistered] = useState(false)
  const [isAlreadyPreregistered, setIsAlreadyPreregistered] = useState(false)
  const [isMigrated, setIsMigrated] = useState(false)

  const [isRegisteredAsSsoUserAlready, setIsRegisteredAsSsoUserAlready] =
    useState(false)

  //will be set in previous componet
  //it is needed in case we need to jump to preregistration for recently added plan members
  //when we cannot find them
  const service = useRegistrationService()
  const { featureConfig } = useMemberAppContext()

  //additional extra dialos for amazon members
  //to tell the primary member they dont need to register - they should go to  a to z
  //one is 'instructional' and happens before they provide any info
  //the other is if when we find them we see they are a primary member
  const { isAmazon } = useAmazon()

  const [
    renderAmazonFoundMemberIsPrimaryDialog,
    setRenderAmazonFoundMemberIsPrimaryDialog,
  ] = useState(false)

  //TODO - get the value based on the reponse from find account eks api
  const [alreadyRegistered, setAlreadyRegistered] = useState(false)

  const theme = useTheme()
  const { COLORS } = useSColors()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  const [isRegisteredDisabled, setIsRegistrationDisabled] =
    useState<boolean>(false)
  const { t } = useSTranslate(['register', 'common'])

  const firstNameCache = prevValuesCache?.firstName || ''
  const lastNameCache = prevValuesCache?.lastName || ''
  const matchTypeCache = prevValuesCache?.matchType || ''
  const firstNameValue = IS_DEV() ? 'VALOUSSA' : firstNameCache
  const lastNameValue = IS_DEV()
    ? '8858288390abcf569779d6611ccf51ab'
    : lastNameCache

  const validationNumberValue = IS_DEV() ? '5615' : ''
  const dateOfBirthCache = IS_DEV()
    ? '01/01/1999'
    : prevValuesCache?.dateOfBirth || ''

  const [firstName, setFirstName] = useState(firstNameValue)
  const [lastName, setLastName] = useState(lastNameValue)
  const [dateOfBirth, setDateOfBirth] = useState(dateOfBirthCache)
  const [validationNumber, setValidationNumber] = useState(
    validationNumberValue,
  )
  const [securityValidationType, setSecurityValidationType] =
    useState(matchTypeCache)
  const fieldsAreEmpty =
    !firstName || !lastName || !dateOfBirth || !validationNumber
  const isDOBIncorrect =
    !!dateOfBirth &&
    !/^(0?[1-9]|1[0-2])\/(0?[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/.test(
      dateOfBirth,
    )
  const shouldDisableNextButton =
    fieldsAreEmpty || !securityValidationType || isDOBIncorrect

  //first prompt the user if recent add to the plan, in which case if yes they will be sent to prereg
  const [renderAskIfRecentPlanMember, setRenderAskIfRecentPlanMember] =
    useState(false)
  //if they say NO in that dialog, we will let them submit optional member support request
  const [
    renderMemberNotFoundSupportRequest,
    setRenderMemberNotFoundSupportRequest,
  ] = useState(false)

  //once they provide their feedback in case of follow up support request, we will show them this dialog
  const [renderOkGotIt, setRenderOkGotIt] = useState(false)

  //is finished before doing standard history.push to subroute
  //so we do it by settinng a custom route and then route to it in useEffect
  const [targetRoute, setTargetRoute] = useState<TargetRoute>()
  function pushToSubrouteViaEffect(subroute: string) {
    //we use the object, not a simple string, to ensure useEffect triggers
    const route: TargetRoute = {
      //todo - this guid is probably not needed anymore, we use a object in state
      uuid: uuidv4(),
      route: subroute,
    }

    setTargetRoute(route)
  }

  //here we watch for the target route to be set and then we push to it
  useEffect(() => {
    if (targetRoute) {
      history.push(targetRoute.route)
    }
  }, [targetRoute])

  //fix the issue where if only memberid is available for type of match, it should default to it
  useEffect(() => {
    if (
      matchedTenant?.memberIdAvailable &&
      !matchedTenant?.ssnAvailable &&
      !matchedTenant?.companyIdAvailable
    ) {
      setSecurityValidationType(NewTenantRadioGroupOptions.MEMBER_ID)
    }
  }, [])

  //new alert - if all 3 matche types are disabled, we cannot register, something is wrong in client config
  useEffect(() => {
    //wait for feature config to be initialized - so we don't
    if (featureConfig && featureConfig.isInitialized) {
      let isRegFeatureDisabled = featureConfig.isRegistrationDisabled

      if (isRegFeatureDisabled) {
        setIsRegistrationDisabled(true)
        LOG.error(
          'registration',
          'Member is trying to register - but registration is disabled via feature flag for client with uuid = ' +
            matchedTenant?.clientUuid,
        )
      }
      if (matchedTenant && matchedTenant.clientUuid) {
        const isAllDisabled =
          !matchedTenant.memberIdAvailable &&
          !matchedTenant.ssnAvailable &&
          !matchedTenant.companyIdAvailable
        if (isAllDisabled && !isRegFeatureDisabled) {
          LOG.error(
            'registration',
            'All member identification types are disabled (BUT registration feature is enabled) - members will not be able to register',
            matchedTenant,
          )
          setIsRegistrationDisabled(true)
        }
      }
    }
  }, [matchedTenant, featureConfig, isAmazon])

  //can happen locally when hotrestarting esp.
  useEffect(() => {
    if (!matchedTenant) {
      history.push(REG_ROUTE_FIND_TENANT)
    }
  }, [matchedTenant])

  // const doFindAccount = async () => {

  //new eks version - old one to be removed
  async function asyncFindAccountEKS(
    tenantUuid: string,
    firstName: string,
    lastName: string,
    dob: string,
    matchType: MatchType,
    matchValue: string,
    isAmazon: boolean,
  ) {
    setNextLoading(true)
    gaService.trackEvent({
      ...gaService.eventMap.step_2_reg_next,
      value: securityValidationType,
    })
    const serverDob = dateWithYearMonthDay(dob)

    try {
      const resp = await service.findAccount_EKS({
        firstName,
        lastName,
        dob: serverDob,
        matchType,
        matchValue,
        tenantUuid,
      })
      setNextLoading(false)
      const msResp = checkMicroServiceResponseStatus(resp)

      if (!msResp.isError) {
        setEksMember(resp)

        //now we know how it is, update the alert logger
        LOG.initMember(resp.id, tenantUuid as any)

        //for amazon primary members, need to instruct them to go to amazon a to z.
        // if (isAmazon && resp.relationship === 'MEMBER') {
        //   LOG.warn(
        //     'registration',
        //     'amazon primary member trying to register - will be instructed to use A to Z',
        //   )
        //   setRenderAmazonFoundMemberIsPrimaryDialog(true)
        //   return
        // }

        //direct flags now if already registered
        if (resp.registered) {
          //TODO - this is a temporary fix to remove the upper right logo from registraiton screen on error card
          setClientUuidInRegistration(undefined)
          setIsAlreadyRegistered(true)
          LOG.warn(
            'registration',
            'member tryng to register - but they are already registered as indicated by the API response',
          )
          return
        }
        //same for preregistered
        if (resp['pre-registered']) {
          setIsAlreadyPreregistered(true)
          LOG.warn(
            'registration',
            'member trying to register - but they are already PREregistered as indicated by the API response,memberId=',
            resp.id,
          )
          return
        }

        if (resp.migrated) {
          setIsMigrated(true)
          LOG.warn(
            'registration',
            'member trying to register - but they are already a legacy V1 migrated user indicated by the API response',
          )
          return
        }

        pushToSubrouteViaEffect(REG_ROUTE_IDENTITY_VALIDATION_EKS)
      } else {
        setApiResponse(resp)

        //new custom handler for member not found
        if (
          resp &&
          (resp as any).httpStatusCode === 404 &&
          (resp as any).scriptaErrorCode === EKS_CODE_NO_MATCHED_MEMBER
        ) {
          //TODO - wait to see if this is a problem with compliance to log this failure case
          // const searchData = {
          //   matchedTenant,
          //   firstName,
          //   lastName,
          //   dob: serverDob,
          //   matchType,
          //   matchValue,
          // }
          LOG.warn(
            'registration_lookup',
            'member not found in registration by lookup' +
              matchType +
              '=' +
              matchValue,
          )
          setRenderAskIfRecentPlanMember(true)
        } else {
          LOG.error(
            'registration_lookup',
            'non-specific non-404 error in find account',
            resp,
          )
          history.push(REG_ROUTE_ERROR_HANDLER)
        }
      }
    } catch (e) {
      LOG.error(
        'registration_lookup',
        'error cought in search-members api execution',
        e,
      )
      setNextLoading(false)
    }
  }

  const formatDateString = (value: string) => {
    const date = value.replace(/\D/g, '') || ''
    const length = date.length
    if (length < 3) return date
    if (length < 5) return `${date.slice(0, 2)}/${date.slice(2)}`
    return `${date.slice(0, 2)}/${date.slice(2, 4)}/${date.slice(4)}`
  }

  const onChangeFirstNameInput = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setFirstName(event.target.value)
    const updatedCache: PrevValuesCache = {
      ...prevValuesCache,
      firstName: event.target.value,
    }
    setPrevValuesCache(updatedCache)
  }

  const onChangeLastNameInput = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setLastName(event.target.value)
    const updatedCache: PrevValuesCache = {
      ...prevValuesCache,
      lastName: event.target.value,
    }
    setPrevValuesCache(updatedCache)
  }

  const onChangeDateOfBirthNameInput = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const date = formatDateString(event.target.value)
    setDateOfBirth(date)
    const updatedCache: PrevValuesCache = {
      ...prevValuesCache,
      dateOfBirth: date,
    }
    setPrevValuesCache(updatedCache)
  }

  const onChangeValidationNumberInput = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setValidationNumber(event.target.value)
  }

  const onChangeMatchType = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const matchType = (event.target as HTMLInputElement)
      .value as NewTenantRadioGroupOptions
    const updatedCache: PrevValuesCache = {
      ...prevValuesCache,
      matchType,
    }
    setSecurityValidationType(matchType)
  }

  //can happen locally when hotrestarting esp.
  if (!matchedTenant) {
    return null
  }

  //NOT doing this anymore - we let everybody that is not registered do it manually
  // if (renderAmazonPrimaryMemberInstructions) {
  //   return (
  //     <SDialog
  //       open={renderAmazonPrimaryMemberInstructions}
  //       onClose={() => {
  //         setRenderAmazonPrimaryMemberInstructions(false)
  //       }}
  //       maxWidth="sm"
  //     >
  //       <Grid
  //         container
  //         alignItems="center"
  //         textAlign="center"
  //         spacing={2}
  //         sx={{ paddingLeft: '50px', paddingRight: '50px' }}
  //       >
  //         <Grid item xs={12}>
  //           <ThemedSVGRemote
  //             importUrl={azPrimaryMemberInstructions}
  //             alt="az primary member instructions"
  //             width={150}
  //             height={150}
  //           />
  //         </Grid>
  //         <Grid item xs={12}>
  //           <STypography variant="body_regular">Before we continue</STypography>
  //         </Grid>
  //         <Grid item xs={12}>
  //           <STypography
  //             variant="bodybig_bold"
  //             sx={{ color: COLORS.TEXT_BRAND }}
  //           >
  //             If you are the Primary member
  //           </STypography>
  //         </Grid>
  //         <Grid item xs={12}>
  //           <STypography variant="body_regular">
  //             Good News!
  //             <strong> You can log in by going to Amazon A to Z</strong> and
  //             click on RxAid powered by Scripta.
  //           </STypography>
  //         </Grid>
  //         <Grid item xs={12}>
  //           <STypography
  //             variant="bodybig_bold"
  //             sx={{ color: COLORS.TEXT_BRAND }}
  //           >
  //             If you are a dependent
  //           </STypography>
  //         </Grid>
  //         <Grid item xs={12}>
  //           <STypography variant="body_regular">
  //             Welcome to RxAid powered by Scripta! Click the button below to
  //             continue with your registration process 🙌
  //           </STypography>
  //         </Grid>
  //         <Grid item xs={12}>
  //           <SButton
  //             data-testid="continue-as-dependent-button"
  //             onClick={() => {
  //               setRenderAmazonPrimaryMemberInstructions(false)
  //             }}
  //           >
  //             Continue
  //           </SButton>
  //         </Grid>
  //       </Grid>
  //     </SDialog>
  //   )
  // }

  if (renderAmazonFoundMemberIsPrimaryDialog) {
    return <SSOAlreadyRegisteredError />
  }

  if (isRegisteredDisabled) {
    return (
      <RegistrationStepCard
        registrationType="registration"
        progressBarStep={2}
        cancelButtonTitle={t('common:back')}
        onClickNextButton={() => {}}
        onClickCancelButton={() => history.push(REG_ROUTE_FIND_TENANT)}
        nextButtonDisabled={true}
      >
        <Box sx={{ width: '100%', paddingTop: '30px' }}>
          <Grid container>
            <Grid item xs={12}>
              <Typography>{t('registrationIsDisabledAtTheMoment')}</Typography>
            </Grid>
          </Grid>
        </Box>
      </RegistrationStepCard>
    )
  }

  if (renderAskIfRecentPlanMember) {
    return (
      <NewPortalDialogDesign
        upperRightX={true}
        open={true}
        headerImageImport={MemberNotFoundImage}
        customPaperSx={{
          width: isSmall ? '358px' : undefined,
          // padding: isSmall ? '0px' : undefined,
        }}
        dualActionProps={{
          yesButtonSx: { width: '80px', minWidth: '80px', padding: '10px' },
          onYes: () => {
            //go to prereg
            //prepare custom state for prereg
            const customState: RegToPreregJumpState = {
              clientUuid: matchedTenant.clientUuid,
              firstName: firstName,
              lastName: lastName,
              dob: dateOfBirth,
            }

            const targetRoute = `${REG_ROUTE_PREREGISTER}`
            history.push(targetRoute, customState)
          },
          onNo: () => {
            //go to support request
            setRenderAskIfRecentPlanMember(false)
            setRenderMemberNotFoundSupportRequest(true)
          },
        }}
        //in case they click close x button, we want to go back to normal flow
        onClose={() => {
          setRenderAskIfRecentPlanMember(false)
          setRenderMemberNotFoundSupportRequest(false)
        }}
      >
        <Grid container>
          <Grid item xs={12} sx={{ padding: '0px 15px 0px 15px' }}>
            <Typography
              sx={{
                textAlign: 'center',
                fontSize: '1.5rem',
                fontWeight: 700,
                paddingLeft: isSmall ? '12px' : '15px',
                paddingRight: isSmall ? '12px' : '15px',
              }}
            >
              {t('oops')}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ paddingTop: '15px' }}>
            <STypography variant="body_regular" sx={{ textAlign: 'center' }}>
              Please double check that you have entered information exactly as
              it appears on your identification. If there was a typo or you'd
              like to try again, please give it another shot!
            </STypography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              margin: '15px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <SButton
              size="small"
              noCaps
              onClick={() => {
                setRenderAskIfRecentPlanMember(false)
                setRenderMemberNotFoundSupportRequest(false)
              }}
            >
              Let me try again
            </SButton>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              marginTop: isSmall ? '10px' : '20px',
              padding: isSmall ? '5px' : '0px 30px 0px 30px',
            }}
          >
            <Typography sx={{ textAlign: 'center', fontSize: '1.15rem' }}>
              If the problem persists, please confirm, were you added to the
              plan recently, within the past 60 days?
            </Typography>
          </Grid>
        </Grid>
      </NewPortalDialogDesign>
    )
  }

  function getBackendIdentificationType(uiIdType?: MatchType | string) {
    switch (uiIdType) {
      case 'MID':
        return 'MEMBER_ID'
      case 'EID':
        return 'EMPLOYEE_ID'
      case 'SSN':
        return 'SOCIAL_SECURITY_NUMBER'
      default:
        return 'MEMBER_ID'
    }
  }

  if (renderMemberNotFoundSupportRequest) {
    return (
      <CompanyOrMemberNotFoundHandler
        variant="member"
        onClickBack={() => {
          setRenderMemberNotFoundSupportRequest(false)
        }}
        onClickSubmitFeedback={async (email, phone) => {
          const supportRequest: MemberNotFoundSupportRequest = {
            'support-type': 'Member not found',
            'member-data': {
              'client-name': matchedTenant.name,

              'client-id': matchedTenant.clientUuid.toString(),

              'user-email': email,
              'user-phone': phone ? toDigitsOnly(phone) : '',
              'identification-type': getBackendIdentificationType(
                securityValidationType,
              ),
              // | 'SOCIAL_SECURITY_NUMBER'
              'first-name': firstName,
              'last-name': lastName,
              dob: toServerDate(dateOfBirth),
              'identification-value': validationNumber,
            },
          }
          const resp = await service.submitCompanyNotFoundSupportRequest(
            supportRequest,
          )
          const respStatus = checkMicroServiceResponseStatus(resp)
          if (!respStatus.isError) {
            setRenderMemberNotFoundSupportRequest(false)
            setRenderOkGotIt(true)
          } else {
            LOG.error('registration', 'error in submit support request', resp)
            setRenderMemberNotFoundSupportRequest(false)
          }
        }}
      />
    )
  }

  if (isAlreadyRegistered || isAlreadyPreregistered || isMigrated) {
    return (
      <>
        {(isAlreadyRegistered || isAlreadyPreregistered) && (
          <BaseErrorCard
            headerImage={accountImg}
            headerTitle={
              isAlreadyRegistered
                ? 'You are already registered'
                : 'You are already pre-registered'
            }
            content={
              <>
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                  <Typography sx={{ fontSize: '16px' }}>
                    Please log in using your existing username and password.
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    textAlign: 'center',
                    paddingTop: '20px',
                    paddingBottom: '20px',
                  }}
                >
                  <Typography sx={{ fontSize: '16px' }}>
                    Need help? Click{' '}
                    <Link
                      component="button"
                      onClick={() => history.push(REG_ROUTE_FORGOT_USERNAME)}
                      sx={{
                        color: COLORS.TEXT_BRAND,
                        // textDecoration: 'none',
                        // marginTo
                        fontWeight: 700,
                        fontSize: '16px',
                        '&:hover': {
                          textDecoration: 'underline',
                        },
                      }}
                    >
                      Forgot Username
                    </Link>
                    {' or '}
                    <Link
                      component="button"
                      onClick={() => history.push(REG_ROUTE_FORGOT_PASSWORD)}
                      sx={{
                        color: COLORS.TEXT_BRAND,
                        // textDecoration: 'none',
                        // marginTo
                        fontWeight: 700,
                        fontSize: '16px',
                        '&:hover': {
                          textDecoration: 'underline',
                        },
                      }}
                    >
                      Forgot Password
                    </Link>
                    {' to reset your credentials'}
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ textAlign: 'center' }}>
                  <SButton
                    onClick={() => {
                      setMatchedTenant(undefined)
                      setClientUuidInRegistration(undefined)
                      setEksMember(undefined)
                      history.push(REG_ROUTE_LOGIN)
                    }}
                  >
                    Click here to go to login
                  </SButton>
                </Grid>
              </>
            }
          />
        )}
        {isMigrated && (
          <BaseErrorCard
            headerImage={accountImg}
            headerTitle="You are already registered as a legacy V1 user"
            content={
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Typography sx={{ fontSize: '16px' }}>
                  Our records indicate that you are already registered as a
                  legacy system user account. Please contact our member support
                  so we can complete your transfer to the new member portal.
                </Typography>
              </Grid>
            }
          />
        )}
      </>
    )
  }
  return (
    <>
      <RegistrationStepCard
        registrationType="registration"
        progressBarStep={2}
        // step={t('step', { current: 2, total: 7 })}
        // title={title}
        // message={t('scriptaRegistrationDescription')}
        cancelButtonTitle={t('common:back')}
        onClickNextButton={async () => {
          await asyncFindAccountEKS(
            matchedTenant.clientUuid,
            firstName,
            lastName,
            dateOfBirth,
            securityValidationType as MatchType,
            validationNumber,
            isAmazon,
          )
        }}
        onClickCancelButton={() => history.push(REG_ROUTE_FIND_TENANT)}
        nextButtonDisabled={shouldDisableNextButton}
        nextLoading={nextLoading}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography
              sx={{
                textAlign: 'center',
                fontSize: isSmall ? '1.125rem' : '1.5rem',
                fontWeight: 700,
                paddingBottom: '20px',
                paddingTop: '0px',
                // marginTop: isSmall ? '-15px' : '0px',
                lineHeight: '21.92px',
              }}
            >
              {t('genericHiMember')}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              paddingBottom: '20px',
              textAlign: 'center',
              paddingLeft: isSmall ? '20px' : '10px',
              paddingRight: isSmall ? '20px' : '10px',
              lineHeight: '20px',
            }}
          >
            <Typography sx={{ lineHeight: '20px' }}>
              {t('scriptaRegistrationDescription')}
            </Typography>
          </Grid>
        </Grid>
        <RegistrationFormFieldsBox>
          <CustomTextField
            data-testid="first-name-input"
            value={firstName}
            onChange={onChangeFirstNameInput}
            label={t('register:legalFirstName')}
            placeholder={t('enterYourFirstName')}
          />
          <CustomTextField
            data-testid="last-name-input"
            containerSx={{ marginTop: '20px' }}
            value={lastName}
            onChange={onChangeLastNameInput}
            label={t('register:legalLastName')}
            placeholder={t('enterYourLastName')}
          />

          <CustomTextField
            data-testid="dob-input"
            containerSx={{ marginTop: '20px' }}
            value={dateOfBirth}
            onChange={onChangeDateOfBirthNameInput}
            label={t('dob')}
            placeholder={t('MM/DD/YYYY')}
            inputProps={{ maxLength: 10 }}
            startAdornment={
              <InputAdornment position="start">
                <CalendarTodayOutlinedIcon sx={{ color: '#96939B' }} />
              </InputAdornment>
            }
            error={isDOBIncorrect}
          />
        </RegistrationFormFieldsBox>
        <Grid
          container
          sx={{
            paddingTop: '25px',
            paddingBottom: '20px',
            textAlign: 'left',
            // marginLeft: '20px',
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              paddingLeft: isSmall ? '20px' : undefined,
              paddingRight: isSmall ? '20px' : undefined,
            }}
          >
            <Typography
              // my={'27px'}
              sx={(theme) => ({
                textAlign: isSmall ? 'left' : 'center',
                // paddingTop: '10px',
                fontSize: 18,
                fontWeight: '400',
                lineHeight: '21.92px',
              })}
            >
              {t('provideOneOfTheFollowing')}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            paddingLeft: isSmall ? '10px' : '20px',
            paddingRight: isSmall ? '10px' : undefined,
          }}
        >
          <NewTenantRadioGroup
            isSmall={isSmall}
            matchedTenant={matchedTenant}
            value={securityValidationType}
            onChange={onChangeMatchType}
          />
        </Grid>
        <RegistrationFormFieldsBox>
          <CustomTextField
            data-testid="identification-value-input"
            value={validationNumber}
            onChange={onChangeValidationNumberInput}
            placeholder={t('enterThatNumber')}
            disabled={!securityValidationType}
          />
        </RegistrationFormFieldsBox>
        {renderOkGotIt && (
          <GotItDialogForSupportRequest
            onOk={() => {
              setRenderOkGotIt(false)
            }}
          />
        )}
      </RegistrationStepCard>
      {/* //TODO - review this footer spacer was added when new landing page was added */}
      {/* <Box
        sx={(theme) => ({
          minHeight: '30px',
        })}
      ></Box> */}
    </>
  )
}
