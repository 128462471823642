import CloseIcon from '@mui/icons-material/Close'
import InfoIcon from '@mui/icons-material/Info'
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Link,
  Radio,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import animePreRegister from '../../images/login_anime_preregister_cropped.gif'
import animeRegister from '../../images/login_anime_register_cropped.gif'

import { useEffect, useState } from 'react'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import {
  FindMemberEKSResp,
  RequestIdentificationData,
  TenantIdWithOptions,
} from '../../types'
import { useMemberAppContext } from '../MemberAppContext'
import { ROUTE_COMPLETE_LOGIN } from '../PortalRoutes'
import {
  checkMicroServiceResponseStatus,
  IS_DEV,
  IS_INTEGRATION,
  IS_NATIVE,
  IS_PROD,
  isQuickLinkRegistration,
  nativeApi_setBackButtonVisible,
  nativeApi_setCanGoBack,
} from '../apiexec/utils'

import { useLocation } from 'react-router'
import {
  EKS_LOGIN_INVALID_CREDENTIALS,
  EKS_LOGIN_MEMBER_NOT_REGISTERED,
} from 'src/app/EKSStatuCodes'
import STypography from 'src/app/system/customcomponents/STypography'
import {
  InfoCardAutoSSOLoggedOut,
  InfoCardManualSSOLoggedOut,
} from 'src/app/system/error/BaseErrorCard'
import { v4 as uuidv4 } from 'uuid'
import {
  EKS_LOGIN_ACCOUNT_LOCKED_DUE_TO_WRONG_PASSWORD_ATTEMPTS,
  EKS_LOGIN_MINOR_NOT_ALLOWED,
  EKS_LOGIN_PREREG_MEMBER_NEEDS_TO_DO_SECURITY,
  EKS_LOGIN_PREREG_MEMBER_NOT_VERIFIED_BY_BIZOPS,
  EKS_LOGIN_NEED_PASSWORD_RESET,
} from '../EKSStatuCodes'
import { getUrlParameter } from '../apiexec/utils'
import { URL_PARAM_MOBILE_APP_VERSION } from '../application/ForceUpgradeDialog'
import { GAService } from '../application/ga/GAService'
import { COMMON_BOLD_WEIGHT, useSColors } from '../styles/scripta-theme'
import SButton from '../system/customcomponents/SButton'
import { TargetRoute } from '../v2/SavingGuide'
import { LOG } from '../v2/applog'
import { LearnMoreBtn, PreReg } from './PreReg'
import { useRegistration } from './RegistrationContext'
import passwordExpired from '../v2/images/password_expired.svg'
import {
  REG_ROUTE_FIND_TENANT,
  REG_ROUTE_FORGOT_PASSWORD,
  REG_ROUTE_FORGOT_USERNAME,
  REG_ROUTE_IDENTITY_VALIDATION_EKS,
} from './RegistrationSubRoutes'
import { CustomTextField } from './components/CustomTextField'
import { useRegistrationService } from './useRegistrationService'
import { SDialog } from 'src/app/system/customcomponents/SDialog'
import { URL_PARAM_RESET_PASSWORD_USERNAME } from 'src/app/app-root-types'
import { useSizes } from 'src/app/v2/useNewHooks'
import { ThemedSVGRemote } from 'src/app/v2/ThemedSVGRemote'

//new response from backend when prereg member needs to validate
interface PreRegMemberSucrityFields {
  memberId: string
  userName: string
  clientUuid: string
  mobileNumber: string
  email: string
}

export function LoginOrRegister() {
  const { COLORS } = useSColors()
  const { t } = useSTranslate(['login', 'common'])
  const theme = useTheme()
  const { history, setApiResponse, setProgressBarState } = useRegistration()
  const { logoutMsg, setLogoutMsg, setJwt, isSsoLogin } = useMemberAppContext()
  const [useQuickLinkRegistration, setUseQuickLinkRegistration] =
    useState(false)

  const [renderPreReg, setRenderPreReg] = useState(false)

  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))

  //main login vs register
  const [selectedTab, setSelectedTab] = useState('login')

  //if on register, is it register or preregister
  const [selectedRegType, setSelectedRegType] = useState<
    'register' | 'preregister'
  >('register')

  //check if there is anything in router state (i.e. clicking Cancel from Registeration)
  const location = useLocation()
  useEffect(() => {
    const state = history.location.state
    if (state && (state as any).previouslySelectedTab != undefined) {
      setSelectedTab((state as any).previouslySelectedTab)
      const newState = { ...(state as any), previouslySelectedTab: undefined }
      //then remove it?
      history.replace({ state: newState })
    }
  }, [location.state])

  //clean up progress bar state, if any
  useEffect(() => {
    setProgressBarState({
      type: 'registration',
      disabled: true,
      currentStep: 0,
    })
  }, [])

  useEffect(() => {
    if (isQuickLinkRegistration()) {
      setUseQuickLinkRegistration(true)
    }
  }, [])

  useEffect(() => {
    document.title = 'Login or Register - Scripta Insights'
  })

  useEffect(() => {
    if (IS_NATIVE()) {
      // TODO: the back button is visible by default on the mobile app level
      // remove the back button from mobile app code and remove the need for
      // nativeApi_setBackButtonVisible
      nativeApi_setBackButtonVisible(false)
      nativeApi_setCanGoBack(false)

      return () => {
        nativeApi_setCanGoBack(true)
      }
    }
  }, [])

  // auto sso user log out
  if (logoutMsg && isSsoLogin) {
    return <InfoCardAutoSSOLoggedOut />
  }

  // manual sso user log out
  if (isSsoLogin) {
    return <InfoCardManualSSOLoggedOut />
  }

  if (renderPreReg) {
    return (
      <PreReg
        onClickCancel={() => {
          setRenderPreReg(false)
          //remove progress bar
          setProgressBarState({
            type: 'registration',
            disabled: true,
            currentStep: 0,
          })
        }}
      />
    )
  }

  return (
    <Grid
      container
      justifyContent="center"
      sx={{
        paddingTop: '0px',
        // paddingLeft: '30px',
        // paddingRight: '30px',
        // paddingBottom: '30px',
      }}
    >
      <Grid item xs={12} container>
        <Grid
          item
          xs={12}
          // md={8}
          sx={{
            //MF - removing this ridicilous 3MB file from the entry page due to performance issues as per lighthouse report
            // backgroundImage: "url('/images/login-vid-sample_medium.gif')",
            borderRadius: '15px 15px 0px 0px',
            backgroundPosition: 'center',
            minHeight: isSmall ? '136px' : '166px',
            minWidth: '100%',
            backgroundSize: 'cover',
            // backgroundBlendMode: 'screen',
            backgroundColor: COLORS.PRIMARY_BRAND,
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection={'column'}
            sx={{ height: '100%' }}
          >
            <Box>
              <Typography
                sx={{
                  textAlign: 'center',
                  fontSize: '1.375rem',
                  fontWeight: 700,
                  color: COLORS.TEXT_LIGHT_PRIMARY_WHITE,
                }}
              >
                {t('welcomeToScripta')}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  color: COLORS.TEXT_LIGHT_PRIMARY_WHITE,
                  textAlign: 'center',
                  padding: isSmall ? '0px 30px 0px 30px' : '0px 20px 0px 20px',
                  // paddingTop: '15px',
                  fontSize: '1rem',
                }}
              >
                {t('weCannotWait')}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        container
        alignItems="center"
        sx={
          {
            // minHeight: '80px',
          }
        }
      >
        <TabSelector
          onTabSelected={(tabId) => {
            setSelectedTab(tabId)
          }}
          selected={selectedTab}
        />
      </Grid>

      <Grid item xs={12} container>
        <Box
          sx={(theme) => ({
            width: '100%',
            //need etra care for very small screens (below iphone 12pro of 390)
            [theme.breakpoints.down(390)]: {
              padding: '5px',
            },

            [theme.breakpoints.up(390)]: {
              padding: '0px 20px 20px 20px',
            },
            [theme.breakpoints.up(500)]: {
              padding: '30px',
            },
          })}
        >
          {selectedTab === 'register' && (
            <RegOrPreregTabContent
              selectedRegType={selectedRegType}
              setSelectedRegType={setSelectedRegType}
              onContinueRegister={() => {
                history.push(REG_ROUTE_FIND_TENANT)
              }}
              onContinuePreRegister={() => {
                setRenderPreReg(true)
                // history.push(REG_ROUTE_FIND_TENANT_MEMBER_FROM_QR)
              }}
            />
          )}

          {logoutMsg && (
            <Grid
              item
              xs={12}
              style={{ marginTop: '0px', marginBottom: '20px' }}
            >
              <Typography style={{ fontWeight: 500 }}>{logoutMsg}</Typography>
            </Grid>
          )}
          {selectedTab === 'login' && <SingInTabContent />}
        </Box>
      </Grid>
    </Grid>
  )
}

function PreregInProgressDialog({
  open,
  setOpen,
}: {
  open: boolean
  setOpen: any
}) {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useSTranslate('prereg')

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false)
      }}
      maxWidth="xs"
      PaperProps={{
        sx: {
          borderRadius: '20px',
          // padding: '20px',
          padding: isSmall ? '0px' : '20px',
        },
      }}
    >
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setOpen(false)
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              sx={{ textAlign: 'center', fontSize: '1.4rem', fontWeight: 600 }}
            >
              {t('preregAlready')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{ textAlign: 'center', marginTop: '20px', fontSize: '1rem' }}
            >
              {t('weWillReachOut')}
            </Typography>
          </Grid>

          <Grid item xs={12} sx={{ textAlign: 'center', marginTop: '20px' }}>
            <Typography>{t('toLearn')}</Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '20px' }}>
            <Divider />
          </Grid>
          <Grid
            item
            container
            sx={{ marginTop: '25px' }}
            justifyContent={'flex-end'}
          >
            <Grid item>
              <SButton
                onClick={() => {
                  setOpen(false)
                }}
                size="small"
                sx={{ marginRight: '20px' }}
                variant="outlined"
              >
                {t('closeBtn')}
              </SButton>
            </Grid>
            <Grid item>
              <LearnMoreBtn />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export function TabSelector({
  selected,
  onTabSelected,
}: {
  selected: string
  onTabSelected: (tab: string) => void
}) {
  const { t } = useSTranslate('login')
  const { COLORS } = useSColors()

  function UnderlinePill({ selected }: { selected: boolean }) {
    if (!selected) return null
    return (
      <Box
        sx={{
          marginLeft: 'auto',
          marginRight: 'auto',
          color: COLORS.PRIMARY_BRAND,
          textAlign: 'center',
          minHeight: '8px',
          maxHeight: '8px',
          width: '46px',
          maxWidth: '46px',
          backgroundColor: COLORS.PRIMARY_BRAND,
          borderRadius: '5px',
          // marginTop: '10px',
        }}
      ></Box>
    )
  }
  return (
    <Grid
      container
      sx={{
        // borderBottom: '2px solid',
        // borderColor: PORTAL_COLOR_YET_ANOTHER_GREY_FROM_DESIGNER,
        // backgroundColor: 'red',
        padding: '15px 5px 15px 5px',
      }}
    >
      <Grid item xs={6} sx={{ textAlign: 'center' }}>
        <CustomTab
          dataTestId="signin-tab"
          label={t('singInTab')}
          selected={selected === 'login' ? true : false}
          onClick={() => {
            onTabSelected('login')
          }}
        />
      </Grid>
      <Grid item xs={6} sx={{ textAlign: 'center' }}>
        <CustomTab
          dataTestId="register-tab"
          label={t('registerTab')}
          selected={selected === 'register' ? true : false}
          onClick={() => {
            onTabSelected('register')
          }}
        />
      </Grid>
      <Grid item container xs={12}>
        <Grid item xs={12}>
          <hr />
        </Grid>
        <Grid item container xs={6} sx={{ marginTop: '-13px' }}>
          <UnderlinePill selected={selected === 'login' ? true : false} />
        </Grid>
        <Grid item container xs={6} sx={{ marginTop: '-13px' }}>
          <UnderlinePill selected={selected === 'register' ? true : false} />
        </Grid>
      </Grid>
    </Grid>
  )
}
function CustomTab({
  label,
  selected,
  onClick,
  dataTestId,
}: {
  label: string
  selected: boolean
  onClick: () => void
  dataTestId: string
}) {
  const { COLORS } = useSColors()
  return (
    <Grid container justifyItems={'center'}>
      <Grid item xs={12}>
        <Typography
          sx={{
            cursor: 'pointer',
            fontSize: '1rem',
            fontWeight: 600,
            color: COLORS.PRIMARY_BRAND,
            opacity: selected ? 1 : 0.65,
            // borderBottom: selected ? '2px solid' : 'none',
          }}
          data-testid={dataTestId}
          onClick={onClick}
        >
          {label}
        </Typography>
      </Grid>
      {/* <Grid item xs={12} container>
        <Box
          sx={{
            margin: 'auto',
            backgroundColor: PORTAL_COLOR_MAIN_PURPLE,
            height: '10px',
            minHeight: '10px',
            width: '60px',
            marginTop: '30px',
          }}
        ></Box>
      </Grid> */}
    </Grid>
  )
}

function SingInTabContent() {
  const { COLORS } = useSColors()
  const gaService = new GAService()
  const { t } = useSTranslate(['login', 'common'])
  const theme = useTheme()

  const {
    history,
    setApiResponse,
    setMember,
    setRegistrationData,
    setEksMember,
    setMatchedTenant,
  } = useRegistration()
  const {
    setIsFirstTimePreregMemberAccess,
    setPreRegCredentials,
    setRenderPreregFirstAccessWelcomeDialog,
    setClientUuidInRegistration,
  } = useMemberAppContext()

  const [targetRoute, setTargetRoute] = useState<TargetRoute>()
  function pushToSubrouteViaEffect(subroute: string) {
    //we use the object, not a simple string, to ensure useEffect triggers
    const route: TargetRoute = {
      //todo - this guid is probably not needed anymore, we use a object in state
      uuid: uuidv4(),
      route: subroute,
    }

    setTargetRoute(route)
  }
  //here we watch for the target route to be set and then we push to it
  useEffect(() => {
    if (targetRoute) {
      history.push(targetRoute.route)
    }
  }, [targetRoute])

  const { logoutMsg, setLogoutMsg, setJwt } = useMemberAppContext()

  const [loginApiErrorMsg, setLoginApiErrorMsg] = useState<string | undefined>(
    undefined,
  )

  const [useQuickLinkRegistration, setUseQuickLinkRegistration] =
    useState(false)
  const [userLoginName, setUserLoginName] = useState(
    IS_DEV() ? 'mirza+newsso@scriptainsights.com' : '',
  )

  const [password, setPassword] = useState(IS_DEV() ? 'Password9$$' : '')

  const [isLoading, setIsLoading] = useState(false)
  const regService = useRegistrationService()

  //stay on this screen if the error is invalid username or password
  const [isInvalidCredentials, setIsInvalidCredentials] = useState(false)
  const [isPreregInProgress, setIsPreregInProgress] = useState(false)

  const [needsPasswordReset, setNeedsPasswordReset] = useState(false)

  const [reqIdData, setReqIdData] = useState<RequestIdentificationData>(
    {} as any,
  )

  const isMedium = useMediaQuery(theme.breakpoints.down('md'))
  const isTabletMode: boolean = isMedium
  const location = useLocation()

  const { isMobile } = useSizes()

  //check if auto logim (coming from registration where we just created the new user account)
  useEffect(() => {
    const autoLoginState = history.location.state
    if (autoLoginState && (autoLoginState as any).autoLogin) {
      const userNameTouse = (autoLoginState as any).autoLogin
        .justRegisteredUserName
      const userPassToUse = (autoLoginState as any).autoLogin
        .justRegisteredPassword

      asyncLogin(userNameTouse, userPassToUse)
    }
  }, [location])

  useEffect(() => {
    if (isQuickLinkRegistration()) {
      setUseQuickLinkRegistration(true)
    }
  }, [])

  useEffect(() => {
    setReqIdData({ password: password, userName: userLoginName } as any)
  }, [userLoginName, password])

  async function asyncLogin(userName: string, password: string) {
    try {
      setIsLoading(true)
      setLoginApiErrorMsg(undefined)
      setIsInvalidCredentials(false)
      const authResponse = await regService.authenticateMember_EKS(
        userName,
        password,
        // 'initToContext',
      )

      const authStatus = checkMicroServiceResponseStatus(authResponse)
      setIsLoading(false)
      if (!authStatus.isError) {
        //BASIC SUCCESSFULL LOGIN
        const jwt = authResponse.token
        setJwt(jwt)
        setIsLoading(false)
        nativeApi_setBackButtonVisible(false)
        setIsInvalidCredentials(false)
        setLogoutMsg(undefined)

        if (getUrlParameter(URL_PARAM_MOBILE_APP_VERSION)) {
          pushToSubrouteViaEffect(
            ROUTE_COMPLETE_LOGIN +
              '?' +
              URL_PARAM_MOBILE_APP_VERSION +
              '=' +
              getUrlParameter(URL_PARAM_MOBILE_APP_VERSION),
          )
        } else {
          pushToSubrouteViaEffect(ROUTE_COMPLETE_LOGIN)
        }
      } else {
        //various login edge cases
        //bad credentials - eitehr 404 member not registered

        let CODE = (authResponse as any)
          ? (authResponse as any).scriptaErrorCode
          : undefined
        console.log('auto code', CODE)

        if (
          CODE === EKS_LOGIN_MEMBER_NOT_REGISTERED ||
          //627
          CODE === EKS_LOGIN_INVALID_CREDENTIALS
        ) {
          setIsInvalidCredentials(true)
          return
        }

        if (CODE === EKS_LOGIN_NEED_PASSWORD_RESET) {
          setIsInvalidCredentials(false)
          setLoginApiErrorMsg(undefined)
          setNeedsPasswordReset(true)
          return
        }

        //similar to above - this is if current password is invalid for whatever reason
        //we have some migrated users for ex. that have old too short or too long passwords that need resetting
        if (
          (authResponse as any) &&
          (authResponse as any).fieldErrors &&
          (authResponse as any).fieldErrors.length > 0 &&
          (authResponse as any).fieldErrors.some(
            (fe: any) => fe.propertyName === 'password',
          )
        ) {
          LOG.error(
            'login',
            'Field error on password reported by the API. Will force user to reset their password',
          )
          setIsInvalidCredentials(false)
          setLoginApiErrorMsg(undefined)
          setNeedsPasswordReset(true)
          return
        }

        if (CODE === EKS_LOGIN_ACCOUNT_LOCKED_DUE_TO_WRONG_PASSWORD_ATTEMPTS) {
          // setIsInvalidCredentials(true)
          setLoginApiErrorMsg(
            'This account has been locked due to too many wrong password attempts. Please try again in 5 minutes.',
          )
          return
        }

        if (CODE === EKS_LOGIN_PREREG_MEMBER_NOT_VERIFIED_BY_BIZOPS) {
          setIsInvalidCredentials(false)
          setLoginApiErrorMsg(undefined)
          setIsPreregInProgress(true)
          return
        }

        if (CODE === EKS_LOGIN_MINOR_NOT_ALLOWED) {
          // setIsInvalidCredentials(true)
          setLoginApiErrorMsg(
            'Minor Logins are disabled for this client. \n Scripta complies with all HIPAA protections, state regulations, and client preferences. Therefore, due to privacy concerns' +
              ' information may be limited for dependents under the age of 18 or minors may not be eligible for an electronic Scripta account.',
          )

          return
        }

        if (CODE === EKS_LOGIN_PREREG_MEMBER_NEEDS_TO_DO_SECURITY) {
          //set this to true so that in security validaiton we can redirect them to login
          setIsFirstTimePreregMemberAccess(true)
          setPreRegCredentials({ userName, password })
          //     //to show custom dialog upon login
          setRenderPreregFirstAccessWelcomeDialog(true)

          //prepare the data for the identity validation using the returned message
          const securityFields: PreRegMemberSucrityFields = JSON.parse(
            (authResponse as any).message,
          )
          console.log('securityFields', securityFields)

          const eksMember: Partial<FindMemberEKSResp> = {
            email: securityFields.email,
            phone: securityFields.mobileNumber,

            id: securityFields.memberId,
          }
          //TODO - this is missing relationship, meaning if primary member preregistered then they could become real

          setEksMember(eksMember as any)
          const inMemoryTenant: Partial<TenantIdWithOptions> = {
            clientUuid: securityFields.clientUuid,
          }
          setMatchedTenant(inMemoryTenant as any)
          setClientUuidInRegistration(securityFields.clientUuid)

          //now we know how it is, update the alert logger
          LOG.initMember(securityFields.memberId, securityFields.clientUuid)

          pushToSubrouteViaEffect(REG_ROUTE_IDENTITY_VALIDATION_EKS)
          // history.push(REG_ROUTE_IDENTITY_VALIDATION_EKS)
          return
        }

        //some fallthru case where we did not get an expected login error code
        // setIsInvalidCredentials(true)
        LOG.error(
          'login',
          'Unexpected login error code in authenticate API:',
          authResponse,
        )
        setLoginApiErrorMsg(
          'We are having trouble logging you in right now. Please try again in a few minutes. If the problem persists, please contact our member support. ' +
            (CODE ? 'Additional support code:' + CODE : ''),
        )
        return
      }
    } catch (e) {
      let maskedUserName = ''
      if (userName.length > 4) {
        const middleStart = Math.max(0, Math.floor((userName.length - 4) / 2))
        const middleEnd = Math.min(userName.length, middleStart + 4)
        maskedUserName =
          userName.slice(0, middleStart) +
          '*'.repeat(middleEnd - middleStart) +
          userName.slice(middleEnd)
      }

      LOG.error(
        'login',
        'Network level exception in authenticate API - request timed out / failed to reach the API server, maskedUserName=' +
          maskedUserName,
        e,
      )
      //maybe a generic error message, at least try again
      setLoginApiErrorMsg(
        "We're having trouble logging you in right now. Please check your internet connection and try again. If the problem persists, please contact our member support.",
      )
      setIsLoading(false)
    }

    setIsLoading(false)
  }

  return (
    <>
      {/* <Box sx={{ marginBottom: 2.5 }}>
        <Typography sx={{ fontSize: '18px', fontWeight: 700 }}>
          {t('header')}
        </Typography>
      </Box> */}
      <CustomTextField
        data-testid="login-username-input"
        autoFocus
        autoComplete="off"
        value={userLoginName}
        onChange={(event) => setUserLoginName(event.target.value)}
        label={t('enterUser')}
        placeholder={t('enterUserPlaceholder')}
        inputProps={{ maxLength: 64 }}
        sx={{ marginBottom: 2.5, height: '48px' }}
        error={isInvalidCredentials}
      />

      <CustomTextField
        data-testid="login-password-input"
        error={isInvalidCredentials}
        autoComplete="off"
        sx={{ marginBottom: 3.8, height: '48px' }}
        value={password}
        type="password"
        onChange={(event) => setPassword(event.target.value)}
        label={t('enterPass')}
        placeholder={t('enterPassPlaceholder')}
      />

      {loginApiErrorMsg && (
        <Grid item xs={12}>
          <Typography
            sx={{ color: 'red', whiteSpace: 'pre-line', marginBottom: '15px' }}
          >
            {loginApiErrorMsg}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        {isInvalidCredentials && (
          <Typography sx={{ color: 'red', marginBottom: '15px' }}>
            {t('invalidUsernameOrPassword')}
          </Typography>
        )}
        {/* if this is a preregistered user trying to log in but they are still being processed / not yet verified by bizops */}
        {isPreregInProgress && (
          <PreregInProgressDialog
            open={isPreregInProgress}
            setOpen={setIsPreregInProgress}
          />
        )}
      </Grid>
      {needsPasswordReset && (
        <SDialog
          upperRightX={false}
          open={needsPasswordReset}
          customPaperSx={
            isMobile
              ? {
                  width: '90%',
                  background: 'linear-gradient(180deg, #CADFEF 0%, #FFF 100%)',
                }
              : {
                  padding: '40px',
                  background: 'linear-gradient(180deg, #CADFEF 0%, #FFF 100%)',
                }
          }
        >
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              paddingLeft: isMobile ? '16px' : '40px',
              paddingRight: isMobile ? '16px' : '40px',
            }}
          >
            <Grid
              item
              xs={12}
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              <ThemedSVGRemote
                importUrl={passwordExpired}
                width={204}
                height={160}
                alt="deductible"
              />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <STypography
                variant="title3_bold"
                sx={{ color: COLORS.TEXT_BRAND }}
              >
                Your password has expired
              </STypography>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: '10px', textAlign: 'center' }}>
              <STypography variant="bodybig_regular">
                You must update your password to continue using your account.
                This helps us ensure your information stays safe and protected.
                It only takes a minute!
              </STypography>
            </Grid>
            {/* <Grid item xs={12} sx={{ marginTop: '10px' }}>
              <STypography variant="body_regular">
                Your password has expired and needs to be reset.
              </STypography>
            </Grid> */}
            <Grid
              item
              xs={12}
              sx={{
                paddingLeft: '40px',
                paddingRight: '40px',
                paddingTop: '20px',
              }}
            >
              <SButton
                fullWidth
                noCaps
                onClick={() => {
                  console.log('userLoginName', userLoginName)
                  console.log(
                    'encoded userLoginName',
                    encodeURIComponent(userLoginName),
                  )
                  history.push(
                    REG_ROUTE_FORGOT_PASSWORD +
                      '?' +
                      URL_PARAM_RESET_PASSWORD_USERNAME +
                      '=' +
                      encodeURIComponent(userLoginName),
                  )
                }}
              >
                Reset your password
              </SButton>
            </Grid>
          </Grid>
        </SDialog>
      )}
      <Grid item xs={12}>
        <Grid item container justifyContent="center">
          <Grid item xs={12}>
            <SButton
              data-testid="login-submit-button"
              fullWidth
              variant="outlined"
              sx={{
                height: '50px',
                color: COLORS.BUTTON_PRIMARY_TEXT,
                backgroundColor: COLORS.BUTTON_PRIMARY_BACKGROUND,
                '&:hover': {
                  backgroundColor: COLORS.BUTTON_PRIMARY_BACKGROUND_ACTIVE,
                },
                '&:disabled': {
                  color: COLORS.TEXT_GREY_LIGHT,
                  backgroundColor: COLORS.BACKGROUND_GREY_LIGHT,
                },
              }}
              disabled={!userLoginName || !password || isLoading}
              onClick={async () => {
                asyncLogin(userLoginName, password)
              }}
            >
              {isLoading && (
                <CircularProgress
                  size={14}
                  sx={{
                    color: COLORS.TEXT_GREY_LIGHT,
                    marginRight: 1,
                  }}
                />
              )}
              {t('login')}
            </SButton>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        container
        justifyContent="center"
        gap={2}
        sx={{ marginTop: '20px' }}
      >
        <Box sx={{ display: 'flex' }}>
          <Link
            component="button"
            variant="body2"
            sx={{
              fontSize: '1rem',
              fontWeight: 400,
              cursor: 'pointer',
            }}
            underline="none"
            onClick={() => {
              history.push(REG_ROUTE_FORGOT_USERNAME)
              gaService.trackEvent(gaService.eventMap.forgot_username)
            }}
          >
            {t('forgotUser')}
          </Link>
          <Typography sx={{ paddingX: '8px' }}>|</Typography>
          <Link
            component="button"
            variant="body2"
            sx={{
              fontSize: '1rem',
              fontWeight: 400,
              cursor: 'pointer',
            }}
            underline="none"
            onClick={() => {
              history.push(REG_ROUTE_FORGOT_PASSWORD)
              gaService.trackEvent(gaService.eventMap.forgot_password)
            }}
          >
            {t('forgotPass')}
          </Link>
        </Box>
      </Grid>
      {!IS_PROD() && (
        <Grid
          item
          xs={12}
          container
          alignSelf={'center'}
          justifyContent={'center'}
          sx={{
            boxShadow: 'inset 0 0 10px #f8a100',
            padding: '5px',
            textAlign: 'center',
            borderRadius: '5px',
            marginTop: '5px',
          }}
        >
          <Grid item>
            <STypography variant="bodysmall_regular">
              🚧 UAT Environment Details: You are connected to:{' '}
              {IS_DEV() ? 'DEV' : IS_INTEGRATION() ? 'INTEGRATION' : 'BLUE'}
              {IS_NATIVE() ? <br /> : null}
              {IS_NATIVE()
                ? `Mobile version ${(window as any).mobileVersion}`
                : null}
            </STypography>
          </Grid>
        </Grid>
      )}
    </>
  )
}

function RegOrPreregTabContent({
  selectedRegType,
  setSelectedRegType,
  onContinueRegister,
  onContinuePreRegister,
}: {
  selectedRegType: 'register' | 'preregister'
  setSelectedRegType: (selected: 'register' | 'preregister') => void
  onContinueRegister: () => void
  onContinuePreRegister: () => void
}) {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const { t } = useSTranslate(['login', 'common'])

  const [forceTriggerTooltip, setForceTriggerTooltip] = useState(false)

  // const [animationSrc, setAnimatioSrc] = useState<string>(animeRegister)
  const [animationSrc, setAnimatioSrc] = useState<string>(animeRegister)
  useEffect(() => {
    if (selectedRegType === 'register') {
      setAnimatioSrc(animeRegister)
    } else {
      setAnimatioSrc(animePreRegister)
    }
  }, [selectedRegType])

  return (
    <Grid container sx={{}}>
      <Grid
        item
        xs={12}
        sx={(theme) => ({
          display: 'flex',
          justifyContent: 'center',
          //pull the image up a bit on desktop
          margingTop: '-20px',
          [theme.breakpoints.up('md')]: {
            marginTop: '-30px',
          },
        })}
      >
        <Box
          sx={(theme) => ({
            // border: '1px solid black',
            alignSelf: 'center',
            paddingBottom: '20px',
            [theme.breakpoints.down('sm')]: {
              width: '180px',
              height: '180px',
            },
            [theme.breakpoints.up('sm')]: {
              width: '311px',
              height: '311px',
            },
          })}
        >
          <img
            alt="register-animation"
            data-testid="register-animation"
            src={animationSrc}
            width="100%"
            height="100%"
          />
        </Box>
      </Grid>

      <Grid item xs={12} container justifyContent={'center'}>
        <Grid
          item
          xs={12}
          container
          justifyContent={'center'}
          sx={{ paddingBottom: '20px' }}
        >
          {selectedRegType === 'register' && (
            <Typography
              sx={{
                textAlign: 'center',
                fontSize: '1rem',
                fontWeight: COMMON_BOLD_WEIGHT,
                lineHeight: '19.5px',
              }}
            >
              {t('regTimeMsg')}
            </Typography>
          )}
          {selectedRegType === 'preregister' && (
            <Box
              onClick={() => {
                setForceTriggerTooltip(true)
              }}
              sx={{
                display: 'flex',
              }}
            >
              <Typography
                sx={{
                  textAlign: 'center',
                  fontSize: '1rem',
                  fontWeight: COMMON_BOLD_WEIGHT,
                  lineHeight: '19.5px',
                }}
              >
                {t('preregTimeMsg')}
                <Tooltip
                  arrow
                  title={
                    <Typography sx={{ fontSize: '1rem' }}>
                      {t('preregTooltip')}
                    </Typography>
                  }
                  placement={isSmall ? 'bottom' : 'right'}
                  enterTouchDelay={0}
                  leaveTouchDelay={5000}
                >
                  <InfoIcon
                    data-testid="prereg-tooltip-icon"
                    sx={{
                      verticalAlign: 'middle',
                      cursor: 'pointer',
                      paddingLeft: '7px',
                      marginTop: '-5px',
                    }}
                    color="primary"
                    fontSize="small"
                  />
                </Tooltip>
              </Typography>
            </Box>
          )}

          {/* {selectedRegType === 'preregister' && <Grid item xs={1}></Grid>} */}
        </Grid>
        <Grid
          item
          sx={{
            padding: isSmall ? '5px' : '10px',
            marginRight: isSmall ? 'auto' : undefined,
          }}
        >
          <RegisterChip
            dataTestId="register-radio"
            label={t('chipRegisterNow')}
            selected={selectedRegType === 'register' ? true : false}
            onClick={() => {
              setSelectedRegType('register')
            }}
          />
        </Grid>
        <Grid
          item
          sx={{
            padding: isSmall ? '5px' : '10px',
            marginRight: isSmall ? 'auto' : undefined,
          }}
        >
          <RegisterChip
            dataTestId="preregister-radio"
            label={t('chipPreRegister')}
            selected={selectedRegType === 'preregister' ? true : false}
            onClick={() => {
              setSelectedRegType('preregister')
            }}
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        justifyContent={'center'}
        sx={{ paddingTop: '20px', paddingBottom: '10px' }}
      >
        <SButton
          data-testid="continue-button"
          sx={{ width: isSmall ? '290px' : '320px' }}
          onClick={() => {
            if (selectedRegType === 'register') {
              onContinueRegister()
            } else {
              onContinuePreRegister()
            }
          }}
        >
          {t('btnContinue')}
        </SButton>
      </Grid>
    </Grid>
  )
}

function RegisterChip({
  label,
  selected,
  onClick,
  dataTestId,
}: {
  label: string
  selected: boolean
  onClick: () => void
  dataTestId: string
}) {
  const { COLORS } = useSColors()
  //box with 100px raidus and main purple color and white text, if active highlighted

  return (
    <Box
      sx={{
        cursor: 'pointer',
        borderRadius: '100px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: selected
          ? COLORS.TEXT_LIGHT_PRIMARY_WHITE
          : COLORS.BACKGROUND_GREY_DARKER,
        border: '1px solid',
        // minWidth: '200px',
        padding: '0px 15px 0px 15px',
        height: '44px',
        maxHeight: '44px',
        backgroundColor: selected
          ? COLORS.PRIMARY_BRAND
          : COLORS.BACKGROUND_GREY_LIGHTER,
      }}
      data-testid={dataTestId}
      onClick={onClick}
    >
      <Radio
        sx={{
          padding: '0px',
          width: '12px',
          height: '12px',

          //if selected, background and color are white
          color: selected
            ? COLORS.TEXT_LIGHT_PRIMARY_WHITE
            : COLORS.BACKGROUND_GREY_DARKER,
          backgroundColor: selected
            ? COLORS.TEXT_LIGHT_PRIMARY_WHITE
            : 'transparent',
        }}
      />
      <Typography
        sx={{
          fontSize: '1rem',
          fontWeight: selected ? 600 : 400,
          paddingLeft: '10px',
        }}
      >
        {label}
      </Typography>
    </Box>
  )
}
