import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  useTheme,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { useEffect, useState } from 'react'
import { useSTranslate } from 'src/app/hooks/useSTranslate'
import { useMemberAppContext } from '../MemberAppContext'
import { GAService } from '../application/ga/GAService'
import { useNotifier } from '../hooks/useNotify'

import { checkMicroServiceResponseStatus } from 'src/app/apiexec/utils'
import { SDialog } from 'src/app/system/customcomponents/SDialog'
import { LOG } from 'src/app/v2/applog'
import { useSColors } from '../styles/scripta-theme'
import STypography from '../system/customcomponents/STypography'
import { SPACING } from '../system/theme2'
import { useSizes } from '../v2/useNewHooks'
import { DepsInviteCell } from './DepsInviteCell'
import {
  GetDependentsRespEks,
  InviteDependentData,
  useMemberService,
} from './useMemberService'
import {
  toDigitsOnly,
  toFullNumberFromDisplayFriendlyString,
} from './phone-field-utils'
import { EKS_CODE_NO_DEPENDENTS } from 'src/app/EKSStatuCodes'
import { EKSMemberProfile } from 'src/app/member/types'

export function MyDependentsManagementContainer({
  renderAsRegistrationDialogIfHasDeps = false,
  onDialogClose,
}: {
  renderAsRegistrationDialogIfHasDeps: boolean
  onDialogClose: () => void
}) {
  const { isMobile } = useSizes()
  const gaService = new GAService()
  const service = useMemberService()
  const [depsResponse, setDepsResponse] = useState<
    GetDependentsRespEks | undefined
  >()
  const [errorMsg, setErrorMsg] = useState<string | undefined>()

  const [isLoading, setIsLoading] = useState(false)
  const { currentMemberProfile } = useMemberAppContext()
  const { sendMsg } = useNotifier()
  const theme = useTheme()
  const { t } = useSTranslate('deps')

  const [isDialogVariantOpen, setIsDialogVariantOpen] = useState(false)

  async function loadDepsAsync(primaryMemberId: string | number) {
    setIsLoading(true)
    const s = await service.getDependends_EKS(primaryMemberId)

    const respCheck = checkMicroServiceResponseStatus(s)
    if (!respCheck.isError) {
      setIsLoading(false)
      setDepsResponse(s)
    } else {
      //if its 404 and scriptaErrorCode 614 - its a valid no deps respone
      if (
        s &&
        s.httpStatusCode === 404 &&
        (s as any).scriptaErrorCode === EKS_CODE_NO_DEPENDENTS
      ) {
        setIsLoading(false)
        setDepsResponse(undefined)
      } else {
        //otherwise its an error that we want to alert on
        LOG.error(
          'dependents',
          'Error response returned when fetching dependencts',
          s,
        )
        setIsLoading(false)
        setDepsResponse(undefined)
      }
    }
  }

  async function sendInvitesAsync(
    depId: number,
    email: string,
    phone?: string,
  ) {
    if (renderAsRegistrationDialogIfHasDeps) {
      gaService.trackEvent(gaService.eventMap.home_page_dependent_invite_sent)
    }
    //alwasy email first
    let emailSent = false

    let inviteData: InviteDependentData = {
      'notification-type': 'EMAIL',
      'member-id': currentMemberProfile!.id,
      'dependent-id': depId,
      email: email,
    }

    const resp = await service.inviteDependent_EKS(inviteData)

    let allowUndefinedResp = true
    const respCheck = checkMicroServiceResponseStatus(resp, allowUndefinedResp)

    if (!respCheck.isError) {
      sendMsg('Dependent invite sent!', 'success')
      emailSent = true
    } else {
      LOG.error('dependents', 'Error sending dependent invitation', resp)
      sendMsg(
        'Unable to send dependent email invitation at the moment. Please contact member support for assistance.',
        'error',
      )
    }

    //repeats the same process for phone
    if (phone) {
      delete inviteData['email']
      inviteData['notification-type'] = 'TEXT'
      //prepare for backend format
      inviteData['phone-number'] = toFullNumberFromDisplayFriendlyString(phone)

      const respPhone = await service.inviteDependent_EKS(inviteData)
      const respPhoneCheck = checkMicroServiceResponseStatus(
        respPhone,
        allowUndefinedResp,
      )

      if (!respPhoneCheck.isError) {
        //dont send UI notification  if we already send it via email
        if (!emailSent) {
          sendMsg('Dependent invite sent!', 'success')
        }
      } else {
        LOG.error(
          'dependents',
          'Error sending dependent text invitation',
          respPhone,
        )
        sendMsg(
          'Unable to send dependent text invitation at the moment. Please contact member support for assistance.',
          'error',
        )
      }
    }
  }

  useEffect(() => {
    if (currentMemberProfile) {
      setIsLoading(true)
      loadDepsAsync(currentMemberProfile.id)
    }
  }, [currentMemberProfile])

  if (renderAsRegistrationDialogIfHasDeps) {
    return (
      <DepsAsDialogWrapper
        isSmall={isMobile}
        currentMemberProfile={currentMemberProfile}
        depsResponse={depsResponse}
        sendInvitesAsync={sendInvitesAsync}
        onDialogClose={onDialogClose}
        isLoading={isLoading}
      />
    )
  }

  return (
    <DepsContent
      isLoading={isLoading}
      isMobileMode={isMobile}
      currentMemberProfile={currentMemberProfile}
      depsResponse={depsResponse}
      sendInvitesAsync={sendInvitesAsync}
    />
  )
}

function Disclaimer({ isMobileMode }: { isMobileMode: boolean }) {
  const { t } = useSTranslate('deps')

  return (
    <Box
      sx={{
        padding: isMobileMode ? '10px' : '16px',
        backgroundColor: '#F9F9F9',
        border: '1px solid #D4C5C7',
        borderRadius: '8px',
        marginTop: '20px',
      }}
    >
      <Typography
        sx={{
          fontSize: isMobileMode ? '12px' : '14px',
          fontWeight: 600,
        }}
      >
        {t('disclaimerTitle')}
      </Typography>
      <Typography
        sx={{
          fontSize: isMobileMode ? '12px' : '13px',
          fontWeight: 400,
          color: '#201F22',
          opacity: '0.7',
        }}
      >
        {t('disclaimer')}
      </Typography>
    </Box>
  )
}

function DepsContent({
  isLoading,
  isMobileMode,
  currentMemberProfile,
  depsResponse,
  sendInvitesAsync,
}: {
  isLoading: boolean
  isMobileMode: boolean
  currentMemberProfile: EKSMemberProfile | undefined
  depsResponse: GetDependentsRespEks | undefined
  sendInvitesAsync: any
}) {
  const { t } = useSTranslate('deps')
  if (!currentMemberProfile) {
    return null
  }
  const displayName = `${currentMemberProfile['first-name']} ${currentMemberProfile['last-name']}`

  return (
    <Grid container>
      <Grid container>
        <Grid item xs={12}>
          <STypography variant="body_regular">{t('description')}</STypography>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: SPACING._1 }}>
          <STypography variant="body_bold" sx={{ textTransform: 'capitalize' }}>
            {displayName.toLowerCase()}
          </STypography>
        </Grid>
        <Grid item xs={12}>
          <Typography fontSize={13}>Member</Typography>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '10px' }}>
          <Divider />
        </Grid>
        {isLoading ? (
          <Grid
            item
            container
            justifyContent="center"
            xs={12}
            sx={{ padding: '20px' }}
          >
            <Grid item xs={12} textAlign="center">
              <CircularProgress size={20} />
            </Grid>
            <Grid item xs={12} textAlign="center">
              <STypography variant="body_semibold">
                Please wait, searching for dependents...
              </STypography>
            </Grid>
          </Grid>
        ) : (
          <Grid container item xs={12} sx={{ marginTop: '10px' }}>
            {depsResponse?.dependents.map((dep, i) => {
              return (
                <Grid key={dep.id} item xs={12} sx={{ marginTop: SPACING._1 }}>
                  {i === 0 && (
                    <STypography
                      sx={{ marginBottom: SPACING._1 }}
                      variant="bodybig_bold"
                    >
                      Dependents
                    </STypography>
                  )}
                  <DepsInviteCell
                    key={dep.id}
                    dep={dep}
                    inviteDep={sendInvitesAsync}
                  />
                </Grid>
              )
            })}
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <Disclaimer isMobileMode={isMobileMode} />
      </Grid>
    </Grid>
  )
}

export const useDepsAsDialogStyles = makeStyles((theme: Theme) => {
  return createStyles({
    backDrop: {
      background: 'white',
    },
    dialogActions: {
      marginRight: '55px',
      marginBottom: '55px',
    },
    dialogPaper: {
      [theme.breakpoints.down(480)]: {
        margin: 14,
      },
    },
  })
})

function DepsAsDialogWrapper({
  isSmall,
  currentMemberProfile,
  depsResponse,
  sendInvitesAsync,
  onDialogClose,
  isLoading,
}: {
  isSmall: boolean
  currentMemberProfile: EKSMemberProfile | undefined
  depsResponse: GetDependentsRespEks | undefined
  sendInvitesAsync: any
  onDialogClose: () => void
  isLoading: boolean
}) {
  const { COLORS } = useSColors()
  const theme = useTheme()
  const classes = useDepsAsDialogStyles()
  const { t } = useSTranslate('deps')

  const [isOpen, setIsOpen] = useState(true)
  if (
    !depsResponse ||
    !depsResponse.dependents ||
    depsResponse.dependents.length === 0
  ) {
    return null
  }

  return (
    <SDialog
      title={t('title')}
      customTitleSx={{ textAlign: 'center', padding: '20px' }}
      open={true}
      // upperRightX={false}
      onClose={() => {
        onDialogClose()
      }}
      // customTitleSx={{ color: COLORS.TEXT_BLACK, paddingTop: '20px' }}
      customPaperSx={
        isSmall
          ? {
              width: '90%',
              background: 'linear-gradient(180deg, #CADFEF 0%, #FFF 100%)',
            }
          : {
              padding: '40px',
              background: 'linear-gradient(180deg, #CADFEF 0%, #FFF 100%)',
            }
      }
      singleActionProps={{
        text: 'Close',
        onClick: () => {
          onDialogClose()
        },
        buttonDataTestId: 'my-dependents-management-close-dialog-button',
        // buttonSx: {
        //   // width: '100px',
        // },
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <DepsContent
            isLoading={isLoading}
            isMobileMode={isSmall}
            currentMemberProfile={currentMemberProfile}
            depsResponse={depsResponse}
            sendInvitesAsync={sendInvitesAsync}
          />
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '20px' }}></Grid>
      </Grid>
    </SDialog>
  )
}
